import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import VueI18n from 'vue-i18n'
import dayjs from 'dayjs'
import { Formatters } from '@/shared/plugins/formatters'

export interface CancellationCondition {
  relative_fee: number
  period: number
  id?: string
}

export class CancellationPolicy extends ApiResource {
  static jsonApiType = 'cancellation-policies'
  @Attr([]) conditions: CancellationCondition[]
  @Attr(100) defaultFee: number
  @Attr() default_fee: number
  @Attr() createdAt: string
}

export function getCancellationForBooking(
  booking: Booking,
  i18n: VueI18n,
  formatters: Formatters
) {
  const amount = 0
  let text = ''

  if (
    booking.status === 'canceled' ||
    booking.status === 'rejected' ||
    booking.status === 'expired'
  ) {
    return {
      amount: 0,
      text: i18n.t('common.components.bookings.cancelBooking.bookingCancelled'),
    }
  }

  if (!booking.cancellationPolicy) {
    return {
      amount: 0,
      text: i18n.t(
        'common.components.bookings.cancelBooking.noCancellationPolicy'
      ),
    }
  }

  const conditions = booking.cancellationPolicy?.conditions ?? []
  let firstChargedPeriod = 0
  if (conditions) {
    firstChargedPeriod = Math.max(
      0,
      ...conditions.map((c: CancellationCondition) => c.period)
    )
  }

  // TODO: check if cancellation is still possible

  // no custom conditions
  if (!conditions || conditions.length === 0) {
    if (firstChargedPeriod === 0) {
      // free cancellation
      text = i18n.t(
        'common.components.cancellationPolicy.freeCancellation'
      ) as string
    } else if (booking.cancellationPolicy.defaultFee < 100) {
      text = i18n.t('common.components.cancellationPolicy.defaultRefund', {
        refund: formatters.percent(
          (100 - booking.cancellationPolicy.defaultFee) / 100
        ),
      }) as string
    } else if (booking.cancellationPolicy.defaultFee === 100) {
      text = i18n.t(
        'common.components.cancellationPolicy.noCancellation'
      ) as string
    }
  } else {
    // find right condition
    // const condition = conditions.find(
    text = 'based on condition'

    const a = 123

    //
    // find the latest condition that is applied based on booking start date
    let applyingCondition = null as CancellationCondition | null
    conditions.forEach((c: CancellationCondition) => {
      const cancellationDate = dayjs(booking.startDate).subtract(
        c.period,
        'minute'
      )

      // cancellation date has to be in the past and younger than current applying condition
      if (cancellationDate.isBefore(dayjs()) && !applyingCondition) {
        applyingCondition = c
      } else if (
        cancellationDate.isBefore(dayjs()) &&
        applyingCondition &&
        cancellationDate.isAfter(
          dayjs(booking.startDate).subtract(applyingCondition.period, 'minute')
        )
      ) {
        applyingCondition = c
      }
    })

    if (applyingCondition) {
      const refund = formatters.percent(
        (100 - applyingCondition.relative_fee) / 100
      )

      text = `You get a refund of ${refund}% for this booking.`
    }
  }

  return {
    amount,
    text,
  }
}
