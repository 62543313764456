import { render, staticRenderFns } from "./NavbarCartExpiry.vue?vue&type=template&id=ed5eff38&scoped=true"
import script from "./NavbarCartExpiry.vue?vue&type=script&lang=ts"
export * from "./NavbarCartExpiry.vue?vue&type=script&lang=ts"
import style0 from "./NavbarCartExpiry.vue?vue&type=style&index=0&id=ed5eff38&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5eff38",
  null
  
)

export default component.exports