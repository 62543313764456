
import Vue, { PropType } from 'vue'
import LoginButton from '@/bookingbuddy-shop/components/account/LoginButton.vue'

export interface CommunityAccessError {
  status: string
  code: string
  title: string
  detail: string
  meta: {
    communities: {
      id: string
      slug: string | null
      name: string | null
      allow_customer_requests: boolean
      allow_organization_requests: boolean
    }[]
  }
}

export default Vue.extend({
  name: 'CommunityAccessError',
  components: { LoginButton },
  props: {
    error: {
      type: Object as PropType<CommunityAccessError>,
      required: true,
    },
  },
  data() {
    return {
      loggingIn: false,
    }
  },
  computed: {
    redirectPath(): string {
      return this.$config.baseUrl + this.$route.path
    },
  },
  methods: {
    async handleLogin() {
      this.loggingIn = false
      await this.$router.push(this.$route.path)
    },
  },
})
