import { Context, Middleware, NuxtError } from '@nuxt/types'
import Bowser from 'bowser'

const DEFAULT_USER_AGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.181 Safari/537.36'

/**
 * Check if unsupported browser is used
 *
 * @param ctx
 */
export const browserSupportMiddleware: Middleware = (ctx: Context) => {
  // check only server-side
  let userAgent = DEFAULT_USER_AGENT
  if (ctx.req && ctx.req.headers) {
    userAgent = ctx.req.headers['user-agent'] as string
  } else if (typeof navigator !== 'undefined') {
    userAgent = navigator.userAgent
  }
  if (userAgent) {
    const bowser = Bowser.getParser(userAgent)
    if (bowser.isBrowser('Internet Explorer', true)) {
      return ctx.error({
        title: String(ctx.app.i18n.t('common.errors.unsupportedBrowser.title')),
        message: String(
          ctx.app.i18n.t('common.errors.unsupportedBrowser.message')
        ),
        statusCode: 400,
      } as NuxtError)
    }
  }
}
