<template>
  <v-select
    transition="eb-dropdown"
    :value="selectedLocale"
    :options="locales"
    :clearable="false"
    :searchable="false"
    :multiple="false"
    :get-option-key="getOptionKey"
    label="name"
    :input-id="inputId"
    class="eb-v-select transparent-bg hide-input collapse"
    :class="selectedPlace"
    @input="switchLocale"
  >
    <!-- selected option -->
    <template #selected-option="option">
      <label
        class="d-flex align-items-center m-0 text-uppercase"
        :for="inputId"
        style="cursor: pointer"
      >
        <FlagIcon
          v-show="showFlag"
          circular
          clickable
          :flag="option.flag"
          size="16"
          :class="{ 'me-2': showLocaleString }"
        />
        <span v-if="showLocaleString">
          {{ option.code }}
        </span>
      </label>
    </template>
    <!-- option -->
    <template #option="option">
      <span class="w-100 d-flex align-items-center flex-fill">
        <FlagIcon
          circular
          :flag="option.flag"
          size="16"
          class="me-2 flex-shrink-0"
        />
        <span>
          {{ option.name }}
        </span>
      </span>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import FlagIcon from '../../utils/FlagIcon'

export default {
  name: 'LocaleSelect',
  components: {
    FlagIcon,
    vSelect,
  },
  model: {
    prop: 'locale',
    event: 'input',
  },
  props: {
    /**
     * Custom options (optional)
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * Selected locale code
     */
    locale: {
      type: String,
      required: true,
    },
    showFlag: {
      type: Boolean,
      default: true,
    },
    placement: {
      type: String,
      default: 'bottom',
      validator: (val) => ['bottom', 'top'].includes(val),
    },
    showLocaleString: {
      type: Boolean,
      default: true,
    },
    direction: {
      type: String,
      default: 'right',
      validator: (val) => ['left', 'right'].includes(val),
    },
  },
  data() {
    return {
      inputId: 'v-select-input',
    }
  },
  computed: {
    /**
     * Default to i18n locales as options
     */
    locales() {
      if (this.options && this.options.length > 0) {
        return this.options
      } else {
        return this.$i18n.locales
      }
    },
    selectedLocale() {
      // find locale object, since $i18n.locale only holds code string
      return this.locales.find(({ code }) => this.locale === code)
    },
    selectedPlace() {
      return {
        'eb-v-select-top': this.placement === 'top',
        'dropdown-right': this.direction === 'right',
        'dropdown-left': this.direction === 'left',
      }
    },
  },
  mounted() {
    this.inputId = 'v-select-input-' + this._uid
  },
  methods: {
    /**
     * Emit input event for locale switch
     * @param locale
     */
    switchLocale(locale) {
      this.$emit('input', locale.code)
    },
    /**
     * Get option key
     * @param locale
     * @return {string}
     */
    getOptionKey(locale) {
      return locale.code
    },
  },
}
</script>

<style lang="scss"></style>
