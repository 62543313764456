import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'

export class Skill extends ApiResource {
  static jsonApiType = 'skills'

  @Attr('') name: string
  @Attr({}) nameI18n?: Record<string, string> | null
  @Attr('') iconUrl: string | null
  @Attr('') groupKey: string
  @Attr('') groupTitle: string
  @Attr('') groupIndex: string
}
