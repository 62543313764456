import VTooltip from 'v-tooltip'
import Vue from 'vue'

Vue.use(VTooltip, {
  defaultTemplate:
    '<div class="v-tooltip" role="tooltip"><div class="v-tooltip-arrow"></div><div class="v-tooltip-inner"></div></div>',
  defaultArrowSelector: '.v-tooltip-arrow',
  defaultInnerSelector: '.v-tooltip-inner',
  popover: {
    defaultBaseClass: 'v-tooltip v-popover',
    defaultInnerClass: 'v-popover-inner',
    defaultArrowClass: 'v-tooltip-arrow v-popover-arrow',
  },
})
