import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'

export class AddOnGroup extends ApiResource {
  static jsonApiType = 'add-on-groups'

  @Attr() name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  settings: Record<string, any>
}
