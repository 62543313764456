import Vue from 'vue'
import posthog, { PostHog } from 'posthog-js'
import { Context, Plugin } from '@nuxt/types'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $posthog?: PostHog
  }
  interface Context {
    $posthog?: PostHog
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $posthog?: PostHog
  }
}

/**
 * Posthog app analytics plugin
 *
 * @link https://posthog.com/docs/libraries/nuxt-js
 */
const posthogPlugin: Plugin = (ctx: Context, inject) => {
  if (process.server) {
    return
  }

  // check if enabled
  if (!ctx.$config.posthogEnabled || !ctx.$config.posthogApiKey) {
    return
  }

  // Init PostHog
  posthog.init(ctx.$config.posthogApiKey, {
    api_host: ctx.$config.posthogHost,
    capture_pageview: false, // track pageviews manually
    autocapture: ctx.$config.posthogAutoCapture,
    secure_cookie: true,
    disable_session_recording: !ctx.$config.posthogSessionRecording,
    disable_persistence: ctx.$config.posthogDisablePersistence, // disable cookies
    loaded: () => {
      if (!ctx.$authService) {
        return
      }
      const org = ctx.$authService._organization

      if (ctx.$config.internalAppName) {
        posthog.register_once({ app: ctx.$config.internalAppName })
      }

      // identify user
      if (ctx.$authService._user) {
        posthog.identify(ctx.$authService._user.cognitorId, {
          // track for cohort management
          activeOrganizationId: org?.id,
          activeIndustryId: org?.industry?.id,
          activePlanId: org?.mainSubscription?.plan?.id,
        })

        if (org) {
          // track organization group
          posthog.group('organization', org.id, {
            industryId: org.industry?.id,
            industryName: org.industry?.name,
            planId: org.mainSubscription?.plan?.id,
            createdAt: org.createdAt,
          })
          // track industry group
          if (org.industry) {
            posthog.group('industry', org.industry.id, {
              name: org.industry.name,
            })
          }
        }
      }
      // identify customer account
      if (ctx.$authService.account) {
        posthog.identify(ctx.$authService.account.cognitorId, {})
      }
    }, // If you can already identify your user
  })

  // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
  inject('posthog', posthog)

  if (ctx.$config.posthogCapturePageview) {
    // Make sure that pageviews are captured with each route change
    ctx.app.router!.afterEach((to) => {
      Vue.nextTick(() => {
        posthog.capture('$pageview', {
          $current_url: to.fullPath,
        })
      })
    })
  }
}

export default posthogPlugin

export function posthogIdUrl(url: string, posthog?: PostHog): string {
  if (!posthog) {
    return url
  }
  if (url.includes('?')) {
    return `${url}&ph=${posthog.get_distinct_id()}`
  }
  return `${url}?ph=${posthog.get_distinct_id()}`
}
