<template>
  <div
    class="eventbuddy-circular-flag"
    :class="{ clickable: clickable, circular: circular }"
    :style="{
      backgroundImage: `url(flags/${flag.toLowerCase()}.svg)`,
      '--size': size + 'px',
    }"
  >
    <div class="flag-overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'FlagIcon',
  props: {
    flag: {
      type: String,
      required: true,
    },
    size: {
      type: [String, Number],
      default: 32,
    },
    circular: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
.eventbuddy-circular-flag {
  display: inline-block;
  width: calc(var(--size) * 4 / 3);
  height: var(--size);
  background-position: center;
  background-size: cover;
  &.circular {
    --radius: calc(var(--size) / 2);
    border-radius: var(--radius);
    width: var(--size);
  }
  &.clickable {
    cursor: pointer;
    position: relative;
    overflow: hidden;
    .flag-overlay {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: opacity 0.05s;
      background-color: #000000;
    }
    &:hover {
      .flag-overlay {
        opacity: 0.1;
      }
    }
  }
}
</style>
