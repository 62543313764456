
import Vue from 'vue'
import Modal from '@/shared/components/modals/Modal.vue'
import { ResourceCollection } from '@anny.co/vue-jsonapi-orm'
import { Lens } from '@/shared/jsonapi-orm/bookingbuddy/Lens'
import OrmList from '@/shared/components/orm/OrmList.vue'
import LensPreview from '@/bookingbuddy-shop/components/lenses/LensPreview.vue'
import ResourceSearchPreview from '@/bookingbuddy-shop/components/search/ResourceSearchPreview.vue'
import IconWithBackground from '@/shared/components/utils/IconWithBackground.vue'

export default Vue.extend({
  name: 'ShopSearchButton',
  components: {
    ResourceSearchPreview,
    LensPreview,
    Modal,
    OrmList,
    IconWithBackground,
  },
  async beforeRouteLeave(from, to, next) {
    this.showSearchModal = false
    await this.$nextTick()
    next()
  },
  props: {},
  data() {
    return {
      showSearchModal: false,
      lensCollection: new ResourceCollection(
        Lens.api(this.$jsonApiService).where('featured', true)
      ),
    }
  },
  beforeDestroy() {
    this.lensCollection.$destruct()
  },
  methods: {
    openSearchModal() {
      this.showSearchModal = true
      try {
        this.lensCollection.requestItems()
        this.$shopService.likedResourceCollection?.requestItems()
        this.$shopService.recentResourceCollection?.requestItems()
      } catch (e) {
        // ignore
      }
    },
  },
})
