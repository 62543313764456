/**
 * Promisify timeout function
 *
 * @param seconds
 * @return {Promise<unknown>}
 */
export function timeout(seconds) {
  return new Promise((resolve, reject) => {
    if (!window) {
      reject()
    } else {
      window.setTimeout(resolve, seconds * 1000)
    }
  })
}
