import { Attr, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { CustomField } from './CustomField'
import { CustomFormModel } from '@/shared/jsonapi-orm/custom-fields/CustomFormModel'

export enum TargetModelType {
  ORDERS = 'orders',
  BOOKINGS = 'bookings',
  CUSTOMERS = 'customers',
  TESTS = 'tests',
}

export class CustomForm extends ApiResource {
  static jsonApiType = 'custom-forms'
  @Attr('') name: string
  @Attr('') description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr('') targetModelType: TargetModelType
  @HasMany() customFields: CustomField[]
  @HasMany() customFormModels: CustomFormModel[]
}
