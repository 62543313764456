export const bookingInclude: string[] = [
  'resource',
  'resource.coverImage',
  'resource.category',
  'resource.parent',
  'order.customer.address',
  'order.tmpInvoice.items',
  'order.lastPayment.method',
  'customer.address',
  'bookingAddOns.addOn',
  'subBookings.resource',
  'superBooking',
  'externalCalendar',
  'service',
  'modelIdentifiers',
  'testResults',
  'sequence',
  'bookingSeries',
  'seriesBookings',
  'seriesMaster',
  'host',
  'attendees',
  'files',
]
export const bookingFields: Record<string, string[]> = {
  'external-calendars': ['name'],
}
