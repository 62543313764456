
import Vue from 'vue'
// import PageFooter from '@/shared/components/partials/Footer.vue'
// import MiniFooter from '@/shared/components/partials/MiniFooter.vue'
import BaseLayout from '@/shared/layouts/BaseLayout.vue'
import Watcher from '../components/partials/Watcher.vue'
import PoweredBy from '@/shared/components/partials/PoweredBy.vue'
import NavbarCartExpiry from '../components/partials/NavbarCartExpiry.vue'
import NavbarCart from '../components/partials/NavbarCart.vue'
import { mapFields } from 'vuex-map-fields'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import ResizeSensor from 'css-element-queries/src/ResizeSensor'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import ContextNavigationItemComp from '@/bookingbuddy-shop/components/partials/ContextNavigationItem.vue'

const uxStoreFields = {
  contextNavigationItem: 'contextNavigationItem',
  isEmbedded: 'isEmbedded',
  backgroundImageUrl: 'backgroundImageUrl',
}

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    PoweredBy,
    ContextNavigationItemComp,
    Watcher,
    BaseLayout,
    // PageFooter,
    // MiniFooter,
    NavbarCart,
    NavbarCartExpiry,
  },
  middleware: ['browserSupport', 'preferredLocale', 'shouldLoginQuery'],
  data() {
    return {
      platform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
    }
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    customStyles(): string | null {
      const platformStyles = this.platform?.config?.styles ?? null
      const customStyles = this.$store.getters['ux/getCustomGlobalCss']
      if (!platformStyles && !customStyles) {
        return null
      }
      let css = ''
      if (platformStyles) {
        css += platformStyles
      }
      if (customStyles) {
        css += customStyles
      }
      return css
    },
  },
  async mounted() {
    this.initResizeSensor()
  },
  methods: {
    initResizeSensor() {
      // iframe v2 provides frame id with new event api
      if (this.$embedService) {
        // send initial height on load
        this.$embedService.sendResizeEvent(document.body.scrollHeight)
        window.addEventListener('load', () => {
          this.$embedService!.sendResizeEvent(document.body.scrollHeight)
        })
        // send new height after resize
        new ResizeSensor(document.body, () => {
          this.$embedService!.sendResizeEvent(document.body.scrollHeight)
        })
      }
      // iframe v1 only accepts resize event with integer payload
      else if (this.isEmbedded) {
        // send initial height on load
        window.addEventListener('load', function () {
          window.parent.postMessage(document.body.scrollHeight, '*')
        })
        // send new height after resize
        new ResizeSensor(document.body, () => {
          window.parent.postMessage(document.body.scrollHeight, '*')
        })
      }
    },
  },
})
