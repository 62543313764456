
import Vue, { PropOptions } from 'vue'
import ActionList from './ActionList.vue'
import { ActionDefinition } from '@/shared/types/DataTable'
import BaseDropdownPopper from '@/shared/components/popovers/BaseDropdownPopper.vue'

export default Vue.extend({
  name: 'ActionDropdown',
  components: { ActionList, BaseDropdownPopper },
  model: {
    prop: 'activeAction',
    event: 'change',
  },
  props: {
    actions: {
      type: Array,
      default: () => [],
    } as PropOptions<ActionDefinition[]>,
    closeOnSelection: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    activeAction: {
      type: Object,
      required: false,
      default: undefined,
    } as PropOptions<ActionDefinition | undefined>,
    argument: {
      type: Object,
      required: false,
      default: undefined,
    } as PropOptions<any>,
  },
  methods: {
    /**
     * Handle action click
     *
     * @param action DropdownAction
     * @param close {Function}
     */
    handleClick(action: ActionDefinition, close: () => void) {
      if (this.closeOnSelection) {
        close()
      }
      this.$emit('change', action)
      this.$emit('select', action)
    },
  },
})
