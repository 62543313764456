
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import Vue, { PropOptions } from 'vue'
import { UXState } from '@/shared/store/uxStore'
import ResizeObserver from 'resize-observer-polyfill'

const mapUxFields = { modals: 'modals' }

export default Vue.extend({
  name: 'Modal',
  props: {
    show: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    scrollable: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    fullWidth: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    applyPadding: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showCloseButton: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    appendToBody: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    /**
     * Show alert before closing modal
     */
    warnBeforeClose: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    isVideo: {
      type: Boolean,
      default: false,
    },
    triggerDismissedKey: {
      type: Boolean,
      default: false,
    },
    hideModal: {
      type: Function,
      default: null,
    },
    additionalZIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      modalId: 'modal_',
      resizeObserver: null as ResizeObserver | null,
    }
  },
  computed: {
    isEmbedded(): boolean {
      return this.$ux.isEmbedded
    },
    ...mapFields<typeof mapUxFields, UXState>('ux', mapUxFields),
    /**
     * Return number of active modals beneath the current modal
     * @return {number}
     */
    modalDepth(): number {
      return Math.max(
        this.modals.findIndex(({ id }) => id === this.modalId),
        0
      )
    },
    /**
     * Calculate zIndex
     */
    zIndex(): number {
      return 1000 + 100 * this.modalDepth + this.additionalZIndex
    },
    /**
     * Higher offset for upper modals
     */
    marginTop(): undefined | string {
      if (this.isEmbedded) {
        return `calc(5rem + ${this.modalDepth * 2}rem)`
      } else if (this.scrollable) {
        return `calc(15vh + ${this.modalDepth * 2}rem)`
      } else {
        return undefined
      }
    },
  },
  watch: {
    /**
     * Handle scroll locking
     * @link https://github.com/willmcpo/body-scroll-lock
     * @param newVal
     */
    show(newVal: boolean) {
      if (!newVal) {
        if (!this.isEmbedded) {
          enableBodyScroll(this.$refs.modal as Element)
        }
        this.$nextTick(() => {
          // remove resize oberserver before detaching modal from state
          this.removeResize()
          this.removeModal(this.modalId)
        })
      } else if (newVal) {
        // wait until dom updated
        this.$nextTick(() => {
          this.handleShow()
        })
      }
    },
  },
  mounted() {
    this.modalId += this._uid
    // append el to body
    if (this.appendToBody) {
      let baseLayout = document.getElementById('eb-base-layout-container')
      if (baseLayout) baseLayout.insertBefore(this.$el, baseLayout.firstChild)
    }
    if (this.show) {
      this.handleShow()
    }
  },
  beforeDestroy() {
    if (this.$refs.modal) {
      enableBodyScroll(this.$refs.modal as Element)
    }
    // remove modal from stack
    if (this.appendToBody) {
      this.$el.remove()
    }
    this.removeModal(this.modalId)
  },
  methods: {
    ...mapMutations('ux', ['pushModal', 'removeModal']),
    initResize() {
      if (this.$embedService) {
        this.updateScrollHeight()
        // send new height after resize
        this.resizeObserver = new ResizeObserver(this.updateScrollHeight)
        this.resizeObserver.observe(this.$refs.modal as HTMLElement)
      }
    },
    updateScrollHeight() {
      const modal = this.$refs.modal as HTMLElement | null
      const modalHeight = modal?.scrollHeight ?? 0 // handle nullable for closed modal
      this.$embedService!.sendResizeEvent(modalHeight, true)
    },
    removeResize() {
      if (this.$embedService) {
        // reset to doc height on last modal
        if (this.modalDepth === 0) {
          this.$embedService!.sendResizeEvent(document.body.scrollHeight, false)
        }
        this.resizeObserver?.disconnect()
        this.resizeObserver = null
      }
    },
    handleCancelAttempt(e: MouseEvent) {
      if (!e.isTrusted) return

      // This prevents to close the modal when user uses
      // the scrollbar
      if (e.offsetX > window.innerWidth - 30) {
        return
      }

      if (e.currentTarget === e.target) {
        e.stopPropagation()
        this.cancel()
      }
    },
    /**
     * Close via esc, click outside or close button
     */
    cancel() {
      if (this.warnBeforeClose) {
        this.$store.commit('ux/showAlert', {
          cancelText: this.$i18n.t('common.actions.cancel'),
          proceedText: this.$i18n.t('common.actions.close'),
          message: this.$i18n.t('common.components.alerts.closing.message'),
          cancelable: true,
          destructive: true,
          proceedAction: this.close,
        })
      } else {
        this.close()
      }
    },
    handleShow() {
      // push modal to stack
      this.pushModal({
        id: this.modalId,
        close: this.cancel,
      })
      // disable body scroll, do not use in embedded env
      if (!this.isEmbedded) {
        disableBodyScroll(this.$refs.modal as Element)
      } else if (this.$embedService) {
        // scroll to top of frame
        this.$embedService.scrollTo()
      }

      // init resize observe
      this.initResize()
    },
    /**
     * Close modal programmatically
     */
    close() {
      this.$emit('close')
      this.$emit('update:show', false)
      if (this.triggerDismissedKey) {
        this.hideModal()
      }
    },
  },
})
