import { RawLocation } from 'vue-router'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'

export interface FooterUrls {
  imprintUrl: string
  supportUrl: string
  termsAndConditionsUrl: string
  privacyPolicyUrl: string
  termsOfUseUrl: string
}

/**
 *
 * @param $config {NuxtRuntimeConfig}
 * @param localePath
 */
export function createFooterUrls(
  $config: NuxtRuntimeConfig | Record<string, any>,
  localePath: (route: RawLocation, locale?: string) => string
): FooterUrls {
  // Create urls
  // imprint
  let imprintUrl = ''
  if ($config.imprintUrl) {
    imprintUrl = $config.imprintUrl
  }

  // Support
  let supportUrl = ''
  if ($config.supportEnabled) {
    supportUrl =
      $config.supportUrl ??
      localePath({
        name: 'support',
      })
  }

  // terms
  let termsAndConditionsUrl = ''
  if ($config.termsAndConditionsUrl) {
    termsAndConditionsUrl = $config.termsAndConditionsUrl
  }

  // privacy policy
  let privacyPolicyUrl = ''
  if ($config.privacyPolicyUrl) {
    privacyPolicyUrl = $config.privacyPolicyUrl
  }

  // terms of use
  let termsOfUseUrl = ''
  if ($config.termsOfUseUrl) {
    termsOfUseUrl = $config.termsOfUseUrl
  }
  return {
    imprintUrl,
    supportUrl,
    termsAndConditionsUrl,
    privacyPolicyUrl,
    termsOfUseUrl,
  }
}
