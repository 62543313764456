import { initializeApp, getApps } from 'firebase/app'
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging'
import { Context, Plugin } from '@nuxt/types'
import { Messaging } from '@firebase/messaging'
import Vue from 'vue'
import { FcmToken } from '@/shared/jsonapi-orm/common/FcmToken'
import dayjs from 'dayjs'
import { get } from 'lodash'

declare module 'vue/types/vue' {
  interface Vue {
    $firebase: FirebaseService
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $firebase: FirebaseService
  }
  // nuxtContext.$customerAccount
  interface Context {
    $firebase: FirebaseService
  }
}

class FirebaseService {
  private messaging: Messaging | null = null
  private swRegistration: ServiceWorkerRegistration | null = null

  public initialized = false
  constructor(private ctx: Context) {}

  get hasToken() {
    return !!this.storageToken
  }
  get storageToken() {
    return localStorage.getItem(this.tokenStorageKey)
  }
  get tokenStorageKey() {
    return this.ctx.$config.storagePrefix + 'ft'
  }

  get tokenTimeStorageKey() {
    return this.ctx.$config.storagePrefix + 'ftt'
  }

  async requestPermission(): Promise<boolean> {
    // init firebase
    await this.init()
    // check if we can use it
    if (!this.messaging || !this.swRegistration) {
      return false
    }

    const permission = await Notification.requestPermission()

    if (permission !== 'granted') {
      return false
    }

    // show initial notification
    if (permission === 'granted') {
      new Notification(
        this.ctx.i18n.t(
          'common.components.utils.pushNotificationBanner.welcomeText'
        ) as string,
        {
          body: this.ctx.i18n.t(
            'common.components.utils.pushNotificationBanner.welcomeText'
          ) as string,
          icon: this.ctx.$config.baseUrl + '/icons/logo_color.png',
        }
      )
    }

    // retrieve token
    try {
      await this.retrieveToken()
    } catch (e) {
      return false
    }

    await this.initListener()

    return true
  }

  async retrieveToken() {
    if (!this.messaging) {
      return
    }
    const token = await getToken(this.messaging, {
      vapidKey: this.ctx.$config.firebaseVapidKey,
      serviceWorkerRegistration: this.swRegistration!,
    })

    // store token in local storage
    const fcmToken = new FcmToken(this.ctx.$jsonApiService, {
      token: token,
      platform: 'desktop',
    })
    await fcmToken.save()

    // store to local storage
    localStorage.setItem(this.tokenStorageKey, token)
    localStorage.setItem(this.tokenTimeStorageKey, dayjs().format())
  }

  async deleteToken(token: string) {
    try {
      await FcmToken.api(this.ctx.$jsonApiService).request(
        'destroy',
        'delete',
        {
          data: {
            token,
          },
        }
      )
    } catch (e) {
      // ignored
    }
  }

  async boot() {
    // check if local storage has t n and is not older then 1 week
    if (process.client) {
      if (this.hasToken) {
        // init if we already have a token
        await this.init()

        // check token lifetime
        const tokenTime = localStorage.getItem(this.tokenTimeStorageKey)
        if (
          !tokenTime ||
          dayjs(tokenTime).isBefore(dayjs().subtract(1, 'week'))
        ) {
          // delete old token
          await this.deleteToken(this.storageToken!)
          // retrieve new token
          await this.retrieveToken()
        }

        await this.initListener()
      }
    }
  }

  async init() {
    if (this.initialized) {
      return
    }

    const app = initializeApp(this.ctx.$config.firebaseConfig)
    this.messaging = getMessaging(app)
    let swRegistration: ServiceWorkerRegistration | null = null
    if (process.client) {
      let basePath = String(this.ctx.$config.basePath)
      if (basePath.endsWith('/')) {
        basePath = basePath.slice(0, -1)
      }
      swRegistration = await navigator.serviceWorker.register(
        basePath + '/firebase-messaging-sw.js',
        {
          scope: basePath + '/',
        }
      )

      this.swRegistration = swRegistration
    }

    this.initialized = true
  }

  initListener() {
    // register listener
    if (this.messaging) {
      onMessage(this.messaging, (msg) => {
        const notification = new Notification(msg.notification?.title ?? '', {
          body: msg.notification!.body ?? '',
          icon: this.ctx.$config.baseUrl + '/icons/logo_color.png',
          data: msg.data,
        })

        let basePath = String(this.ctx.$config.basePath)
        if (basePath.endsWith('/')) {
          basePath = basePath.slice(0, -1)
        }

        notification.onclick = (e) => {
          const target = e.target as Notification
          const defaultUrl = `${this.ctx.$config.baseUrl}${basePath}/account/planner`
          const link = get(target.data, 'link', defaultUrl)

          window.open(link, '_blank')
          target.close()
        }
      })
    }
  }
}

const plugin: Plugin = async function (ctx: Context, inject) {
  if (!ctx.$config.firebaseEnabled || !ctx.$config.firebaseConfig) {
    return
  }

  const service = new FirebaseService(ctx)
  if (ctx.$authService.account) {
    await service.boot()
  }

  // TODO add listener for login
  const observableService = Vue.observable(service)

  inject('firebase', observableService)
}

export default plugin
