import { Attr, JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'

export interface PlatformConfig {
  shortName: string
  name: string
  streetAddress: string
  zipCode: string
  city: string
  countryCode: string

  phone: string | null
  email: string | null
  supportEmail: string | null

  ceo: string | null
  vatId: string | null
  registerNumber: string | null
  localCourt: string | null
  courtAbbr: string | null

  homeUrl: string | null
  logoUrlLight: string | null
  logoUrlLight_png: string | null
  logoUrlDark: string | null
  logoUrlIcon: string | null

  shopBaseUrl: string
  adminBaseUrl: string
  panelsBaseUrl?: string
  bookingsApiBaseUrl: string
  iamBaseUrl: string

  // platform search styling
  claim?: string
  subtitle?: string
  searchBackgroundImage?: string
  svgFaviconUrl?: string
  useSearch?: boolean
  useLocationSearch?: boolean

  // defaults
  defaultIndustryId?: string | null

  requiresSubscription?: boolean

  // client only
  clientMessages: Record<string, Record<string, string>> | null
  styles: string | null
  [key: string]: any
}

export class Platform extends ApiResource {
  static jsonApiType = 'platforms'
  @Attr() name: string
  @Attr() domain: string
  @Attr() config: Partial<PlatformConfig>

  static async fromOrigin(
    origin: string,
    apiService: JsonApiService
  ): Promise<Platform | null> {
    const { data } = await new QueryBuilder(
      `${this.apiPath}/platform`,
      apiService.getClient(this),
      apiService
    ).request('', 'GET', {
      headers: {
        Origin: origin,
      },
    })
    // 1st level data from axios, 2nd level data from jsonapi
    if (data && data.data) {
      return Platform.resourceFromResponse(data, apiService).data
    } else {
      return null
    }
  }
}
