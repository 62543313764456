export const prefLocaleCookieName = 'pref_locale'

export const defaultLocale = 'de'

export const locales = [
  {
    name: 'Deutsch',
    code: 'de',
    iso: 'de-DE',
    file: 'de-DE.js',
    flag: 'de',
  },
  {
    name: 'Deutsch (Sie)',
    code: 'de-formal',
    iso: 'de-DE',
    file: 'de-DE-formal.js',
    flag: 'de',
  },
  {
    name: 'English',
    code: 'en',
    iso: 'en-GB',
    file: 'en-GB.js',
    flag: 'gb',
  },
  {
    name: 'English (US)',
    code: 'en-us',
    iso: 'en-US',
    file: 'en-US.js',
    flag: 'us',
  },
  {
    name: 'Українська',
    code: 'ua',
    iso: 'uk-UA',
    file: 'uk-UA.js',
    flag: 'ua',
  },
  {
    name: 'Русский',
    code: 'ru',
    iso: 'ru-RU',
    file: 'ru-RU.js',
    flag: 'ru',
  },
  {
    name: 'Nederlands',
    code: 'nl',
    iso: 'nl-NL',
    file: 'nl-NL.js',
    flag: 'nl',
  },
  {
    name: 'Français',
    code: 'fr',
    iso: 'fr-FR',
    file: 'fr-FR.js',
    flag: 'fr',
  },
  {
    name: 'Español',
    code: 'es',
    iso: 'es-ES',
    file: 'es-ES.js',
    flag: 'es',
  },
  {
    name: 'Português',
    code: 'pt',
    iso: 'pt-PT',
    file: 'pt-PT.js',
    flag: 'pt',
  },
  {
    name: 'Türkçe',
    code: 'tr',
    iso: 'tr-TR',
    file: 'tr-TR.js',
    flag: 'tr',
  },
  {
    name: 'Polski',
    code: 'pl',
    iso: 'pl-PL',
    file: 'pl-PL.js',
    flag: 'pl',
  },
  {
    name: 'Română',
    code: 'ro',
    iso: 'ro-RO',
    file: 'ro-RO.js',
    flag: 'ro',
  },
  {
    name: 'Čeština',
    code: 'cs',
    iso: 'cs-CZ',
    file: 'cs-CZ.js',
    flag: 'cz',
  },
  {
    name: 'Bokmål',
    code: 'nb',
    iso: 'nb-NO',
    file: 'nb-NO.js',
    flag: 'no',
  },
  {
    name: 'Italiano',
    code: 'it',
    iso: 'it-IT',
    file: 'it-IT.js',
    flag: 'it',
  },
  {
    name: 'Magyar',
    code: 'hu',
    iso: 'hu-HU',
    file: 'hu-HU.js',
    flag: 'hu',
  },
]

/**
 * Get formal alternative for locale.
 * @param activeLocale
 */
export function getFormalLocale(activeLocale: string) {
  let newLocale: string
  if (activeLocale.includes('-informal')) {
    newLocale = activeLocale.replace('-informal', '')
  } else {
    newLocale = `${activeLocale}-formal`
  }
  const availableLocales = locales.map((l) => l.code)
  if (newLocale !== activeLocale && availableLocales.includes(newLocale)) {
    return newLocale
  }
  return null
}

export function localeExists(localeCode: string) {
  return !!locales.find((locale) => {
    return locale.code === localeCode
  })
}
