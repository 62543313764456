import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { Skill } from '@/shared/jsonapi-orm/bookingbuddy/Skill'

export class AccountSkill extends ApiResource {
  static jsonApiType = 'account-skills'
  static isPivotResource = true

  @Attr(null) value: string | null
  @Attr(0) orderIndex: number
  @BelongsTo() property: Property
  @BelongsTo() skill: Skill
}
