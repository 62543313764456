import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import {
  Booking,
  BookingSeriesType,
  RecurringSettings,
} from '@/shared/jsonapi-orm/bookingbuddy/Booking'

export type BookingSeriesMeta = {
  total: number
  available: number
}

export class BookingSeries extends ApiResource {
  static jsonApiType = 'booking-series'
  @Attr() readableRule?: string
  @Attr() meta?: BookingSeriesMeta
  @Attr() seriesType: BookingSeriesType
  @Attr() recurringSettings?: RecurringSettings
  @Attr() isInfinite: boolean
  @BelongsTo() booking?: Booking
}
