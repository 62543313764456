export default function () {
  console.group('Neugierig? 🧐')

  console.log(
    'Wir lieben Neugierde, Wissensdurst und Eigeninitiative. Schau doch mal vorbei: https://anny.co/jobs'
  )

  console.log(
    'Dir sagen Nuxt.js, Vue.js, Typescript und Laravel etwas? Dann werde Teil unseres Entwicklerteams und genieße enorm guten Kaffee, Tee und Gin! ☕️, 🫖, 🍸'
  )

  console.groupEnd()
}
