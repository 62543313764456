export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":{"de-formal":["de"],"uk":["ru"],"default":["en"]}},
  vueI18nLoader: false,
  locales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js","flag":"de"},{"name":"Deutsch (Sie)","code":"de-formal","iso":"de-DE","file":"de-DE-formal.js","flag":"de"},{"name":"English","code":"en","iso":"en-GB","file":"en-GB.js","flag":"gb"},{"name":"English (US)","code":"en-us","iso":"en-US","file":"en-US.js","flag":"us"},{"name":"Українська","code":"ua","iso":"uk-UA","file":"uk-UA.js","flag":"ua"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru-RU.js","flag":"ru"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl-NL.js","flag":"nl"},{"name":"Français","code":"fr","iso":"fr-FR","file":"fr-FR.js","flag":"fr"},{"name":"Español","code":"es","iso":"es-ES","file":"es-ES.js","flag":"es"},{"name":"Português","code":"pt","iso":"pt-PT","file":"pt-PT.js","flag":"pt"},{"name":"Türkçe","code":"tr","iso":"tr-TR","file":"tr-TR.js","flag":"tr"},{"name":"Polski","code":"pl","iso":"pl-PL","file":"pl-PL.js","flag":"pl"},{"name":"Română","code":"ro","iso":"ro-RO","file":"ro-RO.js","flag":"ro"},{"name":"Čeština","code":"cs","iso":"cs-CZ","file":"cs-CZ.js","flag":"cz"},{"name":"Bokmål","code":"nb","iso":"nb-NO","file":"nb-NO.js","flag":"no"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it-IT.js","flag":"it"},{"name":"Magyar","code":"hu","iso":"hu-HU","file":"hu-HU.js","flag":"hu"}],
  defaultLocale: "de",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_and_default",
  lazy: {"skipNuxtState":true},
  langDir: "lang/",
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":true,"cookieDomain":null,"cookieKey":"_i18n_redirected","cookieSecure":true,"fallbackLocale":"","redirectOn":"no prefix","useCookie":true},
  differentDomains: false,
  baseUrl: "https://anny.co/b",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"name":"Deutsch","code":"de","iso":"de-DE","file":"de-DE.js","flag":"de"},{"name":"Deutsch (Sie)","code":"de-formal","iso":"de-DE","file":"de-DE-formal.js","flag":"de"},{"name":"English","code":"en","iso":"en-GB","file":"en-GB.js","flag":"gb"},{"name":"English (US)","code":"en-us","iso":"en-US","file":"en-US.js","flag":"us"},{"name":"Українська","code":"ua","iso":"uk-UA","file":"uk-UA.js","flag":"ua"},{"name":"Русский","code":"ru","iso":"ru-RU","file":"ru-RU.js","flag":"ru"},{"name":"Nederlands","code":"nl","iso":"nl-NL","file":"nl-NL.js","flag":"nl"},{"name":"Français","code":"fr","iso":"fr-FR","file":"fr-FR.js","flag":"fr"},{"name":"Español","code":"es","iso":"es-ES","file":"es-ES.js","flag":"es"},{"name":"Português","code":"pt","iso":"pt-PT","file":"pt-PT.js","flag":"pt"},{"name":"Türkçe","code":"tr","iso":"tr-TR","file":"tr-TR.js","flag":"tr"},{"name":"Polski","code":"pl","iso":"pl-PL","file":"pl-PL.js","flag":"pl"},{"name":"Română","code":"ro","iso":"ro-RO","file":"ro-RO.js","flag":"ro"},{"name":"Čeština","code":"cs","iso":"cs-CZ","file":"cs-CZ.js","flag":"cz"},{"name":"Bokmål","code":"nb","iso":"nb-NO","file":"nb-NO.js","flag":"no"},{"name":"Italiano","code":"it","iso":"it-IT","file":"it-IT.js","flag":"it"},{"name":"Magyar","code":"hu","iso":"hu-HU","file":"hu-HU.js","flag":"hu"}],
  localeCodes: ["de","de-formal","en","en-us","ua","ru","nl","fr","es","pt","tr","pl","ro","cs","nb","it","hu"],
  additionalMessages: [],
}
export const localeFiles = {
  0: "{",
  1: "\"",
  2: "d",
  3: "e",
  4: "-",
  5: "D",
  6: "E",
  7: ".",
  8: "j",
  9: "s",
  10: "\"",
  11: ":",
  12: "\"",
  13: ".",
  14: ".",
  15: "/",
  16: "l",
  17: "a",
  18: "n",
  19: "g",
  20: "/",
  21: "d",
  22: "e",
  23: "-",
  24: "D",
  25: "E",
  26: ".",
  27: "j",
  28: "s",
  29: "\"",
  30: ",",
  31: "\"",
  32: "d",
  33: "e",
  34: "-",
  35: "D",
  36: "E",
  37: "-",
  38: "f",
  39: "o",
  40: "r",
  41: "m",
  42: "a",
  43: "l",
  44: ".",
  45: "j",
  46: "s",
  47: "\"",
  48: ":",
  49: "\"",
  50: ".",
  51: ".",
  52: "/",
  53: "l",
  54: "a",
  55: "n",
  56: "g",
  57: "/",
  58: "d",
  59: "e",
  60: "-",
  61: "D",
  62: "E",
  63: "-",
  64: "f",
  65: "o",
  66: "r",
  67: "m",
  68: "a",
  69: "l",
  70: ".",
  71: "j",
  72: "s",
  73: "\"",
  74: ",",
  75: "\"",
  76: "e",
  77: "n",
  78: "-",
  79: "G",
  80: "B",
  81: ".",
  82: "j",
  83: "s",
  84: "\"",
  85: ":",
  86: "\"",
  87: ".",
  88: ".",
  89: "/",
  90: "l",
  91: "a",
  92: "n",
  93: "g",
  94: "/",
  95: "e",
  96: "n",
  97: "-",
  98: "G",
  99: "B",
  100: ".",
  101: "j",
  102: "s",
  103: "\"",
  104: ",",
  105: "\"",
  106: "e",
  107: "n",
  108: "-",
  109: "U",
  110: "S",
  111: ".",
  112: "j",
  113: "s",
  114: "\"",
  115: ":",
  116: "\"",
  117: ".",
  118: ".",
  119: "/",
  120: "l",
  121: "a",
  122: "n",
  123: "g",
  124: "/",
  125: "e",
  126: "n",
  127: "-",
  128: "U",
  129: "S",
  130: ".",
  131: "j",
  132: "s",
  133: "\"",
  134: ",",
  135: "\"",
  136: "u",
  137: "k",
  138: "-",
  139: "U",
  140: "A",
  141: ".",
  142: "j",
  143: "s",
  144: "\"",
  145: ":",
  146: "\"",
  147: ".",
  148: ".",
  149: "/",
  150: "l",
  151: "a",
  152: "n",
  153: "g",
  154: "/",
  155: "u",
  156: "k",
  157: "-",
  158: "U",
  159: "A",
  160: ".",
  161: "j",
  162: "s",
  163: "\"",
  164: ",",
  165: "\"",
  166: "r",
  167: "u",
  168: "-",
  169: "R",
  170: "U",
  171: ".",
  172: "j",
  173: "s",
  174: "\"",
  175: ":",
  176: "\"",
  177: ".",
  178: ".",
  179: "/",
  180: "l",
  181: "a",
  182: "n",
  183: "g",
  184: "/",
  185: "r",
  186: "u",
  187: "-",
  188: "R",
  189: "U",
  190: ".",
  191: "j",
  192: "s",
  193: "\"",
  194: ",",
  195: "\"",
  196: "n",
  197: "l",
  198: "-",
  199: "N",
  200: "L",
  201: ".",
  202: "j",
  203: "s",
  204: "\"",
  205: ":",
  206: "\"",
  207: ".",
  208: ".",
  209: "/",
  210: "l",
  211: "a",
  212: "n",
  213: "g",
  214: "/",
  215: "n",
  216: "l",
  217: "-",
  218: "N",
  219: "L",
  220: ".",
  221: "j",
  222: "s",
  223: "\"",
  224: ",",
  225: "\"",
  226: "f",
  227: "r",
  228: "-",
  229: "F",
  230: "R",
  231: ".",
  232: "j",
  233: "s",
  234: "\"",
  235: ":",
  236: "\"",
  237: ".",
  238: ".",
  239: "/",
  240: "l",
  241: "a",
  242: "n",
  243: "g",
  244: "/",
  245: "f",
  246: "r",
  247: "-",
  248: "F",
  249: "R",
  250: ".",
  251: "j",
  252: "s",
  253: "\"",
  254: ",",
  255: "\"",
  256: "e",
  257: "s",
  258: "-",
  259: "E",
  260: "S",
  261: ".",
  262: "j",
  263: "s",
  264: "\"",
  265: ":",
  266: "\"",
  267: ".",
  268: ".",
  269: "/",
  270: "l",
  271: "a",
  272: "n",
  273: "g",
  274: "/",
  275: "e",
  276: "s",
  277: "-",
  278: "E",
  279: "S",
  280: ".",
  281: "j",
  282: "s",
  283: "\"",
  284: ",",
  285: "\"",
  286: "p",
  287: "t",
  288: "-",
  289: "P",
  290: "T",
  291: ".",
  292: "j",
  293: "s",
  294: "\"",
  295: ":",
  296: "\"",
  297: ".",
  298: ".",
  299: "/",
  300: "l",
  301: "a",
  302: "n",
  303: "g",
  304: "/",
  305: "p",
  306: "t",
  307: "-",
  308: "P",
  309: "T",
  310: ".",
  311: "j",
  312: "s",
  313: "\"",
  314: ",",
  315: "\"",
  316: "t",
  317: "r",
  318: "-",
  319: "T",
  320: "R",
  321: ".",
  322: "j",
  323: "s",
  324: "\"",
  325: ":",
  326: "\"",
  327: ".",
  328: ".",
  329: "/",
  330: "l",
  331: "a",
  332: "n",
  333: "g",
  334: "/",
  335: "t",
  336: "r",
  337: "-",
  338: "T",
  339: "R",
  340: ".",
  341: "j",
  342: "s",
  343: "\"",
  344: ",",
  345: "\"",
  346: "p",
  347: "l",
  348: "-",
  349: "P",
  350: "L",
  351: ".",
  352: "j",
  353: "s",
  354: "\"",
  355: ":",
  356: "\"",
  357: ".",
  358: ".",
  359: "/",
  360: "l",
  361: "a",
  362: "n",
  363: "g",
  364: "/",
  365: "p",
  366: "l",
  367: "-",
  368: "P",
  369: "L",
  370: ".",
  371: "j",
  372: "s",
  373: "\"",
  374: ",",
  375: "\"",
  376: "r",
  377: "o",
  378: "-",
  379: "R",
  380: "O",
  381: ".",
  382: "j",
  383: "s",
  384: "\"",
  385: ":",
  386: "\"",
  387: ".",
  388: ".",
  389: "/",
  390: "l",
  391: "a",
  392: "n",
  393: "g",
  394: "/",
  395: "r",
  396: "o",
  397: "-",
  398: "R",
  399: "O",
  400: ".",
  401: "j",
  402: "s",
  403: "\"",
  404: ",",
  405: "\"",
  406: "c",
  407: "s",
  408: "-",
  409: "C",
  410: "Z",
  411: ".",
  412: "j",
  413: "s",
  414: "\"",
  415: ":",
  416: "\"",
  417: ".",
  418: ".",
  419: "/",
  420: "l",
  421: "a",
  422: "n",
  423: "g",
  424: "/",
  425: "c",
  426: "s",
  427: "-",
  428: "C",
  429: "Z",
  430: ".",
  431: "j",
  432: "s",
  433: "\"",
  434: ",",
  435: "\"",
  436: "n",
  437: "b",
  438: "-",
  439: "N",
  440: "O",
  441: ".",
  442: "j",
  443: "s",
  444: "\"",
  445: ":",
  446: "\"",
  447: ".",
  448: ".",
  449: "/",
  450: "l",
  451: "a",
  452: "n",
  453: "g",
  454: "/",
  455: "n",
  456: "b",
  457: "-",
  458: "N",
  459: "O",
  460: ".",
  461: "j",
  462: "s",
  463: "\"",
  464: ",",
  465: "\"",
  466: "i",
  467: "t",
  468: "-",
  469: "I",
  470: "T",
  471: ".",
  472: "j",
  473: "s",
  474: "\"",
  475: ":",
  476: "\"",
  477: ".",
  478: ".",
  479: "/",
  480: "l",
  481: "a",
  482: "n",
  483: "g",
  484: "/",
  485: "i",
  486: "t",
  487: "-",
  488: "I",
  489: "T",
  490: ".",
  491: "j",
  492: "s",
  493: "\"",
  494: ",",
  495: "\"",
  496: "h",
  497: "u",
  498: "-",
  499: "H",
  500: "U",
  501: ".",
  502: "j",
  503: "s",
  504: "\"",
  505: ":",
  506: "\"",
  507: ".",
  508: ".",
  509: "/",
  510: "l",
  511: "a",
  512: "n",
  513: "g",
  514: "/",
  515: "h",
  516: "u",
  517: "-",
  518: "H",
  519: "U",
  520: ".",
  521: "j",
  522: "s",
  523: "\"",
  524: "}",
}

export const localeMessages = {
  'de-DE.js': () => import('../../lang/de-DE.js' /* webpackChunkName: "lang-de-DE.js" */),
  'de-DE-formal.js': () => import('../../lang/de-DE-formal.js' /* webpackChunkName: "lang-de-DE-formal.js" */),
  'en-GB.js': () => import('../../lang/en-GB.js' /* webpackChunkName: "lang-en-GB.js" */),
  'en-US.js': () => import('../../lang/en-US.js' /* webpackChunkName: "lang-en-US.js" */),
  'uk-UA.js': () => import('../../lang/uk-UA.js' /* webpackChunkName: "lang-uk-UA.js" */),
  'ru-RU.js': () => import('../../lang/ru-RU.js' /* webpackChunkName: "lang-ru-RU.js" */),
  'nl-NL.js': () => import('../../lang/nl-NL.js' /* webpackChunkName: "lang-nl-NL.js" */),
  'fr-FR.js': () => import('../../lang/fr-FR.js' /* webpackChunkName: "lang-fr-FR.js" */),
  'es-ES.js': () => import('../../lang/es-ES.js' /* webpackChunkName: "lang-es-ES.js" */),
  'pt-PT.js': () => import('../../lang/pt-PT.js' /* webpackChunkName: "lang-pt-PT.js" */),
  'tr-TR.js': () => import('../../lang/tr-TR.js' /* webpackChunkName: "lang-tr-TR.js" */),
  'pl-PL.js': () => import('../../lang/pl-PL.js' /* webpackChunkName: "lang-pl-PL.js" */),
  'ro-RO.js': () => import('../../lang/ro-RO.js' /* webpackChunkName: "lang-ro-RO.js" */),
  'cs-CZ.js': () => import('../../lang/cs-CZ.js' /* webpackChunkName: "lang-cs-CZ.js" */),
  'nb-NO.js': () => import('../../lang/nb-NO.js' /* webpackChunkName: "lang-nb-NO.js" */),
  'it-IT.js': () => import('../../lang/it-IT.js' /* webpackChunkName: "lang-it-IT.js" */),
  'hu-HU.js': () => import('../../lang/hu-HU.js' /* webpackChunkName: "lang-hu-HU.js" */),
}
