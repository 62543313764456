import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { CancellationPolicy } from '@/shared/jsonapi-orm/bookingbuddy/CancellationPolicy'
import { Service, ServiceUnit } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { AddOn } from '@/shared/jsonapi-orm/bookingbuddy/AddOn'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'

export class ServiceConfiguration extends ApiResource {
  static jsonApiType = 'service-configurations'
  @Attr() unit: ServiceUnit
  @Attr() servicesWithQuantity: {
    quantity: number
    service: { name: string; id: number; slug: string }
  }[]
  @Attr() calculationInterval: number
  @Attr() unchargedDuration: number
  @Attr() minDuration: number
  @Attr() maxDuration: number
  @Attr() advanceBookingPeriod: number
  @Attr() leadTime: number
  @Attr() hasFlexibleDuration: boolean
  @Attr() requiresBookingDescription: boolean
  @Attr() allowCustomerNotes: boolean
  @Attr() allowMoreBookings: boolean
  @Attr() anonymousEmail: boolean
  @Attr() paymentFlow: string
  @Attr() priceHidden: boolean
  @Attr() currency: string
  @Attr() taxRate: number
  @Attr() isNetPrice: boolean
  @Attr() isFree: boolean
  @Attr() isOnline: boolean
  @Attr() defaultWeight: number
  @Attr() createBookingSequence: boolean
  @Attr() defaultStartTime: string
  @Attr() defaultEndTime: string
  @Attr() allowRecurring: boolean
  @Attr() recurringFrequencies: any
  @Attr() recurringMaxDays: number
  @BelongsTo() cancellationPolicy: CancellationPolicy | null
  @BelongsTo() mainService: Service

  @HasMany() addOns: AddOn[]
  @HasMany() subResources: Resource[]
}

export interface ServiceConfigurationMeta {
  next_available_date: string | null
}
