
import Vue from 'vue'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { timeout } from '@/shared/utils/timeout'

export default Vue.extend({
  name: 'NavbarCart',
  data() {
    return {
      animated: false,
    }
  },
  computed: {
    currentOrder(): Order | null {
      return this.$shopService.activeOrder
    },
    countBookings(): number {
      if (this.currentOrder && this.currentOrder.bookings) {
        return this.currentOrder.bookings.length
      }

      return 0
    },
    cartPath(): string {
      return this.localePath({
        name: 'checkout',
        query: {
          ...this.$route.query,
        },
      })
    },
  },
  watch: {
    async countBookings(newValue: number, oldValue: number) {
      if (newValue > oldValue) {
        this.animated = true
        await timeout(0.5)
        this.animated = false
      }
    },
  },
})
