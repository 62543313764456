
import Vue, { PropOptions } from 'vue'
import { ActionContext, ActionDefinition } from '@/shared/types/DataTable'

export default Vue.extend({
  name: 'ActionList',
  props: {
    /**
     * A dropdown action
     */
    actions: {
      type: Array,
      default: () => [],
    } as PropOptions<ActionDefinition[]>,
    argument: {
      type: Object,
      required: false,
      default: undefined,
    } as PropOptions<any>,
    horizontal: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    hideLabel: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    buttons: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    /**
     * When this is active the handle of every action should return a link.
     * This link is then used as the action with an <a> tag
     */
    asLinks: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    /**
     * Filter visible actions
     */
    visibleActions() {
      return this.actions.filter((action) => {
        if (typeof action.hidden === 'function') {
          return !action.hidden(this.argument, this.actionContext)
        } else {
          return !action.hidden
        }
      })
    },
    actionContext(): ActionContext {
      return {
        store: this.$store,
        router: this.$router,
        localePath: this.localePath,
        i18n: this.$i18n,
        uxService: this.$ux,
        linkService: this.$linkService,
        authService: this.$authService,
        jsonApiService: this.$jsonApiService,
        clients: this.$clients,
      }
    },
  },
  methods: {
    /**
     * Handle action click
     *
     * @param action DropdownAction
     */
    async handleClick(action: ActionDefinition) {
      this.$emit('select', action)
      const actionName = action.i18n ? this.$t(action.name) : action.name
      // confirm action with alert
      if (action.confirm) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const vm = this
        this.$store.commit('ux/showAlert', {
          cancelText: this.$t('common.actions.cancel'),
          proceedText: actionName,
          message: this.$t(
            'common.components.alerts.actionConfirmation.message',
            {
              action: actionName,
            }
          ),
          cancelable: true,
          destructive: action.destructive,
          proceedAction: async function () {
            await action.handler(vm.argument, vm.actionContext)
            if (action.callback) {
              await action.callback(vm.argument)
            }
            // eslint-disable-next-line vue/custom-event-name-casing
            vm.$emit('actionCompleted', action)
          },
        })
      } else {
        await action.handler(this.argument, this.actionContext)
        if (action.callback) {
          await action.callback(this.argument)
        }
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('actionCompleted', action)
      }
    },
  },
})
