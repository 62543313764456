import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { LegalDocumentLink } from '@/shared/jsonapi-orm/bookingbuddy/LegalDocumentLink'

export enum LegalDocumentType {
  TERMS = 'terms_and_conditions',
  PRIVACY = 'privacy_policy',
  CANCELLATION = 'cancellation_policy',
  CUSTOM = 'custom',
}

export class LegalDocument extends ApiResource {
  static jsonApiType = 'legal-documents'
  @Attr() name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr() content: string | null
  @Attr({}) contentI18n?: Record<string, string> | null
  @Attr() documentType: LegalDocumentType
  @Attr() requestSignature: boolean

  @BelongsTo() organization: Organization
  @HasMany() bookings?: Booking
  @HasMany() legalDocumentLinks?: LegalDocumentLink[]
}
