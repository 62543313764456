
import BuddyCheckbox from '@/shared/components/input/select/BuddyCheckbox.vue'
import Vue, { PropOptions } from 'vue'
import { CookieSettings } from '@/shared/store/uxStore'
import { bootstrap as loadGtag } from 'vue-gtag'

const storageKey = 'eb-cookie-settings'

export default Vue.extend({
  name: 'CookieConsent',
  components: { BuddyCheckbox },
  props: {
    cookies: {
      type: Array,
      required: false,
      default: undefined,
    } as PropOptions,
  },
  data() {
    let cookieOptions = this.cookies
      ? [...this.cookies]
      : [
          {
            name: this.$i18n.t(
              'common.components.utils.cookieConsent.cookieOptions.required'
            ),
            key: 'required',
            mandatory: true,
          },
        ]

    if (this.$config.gaEnabled) {
      cookieOptions.push({
        name: this.$i18n.t(
          'common.components.utils.cookieConsent.cookieOptions.analytics'
        ),
        key: 'analytics',
        mandatory: false,
      })
    }

    return {
      cookieOptions,
      acceptedCookies: [] as string[],
      showCookieConsent: false,
    }
  },
  computed: {
    cookieSettings(): CookieSettings {
      return this.$store.getters['ux/getField']('cookieSettings')
    },
  },
  mounted() {
    // watch changes of cookieSettings after mount
    this.$watch('cookieSettings', this.handleCookieSetting, { deep: true })
    // restore settings from local storage
    if ('localStorage' in window) {
      let settingStr = window.localStorage.getItem(storageKey)
      if (settingStr) {
        let acceptedCookies = JSON.parse(settingStr)
        if (Array.isArray(acceptedCookies) && acceptedCookies.length > 0) {
          this.$store.commit('ux/acceptCookies', {
            accepted: true,
            gtag: acceptedCookies.includes('analytics'),
            options: acceptedCookies,
          } as CookieSettings)
        }
        return
      }
    }
    // check if we have only necessary cookies
    if (
      this.cookieOptions.length === 1 &&
      this.cookieOptions[0].key === 'required'
    ) {
      // auto-accept
      this.acceptNecessary()
    } else {
      this.showCookieConsent = true
    }
  },
  methods: {
    /**
     * Accept all cookies
     */
    acceptAll() {
      // only acctept gtag when it is enabled
      let gtag = false
      if (this.$config.gaEnabled) {
        gtag = true
      }

      this.$store.commit('ux/acceptCookies', {
        accepted: true,
        gtag,
        options: this.cookieOptions.map((option) => option.key),
      } as CookieSettings)
      this.saveCookies()
      this.showCookieConsent = false
    },
    /**
     * Accept required
     */
    acceptNecessary() {
      this.$store.commit('ux/acceptCookies', {
        accepted: true,
        gtag: false,
        options: ['required'],
      } as CookieSettings)
      this.saveCookies()
      this.showCookieConsent = false
    },
    /**
     * Accept settings
     */
    acceptSettings() {
      this.$store.commit('ux/acceptCookies', {
        accepted: true,
        gtag: this.acceptedCookies.includes('analytics'),
        options: this.acceptedCookies,
      } as CookieSettings)
      this.saveCookies()
      this.showCookieConsent = false
    },
    /**
     * Set cookie accepted
     */
    saveCookies() {
      if ('localStorage' in window) {
        window.localStorage.setItem(
          storageKey,
          JSON.stringify(this.cookieSettings.options)
        )
      }
    },
    /**
     * Select/toggle cookie option
     * @param cookie
     */
    toggleCookie(cookie: string) {
      let index = this.acceptedCookies.indexOf(cookie)
      if (index > -1) {
        this.acceptedCookies.splice(index, 1)
      } else {
        this.acceptedCookies.splice(this.acceptedCookies.length, 0, cookie)
      }
    },
    handleCookieSetting({
      accepted,
      gtag,
    }: {
      accepted: boolean
      gtag: boolean
    }) {
      let isProd = !this.$nuxt.context.isDev
      // Load gtag
      if (this.$gtag && this.$config.gaEnabled) {
        if (accepted && gtag && isProd) {
          const gtag = this.$gtag
          loadGtag().then(() => {
            gtag.optIn()
          })
        } else {
          this.$gtag.optOut()
        }
      }
    },
  },
})
