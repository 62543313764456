var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.away),expression:"away"}],key:_vm.dropdownId,staticClass:"eb-dropdown",class:{ 'is-active': _vm.isActive },attrs:{"id":_vm.dropdownId + '_container'}},[_c('div',{staticClass:"eb-dropdown-trigger",style:(_vm.triggerStyle),on:{"click":function($event){$event.preventDefault();return _vm.toggleDropdown(true)},"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_vm._t("trigger",function(){return [_c('BaseButton',{attrs:{"label":_vm.label,"aria-controls":_vm.dropdownId,"aria-haspopup":"true"}})]},{"toggle":_vm.toggleDropdown,"isActive":_vm.isActive,"isOpen":_vm.isActive})],2),_vm._v(" "),_c('transition',{attrs:{"name":"eb-dropdown"}},[(!_vm.openAsModal)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"eb-dropdown-menu",class:{
        'mt-2': _vm.marginTop,
        center: _vm.align === 'center',
        left: _vm.align === 'left',
        right: _vm.align === 'right',
        top: _vm.align === 'top',
        bottom: _vm.align === 'bottom',
        'bottom-left': _vm.align === 'bottom-left',
        'top-left': _vm.align === 'top-left',
        'fixed-width': _vm.fixedWidth,
      },attrs:{"id":_vm.dropdownId,"role":"menu"}},[_c('div',{staticClass:"eb-dropdown-content",on:{"mouseenter":_vm.handleMouseEnter,"mouseleave":_vm.handleMouseLeave}},[_vm._t("default",null,{"isActive":_vm.isActive,"toggle":_vm.toggleDropdown,"close":_vm.closeDropdown})],2)]):_vm._e()]),_vm._v(" "),(_vm.openAsModal)?_c('modal',{attrs:{"show":_vm.isActive,"apply-padding":false,"append-to-body":"","show-close-button":"","scrollable":""},on:{"close":_vm.closeDropdown}},[_c('div',{staticClass:"eb-stretched-modal fixed"},[_vm._t("default",null,{"toggle":_vm.toggleDropdown,"close":_vm.closeDropdown})],2)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }