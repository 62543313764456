import { ApiResource } from '../ApiResource'
import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { Booking } from './Booking'

export class Reminder extends ApiResource {
  static jsonApiType = 'reminders'

  static defaultOffset = 60

  @Attr() offset: number
  @Attr('default') notificationType: string
  @Attr('start_date') remindableAttribute: string
  @BelongsTo() remindable: Booking
}
