import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { OrganizationUser } from '@/shared/jsonapi-orm/bookingbuddy/OrganizationUser'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import {
  Integration,
  IntegrationKey,
} from '@/shared/jsonapi-orm/bookingbuddy/Integration'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { Industry } from '@/shared/jsonapi-orm/bookingbuddy/Industry'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { LegalDocument } from '@/shared/jsonapi-orm/common/LegalDocument'
import { BankAccount } from '@/shared/jsonapi-orm/common/BankAccount'
import { CustomField } from '@/shared/jsonapi-orm/custom-fields/CustomField'
import { UserInvite } from '@/shared/jsonapi-orm/bookingbuddy/UserInvite'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { PlanSubscription } from '@/shared/jsonapi-orm/subscriptions/PlanSubscription'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'
import { PaymentAccount } from '@/shared/jsonapi-orm/payments/PaymentAccount'
import { PaymentMethod } from '@/shared/jsonapi-orm/payments/PaymentMethod'
import { RawLocation } from 'vue-router'
import { ResourceMap } from '@/shared/jsonapi-orm/bookingbuddy/ResourceMap'
import { ServiceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ServiceGroup'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { Plan } from '@/shared/jsonapi-orm/subscriptions/Plan'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { CustomFormModel } from '@/shared/jsonapi-orm/custom-fields/CustomFormModel'
import { CustomForm } from '@/shared/jsonapi-orm/custom-fields/CustomForm'
import { BookingBundle } from '@/shared/jsonapi-orm/booking-quotas/BookingBundle'
import { BookingQuota } from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'

export enum AdditionalFieldEnum {
  TITLE = 'title',
  GIVEN_NAME = 'given_name',
  FAMILY_NAME = 'family_name',
  SEX = 'sex',
  BIRTH_DATE = 'birth_date',
  COMPANY = 'company',
  MOBILE = 'mobile',
  PHONE = 'phone',
}

export interface OrganizationSettings {
  organizeResourcesBy: 'category' | 'group'
  labelFormat?: string
  maxBookings: {
    perSlot: number
    perDay: number
    perWeek: number
    perMonth: number
  }
  resources: {
    maxBookings: {
      perDay: number
      perWeek: number
      perMonth: number
    }
  }
  listServices: boolean
  listResources: boolean
  listPlans: boolean
  listBookingBundles: boolean
  defaultList: 'services' | 'resources' | 'plans'
  cancellation: { allowedPeriod: number }
  checkout: {
    additionalFields: AdditionalFieldEnum[]
    additionalRequiredFields: AdditionalFieldEnum[]
  }
  experimentalFeatures: Record<string, any>
  customDeletionsPeriods?: {
    isEnabled: boolean
    deleteBookings: boolean
    periodInWeeks: {
      freeBookings: number
      bookingsWithoutPayment: number
      bookingsWithPayment: number
    }
  }
  mobileNotifications: {
    isEnabled: boolean
    testResultPublished: boolean
    bookingConfirmed: boolean
    bookingUpdated: boolean
    viaSMS: boolean
    viaWhatsapp: boolean
  }
  invoicing: {
    defaultDuePeriod: number
    enableBankTransfer: boolean
    enableOnlinePayment: boolean
  }
}

export class Organization extends ApiResource {
  static jsonApiType = 'organizations'

  @Attr() slug: string
  @Attr() externalId: string
  @Attr() hasCognitorTenant: boolean
  @Attr() name: string
  @Attr() officialName: string | null
  @Attr() description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr() plainDescription: string | null
  @Attr() supportEmail: string | null
  @Attr() phone: string | null
  @Attr() timezone: string | null
  @Attr('DE') countryCode: string
  @Attr(true) isOrganization: boolean
  @Attr() responsibleForContents: string | null
  @Attr() ceo: string | null
  @Attr() vatId: string | null
  @Attr() registerNumber: string | null
  @Attr() localCourt: string | null
  @Attr() financialOffice: string | null
  @Attr(false) live: boolean
  @Attr(false) setupCompleted?: boolean
  @Attr() previewToken?: string
  @Attr() companyType: string | undefined
  @Attr() businessType: string | null
  @Attr() businessSize: string | null
  @Attr() useCase: string

  // TOS
  @Attr() acceptedDocuments: string[]

  @Meta() settings: OrganizationSettings
  @Meta() metaSettings: Partial<OrganizationSettings>
  @Meta() previewUrl?: string
  @Meta() activeIntegrationKeys: IntegrationKey[]
  @Meta() hasBookingQuotas: boolean
  @Meta() hasBookingBundles: boolean
  // crm id
  @Meta() pId?: string | number | null
  @Meta() pdId?: string | number | null
  @Meta() annyShopCoupon?: string | null
  @Meta() annyShopCouponAmount?: number | null
  @Meta() platformId: string | null
  @Meta() hideUnavailableDays: boolean

  // admin only
  @Attr() createdAt?: string
  @Attr() usesPayments?: boolean
  @Attr() automatedInvoicing: boolean
  @Attr('EUR') currency?: string
  @Attr() requiresSubscription?: boolean
  @Attr() testingPeriodEndDate?: string | null
  @Attr() emailInfo?: string | null
  @Attr({}) emailInfoI18n?: Record<string, string> | null
  @Attr() billingAddress?: string | null
  @Attr() billingEmail?: string | null
  @Attr() defaultLocale?: string | null
  @Attr() features?: string[] | null
  @Attr() featuresEditable?: boolean
  @Attr() systemFeeAbsolute?: number
  @Attr() systemFeeRelative?: number
  @Attr() customStyles?: Record<string, string> | null

  @Attr(false) viewingRequiresCommunity?: boolean
  @Attr(false) bookingRequiresCommunity?: boolean

  @BelongsTo() industry?: Industry
  @BelongsTo() address?: Address // DEPRECATED
  @BelongsTo() location?: Address | null
  @HasMany() addresses?: Address[]
  @HasMany() resources?: Resource[]
  @HasMany() offeredPlans?: Plan[]
  @BelongsTo() coverImage?: Image | null
  @BelongsTo() logoImage?: Image | null
  @BelongsTo() negativeLogoImage?: Image | null
  @HasMany() legalDocuments?: LegalDocument[]
  @HasMany() resourceGroups?: ResourceGroup[]
  @HasMany() serviceGroups?: ServiceGroup[]
  @HasMany() organizationUsers?: OrganizationUser[]
  @HasMany() userInvites?: UserInvite[]
  @HasMany() bankAccounts?: BankAccount[]
  @HasMany() integrations?: Integration[]
  @HasMany() customFormModels?: CustomFormModel[]
  @HasMany() checkoutFields?: CustomField[]
  @HasOne() customerCustomForm?: CustomForm | null
  @HasOne() orderCustomForm?: CustomForm | null
  @HasMany() properties?: Property[]
  @HasMany() usedCategories?: ResourceCategory[]
  @HasMany() resourceMaps?: ResourceMap[]
  @HasMany() ownedCommunities: Community[]

  // lazy loadable relations:
  @BelongsTo() mainSubscription?: PlanSubscription | null
  @HasMany() subscriptionInvoices?: Invoice[]
  @HasMany() paymentMethods?: PaymentMethod[]
  @HasMany() ownedInvoices?: any
  @HasOne() paymentAccount?: PaymentAccount | null

  @HasMany() offeredBookingBundles?: BookingBundle[]
  @HasMany() bookingQuotas?: BookingQuota[]

  async validateSlug(): Promise<boolean> {
    try {
      const response = await this.axiosClient.get(
        `organizations/validate-slug`,
        {
          params: {
            slug: this.slug,
          },
        }
      )
      return response.data.is_available as boolean
    } catch (e) {
      console.log(e)
      return false
    }
  }

  async createTenant() {
    try {
      const response = await this.api().request('create-tenant', 'POST', {
        data: {},
      })

      Organization.resourceFromResponse(response.data, this.apiService)

      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }

  /**
   * Check if organization can use feature.
   * @param featureSlug
   */
  canUseFeature(featureSlug: string): boolean {
    if (!this.requiresSubscription) {
      return true
    } else {
      return this.mainSubscription?.canUse(featureSlug) ?? false
    }
  }

  /**
   * Check if feature is enabled to be displayed.
   * @param featureSlug
   */
  featureEnabled(featureSlug: string): boolean {
    if (!this.features) {
      return true
    }
    return this.features.includes(featureSlug)
  }

  /**
   *
   */
  getShopLocalPathObject(): RawLocation {
    return {
      name: 'organizationSlug',
      params: { organizationSlug: this.slug! },
    }
  }

  /**
   * Check if there is an active integration
   * @param key
   */
  hasActiveIntegration(key: IntegrationKey): boolean {
    return !!this.activeIntegrationKeys?.includes(key)
  }
}
