import { Context, Plugin } from '@nuxt/types'
import {
  CommunityAccountSettingsManager,
  SettingsManager,
} from '@/shared/services/SettingsManager'
import {
  createDefaultClient,
  getApiBaseUrl,
} from '@/shared/services/axiosClientFactory'
import Vue from 'vue'

declare module 'vue/types/vue' {
  interface Vue {
    $customerAccountSettings: SettingsManager
    $communityAccountSettings: CommunityAccountSettingsManager
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$customerAccount inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $customerAccountSettings: SettingsManager
    $communityAccountSettings: CommunityAccountSettingsManager
  }
  // nuxtContext.$customerAccount
  interface Context {
    $customerAccountSettings: SettingsManager
    $communityAccountSettings: CommunityAccountSettingsManager
  }
}

const plugin: Plugin = async function (ctx: Context, inject) {
  const basicClient = createDefaultClient(
    ctx,
    '',
    getApiBaseUrl(ctx),
    true,
    false
  )

  const customerAccountSettings = new SettingsManager(
    basicClient,
    ctx.store,
    ctx.$config.apiBaseUrl,
    'customer-account'
  )

  const communityAccountSettings = new CommunityAccountSettingsManager(
    basicClient,
    ctx.store,
    ctx.$config.apiBaseUrl,
    'community-account'
  )
  const communitySlug = ctx.route.params.communitySlug
  if (communitySlug) {
    communityAccountSettings.setCommunitySlug(communitySlug)
  }

  inject('customerAccountSettings', Vue.observable(customerAccountSettings))
  inject('communityAccountSettings', Vue.observable(communityAccountSettings))
}
export default plugin
