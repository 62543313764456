import { Context } from '@nuxt/types'

/**
 * Load all settings in parallel
 * @param context
 */
export default async function (context: Context) {
  const shouldLoginInput = String(
    context.query.shouldLogin ?? context.query.should_login
  )
  const shouldLogin = shouldLoginInput === 'true' || shouldLoginInput === '1'

  const query = { ...context.route.query }
  delete query.shouldLogin
  delete query.should_login

  if (shouldLogin) {
    if (context.$authService.account) {
      // remove query param
      return context.redirect(context.route.path, query)
    }

    const targetPath = context.app.localePath({
      name: String(context.app.getRouteBaseName(context.route)),
      params: { ...context.route.params },
      query: query,
    }) as string

    let routeName = 'login'

    if (context.$ux.isEmbedded) {
      routeName = 'login-confirm'
    }

    return context.redirect(
      context.app.localePath({
        name: routeName,
        query: {
          ...query,
          returnTo: context.$config.baseUrl + targetPath,
          target: targetPath,
          entityId: context.query.entityId,
          idpUuid: context.query.idpUuid,
        },
      })
    )
  }
}
