
import Vue, { PropType } from 'vue'
import { Lens } from '@/shared/jsonapi-orm/bookingbuddy/Lens'

export default Vue.extend({
  name: 'LensPreview',
  props: {
    lens: {
      type: Object as PropType<Lens>,
      required: true,
    },
  },
  methods: {
    handleClick(e: MouseEvent) {
      this.$emit('click', e)
    },
  },
})
