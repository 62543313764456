<template>
  <component :is="'a'" v-bind="linkAttrs">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    to: {
      type: [Object, String],
      required: true,
    },
    external: {
      type: Boolean,
      default: false,
    },
    prefetch: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    linkAttrs() {
      // open in new window
      if (
        this.external ||
        (typeof this.to === 'string' && this.to.startsWith('http'))
      ) {
        return {
          is: 'a',
          href: this.to,
          target: '_blank',
        }
      }
      // open in same window
      else {
        return {
          is: 'nuxt-link',
          prefetch: this.prefetch,
          to: this.to,
          tag: this.tag,
        }
      }
    },
  },
}
</script>
