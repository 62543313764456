
import Vue, { PropType } from 'vue'
import { Image } from '@/shared/jsonapi-orm/common/Image'

export default Vue.extend({
  name: 'Avatar',
  props: {
    size: {
      type: Number,
      default: 36,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    name: {
      type: String,
      default: '',
    },
    image: {
      type: Object as PropType<
        { small_path?: string; url?: string } | Image | null
      >,
      required: false,
      default: null,
    },
    icon: {
      type: Array as PropType<string[]>,
      default: undefined,
    },
  },
  computed: {
    initials(): string {
      let names = this.name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase()
      if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase()
      } else {
        initials += names[0].substring(1, 2).toUpperCase()
      }
      return initials
    },
    url(): string | false | undefined {
      if (!this.image) {
        return false
      }
      if (this.image instanceof Image) {
        return this.image.smallPath || this.image.url
      }
      return this.image.small_path ?? this.image.url
    },
  },
  methods: {
    handleClick(): void {
      if (this.clickable) {
        this.$emit('click')
      }
    },
  },
})
