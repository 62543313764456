import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import {
  Community,
  CommunityRoleType,
} from '@/shared/jsonapi-orm/bookingbuddy/Community'

export class CommunityInvite extends ApiResource {
  static jsonApiType = 'community-invites'
  @Attr('') email: string | null
  @Attr() acceptedAt: string | null
  @Attr() startDate: string | null
  @Attr() endDate: string | null
  @Attr() expiry: string
  @Attr(false) silent: boolean
  @Attr(CommunityRoleType.MEMBER) role: CommunityRoleType
  @Attr() createdAt: string

  @BelongsTo() community?: Community
  @BelongsTo() organization?: Organization
  @BelongsTo() user?: User

  /**
   * Accept invite when logged in as customer.
   */
  async accept(commit = false) {
    try {
      this.$isLoading = true
      const response = await this.api().request('accept')
      if (commit) {
        CommunityInvite.resourceFromResponse(response.data, this.apiService)
      }
      return true
    } catch (e) {
      console.log(e)
    } finally {
      this.$isLoading = false
    }
  }

  /**
   * Resend invitation.
   */
  async resend() {
    try {
      this.$isLoading = true
      await this.api().request('resend')
      return true
    } catch (e) {
      console.log(e)
    } finally {
      this.$isLoading = false
    }
  }
}

export class PublicCommunityInvite extends CommunityInvite {
  static jsonApiVariant = 'public'
}
