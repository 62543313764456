import { Store } from 'vuex'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { Context, Plugin } from '@nuxt/types'
import Vue from 'vue'
import {
  Alert,
  CookieSettings,
  Notification,
  StatusBarMessage,
} from '@/shared/store/uxStore'
import { ContextNavigationItem } from '@/bookingbuddy-shop/store/ux'
import dayjs, { Dayjs } from 'dayjs'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import {
  bookingFields,
  bookingInclude,
} from '@/shared/utils/bookings/bookingHelper'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import Bowser from 'bowser'
import { ActionDefinition } from '@/shared/types/DataTable'
import mitt, { Emitter } from 'mitt'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'

/*
 * Declare modules for type hinting.
 */
declare module 'vue/types/vue' {
  interface Vue {
    $ux: UxService
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $ux: UxService
  }

  interface Context {
    $ux: UxService
  }
}

export type UxEvents = {
  BookingCreated: Booking
}

/*
 * Define ux service class.
 * The ux service class is handling the user experience,
 * notifications, banners, cookie consent, etc..
 * You can access the service through context.$ux
 *
 * This service requires the uxStore
 */
export class UxService {
  store: Store<any>
  cookies: NuxtCookies

  modalBooking: Booking | null = null
  modalBookingIsOpen = false

  modalNewBookingAttr: Record<string, any | never>
  modalNewBookingsIsOpen = false

  modalCustomer: Customer | null = null
  modalCustomerIsOpen = false

  modalTmpInvoiceState: { payment: Payment; invoice: Invoice } | null = null
  modalTmpInvoiceIsOpen = false

  preventShortcuts = false

  public mitt: Emitter<UxEvents>

  constructor(protected ctx: Context, store: Store<any>, cookies: NuxtCookies) {
    this.store = store
    this.cookies = cookies
    this.mitt = mitt<UxEvents>()
  }

  get isDarkMode(): boolean {
    return this.store.getters['ux/getField']('colorTheme') === 'dark'
  }

  get cookieSettings(): CookieSettings {
    return this.store.getters['ux/getField']('cookieSettings')
  }

  get now(): Dayjs {
    return dayjs(this.store.getters['ux/now'])
  }

  get isEmbedded(): boolean {
    return this.store.getters['ux/getField']('isEmbedded')
  }

  get isMobile(): boolean {
    return this.store.getters['ux/getField']('isMobile')
  }

  get timezone(): string | null {
    return this.store.getters['ux/getField']('timezone')
  }

  get bowser(): Bowser.Parser.Parser | null {
    return this.store.getters['ux/getField'](
      'bowser'
    ) as Bowser.Parser.Parser | null
  }

  get topbarHeight(): string {
    return this.store.getters['ux/getField']('topbar.height') + 'px'
  }

  get localizationLocale(): string {
    return this.store.getters['ux/getField']('localizationLocale')
  }

  setLocalizationLocale(locale: string | null) {
    this.store.commit('ux/updateField', {
      path: 'localizationLocale',
      value: locale,
    })
  }

  get sidebarWith(): number {
    const hidden =
      this.store.getters['ux/getField']('sidebar.hidden') ||
      this.store.getters['ux/getField']('sidebar.overlay')
    if (hidden) {
      return 0
    }
    return this.store.getters['ux/getField']('sidebar.width')
  }

  // show or hide bottom bar
  toggleBottomBar(hidden: boolean) {
    this.store.commit('ux/updateField', {
      path: 'bottomBar.hidden',
      value: hidden,
    })
  }

  // show or hide bottom bar
  disableBottomBar(disabled: boolean) {
    this.store.commit('ux/updateField', {
      path: 'bottomBar.enabled',
      value: !disabled,
    })
  }

  get bottomBarHeight(): number {
    const enabled = this.store.getters['ux/getField']('bottomBar.enabled')
    const hidden = this.store.getters['ux/getField']('bottomBar.hidden')
    const hiddenMobile = this.store.getters['ux/getField'](
      'bottomBar.hiddenMobile'
    )
    if (hidden || !enabled) {
      return 0
    }
    if (this.isMobile && hiddenMobile) {
      return 0
    }
    return this.store.getters['ux/getField']('bottomBar.height')
  }

  isCookieAccepted(type: 'required' | 'all' | 'analytics'): boolean {
    if (type === 'all') {
      return (
        this.isCookieAccepted('required') && this.isCookieAccepted('analytics')
      )
    }
    return (
      this.cookieSettings.accepted && this.cookieSettings.options.includes(type)
    )
  }

  /**
   * Return unsubscribe method.
   * @param callback
   * @param immediate
   */
  onCookieAccepted(
    callback: (settings: CookieSettings) => any,
    immediate = true
  ): () => void {
    if (immediate) {
      callback(this.cookieSettings)
    }
    // subscribe store to get updates
    return this.store.subscribe((mutation) => {
      if (mutation.type === 'ux/acceptCookies') {
        callback(this.cookieSettings)
      }
    })
  }

  async pushNotification(notification: Notification) {
    return await this.store.dispatch('ux/pushNotification', notification)
  }

  setNavigationContext(navItem: ContextNavigationItem) {
    this.store.commit('ux/setContextNavigationItem', navItem)
  }

  showStatusBar(
    message: Partial<StatusBarMessage> &
      Pick<StatusBarMessage, 'message' | 'type'>
  ) {
    this.store.commit('ux/showStatusBar', message)
  }

  showAlert(alert: Partial<Alert>) {
    this.store.commit('ux/showAlert', alert)
  }
  showActionModal(actions: ActionDefinition[]) {
    this.store.commit('ux/showActionsModal', actions)
  }
  hideActionsModal() {
    this.store.commit('ux/hideActionsModal')
  }
  closeAlert() {
    this.store.commit('ux/closeAlert')
  }

  hideStatusBar() {
    this.store.commit('ux/hideStatusBar')
  }

  async openBookingModal(booking: Booking) {
    // load whole booking
    this.modalBooking = await Booking.api(this.ctx.$jsonApiService)
      .include(bookingInclude)
      .select(bookingFields)
      .find(booking.id)
    this.modalBookingIsOpen = true
  }

  closeBookingModal() {
    this.modalBooking = null
    this.modalBookingIsOpen = false
  }

  openNewBookingsModal(newBookingAttributes?: Record<string, any>) {
    if (newBookingAttributes) {
      this.modalNewBookingAttr = newBookingAttributes
    }
    this.modalNewBookingsIsOpen = true
  }

  closeNewBookingsModal() {
    this.modalNewBookingAttr = {}
    this.modalNewBookingsIsOpen = false
  }

  async openCustomerModal(customer: Customer) {
    this.modalCustomer = customer
    this.modalCustomerIsOpen = true
  }

  closeCustomerModal() {
    this.modalCustomer = null
    this.modalCustomerIsOpen = false
  }

  openTmpInvoiceModal(invoice: Invoice, payment?: Payment) {
    this.modalTmpInvoiceState = { payment, invoice }
    this.modalTmpInvoiceIsOpen = true
  }

  closeTmpInvoiceModal() {
    this.modalTmpInvoiceState = null
    this.modalTmpInvoiceIsOpen = false
  }

  disableShortcuts() {
    this.preventShortcuts = true
  }

  enableShortcuts() {
    this.preventShortcuts = false
  }
}

export const uxServicePluginFactory = (): Plugin => {
  return async function (ctx: Context, inject) {
    const service = new UxService(ctx, ctx.store, ctx.$cookies)

    const observableService = Vue.observable(service)

    inject('ux', observableService)
  }
}
