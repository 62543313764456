import { Attr, BelongsTo, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { PaymentSetup } from '@/shared/jsonapi-orm/payments/PaymentSetup'
import { Plan } from '@/shared/jsonapi-orm/subscriptions/Plan'
import { PlanSubscriptionUsage } from '@/shared/jsonapi-orm/subscriptions/PlanSubscriptionUsage'
import dayjs from 'dayjs'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'
import { DocumentAcceptance } from '@/shared/jsonapi-orm/bookingbuddy/DocumentAcceptance'
import { File as FileModel } from '@/shared/jsonapi-orm/bookingbuddy/File'

export type FeatureSummary = {
  slug: string
  name: string | null
  used: number | null
  quota: number | null
  is_active: boolean
  unlimited: boolean
  valid_until: string | null
  usage_fee: number
}

export enum SubscriptionsStatus {
  PLANNED = 'planned',
  TRIAL = 'trial',
  CANCELED = 'canceled',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  REQUESTED = 'requested',
  REJECTED = 'rejected',
  SUSPENDED = 'suspended',
}

export class PlanSubscription extends ApiResource {
  static jsonApiType = 'plan-subscriptions'
  // static defaultInclude = ['plan.features']

  @Attr() name: string
  @Attr() price: number
  // display other price than base fee
  @Attr() displayPrice: number | null
  @Attr(0) relativeDiscount: number
  @Attr(0) absoluteDiscount: number
  @Attr() discountExpiresAt: string | null
  @Attr() noticePeriod: number | null
  @Attr() currency: string
  @Attr() creditedAmount: number
  @Attr() description: string | null
  @Attr() trialStartsAt: string
  @Attr() trialEndsAt: string
  @Attr() startsAt: string | null
  @Attr() endsAt: string | null
  @Attr() suspendedUntil: string | null
  @Attr() nextBilledAt: string | null
  @Attr() cancelsAt: string | null
  @Attr() minCancelsAt: string | null
  @Attr() canceledAt: string | null
  @Attr() createdAt: string | null
  @Attr() chargedAt: string | null
  @Attr() approvedAt: string | null
  @Attr() rejectedAt: string | null
  @Attr() isNetPrice: boolean
  @Attr() isBilledOffline: boolean
  @Attr() autoRenewal: boolean
  // usage summary only available
  // if `include_summary=true` query param was passed
  @Meta() usageSummary?: FeatureSummary[]
  @BelongsTo() plan?: Plan
  @BelongsTo() subscriber?: ApiResource
  @BelongsTo() previousSubscription?: PlanSubscription | null
  @BelongsTo() paymentSetup?: PaymentSetup | null
  @HasMany() usage?: PlanSubscriptionUsage[]
  @HasMany() invoices?: Invoice[]
  // Admin Only
  @BelongsTo() customer?: Customer | null
  @BelongsTo() owner?: Organization | null

  @HasMany() documentAcceptances?: DocumentAcceptance[]
  @HasMany() files?: FileModel[]

  get status(): SubscriptionsStatus {
    if (this.isRequested) {
      return SubscriptionsStatus.REQUESTED
    }
    if (this.isRejected) {
      return SubscriptionsStatus.REJECTED
    }
    if (this.isPlanned) {
      return SubscriptionsStatus.PLANNED
    }
    if (this.isExpired) {
      return SubscriptionsStatus.EXPIRED
    }
    if (this.isCanceled) {
      return SubscriptionsStatus.CANCELED
    }
    if (this.isSuspended) {
      return SubscriptionsStatus.SUSPENDED
    }
    if (this.isActive) {
      return SubscriptionsStatus.ACTIVE
    }
    if (this.onTrial) {
      return SubscriptionsStatus.TRIAL
    }
    return SubscriptionsStatus.ACTIVE
  }

  get isCanceled() {
    return this.cancelsAt !== null
  }

  get isExpired() {
    if (this.cancelsAt) {
      return dayjs().isAfter(this.cancelsAt)
    }
    return dayjs().isAfter(this.endsAt)
  }

  get isActive() {
    if (this.rejectedAt) {
      return false
    }
    if (this.cancelsAt) {
      return (
        dayjs().isAfter(this.trialStartsAt) && dayjs().isBefore(this.cancelsAt)
      )
    }
    if (!this.startsAt && !this.endsAt) {
      return true
    }
    return (
      dayjs().isAfter(this.trialStartsAt) &&
      (!this.endsAt || dayjs().isBefore(this.endsAt ?? ''))
    )
  }

  get isRequested() {
    return this.approvedAt === null && this.rejectedAt === null
  }

  get isRejected() {
    return this.rejectedAt !== null
  }

  get isPlanned() {
    return dayjs().isBefore(this.trialStartsAt)
  }

  get onTrial() {
    return (
      dayjs().isAfter(this.trialStartsAt) && dayjs().isBefore(this.trialEndsAt)
    )
  }

  get isPaid(): boolean {
    return (
      this.price > 0 || (this.displayPrice !== null && this.displayPrice > 0)
    )
  }

  get isSuspended(): boolean {
    return this.suspendedUntil !== null && dayjs().isBefore(this.suspendedUntil)
  }

  /**
   * Cancel the subscription
   */
  async cancel(
    cancelsAt?: string | null,
    refundInvoice = false
  ): Promise<PlanSubscription> {
    await this.fill({
      cancelsAt: cancelsAt ?? new Date().toISOString(),
    }).save(
      true,
      true,
      true,
      [],
      {},
      {
        refund_invoice: refundInvoice,
      }
    )
    return this
  }
  async approve(approvedAt?: string | null): Promise<PlanSubscription> {
    await this.fill({
      approvedAt: approvedAt ?? new Date().toISOString(),
    }).save()
    return this
  }
  async reject(rejectedAt?: string | null): Promise<PlanSubscription> {
    await this.fill({
      rejectedAt: rejectedAt ?? new Date().toISOString(),
    }).save()
    return this
  }
  /**
   * Cancel the subscription
   */
  async resume(): Promise<PlanSubscription> {
    await this.fill({
      cancelsAt: null,
    }).save()
    return this
  }

  /**
   * Check if subscription can use feature by slug
   * requires presence of usageSummary
   *
   * @param featureSlug
   */
  canUse(featureSlug: string) {
    if (this.usageSummary) {
      const feature = this.usageSummary.find((u) => u.slug === featureSlug)
      if (!feature) {
        return false
      }
      if (feature.unlimited) {
        return true
      }
      if (feature.quota) {
        return Number(feature.used) < feature.quota
      }
      if (feature.is_active) {
        return true
      }
    }
    return true
  }

  /**
   * Find usage fee for any feature.
   *
   * @param featureSlug
   */
  getUsageFee(featureSlug: string): number | false {
    const feature = this.usageSummary?.find((u) => u.slug === featureSlug)
    if (!feature) {
      return false
    }
    return feature.usage_fee
  }

  getFeatureName(featureSlug: string): string | null {
    const feature = this.usageSummary?.find((u) => u.slug === featureSlug)
    if (!feature) {
      return null
    }
    return feature.name
  }

  /**
   * Get feature summary by slug
   * @param featureSlug
   */
  getFeatureSummary(featureSlug: string): FeatureSummary | null {
    return this.usageSummary?.find((u) => u.slug === featureSlug) ?? null
  }
}
