import { render, staticRenderFns } from "./OrmList.vue?vue&type=template&id=5030dc15&scoped=true"
import script from "./OrmList.vue?vue&type=script&lang=ts"
export * from "./OrmList.vue?vue&type=script&lang=ts"
import style0 from "./OrmList.vue?vue&type=style&index=0&id=5030dc15&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5030dc15",
  null
  
)

export default component.exports