import { CartPaymentMeta } from '@/shared/jsonapi-orm/payments/Payment'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { Order, OrderStatus } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { calculateTimeDifference } from '@/shared/utils/datetime/datetime'

import {
  ORDER_IDS_KEY,
  ORDER_ACCESS_TOKENS_KEY,
  ORDER_ACCESS_TOKEN_KEY,
  ORDER_ID_KEY,
} from '@/shared/services/ShopService'

export interface TimeObject {
  hours?: number
  minutes: number
  seconds: number
}

/**
 * Create default cart state
 */
const getDefaultState = () => ({
  orderId: null as string | null,
  // order id passed via query (not loaded)
  initialOrderId: null as string | null,
  orderAccessToken: null as string | null,
  initialOrderAccessToken: null as string | null,
  // remember customer
  rememberCustomerToken: null as string | null,
  // last succeeded
  authorizedOrderIds: [] as string[],
  authorizedOrderAccessTokens: [] as string[],
  fetchingCart: true as boolean,
  paymentMethod: '' as string | null,
  payment: null as CartPaymentMeta | null,
})

export type CartState = ReturnType<typeof getDefaultState>

/**
 *
 */
export function useCartStore() {
  const state = getDefaultState

  const getters: GetterTree<CartState, any> = {
    getField,
    /**
     * Get current order
     */
    order:
      (state: CartState) =>
      (apiService: JsonApiService): Order | null => {
        if (state.orderId) {
          return Order.fromId(state.orderId, apiService)
        }

        return null
      },
    /**
     * Get expiry of current cart
     *
     * @param state
     * @param getters
     */
    getExpiry:
      (state: CartState, getters) =>
      (apiService: JsonApiService): string | null => {
        const order = getters.order(apiService) as Order | null

        if (!order) {
          return null
        }

        return order.expiresAt
      },
    /**
     * Get object of remaining time
     * @param state
     * @param getters
     * @param rootState
     */
    getRemainingTimeObject:
      (state: CartState, getters, rootState: any) =>
      (apiService: JsonApiService): TimeObject | null => {
        const expiry = getters.getExpiry(apiService)

        if (!expiry) {
          return null
        }

        return calculateTimeDifference(rootState.ux.now, expiry)
      },
    getAuthorizedOrderIds: (state: CartState) => {
      return state.authorizedOrderIds
    },
    getAuthorizedOrderAccessTokens: (state: CartState) => {
      return state.authorizedOrderAccessTokens
    },
    /**
     * Authorize base order in cart.
     * @param state
     */
    getActiveOrderStateParams: (state: CartState) => {
      return {
        [ORDER_ID_KEY]: state.orderId ?? state.initialOrderId,
        [ORDER_ACCESS_TOKEN_KEY]: state.orderId
          ? state.orderAccessToken
          : state.initialOrderAccessToken,
      }
    },
    /**
     * Authorize all (completed or drafted) sub orders of cart.
     * @param state
     */
    getAuthorizedOrdersParams: (state: CartState) => {
      return {
        [ORDER_IDS_KEY]: state.authorizedOrderIds,
        [ORDER_ACCESS_TOKENS_KEY]: state.authorizedOrderAccessTokens,
      }
    },
    getRememberCustomerToken: (state: CartState) => {
      return state.rememberCustomerToken
    },
  }

  const actions: ActionTree<CartState, any> = {
    /**
     * Delete order and reset state
     * @param commit
     * @param getters
     */
    async clearCart({ commit }): Promise<void> {
      // Save tmp order
      // const order: Order | null = getters.order
      // Clear order id
      commit('setOrder', null)
    },
  }

  const mutations: MutationTree<CartState> = {
    updateField,
    /**
     * Update active order.
     * @param state
     * @param order
     */
    setOrder(state: CartState, order: Order | null) {
      state.orderId = order ? order.id : null
      state.orderAccessToken = order ? order.accessToken : null
      // reset initials
      state.initialOrderId = null
      state.initialOrderAccessToken = null
      // set authorized orders - important for success redirect after checkout
      if (order && order.subOrders) {
        state.authorizedOrderIds = order.subOrders.map((o) => o.id)
        state.authorizedOrderAccessTokens = order.subOrders.map(
          (o) => o.accessToken
        )
      }
      // remove reactivity from order
      order?.$destruct()
    },
    /**
     * Save succeeded order credentials for success page.
     * @param state
     * @param orders
     */
    setAuthorizedOrders(state: CartState, orders: Order[]) {
      state.authorizedOrderIds = orders.map((o) => o.id)
      state.authorizedOrderAccessTokens = orders.map((o) => o.accessToken)
    },
    setRememberCustomerToken(state: CartState, rct: string | null) {
      state.rememberCustomerToken = rct
    },
  }

  return {
    state,
    getters,
    actions,
    mutations,
  }
}
