export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'Intl' in window;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "intl"
        ], function (require) {
            const polyfill = require("intl");

            const install = (intl) => window.Intl = intl;

            install(polyfill);

            resolve();
        });
    });
}