// export function env(
//   key: string,
//   defaultValue: any = undefined
// ): string | number | null | undefined | boolean {

/**
 * WARNING: Do not use this method on client-side
 * @link https://nuxtjs.org/docs/2.x/configuration-glossary/configuration-env#processenv--
 * @param {string} key
 * @param {null|string|boolean|string|number|undefined} defaultValue
 * @return {null|string|boolean|string|number|undefined}
 */
export function env(
  key: string,
  defaultValue?: string | number | boolean | null
): string | number | boolean | null {
  // Check if env var exists
  if (!(key in process.env)) {
    return defaultValue ?? null
  }

  // Check if value is empty
  const value = process.env[key] ?? ''

  if (value === '') {
    if (defaultValue === undefined) {
      console.warn(`ENV ${key} is not defined`)
    }
    return defaultValue ? defaultValue : ''
  }
  return castEnv(value)
}

/**
 * Cast env value to correct type for safe usage.
 * @param value
 */
export function castEnv(
  value: string | undefined
): string | number | boolean | null {
  if (!value) {
    return null
  }
  switch (value.toLowerCase()) {
    case 'true':
    case '(true)':
      return true
    case 'false':
    case '(false)':
      return false
    case 'empty':
    case '(empty)':
      return ''
    case 'null':
    case '(null)':
      return null
  }

  if (value.startsWith('"') && value.endsWith('"')) {
    return value.substring(1, value.length - 1)
  }

  // eslint-disable-next-line
  if (value.startsWith("'") && value.endsWith("'")) {
    return value.substring(1, value.length - 1)
  }

  return value
}
