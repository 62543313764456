export type QueryInput = Record<string, any> | string | number | boolean | null
import set from 'just-safe-set'
import get from 'just-safe-get'

/**
 * Stringify object for query
 * @param query
 * @param stringifiedQuery
 * @param queryKey
 * @param encodeParams
 */
export function stringify(
  query: QueryInput | QueryInput[],
  stringifiedQuery = '',
  queryKey = '',
  encodeParams = true
): string {
  const queryBag: string[] = []
  if (stringifiedQuery) {
    queryBag.push(stringifiedQuery)
  }
  if (query === null) {
    queryBag.push(`${queryKey}=`)
  }
  // handle array
  else if (Array.isArray(query)) {
    query.forEach((iterQuery) => {
      queryBag.push(stringify(iterQuery, '', `${queryKey}[]`, encodeParams))
    })
  }
  // handle object
  else if (typeof query === 'object') {
    if (Object.keys(query).length > 0) {
      Object.entries(query).forEach(([objKey, value]: [string, any]) => {
        queryBag.push(
          stringify(
            value,
            '',
            queryKey ? `${queryKey}[${objKey}]` : objKey,
            encodeParams
          )
        )
      })
    }
  } else if (query === false) {
    queryBag.push(`${queryKey}=false`)
  } else if (query === true) {
    queryBag.push(`${queryKey}=true`)
  } else if (typeof query === 'number') {
    queryBag.push(`${queryKey}=${query}`)
  } else if (encodeParams) {
    queryBag.push(`${queryKey}=${encodeURIComponent(query)}`)
  } else {
    queryBag.push(`${queryKey}=${query}`)
  }

  // return new query part
  return queryBag.filter((s) => !!s).join('&')
}

/**
 * Parse query string into object.
 * @param queryString
 */
export function parse(queryString: string): Record<string, any> {
  // remove leading ?
  if (queryString.startsWith('?')) {
    queryString = queryString.substr(1)
  }
  const queryStringParts = queryString.split('&')
  const queryResult: Record<string, QueryInput> = {}
  queryStringParts.forEach((part: string) => {
    // WARNING: value may contain '=' string
    // need to split by first occurrence of '='
    const valueIndex = part.indexOf('=')
    if (valueIndex > -1) {
      let key = part.substr(0, valueIndex)
      const isArray = isArrayKey(key)
      if (isArray) {
        // remove array key
        key = key.replace('[]', '').replace('%5B%5D', '')
      }
      const dotKey = getDotKey(key)
      // join remaining items
      let value: QueryInput | QueryInput[] =
        part.length > valueIndex + 1 ? part.substr(valueIndex + 1) : ''
      value = gerQueryValue(value)
      if (isArray) {
        const existingArrayValue = get(queryResult, dotKey, [])
        if (Array.isArray(existingArrayValue)) {
          value = [...existingArrayValue, value]
        } else {
          value = [existingArrayValue, value]
        }
      }
      set(queryResult, dotKey, value)
    }
  })
  return queryResult
}

/**
 * Transform bracket key syntax to dot syntax
 * filter[key1][key2] => filter.key1.key2
 * @param queryKey
 */
function getDotKey(queryKey: string): string {
  return queryKey
    .split('[')
    .join('.')
    .split(']')
    .join('')
    .split('%5B')
    .join('.')
    .split('%5D')
    .join('')
}

/**
 * Get formatted query value.
 * @param value
 */
function gerQueryValue(value: QueryInput) {
  if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  } else if (value === '') {
    return null
  }
  // see why: https://stackoverflow.com/questions/17010119/decodeuri-decodes-space-as-symbol
  return decodeURIComponent(String(value).replace(/\+/g, '%20'))
}

/**
 * Check if key is array key
 * @param queryKey
 */
function isArrayKey(queryKey: string): boolean {
  return queryKey.endsWith('[]') || queryKey.endsWith('%5B%5D')
}
