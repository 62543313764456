var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eb-main-container",class:{ 'eb-main-container-embed': _vm.isEmbedded },style:(_vm.colorThemeStyles),attrs:{"id":"eb-base-layout-container"}},[(_vm.localStyles)?_c('custom-style',{attrs:{"css":_vm.localStyles}}):_vm._e(),_vm._v(" "),_c('NotificationGroup',{attrs:{"top":_vm.topbar && !_vm.topbar.hidden ? _vm.topbar.height : 0}}),_vm._v(" "),(_vm.alert)?_c('Alert',{attrs:{"alert":_vm.alert}}):_vm._e(),_vm._v(" "),_vm._t("modals",function(){return [(_vm.customModal)?_c('CustomModal',{attrs:{"custom-modal":_vm.customModal}}):_vm._e(),_vm._v(" "),(_vm.actionsModal)?_c('ActionsModal',{attrs:{"actions-modal":_vm.actionsModal}}):_vm._e()]}),_vm._v(" "),_c('div',{ref:"container",staticClass:"eb-content-container",class:{ 'eb-blurred eb-model-open': _vm.modalOpen },style:({
      paddingBottom: _vm.paddingBottom + 'px',
      paddingLeft: _vm.paddingLeft + 'px',
      paddingTop: _vm.paddingTop + 'px',
      paddingRight: _vm.paddingRight + 'px',
    })},[_c('StatusBar',{attrs:{"status-bar":_vm.statusBar,"top":_vm.topbar && !_vm.topbar.hidden ? _vm.topbar.height : 0},on:{"close":_vm.closeStatusBar}}),_vm._v(" "),_c('header',[_vm._t("header",null,{"isEmbedded":_vm.isEmbedded})],2),_vm._v(" "),_vm._t("fab"),_vm._v(" "),_c('nav',[_vm._t("navbar",null,{"isEmbedded":_vm.isEmbedded})],2),_vm._v(" "),_c('main',{directives:[{name:"background-image",rawName:"v-background-image.optional.cover",value:(_vm.backgroundImageUrl),expression:"backgroundImageUrl",modifiers:{"optional":true,"cover":true}}],staticClass:"eb-main-content",style:({
        '--topbar-height': _vm.navbarHeight,
      })},[_vm._t("default",null,{"isEmbedded":_vm.isEmbedded})],2),_vm._v(" "),_c('footer',[_vm._t("footer",null,{"isEmbedded":_vm.isEmbedded})],2),_vm._v(" "),(!_vm.isEmbedded)?_c('CookieConsent'):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }