
import { mixin as clickaway } from 'vue-clickaway'
import BaseButton from './BaseButton.vue'
import Modal from '../modals/Modal.vue'
import Vue, { PropOptions, PropType } from 'vue'

export default Vue.extend({
  name: 'BaseDropdown',
  components: { BaseButton, Modal },
  mixins: [clickaway],
  model: {
    prop: 'activeAction',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    } as PropOptions<string>,
    align: {
      type: String,
      default: 'left',
    } as PropOptions<string>,
    open: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    fixedWidth: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    /**
     * Open dropdown details in modal
     */
    openAsModal: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    marginTop: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    clickable: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    disabled: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    clickawayEnabled: {
      type: Boolean,
      default: true,
    },
    showOnHover: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    hoverDelay: {
      type: Number as PropType<number>,
      default: 0,
    },
  },
  data() {
    return {
      isActive: this.open,
      dropdownId: '',
      isHovered: true,
      isLeaving: false,
      openedByClick: false,
    }
  },
  computed: {
    triggerStyle(): Record<string, any> | void {
      if (!this.clickable) return { 'pointer-events': 'none' }
      else return { 'pointer-events': 'auto' }
    },
  },
  mounted() {
    this.dropdownId = 'dropdown_' + this._uid
  },
  methods: {
    away() {
      if (this.openAsModal || !this.clickawayEnabled) {
        // prevent closing on click when modal mode active
        return
      }
      this.isActive = false
    },
    toggleDropdown(byClick = true) {
      if (this.disabled) {
        return
      }
      this.isActive = !this.isActive
      if (this.isActive && byClick) {
        this.openedByClick = true
      } else {
        this.openedByClick = false
      }
      this.$emit('toggle', this.isActive)
    },
    closeDropdown() {
      this.isActive = false
      this.openedByClick = false
      this.$emit('close')
    },
    // show on hover after optional delay
    handleMouseEnter() {
      this.isHovered = true
      this.isLeaving = false
      if (this.showOnHover) {
        if (this.hoverDelay > 0) {
          setTimeout(() => {
            if (this.isHovered && !this.isActive) {
              this.toggleDropdown(false)
            }
          }, this.hoverDelay)
        } else if (!this.isActive) {
          this.toggleDropdown(false)
        }
      }
    },
    // close on leave only if popup was not opened by click
    handleMouseLeave() {
      this.isHovered = false
      if (this.showOnHover && this.isActive && !this.openedByClick) {
        this.isLeaving = true
        // leave time to go with mouse from trigger to content
        setTimeout(() => {
          if (this.isLeaving) {
            this.toggleDropdown(false)
            this.isLeaving = false
          }
        }, 150)
      }
    },
  },
})
