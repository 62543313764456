
import Vue, { PropType } from 'vue'
import { mapState } from 'vuex'
import LocaleSwitch from '@/shared/components/input/LocaleSwitch.vue'
import { createFooterUrls } from '@/shared/utils/createFooterUrls'
import Logo from '@/shared/components/partials/Logo.vue'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'

export default Vue.extend({
  name: 'PoweredBy',
  components: {
    LocaleSwitch,
    Logo,
  },
  props: {
    showText: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    dontShowImprintUrl: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    // Get imprint url
    const urls = createFooterUrls(this.$config, this.localePath)
    return {
      imprintUrl: urls.imprintUrl as any,
      activePlatform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
    }
  },
  computed: {
    ...mapState('ux', ['colorTheme']),
    showImprintUrl() {
      return !this.dontShowImprintUrl
    },
  },
})
