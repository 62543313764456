
import Vue, { PropType } from 'vue'
import Spinner from '@/shared/components/partials/Spinner.vue'
export default Vue.extend({
  name: 'LoadingIcon',
  components: { Spinner },
  props: {
    icon: {
      type: Array as PropType<string[] | null>,
      default: () => ['far', 'arrow-right'],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    bounce: {
      type: Boolean,
      default: false,
    },
    fw: {
      type: Boolean,
      default: true,
    },
  },
})
