
import Vue, { PropType } from 'vue'
import { mapMutations } from 'vuex'
import { Notification } from '@/shared/store/uxStore'
export default Vue.extend({
  name: 'Notification',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    notification: {
      type: Object as PropType<Notification>,
      required: true,
    },
  },
  mounted() {
    // hide notification after timeout
    if (this.notification.timeout) {
      window.setTimeout(() => {
        this.removeNotification(this.notification.id)
      }, this.notification.timeout)
    }
  },
  methods: {
    ...mapMutations('ux', ['removeNotification']),
  },
})
