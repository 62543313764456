<template>
  <div class="eb-fad-circle-bg" role="button" @click="$emit('click', $event)">
    <fa :icon="['far', 'ellipsis-h']" />
  </div>
</template>

<script>
export default {
  name: 'MoreOptionsButton',
}
</script>

<style scoped></style>
