/**
 * Error Handling for async requests
 *
 * @param {Context} context
 * @param {AxiosError} e
 */
import { Context, NuxtError } from '@nuxt/types'
import { AxiosError } from 'axios'

export function handleError(context: Context, e: AxiosError | null) {
  const { app, from, redirect, error, route } = context
  if (process.client) {
    console.log(e)
    // client side error handling -> push notification and navigate back
    console.log('handleError: redirect back due to error')
    return redirect({ path: from.path, params: from.params, query: from.query })
  } else if (e) {
    // server side error handling -> dedicated error page
    if (e.response) {
      // We got a response but something went wrong
      const statusCode = e.response.status
      if (statusCode === 401) {
        if (context.$annyDebugRequestId) {
          console.log(context.$annyDebugRequestId, 'redirect to login from 401')
        }
        console.log('handleError: redirect to login')
        return redirect(
          app.localePath({
            name: 'login',
            query: {
              target: route.fullPath,
              invalidToken: 'true',
            },
          })
        )
      }
      console.log(e.message)
      // check json api error
      if (
        Array.isArray(e.response.data?.errors) &&
        e.response.data.errors.length > 0
      ) {
        const firstError: { title?: string; detail?: string } =
          e.response.data.errors[0]

        return error({
          statusCode: statusCode,
          title: firstError.title ?? '',
          message: firstError.detail ?? '',
          apiError: firstError,
        } as NuxtError)
      }
      return error({
        statusCode: statusCode,
        title: app.i18n.t(`common.errors.${statusCode}.title`),
        message: app.i18n.t(`common.errors.${statusCode}.message`) as string,
      } as NuxtError)
    } else if (e.request) {
      // Request was made but no response was received
      console.log('handleError: redirect to network error page')
      return error({
        title: app.i18n.t('common.errors.network.title'),
        message: app.i18n.t('common.errors.network.message') as string,
      } as NuxtError)
    } else if (e) {
      // capture exception with sentry
      if ('$sentry' in context) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        context.$sentry.captureException(e)
      }
      // Something else was wrong
      console.log(
        'handleError: redirect to unknown error page for: ' + e.message
      )
      return error({
        title: app.i18n.t('common.errors.unknown.title'),
        message: app.i18n.t('common.errors.unknown.message') as string,
      } as NuxtError)
    }
  } else {
    console.log('handleError: error is null')
  }
}
