import { Attr, BelongsTo, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { Resource } from './Resource'
import { ApiResource } from '../ApiResource'
import { CustomForm } from '@/shared/jsonapi-orm/custom-fields/CustomForm'
import { ServiceSubResource } from '@/shared/jsonapi-orm/bookingbuddy/ServiceSubResource'
import { CancellationPolicy } from '@/shared/jsonapi-orm/bookingbuddy/CancellationPolicy'
import { AddOn } from '@/shared/jsonapi-orm/bookingbuddy/AddOn'
import { Test } from '@/shared/jsonapi-orm/tests/Test'
import { set } from 'lodash'
import { Schedule } from '@/shared/jsonapi-orm/bookingbuddy/Schedule'
import { ResourceService } from '@/shared/jsonapi-orm/bookingbuddy/ResourceService'
import { ServiceAddOn } from '@/shared/jsonapi-orm/bookingbuddy/ServiceAddOn'
import { ServiceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ServiceGroup'
import { Timeslot } from '@/shared/jsonapi-orm/bookingbuddy/Timeslot'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { CustomFormModel } from '@/shared/jsonapi-orm/custom-fields/CustomFormModel'
import { LegalDocumentLink } from '@/shared/jsonapi-orm/bookingbuddy/LegalDocumentLink'
import { BookingQuotaRestriction } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaRestriction'
import { PriceModifierModel } from '@/shared/jsonapi-orm/bookingbuddy/PriceModifierModel'
import { PriceModifier } from '@/shared/jsonapi-orm/bookingbuddy/PriceModifier'

export type DefaultReminderSetting = {
  offset: number
  notification_type: string
}

export enum CancellationPeriodType {
  BEFORE_BOOKING_START = 'before_booking_start',
  AFTER_BOOKING_START = 'after_booking_start',
  BEFORE_BOOKING_END = 'before_booking_end',
  AFTER_BOOKING_END = 'after_booking_end',
}

export enum ServiceDurationMode {
  FIXED = 'fixed',
  FLEXIBLE = 'flexible',
  AUTO = 'auto',
  SERIES = 'series',
}

export enum ServiceUnit {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

export enum ServiceViewType {
  PICKER = 'picker',
  LIST = 'list',
}

export enum ServiceAssignmentStrategy {
  ROUND_ROBIN = 'round_robin',
  RANDOM = 'random',
  FIRST_AVAILABLE = 'first_available',
}

export enum ServiceRecurringFrequencies {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export enum ServicePaymentFlow {
  OFFLINE = 'pay-offline',
  ONLINE = 'pay-now',
  INVOICE = 'pay-later',
}

export type ServicePaymentSettings = {
  flow: ServicePaymentFlow
  trackPayment: boolean
  createInvoice: boolean
  enableCash: boolean
}

export type ServiceSettings = {
  payment: ServicePaymentSettings
  reminders: {
    beforeStart: DefaultReminderSetting[]
    beforeEnd: DefaultReminderSetting[]
  }
  cancellation: {
    period: CancellationPeriodType
    offset: number
  }
  participants?: {
    showProfileImage: boolean
    allowCommunitySearch: boolean
    showFullName: boolean
    showDepartment: boolean
  }
  pricing?: {
    showBreakdown: boolean
  }
  [key: string]: any
}

export const defaultServiceSettings: ServiceSettings = {
  payment: {
    flow: ServicePaymentFlow.OFFLINE,
    trackPayment: false,
    createInvoice: false,
    enableCash: false,
  },
  reminders: {
    beforeStart: [],
    beforeEnd: [],
  },
  cancellation: {
    period: CancellationPeriodType.BEFORE_BOOKING_START,
    offset: 0,
  },
  participants: {
    showProfileImage: true,
    allowCommunitySearch: true,
    showFullName: true,
    showDepartment: true,
  },
}

type PublicServiceSettings = Pick<ServiceSettings, 'cancellation'>

export type SeriesPeriod = {
  id: string
  start: string
  end: string
  title?: string
}

export class Service extends ApiResource {
  static jsonApiType = 'services'
  @Attr() name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr() slug: string
  @Attr(null) description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr('') accessCode: string | null
  @Attr() access_code: string | null
  @Attr() hidden: boolean
  @Attr(ServiceViewType.PICKER) view: ServiceViewType
  @Attr(false) viewingRequiresCommunity: boolean
  @Attr(false) bookingRequiresCommunity: boolean
  @Attr(false) hiddenInCommunity: boolean
  @Attr(true) isRentalOption: boolean
  @Attr(false) hasFlexibleDuration: boolean
  @Attr(false) autoDuration: boolean
  @Attr(false) isSeries: boolean
  @Attr(false) hasCustomSchedules: boolean
  @Attr(false) allowCrossSchedule: boolean
  @Attr(false) allowEndOffSchedule: boolean
  @Attr(false) anonymousEmail: boolean
  @Attr(false) requiresBookingDescription: boolean
  @Attr(true) requiresAddress: boolean
  @Attr(false) requiresMobile: boolean
  @Attr(false) isOnline: boolean
  @Attr(false) allowCustomerNotes: boolean
  @Attr(true) allowMoreBookings: boolean
  @Attr() chargeOffSchedule: boolean
  @Attr(60) bookingInterval: number
  @Attr(true) autoCalculateBookingInterval: boolean
  @Attr(60) calculationInterval: number
  @Attr(60) minDuration: number
  @Attr(60) maxDuration: number
  @Attr(ServiceUnit.MINUTE) unit: ServiceUnit
  @Attr(ServiceAssignmentStrategy.ROUND_ROBIN)
  assignmentStrategy: ServiceAssignmentStrategy
  @Attr('09:00:00') defaultStartTime: string
  @Attr('18:00:00') defaultEndTime: string
  @Attr(0) leadTime: number
  @Attr(0) paddingBefore: number
  @Attr(0) paddingAfter: number
  @Attr(365) advanceBookingPeriod: number
  @Attr(0) startingFee: number
  @Attr(0) unchargedDuration: number
  @Attr(0.0) price: number
  @Attr(19) taxRate: number
  @Attr('EUR') currency: string
  @Attr(1) defaultWeight: number
  @Attr(false) fullWeight: boolean
  @Attr(null) quota: number | null
  @Attr() settings: ServiceSettings
  @Attr() publicSettings: PublicServiceSettings | null
  @Attr() isFullDay: boolean
  @Attr(false) isNetPrice: boolean
  @Attr(false) priceHidden: boolean
  @Attr(null) priceLabel: string | null
  @Attr({}) priceLabelI18n?: Record<string, string> | null
  @Attr(false) allowRecurring: boolean
  @Attr(true) autoAcceptBookings: boolean
  // personalization settings
  @Attr(false) allowPersonalization: boolean
  @Attr(false) allowSubsequentPersonalization: boolean
  @Attr(false) requirePersonalization: boolean
  @Attr(false) requireAddressPersonalization: boolean
  // listing
  @Attr(false) archived: boolean
  @Attr(false) seo: boolean
  @Attr(false) publicListing: boolean
  @Attr(true) hiddenInList: boolean
  @Attr(true) allowResourceSelection: boolean
  @Attr(null) seriesPeriods?: SeriesPeriod[]
  // participants
  @Attr(false) requiresHost: boolean
  @Attr(false) allowParticipants: boolean
  @Attr(false) requiresQuota: boolean

  // meta
  @Meta() bookingRestricted: boolean
  @Meta() viewingRestricted: boolean
  @Meta() minDurationTotal?: number
  @Meta() platformId: string | null
  @Meta() hasRecommendedResources: boolean
  @Meta() pricePreview?: string
  // relationships
  @HasMany() resources?: Resource[]
  @HasMany() communities?: Community[]
  @BelongsTo() group?: ServiceGroup | null
  @HasMany() schedules?: Schedule[]
  @HasMany() timeslots?: Timeslot[]
  @HasMany() customForms?: CustomForm[]
  @HasMany() customFormModels: CustomFormModel[]
  @HasMany() legalDocumentLinks?: LegalDocumentLink[]
  @HasMany() addOns?: AddOn[]
  @HasMany() tests?: Test[]
  @HasMany() resourceServices?: ResourceService[]
  @HasMany() serviceSubResources?: ServiceSubResource[]
  @HasMany() serviceRecommendedResources?: ServiceSubResource[]
  @HasMany() serviceAddOns?: ServiceAddOn[]
  @HasMany() modifierModels?: PriceModifierModel[]
  @HasMany() priceModifiers?: PriceModifier[]
  @BelongsTo() cancellationPolicy?: CancellationPolicy | null
  @BelongsTo() coverImage?: Image | null
  @BelongsTo() organization?: Organization
  @HasMany() bookingQuotaRestrictions?: BookingQuotaRestriction[] | null
  // admin
  @Attr() emailInfo?: string | null
  @Attr({}) emailInfoI18n?: Record<string, string> | null
  // indicators
  @Meta() hasCustomForms: boolean
  @Meta() hasMandatoryCustomForms: boolean
  @Meta() hasAddOns: boolean
  @Meta() hasFeaturedAddOns: boolean
  @Meta() hasSecondaryAddOns: boolean
  @Meta() hasSubResources: boolean
  @Meta() supportsInstantBooking: boolean

  get isFree(): boolean {
    return this.price <= 0 && this.startingFee <= 0
  }

  get cancellationLoaded(): boolean {
    return 'cancellation_policy' in this.relationships
  }

  hasFreeCancellation(): boolean | undefined {
    if (!this.cancellationLoaded) {
      return undefined
    }
    if (this.cancellationPolicy === null) {
      return true
    }
    if (!this.cancellationPolicy) {
      return false
    }
    if (
      this.cancellationPolicy!.defaultFee === 0 &&
      this.cancellationPolicy!.conditions.length === 0
    ) {
      return true
    }
    return false
  }

  /**
   * Update a single setting.
   *
   * @param path
   * @param value
   */
  updateSetting(path: string, value: any) {
    const settings = JSON.parse(JSON.stringify(this.settings))
    set(settings, path, value)
    this.settings = settings
  }

  /**
   * Validate slug value.
   * @param slug
   */
  async validateSlug(slug: string): Promise<boolean> {
    if (!this.persisted) {
      return true
    }
    try {
      const { data } = await this.api()
        .query({
          slug,
        })
        .request('validate-slug')
      return data && data.is_available
    } catch (e) {
      console.log(e)
      return true
    }
  }

  /**
   * Duplicate instance and its relationships.
   */
  async duplicate(): Promise<Service> {
    const { data } = await this.api()
      .include(['group', 'coverImage'])
      .request('duplicate')
    return Service.resourceFromResponse(data, this.apiService).data
  }
}

export interface ServiceWithQuantity {
  service: Service
  quantity: number
}

type AggregationType = 'min' | 'max' | 'sum' | 'every' | 'some'

const serviceAggregationMap: Record<string, AggregationType> = {
  advanceBookingPeriod: 'min',
  leadTime: 'max',
  allowCrossSchedule: 'every',
  minDuration: 'sum',
}
/**
 * Aggregate values for multiple services.
 */
export function getAggregatedServiceValue(
  services: Service[],
  key: keyof Service,
  mode: AggregationType | 'auto' = 'auto'
) {
  if (mode === 'auto') {
    if (!(key in serviceAggregationMap)) {
      throw new Error(
        'Unknown auto mode for key ' + key + ' in service aggregation'
      )
    }
    mode = serviceAggregationMap[key]
  }
  if (['min', 'max', 'sum'].includes(mode)) {
    const values = services.map((s) => s[key]) as number[]
    if (mode === 'min') {
      return Math.min(...values)
    }
    if (mode === 'max') {
      return Math.max(...values)
    }
    return values.reduce((sum, current) => sum + current, 0)
  } else {
    const values = services.map((s) => s[key]) as any[]
    if (mode === 'every') {
      return values.every((v) => !!v)
    }
    return values.some((v) => !!v)
  }
}
