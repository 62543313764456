import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class Lens extends ApiResource {
  static jsonApiType = 'lenses'
  @Attr() name: string
  @Attr() title: string | null
  @Attr() claim: string | null
  @Attr() previewClaim: string | null
  @Attr() metaDescription: string | null
  @Attr() locationSearch: boolean
  @Attr() categorySearch: boolean
  @Attr() iconImageUrl: string | null
  @Attr() heroImageUrl: string | null
  @Attr() featured: boolean
  @Attr() seo: boolean
  @Attr() soon: boolean
}
