import {
  ApiResourceBase,
  Attr,
  BelongsTo,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { PaymentMethod } from './PaymentMethod'
import stripe from '@stripe/stripe-js'

type ProviderData = {
  method_details?: Partial<stripe.PaymentMethod>
  [key: string]: any
}

type Activity = {
  event: string
  updated_at: string
}

export enum PaymentNextActionEnum {
  REDIRECT = 'redirect',
  CONFIRM = 'confirm',
  SELECT_PAYMENT_METHOD = 'select_payment_method',
}

export interface PaymentSetupNextAction {
  type: PaymentNextActionEnum
  redirect_url?: string
  client_secret?: string
}

export enum PaymentSetupStatus {
  INTENDED = 'intended',
  FAILED = 'failed',
  REJECTED = 'rejected',
  SUCCEEDED = 'succeeded',
}

export class PaymentSetup extends ApiResource {
  static jsonApiType = 'payment-setups'
  @Attr() ownerName: string | null
  @Attr(PaymentSetupStatus.INTENDED) status: PaymentSetupStatus
  @Attr(false) allowOffSession: boolean
  @Attr(false) allowRecurring: boolean

  @Attr() lastError: string | null
  @Attr() activity: Activity[]
  @Attr() providerData: ProviderData | null
  @Attr() lastChargedAt: string | null
  @Attr() createdAt: string
  @Attr() deletedAt: string

  @Meta() nextAction: PaymentSetupNextAction | null
  @Meta() label: string | null

  @BelongsTo() method?: PaymentMethod
  @BelongsTo() owner?: ApiResourceBase
}
