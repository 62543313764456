import dayjs from 'dayjs'
import { castEnv } from '@/shared/utils/env'
import { NuxtCookies } from 'cookie-universal-nuxt'
import { IncomingMessage } from 'connect'
import { VisitedOrganization } from '@/bookingbuddy-shop/store/tracking'

export const LAST_VISITED_ORGANIZATION_KEY = 'ebLastVisitedOrganization'
export const LAST_VISITED_ORGANIZATIONS_KEY = 'aLastVisitedOrganizations'
export const LAST_VISITED_ORGANIZATIONS_COOKIE_KEY = 'alvo'

/**
 * Save access token in cookie and local storage
 * @param lastVisitedOrganizations {array}
 * @param cookies
 * @param secureCookies
 */
export const storeLastVisitedOrganizations = (
  lastVisitedOrganizations: VisitedOrganization[],
  cookies: NuxtCookies,
  secureCookies = true
) => {
  if (process.server) return

  if (!lastVisitedOrganizations) {
    return
  }

  const data = JSON.stringify(lastVisitedOrganizations)

  if ('localStorage' in window) {
    try {
      window.localStorage.setItem(LAST_VISITED_ORGANIZATIONS_KEY, data)
    } catch (e) {
      // fail silent when permission denied
      console.log('info: localeStorage access denied')
    }
  }

  // #langlebedatenschutz
  if (!castEnv(process.env.FF_MAX_PRIVACY_MODE)) {
    cookies.set(LAST_VISITED_ORGANIZATIONS_COOKIE_KEY, data, {
      sameSite: 'none',
      path: '/',
      secure: secureCookies,
      expires: dayjs().add(90, 'day').toDate(),
    })
  }
}

/**
 * Read lastVisitedOrganization from request headers
 *
 * @param req
 * @returns {*}
 */
export const getLastVisitedOrganizationsFromCookie = (req: IncomingMessage) => {
  if (!req.headers.cookie) return
  const trackingCookie = req.headers.cookie
    .split(';')
    .find((c) =>
      c.trim().startsWith(LAST_VISITED_ORGANIZATIONS_COOKIE_KEY + '=')
    )
  if (!trackingCookie) return
  return decodeURIComponent(trackingCookie.split('=')[1])
}

/**
 * Read lastVisitedOrganization from local storage
 *
 * @returns {string|undefined}
 */
export const getLastVisitedOrganizationsLocalStorage = () => {
  if (process.server || !window) return
  if ('localStorage' in window) {
    try {
      return window.localStorage.getItem(LAST_VISITED_ORGANIZATION_KEY)
    } catch (e) {
      // fail silent when permission denied
      console.log('info: localeStorage access denied')
    }
  }
  return undefined
}
