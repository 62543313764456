import {
  isEqual,
  transform,
  isObject,
  isArray,
  camelCase,
  snakeCase,
} from 'lodash'

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object: any, base: any) {
  function changes(object: any, base: any) {
    return transform(object, function (result: any, value, key) {
      if (!isEqual(value, base[key])) {
        result[key] =
          isObject(value) && isObject(base[key]) && !isArray(value)
            ? changes(value, base[key])
            : value
      }
    })
  }
  return changes(object, base)
}

/**
 * Converts an object of snakeCase keys to camel case keys
 * @param obj
 */
export function convertObjectKeysToCamelCase(
  obj: Record<string, any>
): Record<string, any> {
  const newObj: Record<string, any> = {}
  Object.keys(obj).forEach((k: string) => {
    newObj[camelCase(k)] = obj[k]
  })

  return newObj
}

/**
 * converts an object of camel case keys to snake case keys
 * @param obj
 */
export function convertObjectKeysToSnakeCase(
  obj: Record<string, any>
): Record<string, any> {
  const newObj: Record<string, any> = {}
  Object.keys(obj).forEach((k: string) => {
    newObj[snakeCase(k)] = obj[k]
  })

  return newObj
}
