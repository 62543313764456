
import Vue from 'vue'
import { mapFields } from 'vuex-map-fields'
export default Vue.extend({
  name: 'Info',
  props: {
    /**
     * Enable primary color style
     */
    primary: {
      type: Boolean,
      default: true,
    },
    tooltip: {
      type: String,
      default: '',
    },
    iconStyle: {
      type: String,
      default: 'fad',
      validator: (val) => ['far', 'fad', 'fal', 'fas'].includes(val),
    },
  },
  computed: {
    ...mapFields('ux', { isTouch: 'isTouch' }),
  },
})
