export enum DownloadExtension {
  CSV = 'csv',
  XLSX = 'xlsx',
  PDF = 'pdf',
  XML = 'xml',
}

export enum DownloadMimeType {
  CSV = 'application/csv',
  XLSX = 'application/vnd.ms-excel',
  PDF = 'application/pdf',
  XML = 'application/xml',
}

export const downloadMimeTypes: Record<DownloadExtension, DownloadMimeType> = {
  csv: DownloadMimeType.CSV,
  xlsx: DownloadMimeType.XLSX,
  pdf: DownloadMimeType.PDF,
  xml: DownloadMimeType.XML,
}

export const downloadFileTypes: Record<DownloadMimeType, DownloadExtension> = {
  'application/csv': DownloadExtension.CSV,
  'application/vnd.ms-excel': DownloadExtension.XLSX,
  'application/pdf': DownloadExtension.PDF,
  'application/xml': DownloadExtension.XML,
}

export const DownloadExtensionMimeTypeMap: Record<
  DownloadExtension,
  DownloadMimeType
> = {
  [DownloadExtension.CSV]: DownloadMimeType.CSV,
  [DownloadExtension.XLSX]: DownloadMimeType.XLSX,
  [DownloadExtension.PDF]: DownloadMimeType.PDF,
  [DownloadExtension.XML]: DownloadMimeType.XML,
}

export function downloadResponse(
  data: any,
  mimeType: keyof typeof downloadFileTypes,
  fileName = 'download'
) {
  if (!data) return
  // force download by creating anc clicking a link
  const url = URL.createObjectURL(new Blob([data], { type: mimeType }))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName + '.' + downloadFileTypes[mimeType])
  document.body.appendChild(link)
  link.click()
}

export function downloadUrl(
  url: string,
  mimeType: keyof typeof downloadFileTypes,
  fileName = 'download'
) {
  // force download by creating anc clicking a link
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName + '.' + downloadFileTypes[mimeType])
  document.body.appendChild(link)
  link.click()
}
