import { Context } from '@nuxt/types'

interface DomainDetails {
  host: string
  origin: string
  isSubdomain: boolean
  handle: string | null
}

import { IncomingMessage } from 'http'
import { TLSSocket } from 'tls'

/**
 * Check if request is https
 * @param req
 * @param trustProxy
 */
export default function isHTTPS(
  req: IncomingMessage,
  trustProxy = true
): boolean | undefined {
  // Check x-forwarded-proto header
  const _xForwardedProto =
    trustProxy && req.headers ? req.headers['x-forwarded-proto'] : undefined
  const protoCheck =
    typeof _xForwardedProto === 'string'
      ? _xForwardedProto.includes('https')
      : undefined
  if (protoCheck) {
    return true
  }

  // Check tlsSocket
  const _encrypted = req.connection
    ? (req.connection as TLSSocket).encrypted
    : undefined
  const encryptedCheck = _encrypted !== undefined ? _encrypted : undefined
  if (encryptedCheck) {
    return true
  }

  if (protoCheck === undefined && encryptedCheck === undefined) {
    return undefined
  }

  return false
}

export function getHost(req?: Context['req']): string {
  return String(req ? req.headers.host : window.location.host)
    .toLowerCase()
    .replace(/www./g, '')
}

/**
 * Get domain details from request
 * @param req
 */
export function getDomainDetails(req: Context['req']): DomainDetails {
  const nonSubdomainHosts: string[] = []

  let host = getHost(req)
  const handle: string | null = null
  let port: string | null = null

  if (host.indexOf(':') > 0) {
    ;[host, port] = host.split(':')
  }

  const isSubdomain =
    host.indexOf('.') !== -1 && nonSubdomainHosts.indexOf(host) === -1

  // if (isSubdomain) {
  //   const hostSplit = host.split('.')
  //   ;[handle, host] = [hostSplit.shift() ?? null, hostSplit.join('.')]
  // }

  host = host + (port ? ':' + port : '')

  return {
    host: host,
    origin: (isHTTPS(req) ? 'https://' : 'http://') + host,
    isSubdomain,
    handle,
  }
}
