
import Vue, { PropType } from 'vue'
import IconWithBackground from '@/shared/components/utils/IconWithBackground.vue'
import { RawLocation } from 'vue-router'

export type SquareNavItem = {
  title: string
  key: string
  route: RawLocation
  visible?: boolean
  disabled?: boolean
  i18n: boolean
  icon: string
  iconStyle?: string
  exact?: boolean
  color?: string
  iconColor?: string
  lazyIcon?: boolean
}

export default Vue.extend({
  name: 'SquareNavigationList',
  components: {
    IconWithBackground,
  },
  props: {
    navItems: {
      type: Array as PropType<SquareNavItem[]>,
      required: true,
    },
    size: {
      type: Number as PropType<number>,
      default: 38,
    },
    vertical: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },
  computed: {
    visibleNavItems(): SquareNavItem[] {
      return this.navItems.filter((item) => item.visible !== false)
    },
  },
})
