
import Vue, { PropType } from 'vue'
import ResourcePreview from '@/shared/components/resources/ResourcePreview.vue'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'

export default Vue.extend({
  name: 'ResourceSearchPreview',
  components: {
    ResourcePreview,
  },
  props: {
    resource: {
      type: Object as PropType<Resource>,
      required: true,
    },
  },
})
