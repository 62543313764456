import { Attr, BelongsTo, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { PaymentMethod } from './PaymentMethod'
import { PlanSubscription } from '../subscriptions/PlanSubscription'
import { Order } from '../bookingbuddy/Order'
import { Invoice } from '../accounting/Invoice'
import { PaymentSetup } from '@/shared/jsonapi-orm/payments/PaymentSetup'
import { PaymentRefund } from '@/shared/jsonapi-orm/payments/PaymentRefund'
import { PaymentDispute } from '@/shared/jsonapi-orm/payments/PaymentDispute'

type Activity = {
  event: string
  updated_at: string
}

export enum PaymentNextActionEnum {
  REDIRECT = 'redirect',
  CONFIRM = 'confirm',
  AUTHENTICATE = 'authenticate',
  SELECT_PAYMENT_METHOD = 'select_payment_method',
}

export interface PaymentNextAction {
  type: PaymentNextActionEnum
  redirect_url?: string
  client_secret?: string
}

export interface CartPaymentMeta {
  id: string
  next_action: PaymentNextAction | null
  return_url: string
  cancel_url: string
}

export class Payment extends ApiResource {
  static jsonApiType = 'payments'
  @Attr() amount: number
  @Attr() refundableAmount: number
  @Attr() currency: string
  @Attr() countryCode: string
  @Attr() isPartialPayment: boolean
  @Attr() is_partial_payment: boolean
  @Attr() lastError: string | null
  @Attr() status: string
  @Attr() succeededAt: string | null
  @Attr() succeeded_at: string | null
  @Attr() activity: Activity[]
  @Attr() provider_data: Record<string, any> | null
  @Attr() providerData: Record<string, any> | null
  @Attr() secret: string
  @Attr() createdAt: string

  @Attr() cancelUrl: string | null
  @Attr() cancel_url: string | null
  @Attr() successUrl: string | null

  @Meta() nextAction: PaymentNextAction | null
  @Meta() next_action: PaymentNextAction | null
  @Meta() returnUrl: string | null
  @Meta() return_url: string | null

  @BelongsTo() method: PaymentMethod
  @BelongsTo() payable?: Order | PlanSubscription
  @BelongsTo() receipt?: Invoice
  @BelongsTo() paymentSetup?: PaymentSetup

  @HasMany() refunds: PaymentRefund[]
  @HasMany() disputes: PaymentDispute[]

  /**
   * Refresh payment with provider data
   */
  async refreshFromProvider() {
    const { data } = await this.api().request('refresh')
    return this.apiService.resourceFromResponse<Payment>(data)
  }
}
