
import Vue, { PropType } from 'vue'
import NavbarCart from './NavbarCart.vue'
import NavbarCartExpiry from './NavbarCartExpiry.vue'
import ExternalLink from '@/shared/components/navigation/ExternalLink.vue'
import Avatar from '@/shared/components/media/Avatar.vue'
import LocaleSwitch from '@/shared/components/input/LocaleSwitch.vue'
import BaseDropdown from '@/shared/components/buttons/BaseDropdown.vue'
import { mapFields } from 'vuex-map-fields'
import { ContextNavigationItem, UXState } from '@/bookingbuddy-shop/store/ux'
import { mapActions } from 'vuex'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import ShopSearchButton from '@/bookingbuddy-shop/components/search/ShopSearchButton.vue'
import ContextNavigationItemComp from './ContextNavigationItem.vue'
import Logo from '@/shared/components/partials/Logo.vue'
import FooterUrls from '@/shared/components/partials/FooterUrls.vue'
import dayjs from 'dayjs'
const uxFields = {
  showNav: 'showNav',
  isPwa: 'isPwa',
  contextNavigationHeight: 'contextNavigationHeight',
}

export default Vue.extend({
  name: 'ContextNavigation',
  components: {
    ShopSearchButton,
    NavbarCart,
    NavbarCartExpiry,
    ExternalLink,
    Avatar,
    LocaleSwitch,
    BaseDropdown,
    ContextNavigationItemComp,
    Logo,
    FooterUrls,
  },
  props: {
    contextNavigationItem: {
      type: Object as PropType<null | ContextNavigationItem>,
      default: null,
    },
    sidebarHidden: {
      type: Boolean,
      default: false,
    },
    logoPath: {
      type: String as PropType<string>,
      required: true,
    },
  },
  computed: {
    ...mapFields<typeof uxFields, UXState>('ux', uxFields),
    currentYear(): number {
      return dayjs().year() as number
    },
    user(): CustomerAccount | null {
      return this.$authService.account
    },
    loginPath(): string {
      // If we have something in our cart target checkout after login
      let targetPath = this.$route.fullPath
      // else target current path
      const order = this.$store.getters['cart/order'](
        this.$jsonApiService
      ) as Order | null
      if (order) {
        targetPath = this.localePath({ path: '/checkout' })
      }

      return this.localePath({
        name: 'login',
        query: {
          target: targetPath,
          returnTo: this.$config.baseUrl + this.$route.fullPath,
        },
      })
    },
    /**
     * Logout and return to current page
     */
    logoutPath(): string {
      return this.localePath({
        name: 'logout',
        query: {
          target: this.$config.baseUrl + this.$route.fullPath,
        },
      })
    },
  },
  methods: {
    ...mapActions('ux', ['toggleColorTheme']),
  },
})
