<template>
  <div class="eb-intersection-observer" style="height: 1px; margin-bottom: 1px">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'IntersectionObserver',
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      observer: null,
      defaultOptions: {
        threshold: 0.9,
      },
    }
  },
  mounted() {
    this.observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          this.$emit('intersect', entry)
        }
        // check further position
        if (entry.boundingClientRect.y < 0) {
          this.$emit('scrolled-below', entry)
        } else {
          this.$emit('scrolled-above', entry)
        }
      },
      {
        ...this.defaultOptions,
        ...this.options,
      }
    )

    this.observer.observe(this.$el)
  },
  destroyed() {
    this.observer.disconnect()
  },
}
</script>

<style scoped></style>
