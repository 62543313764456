import { Attr, BelongsTo, HasOne, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { AddOnGroup } from '@/shared/jsonapi-orm/bookingbuddy/AddOnGroup'
import { Image } from '@/shared/jsonapi-orm/common/Image'

export interface StaggeredAddOnPricing {
  id: string
  min_quantity: number
  price: number
  price_label?: string
}

export enum AddOnPriceType {
  UNIT = 'unit',
  RELATIVE = 'relative',
  INTERVAL = 'interval',
}

export class AddOn extends ApiResource {
  static jsonApiType = 'add-ons'
  @Attr('') name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr(null) description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr(null) plainDescription: string | null
  @Attr('') customerNotice: string | null
  @Attr({}) customerNoticeI18n?: Record<string, string> | null
  @Attr(0) leadTime: number | null
  @Attr(false) isThirdParty: boolean
  @Attr('EUR') currency: string
  @Attr(19) taxRate: number
  @Attr(AddOnPriceType.UNIT) priceType: AddOnPriceType | string
  @Attr(0) price: number
  @Attr(0) minQuantity: number | null
  @Attr(null) maxQuantity: number | null
  @Attr(false) isNetPrice: boolean
  @Attr(false) featured: boolean
  @Attr(false) fullRefund: boolean
  @Attr() staggeredPricing: StaggeredAddOnPricing[] | null
  @Attr(60) calculationInterval: number | null
  @Attr('minute') calculationUnit: string | null

  // Meta
  @Meta() priceLabel: string | null

  // Relations
  @BelongsTo() group?: AddOnGroup | null
  @HasOne() coverImage?: Image | null

  get hasStaggeredPricing(): boolean {
    return !!this.staggeredPricing && this.staggeredPricing.length > 0
  }
}
