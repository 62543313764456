import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Plan } from './Plan'

export class PlanFeature extends ApiResource {
  static jsonApiType = 'plan-features'

  @Attr() name: string
  @Attr({}) nameI18n?: Record<string, string> | null
  @Attr() description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null

  @BelongsTo() plan?: Plan
}
