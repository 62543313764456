import { Context, Plugin } from '@nuxt/types'
import { Store } from 'vuex'
import Vue from 'vue'
import VueRouter, { RawLocation } from 'vue-router'
import { randomString } from '@/shared/utils/Helpers'
import * as QueryParser from '@/shared/utils/QueryParser'

export const EMBED_ENTRY_PATH_KEY = 'aep' // anny entry path

declare module 'vue/types/vue' {
  interface Vue {
    $embedService: EmbedService | null
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$customerAccount inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $embedService: EmbedService | null
  }
  // nuxtContext.$customerAccount
  interface Context {
    $embedService: EmbedService | null
  }
}

interface PayloadType {
  resize: {
    height: number
    frame: string
  }
  navigate: {
    path: string
    frame: string
  }
  setState: {
    key: string
    value: string | number | boolean | null
  }
  // request state value
  getState: {
    key: string
    requestId: string
    frame: string
  }
  // result of getState request
  transmit: {
    requestId: string
    key: string
    value: string | number | boolean | null
  }
  scrollTo: {
    selector?: string
    frame: string
  }
}

interface MessageEventPayload<T extends keyof PayloadType = any> {
  _anny: boolean
  type: T
  payload: PayloadType[T]
}

/**
 * Handles communication between this (iframe) and parent (hosting page) context.
 * Enables the use of cookies through parent without browser issues.
 */
export class EmbedService {
  store: Store<any>
  router: VueRouter
  frameId: string
  lastHeight: number | null = null
  // url of hosting page
  hostBaseUrl: string | null
  localePath: (route: RawLocation) => string

  // custom css styling
  customCssVariables: Record<string, string>
  // layout options
  modifiers: string[]

  constructor(
    frameId: string,
    hostBaseUrl: string | null,
    router: VueRouter,
    localePath: (route: RawLocation) => string,
    store: Store<any>
  ) {
    this.customCssVariables = {}
    this.modifiers = []
    this.localePath = localePath
    this.frameId = frameId
    this.hostBaseUrl = hostBaseUrl
    this.router = router
    this.store = store
  }

  /**
   * Check if modifier is present.
   * @param mod
   */
  hasModifier(mod: string) {
    return this.modifiers.includes(mod)
  }

  /**
   * Build host url with additional query params.
   * Merge existing query params on the fly.
   * @param params
   * @param fallbackUrl
   */
  buildHostUrl(params: Record<string, any>, fallbackUrl: string) {
    const url = this.hostBaseUrl ?? fallbackUrl
    const paramsString = QueryParser.stringify(params)
    return url + '?' + paramsString
  }

  listenToMessages() {
    window.addEventListener('message', this.handleMessageEvent, false)
  }

  /**
   * Check and parse payload.
   * @param event
   * @private
   */
  static getEventPayload(event: MessageEvent): MessageEventPayload | null {
    // parse event data
    const eventData: unknown = event.data
    // decode stringified json
    if (typeof eventData === 'object') {
      // check if event is sent by anny
      if (eventData && '_anny' in eventData) {
        return eventData as MessageEventPayload
      }
    }
    return null
  }

  /**
   * Process event sent by parent.
   * @param event
   * @private
   */
  private handleMessageEvent(event: MessageEvent) {
    const parsedEventData = EmbedService.getEventPayload(event)
    // check if frame id matches
    if (!parsedEventData || parsedEventData.payload.frame !== this.frameId) {
      return
    }

    // handle navigation event
    if (parsedEventData.type === 'navigate') {
      const payload = parsedEventData.payload as PayloadType['navigate']
      this.router
        .push(
          this.localePath({
            path: payload.path,
          })
        )
        .then()
    }
  }

  /**
   * Easily persist data in parent window cookie.
   * @param key
   * @param value
   */
  persistData(key: string, value: string | number | boolean | null) {
    this.sendMessageEvent('setState', {
      key,
      value,
    })
  }

  /**
   * Retrieve data from store of parent window.
   * 1. send getState event
   * 2. listen to transmit event with result
   * @param key
   */
  retrieveData(key: string): Promise<any> {
    const state = randomString()

    const promise = new Promise((resolve) => {
      // listen to result
      const transmitListener = (event: MessageEvent) => {
        const parsedEventData = EmbedService.getEventPayload(event)
        if (
          !parsedEventData ||
          parsedEventData.payload.frame !== this.frameId
        ) {
          return
        }
        if (
          parsedEventData.type === 'transmit' &&
          parsedEventData.payload.requestId === state
        ) {
          window.removeEventListener('message', transmitListener, false)
          resolve(parsedEventData.payload.value)
        }
      }

      window.addEventListener('message', transmitListener, false)
    })
    this.sendMessageEvent('getState', {
      key,
      requestId: state,
      frame: this.frameId,
    })
    return promise
  }

  sendResizeEvent(height: number, useMaxHeight = false) {
    if (useMaxHeight && this.lastHeight !== null && height <= this.lastHeight) {
      return
    }
    this.lastHeight = height
    this.sendMessageEvent('resize', {
      height,
      frame: this.frameId,
    })
  }

  /**
   * Use no selector for top of frame.
   */
  scrollTo(selector?: string) {
    this.sendMessageEvent('scrollTo', {
      selector,
      frame: this.frameId,
    })
  }

  /**
   * Send message event to parent window.
   * @param type
   * @param payload
   */
  sendMessageEvent<R extends keyof PayloadType>(
    type: R,
    payload: PayloadType[R]
  ) {
    const event: MessageEventPayload = {
      _anny: true,
      type,
      payload,
    }
    window.parent.postMessage(event, '*')
  }

  /**
   * Read options from query on init.
   * @param query
   */
  initQueryOptions(query: Record<string, any>) {
    const allowedCssOptions = [
      'primaryColor',
      'primaryColorRgb',
      'primaryColorHover',
      'primaryColorOverlay',
      'secondaryColor',
      'textPrimaryColor',
      'textSecondaryColor',
      'textTertiaryColor',
      'primaryBackground',
      'primaryBackgroundRgb',
      'panelBackground',
      'panelBackgroundRgb',
      'panelBackgroundLight',
      'panelBackgroundOverlay',
      'panelBackgroundOverlayDense',
      'panelBackgroundDark',
      'inputBackground',
      'panelBorderRadius',
      'smallBorderRadius',
      'detailBorderRadius',
      'panelShadow',
      'detailShadow',
      'panelBorderStyle',
      'lightBorderColor',
      'tableBorderColor',
    ]
    // hrh = hide resource header
    // hoh = hide organization header
    // hrc = hide resource calendar
    const allowedModifiers = ['hrh', 'hoh', 'hrc']
    const modifiers: string[] = []
    Object.entries(query).forEach(([key, value]) => {
      // extract allowed css options
      if (allowedCssOptions.includes(key) && typeof value === 'string') {
        // build custom css variable key from camel case
        const cssKey =
          '--' + key.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`)
        this.customCssVariables[cssKey] = value
      }
      if (allowedModifiers.includes(key) && value === true) {
        modifiers.push(key)
      }
    })
    this.modifiers = modifiers
    this.store.commit('ux/customCssVariables', this.customCssVariables)
    // set isEmbedded option
    this.store.commit('ux/updateField', { path: 'isEmbedded', value: true })
  }
}

const plugin: Plugin = function (ctx: Context, inject) {
  const query = { ...ctx.query }
  // fid = frame id
  const frameId = query.fid
  // hbu = host base url
  const hostBaseUrl = query.hbu ? String(query.hbu) : null
  // aep = anny entry path
  const entryPath = query.aep ? String(query.aep) : null

  if (typeof frameId === 'string') {
    const service = new EmbedService(
      frameId,
      hostBaseUrl,
      ctx.app.router!,
      ctx.app.localePath,
      ctx.store
    )
    service.initQueryOptions(query)
    if (!process.server) {
      service.listenToMessages()
    }
    const observableService = Vue.observable(service)
    inject('embedService', observableService)

    // navigate to entry path with query params
    if (entryPath) {
      const queryIndex = entryPath.indexOf('?')
      const basePath =
        queryIndex > -1 ? entryPath.substr(0, queryIndex) : entryPath
      // prevent multiple redirects
      delete query.aep
      if (basePath !== ctx.route.path) {
        ctx.redirect({ path: entryPath, query: query })
      }
    }
  } else {
    inject('embedService', null)
  }
}
export default plugin
