import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Context, Plugin } from '@nuxt/types'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import { BookingAddOn } from '@/shared/jsonapi-orm/bookingbuddy/BookingAddOn'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { AddOn } from '@/shared/jsonapi-orm/bookingbuddy/AddOn'
import { CustomForm } from '@/shared/jsonapi-orm/custom-fields/CustomForm'
import { CustomField } from '@/shared/jsonapi-orm/custom-fields/CustomField'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { ResourceProperty } from '@/shared/jsonapi-orm/bookingbuddy/ResourceProperty'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { Property } from '@/shared/jsonapi-orm/bookingbuddy/Property'
import { Schedule } from '@/shared/jsonapi-orm/bookingbuddy/Schedule'
import { Voucher } from '@/shared/jsonapi-orm/bookingbuddy/Voucher'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'
import { Item } from '@/shared/jsonapi-orm/accounting/Item'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { PaymentMethod } from '@/shared/jsonapi-orm/payments/PaymentMethod'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { CancellationPolicy } from '@/shared/jsonapi-orm/bookingbuddy/CancellationPolicy'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { LegalDocument } from '@/shared/jsonapi-orm/common/LegalDocument'
import { PublicTestResult } from '@/shared/jsonapi-orm/tests/TestResult'
import { Test } from '@/shared/jsonapi-orm/tests/Test'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { initJsonApi, JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { Timeslot } from '@/shared/jsonapi-orm/bookingbuddy/Timeslot'
import { Reminder } from '@/shared/jsonapi-orm/bookingbuddy/Reminder'
import { AccountPass } from '@/shared/jsonapi-orm/bookingbuddy/AccountPass'
import { Lens } from '@/shared/jsonapi-orm/bookingbuddy/Lens'
import { Favorite } from '@/shared/jsonapi-orm/bookingbuddy/Favorite'
import { PlanSubscription } from '@/shared/jsonapi-orm/subscriptions/PlanSubscription'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { PublicCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/CommunityInvite'
import { ServiceSubResource } from '@/shared/jsonapi-orm/bookingbuddy/ServiceSubResource'
import { ResourceMap } from '@/shared/jsonapi-orm/bookingbuddy/ResourceMap'
import { CommunityAccount } from '@/shared/jsonapi-orm/bookingbuddy/CommunityAccount'
import { ServiceConfiguration } from '@/shared/jsonapi-orm/bookingbuddy/ServiceConfiguration'
import { QueueTicket } from '@/shared/jsonapi-orm/queues/QueueTicket'
import { QueueAgent } from '@/shared/jsonapi-orm/queues/QueueAgent'
import { QueueCounter } from '@/shared/jsonapi-orm/queues/QueueCounter'
import { ServiceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ServiceGroup'
import { Plan } from '@/shared/jsonapi-orm/subscriptions/Plan'
import { PlanGroup } from '@/shared/jsonapi-orm/subscriptions/PlanGroup'
import { PlanFeatureQuota } from '@/shared/jsonapi-orm/subscriptions/PlanFeatureQuota'
import { PlanFeature } from '@/shared/jsonapi-orm/subscriptions/PlanFeature'
import { PaymentSetup } from '@/shared/jsonapi-orm/payments/PaymentSetup'
import { FcmToken } from '@/shared/jsonapi-orm/common/FcmToken'
import { UnpersonalizedCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/UnpersonalizedCommunityInvite'
import { BookingSeries } from '@/shared/jsonapi-orm/bookingbuddy/BookingSeries'
import { Skill } from '@/shared/jsonapi-orm/bookingbuddy/Skill'
import { AccountSkill } from '@/shared/jsonapi-orm/bookingbuddy/AccountSkill'
import { AddOnGroup } from '@/shared/jsonapi-orm/bookingbuddy/AddOnGroup'
import { BookingParticipant } from '@/shared/jsonapi-orm/bookingbuddy/BookingParticipant'
import { BookingBundle } from '@/shared/jsonapi-orm/booking-quotas/BookingBundle'
import { BookingPass } from '@/shared/jsonapi-orm/booking-quotas/BookingPass'
import { BookingQuota } from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'
import { BookingQuotaGrant } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaGrant'
import { BookingQuotaRestriction } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaRestriction'

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $jsonApiService: JsonApiService
  }
  interface Context {
    $jsonApiService: JsonApiService
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $jsonApiService: JsonApiService
  }
}

const plugin: Plugin = function (ctx: Context, inject) {
  const jsonApiService = new JsonApiService(ctx.store, undefined, true)
  initJsonApi(
    {
      service: jsonApiService,
      store: ctx.store,
      resources: [
        ResourceMap,
        CustomerAccount,
        BookingAddOn,
        Booking,
        BookingParticipant,
        AddOn,
        CustomForm,
        CustomField,
        Resource,
        Service,
        ServiceSubResource,
        ResourceCategory,
        ResourceProperty,
        ResourceGroup,
        Property,
        Schedule,
        Timeslot,
        Voucher,
        Invoice,
        Item,
        Address,
        Payment,
        PaymentMethod,
        Order,
        Image,
        CancellationPolicy,
        Organization,
        Customer,
        LegalDocument,
        PublicTestResult,
        Test,
        Platform,
        Reminder,
        AccountPass,
        Lens,
        Favorite,
        PlanSubscription,
        Community,
        CommunityAccount,
        PublicCommunityInvite,
        ServiceConfiguration,
        QueueTicket,
        QueueAgent,
        QueueCounter,
        ServiceGroup,
        Plan,
        PlanGroup,
        PlanFeatureQuota,
        PlanFeature,
        PaymentSetup,
        FcmToken,
        UnpersonalizedCommunityInvite,
        BookingSeries,
        Skill,
        AccountSkill,
        AddOnGroup,
        BookingBundle,
        BookingPass,
        BookingQuota,
        BookingQuotaGrant,
        BookingQuotaRestriction,
      ],
    },
    process.client
  )

  inject('jsonApiService', jsonApiService)
}

export default plugin
