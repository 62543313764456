import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

type FcmTokenPlatform = 'browser' | 'app'

export class FcmToken extends ApiResource {
  static jsonApiType = 'fcm-tokens'
  @Attr() token: string | null
  @Attr() platform: FcmTokenPlatform | null
}
