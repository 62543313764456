import {
  getLastVisitedOrganizationsFromCookie,
  getLastVisitedOrganizationsLocalStorage,
  storeLastVisitedOrganizations,
} from '@/shared/utils/trackingHelpers'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import Bowser from 'bowser'
const DEFAULT_USER_AGENT =
  'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_6) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/66.0.3359.181 Safari/537.36'

type State = Record<string, unknown>

export const state = (): State => ({})

export const plugins = []

export const getters: GetterTree<State, State> = {}

export const mutations: MutationTree<State> = {}

export const actions: ActionTree<State, State> = {
  /**
   * Initialize store on server side
   * read accessToken from cookie on server
   * read accessToken from localStorage on client
   * nuxtServerInit is only called in main store - not in modules
   *
   * @link https://nuxtjs.org/guide/vuex-store/#the-nuxtserverinit-action
   * @param commit
   * @param req
   * @param app
   */
  nuxtServerInit({ commit }, { req, app, $config, $cookies }) {
    if (process.server && !req) return
    // Process last visited organization
    const lastVisitedOrganizations = process.server
      ? getLastVisitedOrganizationsFromCookie(req)
      : getLastVisitedOrganizationsLocalStorage()

    if (lastVisitedOrganizations) {
      try {
        const data = JSON.parse(lastVisitedOrganizations)
        commit('tracking/setLastVisitedOrganizations', data)
      } catch (e) {
        // remove old last visited organization
        storeLastVisitedOrganizations([], $cookies, $config.useSecureCookies)
      }
    }

    // init bowser
    let userAgent = DEFAULT_USER_AGENT
    if (req && req.headers) {
      userAgent = req.headers['user-agent'] as string
    } else if (typeof navigator !== 'undefined') {
      userAgent = navigator.userAgent
    }
    if (userAgent) {
      const bowser = Bowser.getParser(userAgent)
      // cannot commit bowser at this stage because vuex cannot stringify instances
      commit('ux/updateField', {
        path: 'isSafari',
        value: bowser.is('safari'),
      })
      commit('ux/updateField', {
        path: 'isMobile',
        value: bowser.is('mobile'),
      })
      commit('ux/updateField', {
        path: 'isTablet',
        value: bowser.is('tablet'),
      })
      commit('ux/updateField', {
        path: 'isTouch',
        value: bowser.is('mobile') || bowser.is('tablet'),
      })
    }
  },
  /**
   * Initialize store on client side
   *
   * @link https://github.com/potato4d/nuxt-client-init-module
   * @param storeCtx
   * @param context {Context}
   */
  nuxtClientInit(storeCtx, context) {
    const store = context.store

    // init timezone
    store.commit('ux/initTimezone', null, { root: true })
  },
  /**
   * Reset global state on logout
   *
   * @param state
   * @param commit
   * @param preserveModules
   */
  resetState: ({ commit }, preserveModules: string[] = []) => {
    let modules: string[] = []
    modules = modules.filter((m) => !preserveModules.includes(m))
    modules.forEach((module) => {
      commit(`${module}/resetState`, null, { root: true })
    })
  },
}
