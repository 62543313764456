import { env } from '../env'

/**
 * Additional platform config
 */
export const platformRuntimeConf = {
  activePlatformId: env('ACTIVE_PLATFORM_ID', null),
  platformShortName: env('PLATFORM_SHORT_NAME', 'anny'),
  platformName: env('PLATFORM_NAME', 'anny GmbH'),
  platformStreetAddress: env('PLATFORM_STREET_ADDRESS', 'Cäcilienstr. 30'),
  platformZipCode: env('PLATFORM_ZIP_CODE', '50667'),
  platformCity: env('PLATFORM_CITY', 'Köln'),
  platformCountryCode: env('PLATFORM_COUNTRY_CODE', 'DE'),
  platformPhone: env('PLATFORM_PHONE', '+49 241 93688158'),
  platformEmail: env('PLATFORM_EMAIL', 'hello@anny.co'),
  platformSupportEmail: env('PLATFORM_SUPPORT_EMAIL', 'support@anny.co'),
  platformCeo: env('PLATFORM_CEO', 'Lucian David Holtwiesche'),
  platformVatId: env('PLATFORM_VAT_ID', 'DE330686881'),
  platformRegisterNumber: env('PLATFORM_REGISTER_NUMBER', 'HRB 25775'),
  platformLocalCourt: env('PLATFORM_LOCAL_COURT', 'Amtsgericht Aachen'),
  platformFinancialOffice: env('PLATFORM_FINANCIAL_OFFICE', 'Finanzamt Aachen'),
  // assets
  platformLogoUrlLight: env(
    'PLATFORM_LOGO_URL_LIGHT',
    'icons/logo_landscape_color.svg'
  ),
  platformLogoUrlDark: env(
    'PLATFORM_LOGO_URL_DARK',
    'icons/logo_landscape_dark.svg'
  ),
  platformLogoUrlIcon: env('PLATFORM_LOGO_URL_ICON', 'icons/logo_color.svg'),
  platformUseCategorySearch: env('PLATFORM_USE_CATEGORY_SEARCH', true),
  platformUseLocationSearch: env('PLATFORM_USE_LOCATION_SEARCH', false),
  platformUseKeywordSearch: env('PLATFORM_USE_KEYWORD_SEARCH', true),
  platformWelcomeVideoUrl: env('PLATFORM_WELCOME_VIDEO_URL', ''),
  platformPricingLinkUrl: env(
    'PLATFORM_PRICING_LINK_URL',
    'https://anny.co/pricing/'
  ),
}

/**
 * Map keys of platform config (left) to nuxt runtime config (right) keys.
 */
export const defaultPlatformKeyMapping: Record<string, string | string[]> = {
  appName: 'appName',
  shortName: 'platformShortName',
  name: 'platformName',
  streetAddress: 'platformStreetAddress',
  zipCode: 'platformZipCode',
  city: 'platformCity',
  countryCode: 'platformCountryCode',
  phone: 'platformPhone',
  email: 'platformEmail',
  supportEmail: 'platformSupportEmail',
  ceo: 'platformCeo',
  vatId: 'platformVatId',
  registerNumber: 'platformRegisterNumber',
  localCourt: 'platformLocalCourt',
  financialOffice: 'platformFinancialOffice',
  // assets
  logoUrlLight: 'platformLogoUrlLight',
  logoUrlDark: 'platformLogoUrlDark',
  logoUrlIcon: 'platformLogoUrlIcon',
  svgFaviconUrl: 'svgFaviconUrl',
  // urls
  imprintUrl: 'imprintUrl',
  termsAndConditionsUrl: 'termsAndConditionsUrl',
  privacyPolicyUrl: 'privacyPolicyUrl',
  termsOfUseUrl: 'termsOfUseUrl',
  supportUrl: 'supportUrl',
  supportEnabled: 'supportEnabled',
  accountManagerUrl: 'accountManagerUrl',
  changePasswordUrl: 'changePasswordUrl',
  shopBaseUrl: 'shopBaseUrl',
  adminBaseUrl: 'adminBaseUrl',
  iamBaseUrl: 'cognitorBaseUri',
  homeUrl: ['homeUrl', 'redirectMainPageUrl'],
  redirectMainPageUrl: 'redirectMainPageUrl',
  welcomeVideoUrl: 'platformWelcomeVideoUrl',
  // settings
  stripeToken: 'stripeToken',
  paymentsEnabled: 'paymentsEnabled',
  chatwootEnabled: 'chatwootEnabled',
  messagebirdChatEnabled: 'messagebirdChatEnabled',
  messagebirdWidgetId: 'messagebirdWidgetId',
  intercomChatEnabled: 'intercomChatEnabled',
  intercomAppId: 'intercomAppId',
  helpArticlesEnabled: 'helpArticlesEnabled',
  gaEnabled: 'gaEnabled',
  gaId: 'gaId',
  accountPassesEnabled: 'accountPassesEnabled',
  googlePayWalletEnabled: 'googlePayWalletEnabled',
  accountCreationEnabled: 'accountCreationEnabled',
  // customer
  customerLoginEnabled: 'customerLoginEnabled',
  lensesEnabled: 'lensesEnabled',
  favoritesEnabled: 'favoritesEnabled',
  // timeouts
  apiRequestTimeout: 'apiRequestTimeout',
  apiRequestTimeoutSsr: 'apiRequestTimeoutSsr',
  // search
  useCategorySearch: 'platformUseCategorySearch',
  useLocationSearch: 'platformUseLocationSearch',
  useKeywordSearch: 'platformUseKeywordSearch',
}

export const defaultPlatformConfig = {
  lensesEnabled: false,
  accountRegisterHint: false,
  accountPassesEnabled: false,
  favoritesEnabled: true,
  intercomChatEnabled: false,
  helpArticlesEnabled: false,
  useCategorySearch: false,
  useLocationSearch: false,
}
