import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _23126244 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _4e539dcb = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _79baca40 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6aee77c4 = () => interopDefault(import('../pages/explore/index.vue' /* webpackChunkName: "pages/explore/index" */))
const _e1ee6712 = () => interopDefault(import('../pages/imprint.vue' /* webpackChunkName: "pages/imprint" */))
const _71b152cc = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _e871d8e2 = () => interopDefault(import('../pages/logout/index.vue' /* webpackChunkName: "pages/logout/index" */))
const _c944a9ac = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _3eaddc76 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _5c77a230 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _2f52562d = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _4997a0b8 = () => interopDefault(import('../pages/sentry/index.vue' /* webpackChunkName: "pages/sentry/index" */))
const _7060a200 = () => interopDefault(import('../pages/support/index.vue' /* webpackChunkName: "pages/support/index" */))
const _23b88875 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _21f93a19 = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _7d894316 = () => interopDefault(import('../pages/account/communities/index.vue' /* webpackChunkName: "pages/account/communities/index" */))
const _79003c26 = () => interopDefault(import('../pages/account/favorites/index.vue' /* webpackChunkName: "pages/account/favorites/index" */))
const _cde3ba3e = () => interopDefault(import('../pages/account/planner/index.vue' /* webpackChunkName: "pages/account/planner/index" */))
const _898bac10 = () => interopDefault(import('../pages/account/profile/index.vue' /* webpackChunkName: "pages/account/profile/index" */))
const _0d45c805 = () => interopDefault(import('../pages/account/subscriptions/index.vue' /* webpackChunkName: "pages/account/subscriptions/index" */))
const _a9a93f88 = () => interopDefault(import('../pages/checkout/success.vue' /* webpackChunkName: "pages/checkout/success" */))
const _7d87f172 = () => interopDefault(import('../pages/checkout/window.vue' /* webpackChunkName: "pages/checkout/window" */))
const _28ae462d = () => interopDefault(import('../pages/login/callback.vue' /* webpackChunkName: "pages/login/callback" */))
const _c3d7bcf0 = () => interopDefault(import('../pages/login/confirm.vue' /* webpackChunkName: "pages/login/confirm" */))
const _3a890b78 = () => interopDefault(import('../pages/login/redirect.vue' /* webpackChunkName: "pages/login/redirect" */))
const _8d1d51c6 = () => interopDefault(import('../pages/communities/invites/_communityInviteUuid.vue' /* webpackChunkName: "pages/communities/invites/_communityInviteUuid" */))
const _2cdf28a6 = () => interopDefault(import('../pages/communities/unpersonalized-invites/_unpersonalizedInviteUuid.vue' /* webpackChunkName: "pages/communities/unpersonalized-invites/_unpersonalizedInviteUuid" */))
const _30975bdf = () => interopDefault(import('../pages/explore/c/_communitySlug.vue' /* webpackChunkName: "pages/explore/c/_communitySlug" */))
const _22c13f22 = () => interopDefault(import('../pages/explore/c/_communitySlug/index.vue' /* webpackChunkName: "pages/explore/c/_communitySlug/index" */))
const _edf65216 = () => interopDefault(import('../pages/explore/c/_communitySlug/resources.vue' /* webpackChunkName: "pages/explore/c/_communitySlug/resources" */))
const _7c0a6c3e = () => interopDefault(import('../pages/explore/c/_communitySlug/services.vue' /* webpackChunkName: "pages/explore/c/_communitySlug/services" */))
const _b39654d2 = () => interopDefault(import('../pages/account/subscriptions/_subscriptionId/claim.vue' /* webpackChunkName: "pages/account/subscriptions/_subscriptionId/claim" */))
const _7e66a3d2 = () => interopDefault(import('../pages/book/_resourceSlug/index.vue' /* webpackChunkName: "pages/book/_resourceSlug/index" */))
const _379d12a0 = () => interopDefault(import('../pages/booking/_number.vue' /* webpackChunkName: "pages/booking/_number" */))
const _7a56c41d = () => interopDefault(import('../pages/explore/_lensSlug/index.vue' /* webpackChunkName: "pages/explore/_lensSlug/index" */))
const _173e0b43 = () => interopDefault(import('../pages/invoices/_invoiceUuid/index.vue' /* webpackChunkName: "pages/invoices/_invoiceUuid/index" */))
const _42cba69d = () => interopDefault(import('../pages/legal-documents/_legalDocumentUuid.vue' /* webpackChunkName: "pages/legal-documents/_legalDocumentUuid" */))
const _52fe6fc0 = () => interopDefault(import('../pages/orders/_number.vue' /* webpackChunkName: "pages/orders/_number" */))
const _137c6a8c = () => interopDefault(import('../pages/payments/_uuid.vue' /* webpackChunkName: "pages/payments/_uuid" */))
const _0e3a40c0 = () => interopDefault(import('../pages/services/_serviceSlug.vue' /* webpackChunkName: "pages/services/_serviceSlug" */))
const _020b549a = () => interopDefault(import('../pages/test-results/_testResultId/index.vue' /* webpackChunkName: "pages/test-results/_testResultId/index" */))
const _7dd1cb4e = () => interopDefault(import('../pages/invoices/_invoiceUuid/pay.vue' /* webpackChunkName: "pages/invoices/_invoiceUuid/pay" */))
const _bbd24bc8 = () => interopDefault(import('../pages/book/_resourceSlug/components/calendar.vue' /* webpackChunkName: "pages/book/_resourceSlug/components/calendar" */))
const _11fa5516 = () => interopDefault(import('../pages/book/_resourceSlug/components/panel.vue' /* webpackChunkName: "pages/book/_resourceSlug/components/panel" */))
const _292b6fcb = () => interopDefault(import('../pages/_organizationSlug.vue' /* webpackChunkName: "pages/_organizationSlug" */))
const _36f9b20e = () => interopDefault(import('../pages/_organizationSlug/index.vue' /* webpackChunkName: "pages/_organizationSlug/index" */))
const _1741d749 = () => interopDefault(import('../pages/_organizationSlug/about.vue' /* webpackChunkName: "pages/_organizationSlug/about" */))
const _0bb966e1 = () => interopDefault(import('../pages/_organizationSlug/packages.vue' /* webpackChunkName: "pages/_organizationSlug/packages" */))
const _28564da4 = () => interopDefault(import('../pages/_organizationSlug/resources/index.vue' /* webpackChunkName: "pages/_organizationSlug/resources/index" */))
const _dddffc5c = () => interopDefault(import('../pages/_organizationSlug/services.vue' /* webpackChunkName: "pages/_organizationSlug/services" */))
const _0cb4839c = () => interopDefault(import('../pages/_organizationSlug/subscriptions.vue' /* webpackChunkName: "pages/_organizationSlug/subscriptions" */))
const _22aa3d8a = () => interopDefault(import('../pages/_organizationSlug/resources/_resourceSlug.vue' /* webpackChunkName: "pages/_organizationSlug/resources/_resourceSlug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/b/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _23126244,
    name: "account___de___default"
  }, {
    path: "/checkout",
    component: _4e539dcb,
    name: "checkout___de___default"
  }, {
    path: "/cs",
    component: _79baca40,
    name: "index___cs"
  }, {
    path: "/de",
    component: _79baca40,
    name: "index___de"
  }, {
    path: "/de-formal",
    component: _79baca40,
    name: "index___de-formal"
  }, {
    path: "/en",
    component: _79baca40,
    name: "index___en"
  }, {
    path: "/en-us",
    component: _79baca40,
    name: "index___en-us"
  }, {
    path: "/es",
    component: _79baca40,
    name: "index___es"
  }, {
    path: "/explore",
    component: _6aee77c4,
    name: "explore___de___default"
  }, {
    path: "/fr",
    component: _79baca40,
    name: "index___fr"
  }, {
    path: "/hu",
    component: _79baca40,
    name: "index___hu"
  }, {
    path: "/imprint",
    component: _e1ee6712,
    name: "imprint___de___default"
  }, {
    path: "/it",
    component: _79baca40,
    name: "index___it"
  }, {
    path: "/login",
    component: _71b152cc,
    name: "login___de___default"
  }, {
    path: "/logout",
    component: _e871d8e2,
    name: "logout___de___default"
  }, {
    path: "/nb",
    component: _79baca40,
    name: "index___nb"
  }, {
    path: "/nl",
    component: _79baca40,
    name: "index___nl"
  }, {
    path: "/orders",
    component: _c944a9ac,
    name: "orders___de___default"
  }, {
    path: "/pl",
    component: _79baca40,
    name: "index___pl"
  }, {
    path: "/privacy",
    component: _3eaddc76,
    name: "privacy___de___default"
  }, {
    path: "/pt",
    component: _79baca40,
    name: "index___pt"
  }, {
    path: "/register",
    component: _5c77a230,
    name: "register___de___default"
  }, {
    path: "/ro",
    component: _79baca40,
    name: "index___ro"
  }, {
    path: "/ru",
    component: _79baca40,
    name: "index___ru"
  }, {
    path: "/search",
    component: _2f52562d,
    name: "search___de___default"
  }, {
    path: "/sentry",
    component: _4997a0b8,
    name: "sentry___de___default"
  }, {
    path: "/support",
    component: _7060a200,
    name: "support___de___default"
  }, {
    path: "/terms",
    component: _23b88875,
    name: "terms___de___default"
  }, {
    path: "/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___de___default"
  }, {
    path: "/tr",
    component: _79baca40,
    name: "index___tr"
  }, {
    path: "/ua",
    component: _79baca40,
    name: "index___ua"
  }, {
    path: "/account/communities",
    component: _7d894316,
    name: "account-communities___de___default"
  }, {
    path: "/account/favorites",
    component: _79003c26,
    name: "account-favorites___de___default"
  }, {
    path: "/account/planner",
    component: _cde3ba3e,
    name: "account-planner___de___default"
  }, {
    path: "/account/profile",
    component: _898bac10,
    name: "account-profile___de___default"
  }, {
    path: "/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___de___default"
  }, {
    path: "/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___de___default"
  }, {
    path: "/checkout/window",
    component: _7d87f172,
    name: "checkout-window___de___default"
  }, {
    path: "/cs/account",
    component: _23126244,
    name: "account___cs"
  }, {
    path: "/cs/checkout",
    component: _4e539dcb,
    name: "checkout___cs"
  }, {
    path: "/cs/explore",
    component: _6aee77c4,
    name: "explore___cs"
  }, {
    path: "/cs/imprint",
    component: _e1ee6712,
    name: "imprint___cs"
  }, {
    path: "/cs/login",
    component: _71b152cc,
    name: "login___cs"
  }, {
    path: "/cs/logout",
    component: _e871d8e2,
    name: "logout___cs"
  }, {
    path: "/cs/orders",
    component: _c944a9ac,
    name: "orders___cs"
  }, {
    path: "/cs/privacy",
    component: _3eaddc76,
    name: "privacy___cs"
  }, {
    path: "/cs/register",
    component: _5c77a230,
    name: "register___cs"
  }, {
    path: "/cs/search",
    component: _2f52562d,
    name: "search___cs"
  }, {
    path: "/cs/sentry",
    component: _4997a0b8,
    name: "sentry___cs"
  }, {
    path: "/cs/support",
    component: _7060a200,
    name: "support___cs"
  }, {
    path: "/cs/terms",
    component: _23b88875,
    name: "terms___cs"
  }, {
    path: "/cs/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___cs"
  }, {
    path: "/de-formal/account",
    component: _23126244,
    name: "account___de-formal"
  }, {
    path: "/de-formal/checkout",
    component: _4e539dcb,
    name: "checkout___de-formal"
  }, {
    path: "/de-formal/explore",
    component: _6aee77c4,
    name: "explore___de-formal"
  }, {
    path: "/de-formal/imprint",
    component: _e1ee6712,
    name: "imprint___de-formal"
  }, {
    path: "/de-formal/login",
    component: _71b152cc,
    name: "login___de-formal"
  }, {
    path: "/de-formal/logout",
    component: _e871d8e2,
    name: "logout___de-formal"
  }, {
    path: "/de-formal/orders",
    component: _c944a9ac,
    name: "orders___de-formal"
  }, {
    path: "/de-formal/privacy",
    component: _3eaddc76,
    name: "privacy___de-formal"
  }, {
    path: "/de-formal/register",
    component: _5c77a230,
    name: "register___de-formal"
  }, {
    path: "/de-formal/search",
    component: _2f52562d,
    name: "search___de-formal"
  }, {
    path: "/de-formal/sentry",
    component: _4997a0b8,
    name: "sentry___de-formal"
  }, {
    path: "/de-formal/support",
    component: _7060a200,
    name: "support___de-formal"
  }, {
    path: "/de-formal/terms",
    component: _23b88875,
    name: "terms___de-formal"
  }, {
    path: "/de-formal/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___de-formal"
  }, {
    path: "/de/account",
    component: _23126244,
    name: "account___de"
  }, {
    path: "/de/checkout",
    component: _4e539dcb,
    name: "checkout___de"
  }, {
    path: "/de/explore",
    component: _6aee77c4,
    name: "explore___de"
  }, {
    path: "/de/imprint",
    component: _e1ee6712,
    name: "imprint___de"
  }, {
    path: "/de/login",
    component: _71b152cc,
    name: "login___de"
  }, {
    path: "/de/logout",
    component: _e871d8e2,
    name: "logout___de"
  }, {
    path: "/de/orders",
    component: _c944a9ac,
    name: "orders___de"
  }, {
    path: "/de/privacy",
    component: _3eaddc76,
    name: "privacy___de"
  }, {
    path: "/de/register",
    component: _5c77a230,
    name: "register___de"
  }, {
    path: "/de/search",
    component: _2f52562d,
    name: "search___de"
  }, {
    path: "/de/sentry",
    component: _4997a0b8,
    name: "sentry___de"
  }, {
    path: "/de/support",
    component: _7060a200,
    name: "support___de"
  }, {
    path: "/de/terms",
    component: _23b88875,
    name: "terms___de"
  }, {
    path: "/de/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___de"
  }, {
    path: "/en-us/account",
    component: _23126244,
    name: "account___en-us"
  }, {
    path: "/en-us/checkout",
    component: _4e539dcb,
    name: "checkout___en-us"
  }, {
    path: "/en-us/explore",
    component: _6aee77c4,
    name: "explore___en-us"
  }, {
    path: "/en-us/imprint",
    component: _e1ee6712,
    name: "imprint___en-us"
  }, {
    path: "/en-us/login",
    component: _71b152cc,
    name: "login___en-us"
  }, {
    path: "/en-us/logout",
    component: _e871d8e2,
    name: "logout___en-us"
  }, {
    path: "/en-us/orders",
    component: _c944a9ac,
    name: "orders___en-us"
  }, {
    path: "/en-us/privacy",
    component: _3eaddc76,
    name: "privacy___en-us"
  }, {
    path: "/en-us/register",
    component: _5c77a230,
    name: "register___en-us"
  }, {
    path: "/en-us/search",
    component: _2f52562d,
    name: "search___en-us"
  }, {
    path: "/en-us/sentry",
    component: _4997a0b8,
    name: "sentry___en-us"
  }, {
    path: "/en-us/support",
    component: _7060a200,
    name: "support___en-us"
  }, {
    path: "/en-us/terms",
    component: _23b88875,
    name: "terms___en-us"
  }, {
    path: "/en-us/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___en-us"
  }, {
    path: "/en/account",
    component: _23126244,
    name: "account___en"
  }, {
    path: "/en/checkout",
    component: _4e539dcb,
    name: "checkout___en"
  }, {
    path: "/en/explore",
    component: _6aee77c4,
    name: "explore___en"
  }, {
    path: "/en/imprint",
    component: _e1ee6712,
    name: "imprint___en"
  }, {
    path: "/en/login",
    component: _71b152cc,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _e871d8e2,
    name: "logout___en"
  }, {
    path: "/en/orders",
    component: _c944a9ac,
    name: "orders___en"
  }, {
    path: "/en/privacy",
    component: _3eaddc76,
    name: "privacy___en"
  }, {
    path: "/en/register",
    component: _5c77a230,
    name: "register___en"
  }, {
    path: "/en/search",
    component: _2f52562d,
    name: "search___en"
  }, {
    path: "/en/sentry",
    component: _4997a0b8,
    name: "sentry___en"
  }, {
    path: "/en/support",
    component: _7060a200,
    name: "support___en"
  }, {
    path: "/en/terms",
    component: _23b88875,
    name: "terms___en"
  }, {
    path: "/en/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___en"
  }, {
    path: "/es/account",
    component: _23126244,
    name: "account___es"
  }, {
    path: "/es/checkout",
    component: _4e539dcb,
    name: "checkout___es"
  }, {
    path: "/es/explore",
    component: _6aee77c4,
    name: "explore___es"
  }, {
    path: "/es/imprint",
    component: _e1ee6712,
    name: "imprint___es"
  }, {
    path: "/es/login",
    component: _71b152cc,
    name: "login___es"
  }, {
    path: "/es/logout",
    component: _e871d8e2,
    name: "logout___es"
  }, {
    path: "/es/orders",
    component: _c944a9ac,
    name: "orders___es"
  }, {
    path: "/es/privacy",
    component: _3eaddc76,
    name: "privacy___es"
  }, {
    path: "/es/register",
    component: _5c77a230,
    name: "register___es"
  }, {
    path: "/es/search",
    component: _2f52562d,
    name: "search___es"
  }, {
    path: "/es/sentry",
    component: _4997a0b8,
    name: "sentry___es"
  }, {
    path: "/es/support",
    component: _7060a200,
    name: "support___es"
  }, {
    path: "/es/terms",
    component: _23b88875,
    name: "terms___es"
  }, {
    path: "/es/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___es"
  }, {
    path: "/fr/account",
    component: _23126244,
    name: "account___fr"
  }, {
    path: "/fr/checkout",
    component: _4e539dcb,
    name: "checkout___fr"
  }, {
    path: "/fr/explore",
    component: _6aee77c4,
    name: "explore___fr"
  }, {
    path: "/fr/imprint",
    component: _e1ee6712,
    name: "imprint___fr"
  }, {
    path: "/fr/login",
    component: _71b152cc,
    name: "login___fr"
  }, {
    path: "/fr/logout",
    component: _e871d8e2,
    name: "logout___fr"
  }, {
    path: "/fr/orders",
    component: _c944a9ac,
    name: "orders___fr"
  }, {
    path: "/fr/privacy",
    component: _3eaddc76,
    name: "privacy___fr"
  }, {
    path: "/fr/register",
    component: _5c77a230,
    name: "register___fr"
  }, {
    path: "/fr/search",
    component: _2f52562d,
    name: "search___fr"
  }, {
    path: "/fr/sentry",
    component: _4997a0b8,
    name: "sentry___fr"
  }, {
    path: "/fr/support",
    component: _7060a200,
    name: "support___fr"
  }, {
    path: "/fr/terms",
    component: _23b88875,
    name: "terms___fr"
  }, {
    path: "/fr/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___fr"
  }, {
    path: "/hu/account",
    component: _23126244,
    name: "account___hu"
  }, {
    path: "/hu/checkout",
    component: _4e539dcb,
    name: "checkout___hu"
  }, {
    path: "/hu/explore",
    component: _6aee77c4,
    name: "explore___hu"
  }, {
    path: "/hu/imprint",
    component: _e1ee6712,
    name: "imprint___hu"
  }, {
    path: "/hu/login",
    component: _71b152cc,
    name: "login___hu"
  }, {
    path: "/hu/logout",
    component: _e871d8e2,
    name: "logout___hu"
  }, {
    path: "/hu/orders",
    component: _c944a9ac,
    name: "orders___hu"
  }, {
    path: "/hu/privacy",
    component: _3eaddc76,
    name: "privacy___hu"
  }, {
    path: "/hu/register",
    component: _5c77a230,
    name: "register___hu"
  }, {
    path: "/hu/search",
    component: _2f52562d,
    name: "search___hu"
  }, {
    path: "/hu/sentry",
    component: _4997a0b8,
    name: "sentry___hu"
  }, {
    path: "/hu/support",
    component: _7060a200,
    name: "support___hu"
  }, {
    path: "/hu/terms",
    component: _23b88875,
    name: "terms___hu"
  }, {
    path: "/hu/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___hu"
  }, {
    path: "/it/account",
    component: _23126244,
    name: "account___it"
  }, {
    path: "/it/checkout",
    component: _4e539dcb,
    name: "checkout___it"
  }, {
    path: "/it/explore",
    component: _6aee77c4,
    name: "explore___it"
  }, {
    path: "/it/imprint",
    component: _e1ee6712,
    name: "imprint___it"
  }, {
    path: "/it/login",
    component: _71b152cc,
    name: "login___it"
  }, {
    path: "/it/logout",
    component: _e871d8e2,
    name: "logout___it"
  }, {
    path: "/it/orders",
    component: _c944a9ac,
    name: "orders___it"
  }, {
    path: "/it/privacy",
    component: _3eaddc76,
    name: "privacy___it"
  }, {
    path: "/it/register",
    component: _5c77a230,
    name: "register___it"
  }, {
    path: "/it/search",
    component: _2f52562d,
    name: "search___it"
  }, {
    path: "/it/sentry",
    component: _4997a0b8,
    name: "sentry___it"
  }, {
    path: "/it/support",
    component: _7060a200,
    name: "support___it"
  }, {
    path: "/it/terms",
    component: _23b88875,
    name: "terms___it"
  }, {
    path: "/it/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___it"
  }, {
    path: "/login/callback",
    component: _28ae462d,
    name: "login-callback___de___default"
  }, {
    path: "/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___de___default"
  }, {
    path: "/login/redirect",
    component: _3a890b78,
    name: "login-redirect___de___default"
  }, {
    path: "/nb/account",
    component: _23126244,
    name: "account___nb"
  }, {
    path: "/nb/checkout",
    component: _4e539dcb,
    name: "checkout___nb"
  }, {
    path: "/nb/explore",
    component: _6aee77c4,
    name: "explore___nb"
  }, {
    path: "/nb/imprint",
    component: _e1ee6712,
    name: "imprint___nb"
  }, {
    path: "/nb/login",
    component: _71b152cc,
    name: "login___nb"
  }, {
    path: "/nb/logout",
    component: _e871d8e2,
    name: "logout___nb"
  }, {
    path: "/nb/orders",
    component: _c944a9ac,
    name: "orders___nb"
  }, {
    path: "/nb/privacy",
    component: _3eaddc76,
    name: "privacy___nb"
  }, {
    path: "/nb/register",
    component: _5c77a230,
    name: "register___nb"
  }, {
    path: "/nb/search",
    component: _2f52562d,
    name: "search___nb"
  }, {
    path: "/nb/sentry",
    component: _4997a0b8,
    name: "sentry___nb"
  }, {
    path: "/nb/support",
    component: _7060a200,
    name: "support___nb"
  }, {
    path: "/nb/terms",
    component: _23b88875,
    name: "terms___nb"
  }, {
    path: "/nb/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___nb"
  }, {
    path: "/nl/account",
    component: _23126244,
    name: "account___nl"
  }, {
    path: "/nl/checkout",
    component: _4e539dcb,
    name: "checkout___nl"
  }, {
    path: "/nl/explore",
    component: _6aee77c4,
    name: "explore___nl"
  }, {
    path: "/nl/imprint",
    component: _e1ee6712,
    name: "imprint___nl"
  }, {
    path: "/nl/login",
    component: _71b152cc,
    name: "login___nl"
  }, {
    path: "/nl/logout",
    component: _e871d8e2,
    name: "logout___nl"
  }, {
    path: "/nl/orders",
    component: _c944a9ac,
    name: "orders___nl"
  }, {
    path: "/nl/privacy",
    component: _3eaddc76,
    name: "privacy___nl"
  }, {
    path: "/nl/register",
    component: _5c77a230,
    name: "register___nl"
  }, {
    path: "/nl/search",
    component: _2f52562d,
    name: "search___nl"
  }, {
    path: "/nl/sentry",
    component: _4997a0b8,
    name: "sentry___nl"
  }, {
    path: "/nl/support",
    component: _7060a200,
    name: "support___nl"
  }, {
    path: "/nl/terms",
    component: _23b88875,
    name: "terms___nl"
  }, {
    path: "/nl/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___nl"
  }, {
    path: "/pl/account",
    component: _23126244,
    name: "account___pl"
  }, {
    path: "/pl/checkout",
    component: _4e539dcb,
    name: "checkout___pl"
  }, {
    path: "/pl/explore",
    component: _6aee77c4,
    name: "explore___pl"
  }, {
    path: "/pl/imprint",
    component: _e1ee6712,
    name: "imprint___pl"
  }, {
    path: "/pl/login",
    component: _71b152cc,
    name: "login___pl"
  }, {
    path: "/pl/logout",
    component: _e871d8e2,
    name: "logout___pl"
  }, {
    path: "/pl/orders",
    component: _c944a9ac,
    name: "orders___pl"
  }, {
    path: "/pl/privacy",
    component: _3eaddc76,
    name: "privacy___pl"
  }, {
    path: "/pl/register",
    component: _5c77a230,
    name: "register___pl"
  }, {
    path: "/pl/search",
    component: _2f52562d,
    name: "search___pl"
  }, {
    path: "/pl/sentry",
    component: _4997a0b8,
    name: "sentry___pl"
  }, {
    path: "/pl/support",
    component: _7060a200,
    name: "support___pl"
  }, {
    path: "/pl/terms",
    component: _23b88875,
    name: "terms___pl"
  }, {
    path: "/pl/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___pl"
  }, {
    path: "/pt/account",
    component: _23126244,
    name: "account___pt"
  }, {
    path: "/pt/checkout",
    component: _4e539dcb,
    name: "checkout___pt"
  }, {
    path: "/pt/explore",
    component: _6aee77c4,
    name: "explore___pt"
  }, {
    path: "/pt/imprint",
    component: _e1ee6712,
    name: "imprint___pt"
  }, {
    path: "/pt/login",
    component: _71b152cc,
    name: "login___pt"
  }, {
    path: "/pt/logout",
    component: _e871d8e2,
    name: "logout___pt"
  }, {
    path: "/pt/orders",
    component: _c944a9ac,
    name: "orders___pt"
  }, {
    path: "/pt/privacy",
    component: _3eaddc76,
    name: "privacy___pt"
  }, {
    path: "/pt/register",
    component: _5c77a230,
    name: "register___pt"
  }, {
    path: "/pt/search",
    component: _2f52562d,
    name: "search___pt"
  }, {
    path: "/pt/sentry",
    component: _4997a0b8,
    name: "sentry___pt"
  }, {
    path: "/pt/support",
    component: _7060a200,
    name: "support___pt"
  }, {
    path: "/pt/terms",
    component: _23b88875,
    name: "terms___pt"
  }, {
    path: "/pt/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___pt"
  }, {
    path: "/ro/account",
    component: _23126244,
    name: "account___ro"
  }, {
    path: "/ro/checkout",
    component: _4e539dcb,
    name: "checkout___ro"
  }, {
    path: "/ro/explore",
    component: _6aee77c4,
    name: "explore___ro"
  }, {
    path: "/ro/imprint",
    component: _e1ee6712,
    name: "imprint___ro"
  }, {
    path: "/ro/login",
    component: _71b152cc,
    name: "login___ro"
  }, {
    path: "/ro/logout",
    component: _e871d8e2,
    name: "logout___ro"
  }, {
    path: "/ro/orders",
    component: _c944a9ac,
    name: "orders___ro"
  }, {
    path: "/ro/privacy",
    component: _3eaddc76,
    name: "privacy___ro"
  }, {
    path: "/ro/register",
    component: _5c77a230,
    name: "register___ro"
  }, {
    path: "/ro/search",
    component: _2f52562d,
    name: "search___ro"
  }, {
    path: "/ro/sentry",
    component: _4997a0b8,
    name: "sentry___ro"
  }, {
    path: "/ro/support",
    component: _7060a200,
    name: "support___ro"
  }, {
    path: "/ro/terms",
    component: _23b88875,
    name: "terms___ro"
  }, {
    path: "/ro/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___ro"
  }, {
    path: "/ru/account",
    component: _23126244,
    name: "account___ru"
  }, {
    path: "/ru/checkout",
    component: _4e539dcb,
    name: "checkout___ru"
  }, {
    path: "/ru/explore",
    component: _6aee77c4,
    name: "explore___ru"
  }, {
    path: "/ru/imprint",
    component: _e1ee6712,
    name: "imprint___ru"
  }, {
    path: "/ru/login",
    component: _71b152cc,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _e871d8e2,
    name: "logout___ru"
  }, {
    path: "/ru/orders",
    component: _c944a9ac,
    name: "orders___ru"
  }, {
    path: "/ru/privacy",
    component: _3eaddc76,
    name: "privacy___ru"
  }, {
    path: "/ru/register",
    component: _5c77a230,
    name: "register___ru"
  }, {
    path: "/ru/search",
    component: _2f52562d,
    name: "search___ru"
  }, {
    path: "/ru/sentry",
    component: _4997a0b8,
    name: "sentry___ru"
  }, {
    path: "/ru/support",
    component: _7060a200,
    name: "support___ru"
  }, {
    path: "/ru/terms",
    component: _23b88875,
    name: "terms___ru"
  }, {
    path: "/ru/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___ru"
  }, {
    path: "/tr/account",
    component: _23126244,
    name: "account___tr"
  }, {
    path: "/tr/checkout",
    component: _4e539dcb,
    name: "checkout___tr"
  }, {
    path: "/tr/explore",
    component: _6aee77c4,
    name: "explore___tr"
  }, {
    path: "/tr/imprint",
    component: _e1ee6712,
    name: "imprint___tr"
  }, {
    path: "/tr/login",
    component: _71b152cc,
    name: "login___tr"
  }, {
    path: "/tr/logout",
    component: _e871d8e2,
    name: "logout___tr"
  }, {
    path: "/tr/orders",
    component: _c944a9ac,
    name: "orders___tr"
  }, {
    path: "/tr/privacy",
    component: _3eaddc76,
    name: "privacy___tr"
  }, {
    path: "/tr/register",
    component: _5c77a230,
    name: "register___tr"
  }, {
    path: "/tr/search",
    component: _2f52562d,
    name: "search___tr"
  }, {
    path: "/tr/sentry",
    component: _4997a0b8,
    name: "sentry___tr"
  }, {
    path: "/tr/support",
    component: _7060a200,
    name: "support___tr"
  }, {
    path: "/tr/terms",
    component: _23b88875,
    name: "terms___tr"
  }, {
    path: "/tr/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___tr"
  }, {
    path: "/ua/account",
    component: _23126244,
    name: "account___ua"
  }, {
    path: "/ua/checkout",
    component: _4e539dcb,
    name: "checkout___ua"
  }, {
    path: "/ua/explore",
    component: _6aee77c4,
    name: "explore___ua"
  }, {
    path: "/ua/imprint",
    component: _e1ee6712,
    name: "imprint___ua"
  }, {
    path: "/ua/login",
    component: _71b152cc,
    name: "login___ua"
  }, {
    path: "/ua/logout",
    component: _e871d8e2,
    name: "logout___ua"
  }, {
    path: "/ua/orders",
    component: _c944a9ac,
    name: "orders___ua"
  }, {
    path: "/ua/privacy",
    component: _3eaddc76,
    name: "privacy___ua"
  }, {
    path: "/ua/register",
    component: _5c77a230,
    name: "register___ua"
  }, {
    path: "/ua/search",
    component: _2f52562d,
    name: "search___ua"
  }, {
    path: "/ua/sentry",
    component: _4997a0b8,
    name: "sentry___ua"
  }, {
    path: "/ua/support",
    component: _7060a200,
    name: "support___ua"
  }, {
    path: "/ua/terms",
    component: _23b88875,
    name: "terms___ua"
  }, {
    path: "/ua/terms-of-use",
    component: _21f93a19,
    name: "terms-of-use___ua"
  }, {
    path: "/cs/account/communities",
    component: _7d894316,
    name: "account-communities___cs"
  }, {
    path: "/cs/account/favorites",
    component: _79003c26,
    name: "account-favorites___cs"
  }, {
    path: "/cs/account/planner",
    component: _cde3ba3e,
    name: "account-planner___cs"
  }, {
    path: "/cs/account/profile",
    component: _898bac10,
    name: "account-profile___cs"
  }, {
    path: "/cs/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___cs"
  }, {
    path: "/cs/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___cs"
  }, {
    path: "/cs/checkout/window",
    component: _7d87f172,
    name: "checkout-window___cs"
  }, {
    path: "/cs/login/callback",
    component: _28ae462d,
    name: "login-callback___cs"
  }, {
    path: "/cs/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___cs"
  }, {
    path: "/cs/login/redirect",
    component: _3a890b78,
    name: "login-redirect___cs"
  }, {
    path: "/de-formal/account/communities",
    component: _7d894316,
    name: "account-communities___de-formal"
  }, {
    path: "/de-formal/account/favorites",
    component: _79003c26,
    name: "account-favorites___de-formal"
  }, {
    path: "/de-formal/account/planner",
    component: _cde3ba3e,
    name: "account-planner___de-formal"
  }, {
    path: "/de-formal/account/profile",
    component: _898bac10,
    name: "account-profile___de-formal"
  }, {
    path: "/de-formal/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___de-formal"
  }, {
    path: "/de-formal/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___de-formal"
  }, {
    path: "/de-formal/checkout/window",
    component: _7d87f172,
    name: "checkout-window___de-formal"
  }, {
    path: "/de-formal/login/callback",
    component: _28ae462d,
    name: "login-callback___de-formal"
  }, {
    path: "/de-formal/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___de-formal"
  }, {
    path: "/de-formal/login/redirect",
    component: _3a890b78,
    name: "login-redirect___de-formal"
  }, {
    path: "/de/account/communities",
    component: _7d894316,
    name: "account-communities___de"
  }, {
    path: "/de/account/favorites",
    component: _79003c26,
    name: "account-favorites___de"
  }, {
    path: "/de/account/planner",
    component: _cde3ba3e,
    name: "account-planner___de"
  }, {
    path: "/de/account/profile",
    component: _898bac10,
    name: "account-profile___de"
  }, {
    path: "/de/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___de"
  }, {
    path: "/de/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___de"
  }, {
    path: "/de/checkout/window",
    component: _7d87f172,
    name: "checkout-window___de"
  }, {
    path: "/de/login/callback",
    component: _28ae462d,
    name: "login-callback___de"
  }, {
    path: "/de/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___de"
  }, {
    path: "/de/login/redirect",
    component: _3a890b78,
    name: "login-redirect___de"
  }, {
    path: "/en-us/account/communities",
    component: _7d894316,
    name: "account-communities___en-us"
  }, {
    path: "/en-us/account/favorites",
    component: _79003c26,
    name: "account-favorites___en-us"
  }, {
    path: "/en-us/account/planner",
    component: _cde3ba3e,
    name: "account-planner___en-us"
  }, {
    path: "/en-us/account/profile",
    component: _898bac10,
    name: "account-profile___en-us"
  }, {
    path: "/en-us/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___en-us"
  }, {
    path: "/en-us/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___en-us"
  }, {
    path: "/en-us/checkout/window",
    component: _7d87f172,
    name: "checkout-window___en-us"
  }, {
    path: "/en-us/login/callback",
    component: _28ae462d,
    name: "login-callback___en-us"
  }, {
    path: "/en-us/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___en-us"
  }, {
    path: "/en-us/login/redirect",
    component: _3a890b78,
    name: "login-redirect___en-us"
  }, {
    path: "/en/account/communities",
    component: _7d894316,
    name: "account-communities___en"
  }, {
    path: "/en/account/favorites",
    component: _79003c26,
    name: "account-favorites___en"
  }, {
    path: "/en/account/planner",
    component: _cde3ba3e,
    name: "account-planner___en"
  }, {
    path: "/en/account/profile",
    component: _898bac10,
    name: "account-profile___en"
  }, {
    path: "/en/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___en"
  }, {
    path: "/en/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___en"
  }, {
    path: "/en/checkout/window",
    component: _7d87f172,
    name: "checkout-window___en"
  }, {
    path: "/en/login/callback",
    component: _28ae462d,
    name: "login-callback___en"
  }, {
    path: "/en/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___en"
  }, {
    path: "/en/login/redirect",
    component: _3a890b78,
    name: "login-redirect___en"
  }, {
    path: "/es/account/communities",
    component: _7d894316,
    name: "account-communities___es"
  }, {
    path: "/es/account/favorites",
    component: _79003c26,
    name: "account-favorites___es"
  }, {
    path: "/es/account/planner",
    component: _cde3ba3e,
    name: "account-planner___es"
  }, {
    path: "/es/account/profile",
    component: _898bac10,
    name: "account-profile___es"
  }, {
    path: "/es/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___es"
  }, {
    path: "/es/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___es"
  }, {
    path: "/es/checkout/window",
    component: _7d87f172,
    name: "checkout-window___es"
  }, {
    path: "/es/login/callback",
    component: _28ae462d,
    name: "login-callback___es"
  }, {
    path: "/es/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___es"
  }, {
    path: "/es/login/redirect",
    component: _3a890b78,
    name: "login-redirect___es"
  }, {
    path: "/fr/account/communities",
    component: _7d894316,
    name: "account-communities___fr"
  }, {
    path: "/fr/account/favorites",
    component: _79003c26,
    name: "account-favorites___fr"
  }, {
    path: "/fr/account/planner",
    component: _cde3ba3e,
    name: "account-planner___fr"
  }, {
    path: "/fr/account/profile",
    component: _898bac10,
    name: "account-profile___fr"
  }, {
    path: "/fr/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___fr"
  }, {
    path: "/fr/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___fr"
  }, {
    path: "/fr/checkout/window",
    component: _7d87f172,
    name: "checkout-window___fr"
  }, {
    path: "/fr/login/callback",
    component: _28ae462d,
    name: "login-callback___fr"
  }, {
    path: "/fr/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___fr"
  }, {
    path: "/fr/login/redirect",
    component: _3a890b78,
    name: "login-redirect___fr"
  }, {
    path: "/hu/account/communities",
    component: _7d894316,
    name: "account-communities___hu"
  }, {
    path: "/hu/account/favorites",
    component: _79003c26,
    name: "account-favorites___hu"
  }, {
    path: "/hu/account/planner",
    component: _cde3ba3e,
    name: "account-planner___hu"
  }, {
    path: "/hu/account/profile",
    component: _898bac10,
    name: "account-profile___hu"
  }, {
    path: "/hu/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___hu"
  }, {
    path: "/hu/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___hu"
  }, {
    path: "/hu/checkout/window",
    component: _7d87f172,
    name: "checkout-window___hu"
  }, {
    path: "/hu/login/callback",
    component: _28ae462d,
    name: "login-callback___hu"
  }, {
    path: "/hu/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___hu"
  }, {
    path: "/hu/login/redirect",
    component: _3a890b78,
    name: "login-redirect___hu"
  }, {
    path: "/it/account/communities",
    component: _7d894316,
    name: "account-communities___it"
  }, {
    path: "/it/account/favorites",
    component: _79003c26,
    name: "account-favorites___it"
  }, {
    path: "/it/account/planner",
    component: _cde3ba3e,
    name: "account-planner___it"
  }, {
    path: "/it/account/profile",
    component: _898bac10,
    name: "account-profile___it"
  }, {
    path: "/it/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___it"
  }, {
    path: "/it/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___it"
  }, {
    path: "/it/checkout/window",
    component: _7d87f172,
    name: "checkout-window___it"
  }, {
    path: "/it/login/callback",
    component: _28ae462d,
    name: "login-callback___it"
  }, {
    path: "/it/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___it"
  }, {
    path: "/it/login/redirect",
    component: _3a890b78,
    name: "login-redirect___it"
  }, {
    path: "/nb/account/communities",
    component: _7d894316,
    name: "account-communities___nb"
  }, {
    path: "/nb/account/favorites",
    component: _79003c26,
    name: "account-favorites___nb"
  }, {
    path: "/nb/account/planner",
    component: _cde3ba3e,
    name: "account-planner___nb"
  }, {
    path: "/nb/account/profile",
    component: _898bac10,
    name: "account-profile___nb"
  }, {
    path: "/nb/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___nb"
  }, {
    path: "/nb/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___nb"
  }, {
    path: "/nb/checkout/window",
    component: _7d87f172,
    name: "checkout-window___nb"
  }, {
    path: "/nb/login/callback",
    component: _28ae462d,
    name: "login-callback___nb"
  }, {
    path: "/nb/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___nb"
  }, {
    path: "/nb/login/redirect",
    component: _3a890b78,
    name: "login-redirect___nb"
  }, {
    path: "/nl/account/communities",
    component: _7d894316,
    name: "account-communities___nl"
  }, {
    path: "/nl/account/favorites",
    component: _79003c26,
    name: "account-favorites___nl"
  }, {
    path: "/nl/account/planner",
    component: _cde3ba3e,
    name: "account-planner___nl"
  }, {
    path: "/nl/account/profile",
    component: _898bac10,
    name: "account-profile___nl"
  }, {
    path: "/nl/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___nl"
  }, {
    path: "/nl/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___nl"
  }, {
    path: "/nl/checkout/window",
    component: _7d87f172,
    name: "checkout-window___nl"
  }, {
    path: "/nl/login/callback",
    component: _28ae462d,
    name: "login-callback___nl"
  }, {
    path: "/nl/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___nl"
  }, {
    path: "/nl/login/redirect",
    component: _3a890b78,
    name: "login-redirect___nl"
  }, {
    path: "/pl/account/communities",
    component: _7d894316,
    name: "account-communities___pl"
  }, {
    path: "/pl/account/favorites",
    component: _79003c26,
    name: "account-favorites___pl"
  }, {
    path: "/pl/account/planner",
    component: _cde3ba3e,
    name: "account-planner___pl"
  }, {
    path: "/pl/account/profile",
    component: _898bac10,
    name: "account-profile___pl"
  }, {
    path: "/pl/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___pl"
  }, {
    path: "/pl/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___pl"
  }, {
    path: "/pl/checkout/window",
    component: _7d87f172,
    name: "checkout-window___pl"
  }, {
    path: "/pl/login/callback",
    component: _28ae462d,
    name: "login-callback___pl"
  }, {
    path: "/pl/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___pl"
  }, {
    path: "/pl/login/redirect",
    component: _3a890b78,
    name: "login-redirect___pl"
  }, {
    path: "/pt/account/communities",
    component: _7d894316,
    name: "account-communities___pt"
  }, {
    path: "/pt/account/favorites",
    component: _79003c26,
    name: "account-favorites___pt"
  }, {
    path: "/pt/account/planner",
    component: _cde3ba3e,
    name: "account-planner___pt"
  }, {
    path: "/pt/account/profile",
    component: _898bac10,
    name: "account-profile___pt"
  }, {
    path: "/pt/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___pt"
  }, {
    path: "/pt/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___pt"
  }, {
    path: "/pt/checkout/window",
    component: _7d87f172,
    name: "checkout-window___pt"
  }, {
    path: "/pt/login/callback",
    component: _28ae462d,
    name: "login-callback___pt"
  }, {
    path: "/pt/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___pt"
  }, {
    path: "/pt/login/redirect",
    component: _3a890b78,
    name: "login-redirect___pt"
  }, {
    path: "/ro/account/communities",
    component: _7d894316,
    name: "account-communities___ro"
  }, {
    path: "/ro/account/favorites",
    component: _79003c26,
    name: "account-favorites___ro"
  }, {
    path: "/ro/account/planner",
    component: _cde3ba3e,
    name: "account-planner___ro"
  }, {
    path: "/ro/account/profile",
    component: _898bac10,
    name: "account-profile___ro"
  }, {
    path: "/ro/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___ro"
  }, {
    path: "/ro/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___ro"
  }, {
    path: "/ro/checkout/window",
    component: _7d87f172,
    name: "checkout-window___ro"
  }, {
    path: "/ro/login/callback",
    component: _28ae462d,
    name: "login-callback___ro"
  }, {
    path: "/ro/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___ro"
  }, {
    path: "/ro/login/redirect",
    component: _3a890b78,
    name: "login-redirect___ro"
  }, {
    path: "/ru/account/communities",
    component: _7d894316,
    name: "account-communities___ru"
  }, {
    path: "/ru/account/favorites",
    component: _79003c26,
    name: "account-favorites___ru"
  }, {
    path: "/ru/account/planner",
    component: _cde3ba3e,
    name: "account-planner___ru"
  }, {
    path: "/ru/account/profile",
    component: _898bac10,
    name: "account-profile___ru"
  }, {
    path: "/ru/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___ru"
  }, {
    path: "/ru/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___ru"
  }, {
    path: "/ru/checkout/window",
    component: _7d87f172,
    name: "checkout-window___ru"
  }, {
    path: "/ru/login/callback",
    component: _28ae462d,
    name: "login-callback___ru"
  }, {
    path: "/ru/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___ru"
  }, {
    path: "/ru/login/redirect",
    component: _3a890b78,
    name: "login-redirect___ru"
  }, {
    path: "/tr/account/communities",
    component: _7d894316,
    name: "account-communities___tr"
  }, {
    path: "/tr/account/favorites",
    component: _79003c26,
    name: "account-favorites___tr"
  }, {
    path: "/tr/account/planner",
    component: _cde3ba3e,
    name: "account-planner___tr"
  }, {
    path: "/tr/account/profile",
    component: _898bac10,
    name: "account-profile___tr"
  }, {
    path: "/tr/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___tr"
  }, {
    path: "/tr/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___tr"
  }, {
    path: "/tr/checkout/window",
    component: _7d87f172,
    name: "checkout-window___tr"
  }, {
    path: "/tr/login/callback",
    component: _28ae462d,
    name: "login-callback___tr"
  }, {
    path: "/tr/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___tr"
  }, {
    path: "/tr/login/redirect",
    component: _3a890b78,
    name: "login-redirect___tr"
  }, {
    path: "/ua/account/communities",
    component: _7d894316,
    name: "account-communities___ua"
  }, {
    path: "/ua/account/favorites",
    component: _79003c26,
    name: "account-favorites___ua"
  }, {
    path: "/ua/account/planner",
    component: _cde3ba3e,
    name: "account-planner___ua"
  }, {
    path: "/ua/account/profile",
    component: _898bac10,
    name: "account-profile___ua"
  }, {
    path: "/ua/account/subscriptions",
    component: _0d45c805,
    name: "account-subscriptions___ua"
  }, {
    path: "/ua/checkout/success",
    component: _a9a93f88,
    name: "checkout-success___ua"
  }, {
    path: "/ua/checkout/window",
    component: _7d87f172,
    name: "checkout-window___ua"
  }, {
    path: "/ua/login/callback",
    component: _28ae462d,
    name: "login-callback___ua"
  }, {
    path: "/ua/login/confirm",
    component: _c3d7bcf0,
    name: "login-confirm___ua"
  }, {
    path: "/ua/login/redirect",
    component: _3a890b78,
    name: "login-redirect___ua"
  }, {
    path: "/cs/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___cs"
  }, {
    path: "/cs/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___cs"
  }, {
    path: "/cs/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___cs"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___cs"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___cs"
    }]
  }, {
    path: "/de-formal/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___de-formal"
  }, {
    path: "/de-formal/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___de-formal"
  }, {
    path: "/de-formal/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___de-formal"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___de-formal"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___de-formal"
    }]
  }, {
    path: "/de/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___de"
  }, {
    path: "/de/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___de"
  }, {
    path: "/de/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___de"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___de"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___de"
    }]
  }, {
    path: "/en-us/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___en-us"
  }, {
    path: "/en-us/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___en-us"
  }, {
    path: "/en-us/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___en-us"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___en-us"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___en-us"
    }]
  }, {
    path: "/en/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___en"
  }, {
    path: "/en/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___en"
  }, {
    path: "/en/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___en"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___en"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___en"
    }]
  }, {
    path: "/es/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___es"
  }, {
    path: "/es/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___es"
  }, {
    path: "/es/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___es"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___es"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___es"
    }]
  }, {
    path: "/fr/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___fr"
  }, {
    path: "/fr/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___fr"
  }, {
    path: "/fr/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___fr"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___fr"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___fr"
    }]
  }, {
    path: "/hu/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___hu"
  }, {
    path: "/hu/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___hu"
  }, {
    path: "/hu/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___hu"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___hu"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___hu"
    }]
  }, {
    path: "/it/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___it"
  }, {
    path: "/it/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___it"
  }, {
    path: "/it/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___it"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___it"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___it"
    }]
  }, {
    path: "/nb/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___nb"
  }, {
    path: "/nb/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___nb"
  }, {
    path: "/nb/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___nb"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___nb"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___nb"
    }]
  }, {
    path: "/nl/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___nl"
  }, {
    path: "/nl/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___nl"
  }, {
    path: "/nl/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___nl"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___nl"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___nl"
    }]
  }, {
    path: "/pl/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___pl"
  }, {
    path: "/pl/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___pl"
  }, {
    path: "/pl/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___pl"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___pl"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___pl"
    }]
  }, {
    path: "/pt/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___pt"
  }, {
    path: "/pt/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___pt"
  }, {
    path: "/pt/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___pt"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___pt"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___pt"
    }]
  }, {
    path: "/ro/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___ro"
  }, {
    path: "/ro/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___ro"
  }, {
    path: "/ro/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___ro"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___ro"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___ro"
    }]
  }, {
    path: "/ru/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___ru"
  }, {
    path: "/ru/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___ru"
  }, {
    path: "/ru/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___ru"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___ru"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___ru"
    }]
  }, {
    path: "/tr/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___tr"
  }, {
    path: "/tr/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___tr"
  }, {
    path: "/tr/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___tr"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___tr"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___tr"
    }]
  }, {
    path: "/ua/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___ua"
  }, {
    path: "/ua/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___ua"
  }, {
    path: "/ua/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___ua"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___ua"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___ua"
    }]
  }, {
    path: "/cs/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___cs"
  }, {
    path: "/de-formal/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___de-formal"
  }, {
    path: "/de/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___de"
  }, {
    path: "/en-us/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___en-us"
  }, {
    path: "/en/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___en"
  }, {
    path: "/es/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___es"
  }, {
    path: "/fr/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___fr"
  }, {
    path: "/hu/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___hu"
  }, {
    path: "/it/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___it"
  }, {
    path: "/nb/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___nb"
  }, {
    path: "/nl/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___nl"
  }, {
    path: "/pl/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___pl"
  }, {
    path: "/pt/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___pt"
  }, {
    path: "/ro/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___ro"
  }, {
    path: "/ru/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___ru"
  }, {
    path: "/tr/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___tr"
  }, {
    path: "/ua/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___ua"
  }, {
    path: "/communities/invites/:communityInviteUuid?",
    component: _8d1d51c6,
    name: "communities-invites-communityInviteUuid___de___default"
  }, {
    path: "/communities/unpersonalized-invites/:unpersonalizedInviteUuid?",
    component: _2cdf28a6,
    name: "communities-unpersonalized-invites-unpersonalizedInviteUuid___de___default"
  }, {
    path: "/cs/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___cs"
  }, {
    path: "/cs/booking/:number?",
    component: _379d12a0,
    name: "booking-number___cs"
  }, {
    path: "/cs/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___cs"
  }, {
    path: "/cs/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___cs"
  }, {
    path: "/cs/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___cs"
  }, {
    path: "/cs/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___cs"
  }, {
    path: "/cs/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___cs"
  }, {
    path: "/cs/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___cs"
  }, {
    path: "/cs/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___cs"
  }, {
    path: "/de-formal/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___de-formal"
  }, {
    path: "/de-formal/booking/:number?",
    component: _379d12a0,
    name: "booking-number___de-formal"
  }, {
    path: "/de-formal/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___de-formal"
  }, {
    path: "/de-formal/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___de-formal"
  }, {
    path: "/de-formal/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___de-formal"
  }, {
    path: "/de-formal/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___de-formal"
  }, {
    path: "/de-formal/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___de-formal"
  }, {
    path: "/de-formal/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___de-formal"
  }, {
    path: "/de-formal/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___de-formal"
  }, {
    path: "/de/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___de"
  }, {
    path: "/de/booking/:number?",
    component: _379d12a0,
    name: "booking-number___de"
  }, {
    path: "/de/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___de"
  }, {
    path: "/de/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___de"
  }, {
    path: "/de/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___de"
  }, {
    path: "/de/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___de"
  }, {
    path: "/de/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___de"
  }, {
    path: "/de/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___de"
  }, {
    path: "/de/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___de"
  }, {
    path: "/en-us/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___en-us"
  }, {
    path: "/en-us/booking/:number?",
    component: _379d12a0,
    name: "booking-number___en-us"
  }, {
    path: "/en-us/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___en-us"
  }, {
    path: "/en-us/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___en-us"
  }, {
    path: "/en-us/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___en-us"
  }, {
    path: "/en-us/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___en-us"
  }, {
    path: "/en-us/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___en-us"
  }, {
    path: "/en-us/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___en-us"
  }, {
    path: "/en-us/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___en-us"
  }, {
    path: "/en/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___en"
  }, {
    path: "/en/booking/:number?",
    component: _379d12a0,
    name: "booking-number___en"
  }, {
    path: "/en/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___en"
  }, {
    path: "/en/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___en"
  }, {
    path: "/en/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___en"
  }, {
    path: "/en/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___en"
  }, {
    path: "/en/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___en"
  }, {
    path: "/en/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___en"
  }, {
    path: "/en/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___en"
  }, {
    path: "/es/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___es"
  }, {
    path: "/es/booking/:number?",
    component: _379d12a0,
    name: "booking-number___es"
  }, {
    path: "/es/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___es"
  }, {
    path: "/es/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___es"
  }, {
    path: "/es/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___es"
  }, {
    path: "/es/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___es"
  }, {
    path: "/es/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___es"
  }, {
    path: "/es/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___es"
  }, {
    path: "/es/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___es"
  }, {
    path: "/explore/c/:communitySlug?",
    component: _30975bdf,
    children: [{
      path: "",
      component: _22c13f22,
      name: "explore-c-communitySlug___de___default"
    }, {
      path: "resources",
      component: _edf65216,
      name: "explore-c-communitySlug-resources___de___default"
    }, {
      path: "services",
      component: _7c0a6c3e,
      name: "explore-c-communitySlug-services___de___default"
    }]
  }, {
    path: "/fr/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___fr"
  }, {
    path: "/fr/booking/:number?",
    component: _379d12a0,
    name: "booking-number___fr"
  }, {
    path: "/fr/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___fr"
  }, {
    path: "/fr/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___fr"
  }, {
    path: "/fr/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___fr"
  }, {
    path: "/fr/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___fr"
  }, {
    path: "/fr/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___fr"
  }, {
    path: "/fr/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___fr"
  }, {
    path: "/fr/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___fr"
  }, {
    path: "/hu/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___hu"
  }, {
    path: "/hu/booking/:number?",
    component: _379d12a0,
    name: "booking-number___hu"
  }, {
    path: "/hu/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___hu"
  }, {
    path: "/hu/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___hu"
  }, {
    path: "/hu/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___hu"
  }, {
    path: "/hu/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___hu"
  }, {
    path: "/hu/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___hu"
  }, {
    path: "/hu/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___hu"
  }, {
    path: "/hu/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___hu"
  }, {
    path: "/it/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___it"
  }, {
    path: "/it/booking/:number?",
    component: _379d12a0,
    name: "booking-number___it"
  }, {
    path: "/it/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___it"
  }, {
    path: "/it/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___it"
  }, {
    path: "/it/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___it"
  }, {
    path: "/it/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___it"
  }, {
    path: "/it/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___it"
  }, {
    path: "/it/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___it"
  }, {
    path: "/it/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___it"
  }, {
    path: "/nb/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___nb"
  }, {
    path: "/nb/booking/:number?",
    component: _379d12a0,
    name: "booking-number___nb"
  }, {
    path: "/nb/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___nb"
  }, {
    path: "/nb/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___nb"
  }, {
    path: "/nb/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___nb"
  }, {
    path: "/nb/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___nb"
  }, {
    path: "/nb/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___nb"
  }, {
    path: "/nb/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___nb"
  }, {
    path: "/nb/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___nb"
  }, {
    path: "/nl/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___nl"
  }, {
    path: "/nl/booking/:number?",
    component: _379d12a0,
    name: "booking-number___nl"
  }, {
    path: "/nl/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___nl"
  }, {
    path: "/nl/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___nl"
  }, {
    path: "/nl/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___nl"
  }, {
    path: "/nl/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___nl"
  }, {
    path: "/nl/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___nl"
  }, {
    path: "/nl/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___nl"
  }, {
    path: "/nl/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___nl"
  }, {
    path: "/pl/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___pl"
  }, {
    path: "/pl/booking/:number?",
    component: _379d12a0,
    name: "booking-number___pl"
  }, {
    path: "/pl/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___pl"
  }, {
    path: "/pl/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___pl"
  }, {
    path: "/pl/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___pl"
  }, {
    path: "/pl/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___pl"
  }, {
    path: "/pl/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___pl"
  }, {
    path: "/pl/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___pl"
  }, {
    path: "/pl/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___pl"
  }, {
    path: "/pt/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___pt"
  }, {
    path: "/pt/booking/:number?",
    component: _379d12a0,
    name: "booking-number___pt"
  }, {
    path: "/pt/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___pt"
  }, {
    path: "/pt/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___pt"
  }, {
    path: "/pt/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___pt"
  }, {
    path: "/pt/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___pt"
  }, {
    path: "/pt/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___pt"
  }, {
    path: "/pt/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___pt"
  }, {
    path: "/pt/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___pt"
  }, {
    path: "/ro/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___ro"
  }, {
    path: "/ro/booking/:number?",
    component: _379d12a0,
    name: "booking-number___ro"
  }, {
    path: "/ro/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___ro"
  }, {
    path: "/ro/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___ro"
  }, {
    path: "/ro/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___ro"
  }, {
    path: "/ro/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___ro"
  }, {
    path: "/ro/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___ro"
  }, {
    path: "/ro/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___ro"
  }, {
    path: "/ro/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___ro"
  }, {
    path: "/ru/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___ru"
  }, {
    path: "/ru/booking/:number?",
    component: _379d12a0,
    name: "booking-number___ru"
  }, {
    path: "/ru/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___ru"
  }, {
    path: "/ru/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___ru"
  }, {
    path: "/ru/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___ru"
  }, {
    path: "/ru/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___ru"
  }, {
    path: "/ru/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___ru"
  }, {
    path: "/ru/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___ru"
  }, {
    path: "/ru/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___ru"
  }, {
    path: "/tr/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___tr"
  }, {
    path: "/tr/booking/:number?",
    component: _379d12a0,
    name: "booking-number___tr"
  }, {
    path: "/tr/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___tr"
  }, {
    path: "/tr/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___tr"
  }, {
    path: "/tr/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___tr"
  }, {
    path: "/tr/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___tr"
  }, {
    path: "/tr/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___tr"
  }, {
    path: "/tr/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___tr"
  }, {
    path: "/tr/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___tr"
  }, {
    path: "/ua/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___ua"
  }, {
    path: "/ua/booking/:number?",
    component: _379d12a0,
    name: "booking-number___ua"
  }, {
    path: "/ua/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___ua"
  }, {
    path: "/ua/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___ua"
  }, {
    path: "/ua/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___ua"
  }, {
    path: "/ua/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___ua"
  }, {
    path: "/ua/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___ua"
  }, {
    path: "/ua/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___ua"
  }, {
    path: "/ua/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___ua"
  }, {
    path: "/account/subscriptions/:subscriptionId/claim",
    component: _b39654d2,
    name: "account-subscriptions-subscriptionId-claim___de___default"
  }, {
    path: "/cs/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___cs"
  }, {
    path: "/de-formal/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___de-formal"
  }, {
    path: "/de/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___de"
  }, {
    path: "/en-us/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___en-us"
  }, {
    path: "/en/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___en"
  }, {
    path: "/es/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___es"
  }, {
    path: "/fr/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___fr"
  }, {
    path: "/hu/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___hu"
  }, {
    path: "/it/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___it"
  }, {
    path: "/nb/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___nb"
  }, {
    path: "/nl/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___nl"
  }, {
    path: "/pl/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___pl"
  }, {
    path: "/pt/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___pt"
  }, {
    path: "/ro/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___ro"
  }, {
    path: "/ru/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___ru"
  }, {
    path: "/tr/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___tr"
  }, {
    path: "/ua/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___ua"
  }, {
    path: "/cs/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___cs"
  }, {
    path: "/cs/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___cs"
  }, {
    path: "/de-formal/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___de-formal"
  }, {
    path: "/de-formal/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___de-formal"
  }, {
    path: "/de/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___de"
  }, {
    path: "/de/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___de"
  }, {
    path: "/en-us/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___en-us"
  }, {
    path: "/en-us/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___en-us"
  }, {
    path: "/en/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___en"
  }, {
    path: "/en/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___en"
  }, {
    path: "/es/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___es"
  }, {
    path: "/es/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___es"
  }, {
    path: "/fr/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___fr"
  }, {
    path: "/fr/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___fr"
  }, {
    path: "/hu/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___hu"
  }, {
    path: "/hu/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___hu"
  }, {
    path: "/it/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___it"
  }, {
    path: "/it/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___it"
  }, {
    path: "/nb/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___nb"
  }, {
    path: "/nb/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___nb"
  }, {
    path: "/nl/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___nl"
  }, {
    path: "/nl/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___nl"
  }, {
    path: "/pl/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___pl"
  }, {
    path: "/pl/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___pl"
  }, {
    path: "/pt/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___pt"
  }, {
    path: "/pt/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___pt"
  }, {
    path: "/ro/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___ro"
  }, {
    path: "/ro/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___ro"
  }, {
    path: "/ru/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___ru"
  }, {
    path: "/ru/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___ru"
  }, {
    path: "/tr/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___tr"
  }, {
    path: "/tr/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___tr"
  }, {
    path: "/ua/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___ua"
  }, {
    path: "/ua/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___ua"
  }, {
    path: "/book/:resourceSlug",
    component: _7e66a3d2,
    name: "book-resourceSlug___de___default"
  }, {
    path: "/booking/:number?",
    component: _379d12a0,
    name: "booking-number___de___default"
  }, {
    path: "/cs/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___cs"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___cs"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___cs"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___cs"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___cs"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___cs"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___cs"
    }]
  }, {
    path: "/de-formal/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___de-formal"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___de-formal"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___de-formal"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___de-formal"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___de-formal"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___de-formal"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___de-formal"
    }]
  }, {
    path: "/de/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___de"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___de"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___de"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___de"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___de"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___de"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___de"
    }]
  }, {
    path: "/en-us/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___en-us"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___en-us"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___en-us"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___en-us"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___en-us"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___en-us"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___en-us"
    }]
  }, {
    path: "/en/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___en"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___en"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___en"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___en"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___en"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___en"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___en"
    }]
  }, {
    path: "/es/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___es"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___es"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___es"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___es"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___es"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___es"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___es"
    }]
  }, {
    path: "/explore/:lensSlug",
    component: _7a56c41d,
    name: "explore-lensSlug___de___default"
  }, {
    path: "/fr/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___fr"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___fr"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___fr"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___fr"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___fr"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___fr"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___fr"
    }]
  }, {
    path: "/hu/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___hu"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___hu"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___hu"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___hu"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___hu"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___hu"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___hu"
    }]
  }, {
    path: "/invoices/:invoiceUuid",
    component: _173e0b43,
    name: "invoices-invoiceUuid___de___default"
  }, {
    path: "/it/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___it"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___it"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___it"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___it"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___it"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___it"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___it"
    }]
  }, {
    path: "/legal-documents/:legalDocumentUuid?",
    component: _42cba69d,
    name: "legal-documents-legalDocumentUuid___de___default"
  }, {
    path: "/nb/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___nb"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___nb"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___nb"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___nb"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___nb"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___nb"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___nb"
    }]
  }, {
    path: "/nl/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___nl"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___nl"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___nl"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___nl"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___nl"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___nl"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___nl"
    }]
  }, {
    path: "/orders/:number",
    component: _52fe6fc0,
    name: "orders-number___de___default"
  }, {
    path: "/payments/:uuid?",
    component: _137c6a8c,
    name: "payments-uuid___de___default"
  }, {
    path: "/pl/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___pl"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___pl"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___pl"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___pl"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___pl"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___pl"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___pl"
    }]
  }, {
    path: "/pt/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___pt"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___pt"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___pt"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___pt"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___pt"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___pt"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___pt"
    }]
  }, {
    path: "/ro/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___ro"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___ro"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___ro"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___ro"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___ro"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___ro"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___ro"
    }]
  }, {
    path: "/ru/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___ru"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___ru"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___ru"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___ru"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___ru"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___ru"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___ru"
    }]
  }, {
    path: "/services/:serviceSlug?",
    component: _0e3a40c0,
    name: "services-serviceSlug___de___default"
  }, {
    path: "/test-results/:testResultId",
    component: _020b549a,
    name: "test-results-testResultId___de___default"
  }, {
    path: "/tr/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___tr"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___tr"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___tr"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___tr"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___tr"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___tr"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___tr"
    }]
  }, {
    path: "/ua/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___ua"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___ua"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___ua"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___ua"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___ua"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___ua"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___ua"
    }]
  }, {
    path: "/invoices/:invoiceUuid?/pay",
    component: _7dd1cb4e,
    name: "invoices-invoiceUuid-pay___de___default"
  }, {
    path: "/book/:resourceSlug?/components/calendar",
    component: _bbd24bc8,
    name: "book-resourceSlug-components-calendar___de___default"
  }, {
    path: "/book/:resourceSlug?/components/panel",
    component: _11fa5516,
    name: "book-resourceSlug-components-panel___de___default"
  }, {
    path: "/",
    component: _79baca40,
    name: "index___de___default"
  }, {
    path: "/:organizationSlug",
    component: _292b6fcb,
    children: [{
      path: "",
      component: _36f9b20e,
      name: "organizationSlug___de___default"
    }, {
      path: "about",
      component: _1741d749,
      name: "organizationSlug-about___de___default"
    }, {
      path: "packages",
      component: _0bb966e1,
      name: "organizationSlug-packages___de___default"
    }, {
      path: "resources",
      component: _28564da4,
      name: "organizationSlug-resources___de___default"
    }, {
      path: "services",
      component: _dddffc5c,
      name: "organizationSlug-services___de___default"
    }, {
      path: "subscriptions",
      component: _0cb4839c,
      name: "organizationSlug-subscriptions___de___default"
    }, {
      path: "resources/:resourceSlug",
      component: _22aa3d8a,
      name: "organizationSlug-resources-resourceSlug___de___default"
    }]
  }],

  parseQuery: (s) => {
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      return require('@/shared/utils/QueryParser').parse(s);
    },
  stringifyQuery: (o) => {
      if (!o) {
        return '';
      }
      // eslint-disable-next-line @typescript-eslint/no-var-requires
      const r = require('@/shared/utils/QueryParser').stringify(o);
      return r ? '?' + r : '';
    },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
