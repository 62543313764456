import { AxiosError, AxiosInstance } from 'axios'
import { get, debounce, set } from 'lodash'
import { clone, isEmptyObject } from '../utils/Helpers'
import { difference } from '@/shared/utils/ObjectHelpers'
import { ComputedOptions } from 'vue'
import { Store } from 'vuex'

type Dict = { [key: string]: any }

const defaultDelay = 300

/**
 * Settings Manager
 */
export class SettingsManager<R = { [key: string]: any }> {
  apiBaseUrl: string
  $store: Store<any>
  path: string
  basePath: string
  protected client: AxiosInstance
  protected readonly autosave: boolean
  isSaving = false
  storePath: string

  constructor(
    client: AxiosInstance,
    store: Store<any>,
    apiBaseUrl: string,
    path = '',
    autosave = false,
    basePath = 'api/settings',
    storePath = 'settings'
  ) {
    this.$store = store
    this.apiBaseUrl = apiBaseUrl
    this.path = path
    this.basePath = basePath
    this.autosave = autosave
    this.client = client
    this.storePath = storePath
  }

  get loaded() {
    return this.settingsStore !== null
  }

  get apiPath() {
    return `${this.basePath}/${this.path}`
  }

  get settings(): R | null {
    return this.settingsStore as R | null
  }

  /**
   * Get changes from last save
   */
  get changes(): Partial<R> {
    if (this.settingsStore !== null) {
      return difference(this.settingsStore, this.originalSettings) as Partial<R>
    } else {
      return {}
    }
  }

  /**
   * Check if any settings were changed
   */
  get isDirty(): boolean {
    return Object.keys(this.changes).length > 0
  }

  /**
   * Get settings from store
   */
  get settingsStore(): Dict | null {
    return this.$store.getters[`${this.storePath}/getField`](this.path)
  }

  /**
   * Update settings in store
   * @param newSettings
   */
  set settingsStore(newSettings) {
    this.$store.commit(`${this.storePath}/updateField`, {
      path: this.path,
      value: newSettings,
    })
  }

  /**
   * Get settings from store
   */
  get originalSettings(): Dict {
    return this.$store.getters[`${this.storePath}/getField`](
      `_original.${this.path}`
    )
  }

  /**
   * Update settings in store
   * @param newSettings
   */
  set originalSettings(newSettings) {
    this.$store.commit(`${this.storePath}/updateField`, {
      path: `_original.${this.path}`,
      value: newSettings,
    })
  }

  updateClient = (client: AxiosInstance) => {
    this.client = client
  }

  /**
   * Get single setting
   * @param path
   */
  get = (path: string): any => {
    if (!this.loaded) return undefined
    return get(this.settingsStore, path)
  }

  /**
   * Set single setting
   * @param path
   * @param value
   * @param autosave
   * @param debounce
   */
  set = (
    path: string,
    value: any,
    autosave = this.autosave,
    debounce = true
  ): void => {
    if (!this.loaded) return
    if (value === '') value = null
    // get base path
    const rootPath = path.split('.')[0]
    // safely handle nested structure with nullable keys
    if (path !== rootPath) {
      let rootObj = this.get(rootPath)
      if (!rootObj) {
        rootObj = {}
      } else {
        rootObj = clone(rootObj)
      }
      const keyPath = path.split('.').slice(1).join('.')
      set(rootObj, keyPath, value)
      path = rootPath
      value = rootObj
    }

    this.$store.commit(`${this.storePath}/updateField`, {
      path: `${this.path}.${path}`,
      value: value,
    })
    if (autosave) {
      if (debounce) {
        this.debounceUpdate()
      } else {
        this.save()
      }
    }
  }

  /**
   * Load settings from api
   */
  loadSettings = (shouldThrowException = false) => {
    return this.client
      .get(this.apiPath)
      .then((response) => {
        if (response.data) {
          this.settingsStore = response.data
          this.originalSettings = clone(response.data)
        }
        return response.data
      })
      .catch((e: AxiosError) => {
        if (e?.response?.status === 404 && !shouldThrowException) {
          // silently handle 404 error
          this.settingsStore = null
          console.warn(`Settings ${this.path} not found.`)
        } else {
          throw e
        }
      })
  }

  /**
   * Save current changes
   */
  save = () => {
    const changes = this.changes
    if (isEmptyObject(changes)) return
    const backup = clone(this.originalSettings)
    this.originalSettings = clone(this.settingsStore)
    this.isSaving = true
    return this.client
      .put(this.apiPath, changes)
      .then((response) => {
        if (response.data) {
          this.originalSettings = response.data
        }
        this.isSaving = false
        return response.data
      })
      .catch(() => {
        this.originalSettings = backup
      })
      .finally(() => {
        this.isSaving = false
      })
  }

  /**
   * Reset store to null
   */
  reset = () => {
    this.settingsStore = null
  }

  /**
   * Debounce update settings
   */
  debounceUpdate = debounce(async () => {
    await this.save()
  }, defaultDelay)
}

// Never used:
// type MapSettings = <
//   V extends Record<string, string>,
//   S extends { [P in keyof V]: any } = any
// >(
//   manager: SettingsManager,
//   fields: V,
//   autosave: boolean,
//   debounce: boolean
// ) => {
//   [P in keyof V]: {
//     get: () => S[P]
//     set: (value: S[P]) => void
//   }
// }

/**
 * Map settings field to vue components
 * @param manager
 * @param fields
 * @param autosave
 * @param debounce
 */
export function mapSettings<
  V extends Record<string, string>,
  S extends { [P in keyof V]: any } = any
>(manager: SettingsManager, fields: V, autosave = true, debounce = true) {
  // type IndexType = keyof typeof fields
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const computedProps: {
    [P in keyof V]: ComputedOptions<S[P]>
  } = {}
  let mappings: { [key: string]: string } = {}
  if (Array.isArray(fields)) {
    fields.forEach((path) => {
      const key = path.split('.').pop()
      if (!key) return
      mappings[key] = path
    })
  } else {
    mappings = fields
  }
  Object.keys(mappings).forEach((key: string) => {
    const path = mappings[key]
    if (!path) return
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    computedProps[key] = {
      get: () => {
        return manager.get(path)
      },
      set: (value: any) => {
        return manager.set(path, value, autosave, debounce)
      },
    }
  })
  return computedProps
}

/**
 * TODO make more cleaner
 * @param manager
 * @param fields
 * @param autosave
 * @param debounce
 */
export function mapSettingsArray(
  manager: SettingsManager,
  fields: string[],
  autosave = true,
  debounce = true
) {
  type ValuesOf<T extends any[]> = T[number]
  type IndexType = ValuesOf<typeof fields>
  const computedProps: {
    [k in IndexType]: {
      get: () => any
      set: (value: any) => void
    }
  } = {}
  let mappings: { [key: string]: string } = {}
  if (Array.isArray(fields)) {
    fields.forEach((path) => {
      const key = path.split('.').pop()
      if (!key) return
      mappings[key] = path
    })
  } else {
    mappings = fields
  }
  Object.keys(mappings).forEach((key) => {
    const path = mappings[key]
    if (!path) return
    computedProps[key] = {
      get: () => {
        return manager.get(path)
      },
      set: (value: any) => {
        return manager.set(path, value, autosave, debounce)
      },
    }
  })
  return computedProps
}

export class CommunityAccountSettingsManager extends SettingsManager {
  communitySlug: string | null = null

  get apiPath() {
    if (this.communitySlug) {
      return `${this.basePath}/${this.path}/${this.communitySlug}`
    } else {
      return `${this.basePath}/${this.path}`
    }
  }

  setCommunitySlug(communitySlug: string) {
    this.communitySlug = communitySlug
  }
}
