import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { Context, Plugin } from '@nuxt/types'

/**
 * Google tag manager plugin
 *
 * @link https://matteo-gabriele.gitbook.io/vue-gtag
 */
const vueGtag: Plugin = ({ app, $config }: Context) => {
  // check env for GA_ENABLED
  if (!$config.gaEnabled) {
    return
  }
  if (process.client) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: $config.gaId,
          params: {
            anonymize_ip: true,
          },
        },
        bootstrap: false,
        // bootstrap: false,
        enabled: false, // disabled by default, activate after cookie consent
        appName: $config.appName ?? 'Anny',
      },
      app.router
    )
  }
}

export default vueGtag
