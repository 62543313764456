<template>
  <div class="eb-notification-group" :style="{ top: top + 'px' }">
    <transition-group v-if="isMounted" name="eb-fade-left">
      <Notification
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </transition-group>
  </div>
</template>

<script>
import Notification from './Notification.vue'
import { mapState } from 'vuex'

export default {
  name: 'NotificationGroup',
  components: {
    Notification,
  },
  props: {
    top: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isMounted: false,
    }
  },
  computed: {
    ...mapState('ux', ['notifications']),
  },
  mounted() {
    this.isMounted = true
  },
}
</script>

<style lang="scss">
.eb-notification-group {
  position: fixed;
  right: 1rem;
  margin-top: 1rem;
  z-index: 5000;
}
</style>
