
import BaseButton from '@/shared/components/buttons/BaseButton.vue'
import Popper from '../popovers/Popper.vue'
import Vue, { PropOptions } from 'vue'
import { Options as PopperOptions } from '@popperjs/core/lib/types'

export default Vue.extend({
  name: 'BaseDropdownPopper',
  components: { BaseButton, Popper },
  props: {
    label: {
      type: String,
      default: '',
    } as PropOptions<string>,
    align: {
      type: String,
      default: 'left',
    } as PropOptions<string>,
    // optional exact popper placement
    placement: {
      type: String,
      required: false,
      default: undefined,
    } as PropOptions<PopperOptions['placement'] | undefined>,
    open: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    fixedWidth: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showDefaultTrigger: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      isActive: this.open,
      dropdownId: '',
    }
  },
  computed: {
    computedPlacement(): PopperOptions['placement'] {
      if (this.placement) return this.placement
      if (this.align === 'left') {
        return 'bottom-start'
      } else if (this.align === 'right') {
        return 'bottom-end'
      } else {
        return 'bottom'
      }
    },
  },
  watch: {
    isActive(newValue: boolean, oldValue: boolean) {
      if (newValue !== oldValue) {
        this.$emit('isActiveChange', newValue)
      }
    },
  },
  mounted() {
    this.dropdownId = 'dropdown_' + this._uid
  },
  methods: {
    /**
     * Toggle dropdown
     */
    toggleDropdown() {
      this.isActive = !this.isActive
    },
    /**
     * Toggle dropdown
     */
    closeDropdown() {
      this.isActive = false
    },
  },
})
