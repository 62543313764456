import dayjs from 'dayjs'
import { Store } from 'vuex'

export function applyTime(source: string, destination: string): string {
  let sourceDate = dayjs(source)
  if (!sourceDate.isValid()) sourceDate = dayjs()

  let destinationDate = dayjs(destination)
  if (!destinationDate.isValid()) destinationDate = dayjs()

  return destinationDate
    .hour(sourceDate.hour())
    .minute(sourceDate.minute())
    .second(sourceDate.second())
    .format()
}

/**
 * Apply a time string to a datestring
 * @param {string} timestring
 * @param {string} date
 * @returns {string}
 */
export function applyTimestring(timestring: string, date: string): string {
  const timeArray = timestring.split(':')
  if (timeArray.length === 2) {
    // probably the seconds are missing
    timeArray.push('00')
  }

  return dayjs(date)
    .hour(parseInt(timeArray[0]))
    .minute(parseInt(timeArray[1]))
    .second(parseInt(timeArray[2]))
    .format()
}

/**
 * Calculate the hours, minutes and second between two dates
 *
 * @param startDate
 * @param endDate
 * @param withHours
 * @returns {{seconds: number, minutes: number}|null|{hours: number, seconds: number, minutes: number}}
 */
export function calculateTimeDifference(
  startDate: string,
  endDate: string,
  withHours = false
) {
  const totalSeconds = dayjs(endDate).diff(dayjs(startDate), 'second')
  if (totalSeconds > 0) {
    const seconds = Math.floor(totalSeconds % 60)
    const minutes = Math.floor(totalSeconds / 60)

    if (withHours) {
      const hours = Math.floor(minutes / 60)
      return {
        hours,
        minutes,
        seconds,
      }
    }

    return {
      minutes,
      seconds,
    }
  } else {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    }
  }
}

/**
 *
 * @param store
 * @param date
 * @param baseDate
 * @param abs
 */
export function getRelativeDateString(
  store: Store<any>,
  date: string | null,
  baseDate?: string,
  abs = false
): string | null {
  if (!date) {
    return null
  }

  const nowDayjs = dayjs(store.getters['ux/getField']('now'))

  let totalSeconds = dayjs(baseDate ?? nowDayjs).diff(date, 'second', false)
  if (abs) {
    totalSeconds = Math.abs(totalSeconds)
  }

  if (totalSeconds < 60 * 60) {
    let seconds: number | string = Math.floor(totalSeconds % 60)
    if (seconds < 10) {
      seconds = `0${seconds}`
    }
    let minutes: number | string = Math.floor((totalSeconds / 60) % 60)
    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    return `${minutes}:${seconds}`
  }

  return dayjs(date).from(nowDayjs)
}
