
import Vue, { PropType } from 'vue'
import Logo from '@/shared/components/partials/Logo.vue'
import { mapFields } from 'vuex-map-fields'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import SquareNavigationList, {
  SquareNavItem,
} from '@/shared/components/partials/SquareNavigationList.vue'
import ExternalLink from '@/shared/components/navigation/ExternalLink.vue'
import Popper from '@/shared/components/popovers/Popper.vue'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'

const uxStoreFields = {
  sidebar: 'sidebar',
  bottomBar: 'bottomBar',
  contextNavigationHeight: 'contextNavigationHeight',
}

export default Vue.extend({
  name: 'AppNavigation',
  components: {
    SquareNavigationList,
    Logo,
    ExternalLink,
    Popper,
  },
  props: {
    logoPath: {
      type: String as PropType<string>,
      required: true,
    },
    position: {
      type: String as PropType<'left' | 'bottom'>,
      required: true,
    },
  },
  data() {
    return {
      navItemSize: 38,
      hoveredItem: null,
    }
  },
  // $config.platformLogoUrlIcon
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    styleBinding(): Record<string, any> {
      const width =
        this.position === 'left'
          ? !this.sidebar.hidden
            ? this.sidebar.width + 'px'
            : '0'
          : 'unset'
      const height =
        this.position === 'bottom' ? this.$ux.bottomBarHeight + 'px' : 'unset'
      // hide bottom bar on mobile
      const display: string | undefined =
        this.position === 'bottom' && this.bottomBar.hiddenMobile
          ? 'hidden'
          : undefined
      return {
        display,
        width,
        height,
        '--nav-item-size': this.navItemSize + 'px',
      }
    },
    navItems(): SquareNavItem[] {
      return [
        {
          key: 'bookings',
          exact: true,
          visible: true,
          title: 'shop.navigation.bookings.label',
          i18n: true,
          icon: 'calendar-check',
          iconStyle: 'far',
          route: { name: 'account' },
          iconColor: 'var(--text-secondary-color)',
          color: 'var(--panel-background)',
          lazyIcon: false,
        },
        {
          key: 'planner',
          exact: true,
          visible: true,
          title: 'shop.navigation.planner.label',
          i18n: true,
          icon: 'book-open',
          iconStyle: 'far',
          route: { name: 'account-planner' },
          iconColor: 'var(--text-secondary-color)',
          color: 'var(--panel-background)',
          lazyIcon: false,
        },
        {
          key: 'favorites',
          exact: true,
          visible: true,
          title: 'shop.navigation.favorites.label',
          i18n: true,
          icon: 'heart',
          iconStyle: 'far',
          route: { name: 'account-favorites' },
          iconColor: 'var(--text-secondary-color)',
          color: 'var(--panel-background)',
          lazyIcon: false,
        },
        {
          key: 'profile',
          exact: true,
          visible: true,
          title: 'shop.navigation.account.label',
          i18n: true,
          icon: 'user',
          iconStyle: 'far',
          route: { name: 'account-profile' },
          iconColor: 'var(--text-secondary-color)',
          color: 'var(--panel-background)',
          lazyIcon: false,
        },
      ]
    },
    /**
     * Map communities to dynamic nav items.
     */
    dynamicNavItems(): SquareNavItem[] {
      const communities: Community[] =
        this.$authService.account?.communities ?? []
      return (
        communities.map((community) => ({
          title: community.name ?? '',
          exact: false,
          visible: true,
          i18n: false,
          key: 'community_' + community.id,
          route: {
            name: 'explore-c-communitySlug',
            params: { communitySlug: community.id },
          },
          icon: community.icon ?? 'star',
          iconColor: 'var(--text-secondary-color)',
          color: 'var(--panel-background)',
          lazyIcon: true,
          // color: community.color ?? undefined,
        })) ?? []
      )
    },
  },
  methods: {
    /**
     * Open tooltip.
     * @param item
     * @param el
     */
    openTooltip({ item, el }: { item: SquareNavItem; el: HTMLElement }) {
      const tooltipPopper = this.$refs.tooltipPopper as InstanceType<
        typeof Popper
      > | null
      if (tooltipPopper) {
        tooltipPopper.open(el, item)
      }
    },
    closeTooltip() {
      const tooltipPopper = this.$refs.tooltipPopper as InstanceType<
        typeof Popper
      > | null
      tooltipPopper?.close()
    },
  },
})
