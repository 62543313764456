
import Vue from 'vue'
import PageFooter from '@/shared/components/partials/Footer.vue'
import MiniFooter from '@/shared/components/partials/MiniFooter.vue'
import BaseLayout from '@/shared/layouts/BaseLayout.vue'
import Watcher from '../components/partials/Watcher.vue'
import { mapFields } from 'vuex-map-fields'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { MetaInfo } from 'vue-meta'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import ContextNavigation from '@/bookingbuddy-shop/components/partials/ContextNavigation.vue'

const uxStoreFields = {
  contextNavigationItem: 'contextNavigationItem',
  backgroundImageUrl: 'backgroundImageUrl',
}

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    ContextNavigation,
    Watcher,
    BaseLayout,
    PageFooter,
    MiniFooter,
  },
  middleware: ['browserSupport', 'preferredLocale', 'shouldLoginQuery'],
  data() {
    return {
      platform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
    }
  },
  head(): MetaInfo {
    const svgFaviconUrl = this.platform?.config.svgFaviconUrl
    const platformName = this.platform?.name ?? 'anny'
    let links: MetaInfo['link'] = []
    if (this.platform) {
      links = svgFaviconUrl
        ? [
            {
              rel: 'icon',
              type: 'image/svg+xm',
              href: svgFaviconUrl,
              sizes: 'any',
            },
          ]
        : []
    } else {
      links = [
        {
          rel: 'apple-touch-startup-image',
          hid: 'apple-touch-startup-image',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'apple-touch-icon',
          hid: 'apple-touch-icon',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'shortcut icon',
          href: '_favicons/favicon.ico',
          hid: 'shortcut icon',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-16x16.png',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-32x32.png',
          sizes: '32x32',
        },
      ]
    }

    return {
      title: platformName,
      titleTemplate: `%s | ${platformName}`,
      link: links,
    }
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    logoPath(): string {
      if (this.platform) {
        return this.localePath({
          name: 'explore',
        })
      }
      return this.localePath({
        name: 'checkout',
      })
    },
    previewToken(): string {
      return String(this.$route.query?.preview_token ?? '')
    },
    inPreviewMode(): boolean {
      return this.previewToken !== ''
    },
    customStyles(): string | null {
      const platformStyles = this.platform?.config?.styles ?? null
      const customStyles = this.$store.getters['ux/getCustomGlobalCss']
      if (!platformStyles && !customStyles) {
        return null
      }
      let css = ''
      if (platformStyles) {
        css += platformStyles
      }
      if (customStyles) {
        css += customStyles
      }
      return css
    },
  },
  async mounted() {
    // handle preview mode
    if (this.inPreviewMode) {
      await this.pushPreviewNotification()
    }
  },
  methods: {
    /**
     * Show a notification that the view is currently in preview
     * @return {Promise<void>}
     */
    async pushPreviewNotification(): Promise<void> {
      this.$store.commit('ux/showStatusBar', {
        message: this.$t('pages.organizations.previewMode'),
        type: 'warning',
        fixed: false,
      })
      await this.$store.dispatch('ux/pushNotification', {
        type: 'default',
        html: this.$i18n.t(
          'pages.organizations.resources.resourceSlug.notifications.preview'
        ),
        persistent: true,
      })
    },
  },
})
