import { Attr, BelongsTo, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Test } from '@/shared/jsonapi-orm/tests/Test'
import { User } from '@/shared/jsonapi-orm/bookingbuddy/User'
import {
  DownloadMimeType,
  downloadResponse,
} from '@/shared/utils/DownloadHelper'
import { CustomEntryMap } from '@/shared/jsonapi-orm/custom-fields/CustomEntry'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'

export interface TestDevice {
  id_device: string
  commercial_name: string
  manufacturer: Record<string, string> | null
}

export class PublicTestResult extends ApiResource {
  static jsonApiType = 'test-results'

  @Attr() conductedAt: string | null
  @Attr() testType: string | null
  @Attr() hasResult: boolean | null
  @Attr() sendCustomerNotification: boolean | null
  @Attr({}) customEntryMap: CustomEntryMap

  @BelongsTo() test?: Test
  @BelongsTo() resource?: Resource

  @Meta() certificateUrl: string | null
  @Meta() hasSecret: boolean | null

  // CWA integration meta data
  @Meta() cwaAvailable?: boolean | null
  @Meta() cwaAcceptedAt?: string | null
  @Meta() cwaUrl?: string | null
  @Meta() cwaChoice?: number | null
  @Meta() cwaDccActivated?: boolean | null
  @Meta() cwaPossibleTestDevices?: TestDevice[] | null
  @Meta() cwaUsedTestDevice?: TestDevice | null
  @Meta() cwaPossibleCovidTestKits?: string[] | null
  @Meta() cwaUsedCovidTestKit?: number | null

  // Luca integration meta data
  @Meta() lucaAvailable?: boolean | null
  @Meta() lucaJwt?: string | null

  /**
   * Build url for downloading certificate
   * @param locale
   * @param secret
   */
  getDownloadCertificateUrl(locale?: string, secret?: string): string {
    let url = String(this.certificateUrl)
    if (secret) {
      if (url?.includes('?')) {
        url = this.certificateUrl + '&secret=' + secret
      } else {
        url = this.certificateUrl + '?secret=' + secret
      }
    }

    if (locale) {
      // Split url
      const parts = url.split('?') as string[]
      const query = parts[1]
        .split('&')
        .map((q: string) => {
          if (q.startsWith('locale')) {
            return 'locale=' + locale
          }

          return q
        })
        .join('&')

      url = parts[0] + '?' + query
    }
    return url
  }

  async checkAccess(
    locale?: string,
    secret?: string,
    dontCheckResultExistence = false
  ): Promise<boolean> {
    const response = await this.api().request('check', 'GET', {
      params: {
        secret: secret,
        locale: locale,
        dcre: dontCheckResultExistence ? 1 : 0,
      },
    })

    return !!response.data?.access
  }

  /**
   *
   * @param secret
   * @param locale
   * @param newTab
   */
  async downloadCertificate(
    locale?: string,
    secret?: string,
    newTab = true
  ): Promise<boolean> {
    const url = this.getDownloadCertificateUrl(locale, secret)
    if (newTab && window) {
      window.open(url)
      return true
    } else {
      // download pdf certificate without opening a new tab
      try {
        const response = await this.axiosClient.get(url, {
          responseType: 'arraybuffer',
        })

        downloadResponse(response.data, DownloadMimeType.PDF, 'certificate')
        return true
      } catch (e) {
        // handle error manually due to arraybuffer encoding
        const decoder = new TextDecoder()
        const errors = JSON.parse(decoder.decode(e.response.data)).errors
        errors.forEach((error: { title?: string; detail?: string }) => {
          let html = ''
          if (error.title) html = `<strong>${error.title}</strong>`
          if (error.detail) html += `<br>${error.detail}`
          if (html) {
            this.apiService.$store.dispatch('ux/pushNotification', {
              type: 'error',
              html: html,
            })
          }
        })
        return false
      }
    }
  }
}

export class TestResult extends PublicTestResult {
  static jsonApiType = 'test-results'
  static defaultInclude = ['test', 'tester.profileImage']

  @Attr() result: string | null
  @Attr() conductedAt: string | null
  @Attr() batchNumber: string | null
  @Attr() testType: string | null
  @Attr() createdAt: string

  @BelongsTo() test?: Test
  @BelongsTo() tester?: User | null

  @Meta() certificateUrl: string | null

  async resendMail(): Promise<void> {
    await this.api().request(`resend-mail`)
  }

  async regenerateCertificate(): Promise<void> {
    await this.api().request('regenerate-certificate')
  }
}
