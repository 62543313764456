import { initPlatform } from '@/shared/utils/multi-platform/initPlatform'
import { Context } from '@nuxt/types'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import {
  createPublicJsonApiClient,
  getCognitorApiBaseUrl,
} from '@/shared/services/axiosClientFactory'

export default async function (context: Context) {
  context.$jsonApiService.useClient(
    createPublicJsonApiClient(
      context,
      '/api/v1',
      getCognitorApiBaseUrl(context)
    ),
    Platform
  )
  return await initPlatform(context)
}
