
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'LinkedLogo',
  props: {
    invert: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: '34px',
    },
    width: {
      type: String,
      default: null,
    },
    maxHeight: {
      type: String,
      required: false,
      default: null,
    },
    maxWidth: {
      type: String,
      required: false,
      default: null,
    },
    sourceLight: {
      type: String,
      required: false,
      default: null,
    },
    sourceDark: {
      type: String,
      required: false,
      default: null,
    },
    sourceIcon: {
      type: String,
      required: false,
      default: null,
    },
    altText: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapState('ux', ['colorTheme']),
    light(): boolean {
      return (
        (this.colorTheme === 'light' && !this.invert) ||
        (this.colorTheme === 'dark' && this.invert)
      )
    },
    effectiveLogoSrc(): string | null {
      if (this.light) {
        return this.sourceLight ?? this.sourceIcon
      } else {
        return this.sourceDark ?? this.sourceIcon ?? this.sourceLight
      }
    },
    effectiveIconSrc(): string | null {
      return this.sourceIcon ?? this.effectiveLogoSrc
    },
  },
})
