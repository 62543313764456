<template>
  <div class="eb-input-group-input-container">
    <div
      class="buddy-checkbox eb-input-group"
      :class="{ 'eb-input-row': rowStyle, 'no-border': noBorder }"
    >
      <label
        class="buddy-checkmark-wrapper"
        :class="{ disabled: disabled }"
        :style="{ '--color': color }"
      >
        <input
          type="checkbox"
          :checked="value"
          :disabled="disabled"
          @change="handleInput"
        />
        <span
          class="buddy-checkbox-checkmark"
          :class="{ 'buddy-checkbox-error': validation && validation.$error }"
        ></span>
      </label>
      <div class="buddy-checkbox-label d-inline-block">
        <slot>
          <!-- Label -->
          <span v-if="isHtml" v-html="label" />
          <span v-else>
            {{ label }}
          </span>
          <span v-if="validation && validation.hasOwnProperty('required')">
            *
          </span>
          <!-- Info tooltip -->
          <Info
            v-if="tooltip"
            :tooltip="tooltip"
            icon-style="far"
            :primary="false"
          />
        </slot>
      </div>
    </div>
    <vuelidate-error
      v-if="validation && validation.$anyError"
      :validation="validation"
      :label="label"
    />
  </div>
</template>

<script>
import Info from '@/shared/components/utils/Info'
import VuelidateError from '@/shared/components/utils/VuelidateError'

export default {
  name: 'BuddyCheckbox',
  components: { VuelidateError, Info },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    /**
     * Input Label
     */
    label: {
      type: String,
      required: false,
      default: undefined,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
      default: undefined,
    },
    validation: {
      type: Object,
      required: false,
      default: undefined,
    },
    tooltip: {
      type: String,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rowStyle: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleInput(event) {
      if (this.disabled) return
      if (this.validation) {
        this.validation.$touch()
      }
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<style lang="scss" scoped>
.buddy-checkbox {
  display: flex;
  align-items: center;

  &.eb-input-row {
    .buddy-checkbox-label {
      flex-grow: 1;
      flex-shrink: 1;
    }
    .buddy-checkmark-wrapper {
      margin-right: 0;
    }
    flex-flow: row-reverse;
  }

  /* The container */
  .buddy-checkmark-wrapper {
    --size: 1.25rem;
    --color: var(--primary-color);
    --color-disabled: var(--primary-color-hover);
    position: relative;
    //padding-left: calc(var(--size) + 0.5rem);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-right: 0.5rem;
    margin-bottom: 0;

    &.disabled {
      cursor: not-allowed;
    }

    /* Hide the browser's default checkbox */
    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
      &:disabled {
        cursor: not-allowed;
      }
    }

    /* Create a custom checkbox */
    .buddy-checkbox-checkmark {
      position: relative;
      display: block;
      margin-bottom: 0;
      height: var(--size);
      width: var(--size);
      background-color: var(--input-background);
      border-radius: calc(var(--size) * 0.3);

      &.buddy-checkbox-error {
        background-color: var(--danger-color-overlay);
        border-color: var(--danger-color);
      }

      &:after {
        content: '';
        position: absolute;
        display: none;
      }
    }

    &.disabled {
      input:not(:checked):disabled ~ .buddy-checkbox-checkmark {
        &:after {
          content: '';
          position: absolute;
          display: block;
          top: 46%;
          left: 15%;
          width: 70%;
          height: 8%;
          border-radius: 0.25rem;
          transform: rotate(0deg);
          border: none;
          background-color: var(--text-secondary-color);
          opacity: 0.5;
        }
      }
    }

    &:focus-within
      input
      ~ .buddy-checkbox-checkmark:not(.buddy-checkbox-error) {
      box-shadow: 0 0 0 2px var(--primary-color);
    }

    &:hover input ~ .buddy-checkbox-checkmark {
      background-color: var(--input-background);
    }

    /* When the checkbox is checked, add a blue background */
    input:checked ~ .buddy-checkbox-checkmark:not(.buddy-checkbox-error) {
      background-color: var(--color);

      &:after {
        display: block;
      }
    }

    // disabled style
    input:checked:disabled ~ .buddy-checkbox-checkmark {
      background-color: var(--input-background);
      cursor: not-allowed;
      &:after {
        border-color: var(--text-secondary-color);
      }
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .buddy-checkbox-checkmark {
      /* Style the checkmark/indicator */
      &:after {
        left: calc(var(--size) * 0.368);
        top: calc(var(--size) * 0.2);
        width: calc(var(--size) * 0.3);
        height: calc(var(--size) * 0.48);
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
  }
}
</style>
