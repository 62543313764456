var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eb-status-bar",class:{
    error: _vm.statusBar.type === 'error',
    warning: _vm.statusBar.type === 'warning',
    success: _vm.statusBar.type === 'success',
    'position-fixed': _vm.statusBar.static,
  },style:({
    height: _vm.statusBar.height + 'px',
    top: _vm.top + 'px',
  })},[_c('div',{staticClass:"flex-grow-1 d-flex flex-row justify-content-center"},[(_vm.statusBar.route)?_c('nuxt-link',{attrs:{"to":_vm.statusBar.route}},[_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.statusBar.message),expression:"statusBar.message"}]}),_vm._v(" "),_c('fa',{attrs:{"icon":['far', 'arrow-right']}})],1):_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.statusBar.message),expression:"statusBar.message"}]}),_vm._v(" "),(_vm.statusBar.action)?_c('div',[_c('button',{staticClass:"eb-btn-link text-decoration-underline ms-2",on:{"click":function($event){return _vm.statusBar.action.handler()}}},[_vm._v("\n        "+_vm._s(_vm.statusBar.action.text)+"\n      ")])]):_vm._e()],1),_vm._v(" "),_c('button',{staticClass:"eb-btn-link eb-secondary-link pe-3",staticStyle:{"color":"inherit"},on:{"click":_vm.handleClose}},[_c('fa',{attrs:{"icon":['far', 'times']}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }