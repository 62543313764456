
import Vue, { PropType } from 'vue'
import { StatusBarMessage } from '@/shared/store/uxStore'

export default Vue.extend({
  name: 'StatusBar',
  props: {
    statusBar: {
      type: Object as PropType<StatusBarMessage>,
      required: true,
    },
    top: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleClose(): void {
      if (this.statusBar.closeCallback) {
        this.statusBar.closeCallback()
      }

      this.$emit('close')
    },
  },
})
