import { Attr, BelongsTo, JsonApiService } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { TimeslotPreview } from '@/shared/types/bookings/BookingPreview'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export class Timeslot extends ApiResource {
  static jsonApiType = 'timeslots'

  @Attr() startDate: string | null
  @Attr() endDate: string | null
  @Attr() timezone: string
  @Attr(null) quota: number | null

  // relationships
  @BelongsTo() resource?: Resource | null
  @BelongsTo() service?: Service | null

  static builderForPeriod(
    apiService: JsonApiService,
    startDate: string,
    endDate: string,
    resources: string[] | Resource[]
  ) {
    const resourceIds = resources.map((r: string | Resource) =>
      r instanceof Resource ? r.id : r
    )
    return this.api(apiService).filter({
      startDate,
      endDate,
      r: resourceIds,
    })
  }

  /**
   * Get preview data.
   */
  getPreviewData(): TimeslotPreview {
    return {
      id: this.id,
      quota: this.quota,
      startDate: this.startDate,
      endDate: this.endDate,
      resource: this.resource?.getPreviewData(),
    }
  }
}
