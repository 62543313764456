import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'

export class BookingQuotaRestriction extends ApiResource {
  static jsonApiType = 'booking-quota-restrictions'
  static isPivotResource = true

  @Attr() createdAt: string | null
  @Attr() updatedAt: string | null
  @Attr() restrictedById: string | null
  @Attr() restrictedByType: string | null

  @BelongsTo() restrictedBy: ApiResource
  @BelongsTo() bookingQuota: ApiResource
}
