import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo, Meta } from '@anny.co/vue-jsonapi-orm'
import {
  BookingQuota,
  BookingQuotaValueType,
} from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'

export class BookingQuotaGrant extends ApiResource {
  static jsonApiType = 'booking-quota-grants'
  @Attr() label: string
  @Attr() startDate: string
  @Attr() endDate: string
  @Attr() expiredAt: string
  @Attr() recurringUnit: string
  @Attr() recurringPeriod: number
  @Attr() value: number
  @Attr() usedValue: number
  @Attr() valueType: BookingQuotaValueType | string
  @Attr() currentValue: number
  @Attr() createdAt: string | null
  @Attr() updatedAt: string | null

  @BelongsTo() bookingQuota: BookingQuota
  @BelongsTo() customer: Customer

  @Meta() currentProgress: number
  @Meta() formattedValue: string | null
  @Meta() usedUpLabel: string | null
}
