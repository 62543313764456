/**
 * Get object tree with all invalid properties
 * @param validation
 * @returns {{$invalid}|{$each}|*|{}}
 */
import { Validation } from 'vuelidate'
import { ValidationGroups, ValidationProperties } from 'vue/types/vue'
import Vue from 'vue'
import { required, ValidationFunc } from 'vuelidate/lib/validators'

type InvalidKeys =
  | {
      [key: string]: InvalidKeys | Validation
    }
  | Validation

type GetKeysArgument = ValidationProperties<Vue> | ValidationGroups | Validation

const getInvalidKeys = function (validation: GetKeysArgument): InvalidKeys {
  let hasNestedValidationObject = false
  const invalidKeys: InvalidKeys = {}
  const keys: string[] = Object.keys(validation).filter(
    (key) => key.substr(0, 1) !== '$'
  )
  if (keys.length > 0) {
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const val = validation[key] as GetKeysArgument
      if (typeof val === 'object' && val && '$invalid' in val) {
        hasNestedValidationObject = true
        invalidKeys[key] = getInvalidKeys(val)
      }
    })
  }
  // check $each
  if ('$each' in validation && validation.$each.$invalid) {
    hasNestedValidationObject = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return getInvalidKeys(validation.$each)
  }
  if (!hasNestedValidationObject && '$invalid' in validation) {
    return validation
  }
  return invalidKeys
}

/**
 * touch all invalid keys
 * @param validation
 */
const touchInvalid = function (validation: GetKeysArgument): void {
  let hasNestedValidationObject = false
  const keys = Object.keys(validation).filter((key) => key.substr(0, 1) !== '$')
  if (keys.length) {
    keys.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const val = validation[key]
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (typeof val === 'object' && val && '$invalid' in val) {
        hasNestedValidationObject = true
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        touchInvalid(validation[key])
      }
    })
  }
  // check $each
  if ('$each' in validation && validation.$each.$invalid) {
    hasNestedValidationObject = true
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    touchInvalid(validation.$each)
  }
  if (!hasNestedValidationObject && '$invalid' in validation) {
    ;(validation as Validation).$touch()
  }
}

/**
 * get first vuelidate object out of object
 * @param invalidKeys
 * @returns {{$invalid}|*}
 */
const getFirstInvalidValidation = function (
  invalidKeys: InvalidKeys
): Validation {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const v = invalidKeys[Object.keys(invalidKeys)[0]]
  if ('$invalid' in v) {
    return v as Validation
  } else {
    return getFirstInvalidValidation(v as InvalidKeys)
  }
}

/**
 * get first invalid prop and focus input field
 * @param validation
 */
const focusFirstInvalidInput = function (validation: GetKeysArgument) {
  const invalidKeys = getInvalidKeys(validation as GetKeysArgument)
  const firstInvalidValidation = getFirstInvalidValidation(invalidKeys)
  if (firstInvalidValidation) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputId: string | undefined = firstInvalidValidation.$inputId
    if (inputId) {
      const element = document.getElementById(inputId)
      if (!element) return
      if (element.tagName === 'input') {
        element.focus()
      } else {
        window.location.hash = inputId
      }
    }
  }
}

/**
 * Wait for async validation
 * @link https://github.com/vuelidate/vuelidate/issues/179#issuecomment-326202539
 */
const isValid = (
  component: InstanceType<typeof Vue>,
  validation: Validation
): Promise<boolean> => {
  return new Promise((resolve) => {
    component.$watch(
      () => !validation.$pending,
      (isNotPending) => {
        if (isNotPending) {
          resolve(!validation.$invalid)
        }
      },
      { immediate: true }
    )
  })
}

/**
 * Return validation object for conditional validation
 * USAGE with SPREAD: { email: { ...reqIf(this.emailIsRequired) } }
 * @param isReq
 */
const reqIf = (
  isReq: boolean | (() => boolean)
): Record<string, ValidationFunc> => {
  let isRequired: boolean
  if (typeof isReq === 'function') {
    isRequired = isReq()
  } else {
    isRequired = isReq
  }
  if (isRequired) {
    return { required }
  } else return {}
}

export { touchInvalid, getInvalidKeys, focusFirstInvalidInput, isValid, reqIf }
