<template>
  <Modal
    :show="alert.show"
    :additional-z-index="9999"
    @close="closeAlert"
  >
    <p>
      <slot>
        <span v-dompurify-html="alert.message" />
      </slot>
    </p>
    <div class="text-end">
      <a
        v-for="(action, index) in actions"
        :key="index"
        href="#"
        class="ms-2"
        :class="{ 'eb-danger-color': action.destructive }"
        @click.prevent="performAction(action.handler)"
      >
        {{ action.name }}
      </a>
    </div>
  </Modal>
</template>

<script>
import Modal from './Modal'

export default {
  name: 'Alert',
  components: { Modal },
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  computed: {
    /**
     * Prepare array of actions
     */
    actions() {
      let actions = []
      if (this.alert.cancelable && this.alert.cancelText) {
        actions.push({
          name: this.alert.cancelText,
          handler: this.alert.cancelAction,
        })
      }
      if (this.alert.actions && this.alert.actions.length > 0) {
        actions = actions.concat(this.alert.actions)
      }
      if (this.alert.proceedText) {
        actions.push({
          name: this.alert.proceedText,
          handler: this.alert.proceedAction,
          destructive: this.alert.destructive,
        })
      }
      return actions
    },
  },
  methods: {
    performAction(action) {
      if (typeof action === 'function') {
        action()
      }
      this.closeAlert()
    },
    closeAlert() {
      this.$store.commit('ux/closeAlert')
    },
  },
}
</script>
