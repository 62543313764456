
import { timeout } from '@/shared/utils/timeout'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { TimeObject } from '@/shared/store/cartStore'
import Vue from 'vue'

export default Vue.extend({
  name: 'NavbarCartExpiry',
  data() {
    return {
      criticalTime: 2,
      warningTime: 5,
      animate: false,
      animateTimeout: null,
    }
  },
  computed: {
    order(): Order | null {
      return this.$shopService.activeOrder
    },
    countBookings(): number {
      if (this.order && this.order.bookings) {
        return this.order.bookings.length
      }

      return 0
    },
    remainingTimeObject(): TimeObject {
      return this.$store.getters['cart/getRemainingTimeObject'](
        this.$jsonApiService
      )
    },
    remainingTime(): string {
      let minutes = '' + this.remainingTimeObject.minutes
      minutes = minutes.padStart(2, '0')

      let seconds = '' + this.remainingTimeObject.seconds
      seconds = seconds.padStart(2, '0')

      return `${minutes}:${seconds}`
    },
    classes(): Record<string, boolean> {
      return {
        'eb-danger-color': this.remainingTimeObject.minutes < this.criticalTime,
        'eb-warning-color':
          this.remainingTimeObject.minutes >= this.criticalTime &&
          this.remainingTimeObject.minutes < this.warningTime,
        heartBeat: this.animate,
      }
    },
  },
  watch: {
    remainingTimeObject: function (newTime): void {
      if (
        newTime &&
        newTime.minutes === this.warningTime &&
        newTime.seconds === 0
      ) {
        this.animateHourGlass()
      }

      if (
        newTime &&
        newTime.minutes === this.criticalTime &&
        newTime.seconds === 0
      ) {
        this.animateHourGlass()
      }
    },
  },
  methods: {
    async randomAnimate(): Promise<void> {
      if (Math.random() * 100 > 90) {
        await this.animateHourGlass()
      }
    },
    async animateHourGlass(): Promise<void> {
      this.animate = true
      await timeout(1)
      this.animate = false
    },
  },
})
