import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { PlanFeature } from '@/shared/jsonapi-orm/subscriptions/PlanFeature'
import { PlanFeatureQuota } from '@/shared/jsonapi-orm/subscriptions/PlanFeatureQuota'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { PlanGroup } from '@/shared/jsonapi-orm/subscriptions/PlanGroup'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { LegalDocumentLink } from '@/shared/jsonapi-orm/bookingbuddy/LegalDocumentLink'
import { BookingQuotaModels } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaModels'
import { BookingQuota } from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'

export class Plan extends ApiResource {
  static jsonApiType = 'plans'

  @Attr('') name: string
  @Attr({}) nameI18n?: Record<string, string> | null
  @Attr(null) description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr({}) emailInfoI18n?: Record<string, string> | null
  @Attr(null) originalPrice: number | null
  @Attr() featureGroup: string | null
  @Attr(null) color: string | null
  @Attr(null) badge: string | null
  @Attr(null) priceDisclaimer: string | null
  @Attr(50) price: number
  @Attr(false) priceHidden: boolean
  @Attr(false) fullCycles: boolean
  // display other price than base fee
  @Attr(null) displayPrice: number | null
  @Attr(0) noticePeriod: number // in days
  @Attr(1) minimumTerm: number // in periods
  @Attr(0) signupFee: number
  @Attr('EUR') currency: string
  @Attr(19) taxRate: number
  @Attr(0) trialPeriod: number
  @Attr() sortOrder: number
  @Attr('day') trialInterval: 'day' | 'month' | 'year'
  @Attr(1) invoicePeriod: number
  @Attr('month') invoiceInterval: 'day' | 'month' | 'year'
  // @Attr() gracePeriod: number
  // @Attr() graceInterval: 'day' | 'month' | 'year'
  // @Attr() proratePeriod: number
  // @Attr() prorateInterval: 'day' | 'month' | 'year'
  // @Attr() prorateExtendDue: string | null
  @Attr() illustrationPath: string | null
  @Attr(true) allowSwitching: boolean
  @Attr(false) isBillableOffline: boolean
  @Attr(false) isNetPrice: boolean
  @Attr(false) requestPricing: boolean
  @Attr(null) requestPricingUrl: string | null
  @Attr(false) public: boolean
  @Attr(false) requiresApproval: boolean
  @Attr(true) autoRenewal: boolean
  @HasMany() features?: PlanFeature[]
  @HasMany() quotas?: PlanFeatureQuota[]
  @HasMany() communities?: Community[]
  @HasMany() legalDocumentLinks?: LegalDocumentLink[]
  @HasMany() bookingQuotaModels?: BookingQuotaModels[]
  @HasMany() bookingQuotas?: BookingQuota[]
  @BelongsTo() organization: Organization | null
  @BelongsTo() coverImage?: Image | null
  @BelongsTo() group: PlanGroup | null

  get hasMinimumTerm(): boolean {
    return this.minimumTerm > 1
  }
  get hasSignupFee(): boolean {
    return this.signupFee > 0
  }
  get hasNoticePeriod(): boolean {
    return this.noticePeriod > 0
  }
  get hasTrialPeriod(): boolean {
    return this.trialPeriod > 0
  }
  get hasOriginalPrice(): boolean {
    return this.originalPrice !== null
  }

  get effectivePrice(): number {
    return this.displayPrice ?? this.price
  }

  get isPaid(): boolean {
    return (
      this.price > 0 || (this.displayPrice !== null && this.displayPrice > 0)
    )
  }
}
