import { BookingQuotaRestriction } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaRestriction'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { ServiceUnit } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export enum BookingQuotaValueType {
  QUANTITY = 'quantity',
  CURRENCY = 'currency',
  DURATION = 'duration',
}

export class BookingQuota extends ApiResource {
  static jsonApiType = 'booking-quotas'
  @Attr() name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr(10) value: number
  @Attr(ServiceUnit.MINUTE) valueUnit: string | null
  @Attr(BookingQuotaValueType.QUANTITY) valueType:
    | BookingQuotaValueType
    | string
  @Attr() createdAt: string | null
  @Attr() updatedAt: string | null

  @Attr(ServiceUnit.MONTH) recurringUnit?: string | null
  @Attr(null) recurringPeriod?: number | null
  @Attr(365) advanceQuotaPeriods: number

  @HasMany() bookingQuotaRestrictions: BookingQuotaRestriction[]

  @Meta() valuePreview: string
}
