import { Attr, HasMany, HasOne, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { DomainOwnership } from '@/shared/jsonapi-orm/bookingbuddy/DomainOwnership'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { ResourceGroup } from '@/shared/jsonapi-orm/bookingbuddy/ResourceGroup'
import { Plan } from '@/shared/jsonapi-orm/subscriptions/Plan'
import { UnpersonalizedCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/UnpersonalizedCommunityInvite'
import { BookingQuotaModels } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaModels'
import { BookingQuota } from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'

export interface AutoJoinViaBookingRule {
  id: string
  resource_id: string | null
  service_id: string | null
  apply_start_date: boolean
  apply_end_date: boolean
}

export enum CommunityRoleType {
  MEMBER = 'member', // can see non-incognito people and book
  OBSERVER = 'observer', // can see non-incognito people but not book
  VISITOR = 'visitor', // everybody else is incognito but can book
  MANAGER = 'manager', // can see all people and book
}

export class Community extends ApiResource {
  static jsonApiType = 'communities'
  @Attr() slug: string
  @Attr() name: string | null
  @Attr('var(--secondary-color)') color: string | null
  @Attr('building') icon: string | null
  @Attr(null) welcomeText: string | null
  @Attr(true) isPrivate: boolean
  @Attr(true) includeAllServices: boolean
  @Attr(false) allowCustomerRequests: boolean
  @Attr(false) allowOrganizationRequests: boolean
  @Attr(false) autoJoinEnabled?: boolean
  @Attr(false) listServices: boolean
  @Attr(true) listResources: boolean
  @Attr('resources') defaultList: 'resources' | 'services'
  @Attr(null) autoJoinSettings?: {
    via_booking?: AutoJoinViaBookingRule[]
  } | null
  @Attr(false) forceIncognito: boolean
  @Attr(false) defaultIncognito: boolean
  @Attr(false) disableIncognito: boolean
  @Attr(CommunityRoleType.MEMBER) defaultRole: CommunityRoleType
  @Meta() membersCount?: number

  @HasMany() domains?: DomainOwnership[]
  @HasMany() resources?: Resource[]
  @HasMany() resourceGroups?: ResourceGroup[]
  @HasMany() services?: Service[]
  @HasMany() organizations?: Organization[]
  @HasMany() allCategories?: ResourceCategory[] // cannot be eager loaded
  @HasMany() plans?: Plan[]
  @HasOne() unpersonalizedInvite?: UnpersonalizedCommunityInvite
  @HasMany() bookingQuotaModels?: BookingQuotaModels[]
  @HasMany() bookingQuotas?: BookingQuota[]
  /**
   * Send mass invites.
   * @param emails
   */
  async sendInvites(
    emails: string[],
    role: CommunityRoleType,
    body: string | null = null,
    silent = false,
    startDate: string | null = null,
    endDate: string | null = null
  ) {
    try {
      this.$isLoading = true
      await this.api().request('invites', 'POST', {
        data: {
          emails,
          role,
          body,
          silent,
          start_date: startDate,
          end_date: endDate,
        },
      })
    } finally {
      this.$isLoading = false
    }
  }

  /**
   * Synchronize auto join rules with existing accounts.
   * Returns number of added accounts.
   */
  async syncAutoJoin() {
    try {
      this.$isLoading = true
      const { data }: { data: { added_count: number } } =
        await this.api().request('sync-auto-join')
      return data.added_count
    } finally {
      this.$isLoading = false
    }
  }
}
