import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class AccountPass extends ApiResource {
  static jsonApiType = 'account-passes'
  @Attr() code: string | null
  @Attr(true) includeAddress: boolean
  @Attr(true) includeBirthDate: boolean
  @Attr(null) revokedAt: string | null

  /**
   * Revoke pass.
   */
  async revoke() {
    try {
      const response = await this.api().request('revoke')
      this.apiService.updateFromResponse(response.data)
      return this
    } catch (e) {
      console.log(e)
      return false
    }
  }
}
