
import Vue, { PropType } from 'vue'
import { Validation } from 'vuelidate'
import { ValidationGroups, ValidationProperties } from 'vue/types/vue'

export default Vue.extend({
  name: 'VuelidateError',
  props: {
    validation: {
      type: Object as PropType<
        ValidationProperties<Vue> & ValidationGroups & Validation
      >,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: false,
      default: '',
    },
    validationMessages: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    /**
     * Get validation keys
     * exclude vuelidate default keys ($)
     * @returns {string[]}
     */
    validationKeys(): string[] {
      return Object.keys(this.validation).filter((key) => {
        return key.substr(0, 1) !== '$'
      })
    },
    cleanLabel(): string {
      return String(this.label).replace(/<\/?[^>]+(>|$)/g, '')
    },
  },
})
