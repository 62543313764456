
import Avatar from '../media/Avatar.vue'
import Vue, { PropOptions } from 'vue'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'

export default Vue.extend({
  name: 'ResourcePreview',
  components: { Avatar },
  props: {
    resource: {
      type: Object,
      required: true,
    } as PropOptions<Resource>,
    size: {
      type: String,
      default: 'h6',
    } as PropOptions<string>,
    bold: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    largeAvatar: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showLink: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showEditLink: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showAvailability: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showAvatar: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showParent: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    withSpacing: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  computed: {
    resourceUrl(): string {
      return this.$linkService.getResourceClientUrl(this.resource)
    },
  },
})
