
import Vue from 'vue'
import ExternalLink from '@/shared/components/navigation/ExternalLink.vue'
import { mapFields } from 'vuex-map-fields'
import { UXState } from '@/shared/store/uxStore'
import { createFooterUrls, FooterUrls } from '@/shared/utils/createFooterUrls'

const mappedFields = { isPwa: 'isPwa', now: 'now' }

export default Vue.extend({
  name: 'FooterUrls',
  components: {
    ExternalLink,
  },
  data(): FooterUrls {
    return createFooterUrls(this.$config, this.localePath)
  },
  computed: {
    ...mapFields<typeof mappedFields, UXState>('ux', mappedFields),
  },
  methods: {
    startsWithHttp(str: string): boolean {
      return str.startsWith('http')
    },
  },
})
