
// https://popper.js.org/docs/v2/tree-shaking/
// import { popperGenerator } from '@popperjs/core/lib/popper-lite';
// import flip from '@popperjs/core/lib/modifiers/flip';
// import preventOverflow from '@popperjs/core/lib/modifiers/preventOverflow';
// const createPopper = popperGenerator({
//     defaultModifiers: [...defaultModifiers, flip, preventOverflow],
// });

// eslint-disable-next-line no-unused-vars
import { createPopper } from '@popperjs/core'
import {
  Instance as PopperInstance,
  Options as PopperOptions,
} from '@popperjs/core/lib/types'

import VueClickaway from 'vue-clickaway'
import Vue, { PropOptions } from 'vue'
import ResizeObserver from 'resize-observer-polyfill'
import AnnyPortal from '@/shared/components/utils/AnnyPortal.vue'

export default Vue.extend({
  name: 'Popper',
  directives: {
    clickaway: VueClickaway.directive,
  },
  components: {
    AnnyPortal,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
    } as PropOptions<keyof HTMLElementTagNameMap>,
    /**
     * Custom data
     */
    data: {
      type: [Object],
      default: () => ({}),
    } as PropOptions<Record<string, any>>,
    /**
     * Tooltip placement
     */
    placement: {
      type: String,
      default: 'top-start',
    } as PropOptions<PopperOptions['placement']>,
    /**
     * Initial open state
     */
    isOpen: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    // eslint-disable-next-line vue/require-prop-types
    defaultTarget: {
      required: false,
      default: null,
    } as PropOptions<HTMLElement | null>,
    /**
     * Transition between positions
     */
    transition: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    attachToBody: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    allowFlip: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    containerClass: {
      type: Object,
      default: () => ({}),
    } as PropOptions<Record<string, any>>,
  },
  data() {
    return {
      isActive: this.isOpen as boolean,
      customData: { ...this.data } as Record<string, any>,
      popperInstance: null as PopperInstance | null,
      isTransitionActive: false,
      resizeObserver: null as ResizeObserver | null,
      contentHeight: 0,
      // save state when opened by click -> prevent closing on same click
      clickDidOpen: false,
    }
  },
  watch: {
    isOpen(newVal: boolean) {
      if (newVal !== this.isActive && !newVal) {
        this.close()
      } else if (newVal) {
        if (this.popperInstance) {
          this.isActive = true
        } else if (this.defaultTarget) {
          this.$nextTick(() => this.open(this.defaultTarget))
        }
      }
    },
  },
  mounted() {
    // Add resize observer when height of content changes
    this.resizeObserver = new ResizeObserver(this.updatePopper)
  },
  /**
   * Destroy instance
   */
  beforeDestroy() {
    if (this.popperInstance) {
      this.popperInstance.destroy()
      this.popperInstance = null
    }
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    async updatePopper(entries: ResizeObserverEntry[]) {
      if (entries.length === 0) return
      const entry = entries[0]
      if (entry.contentRect.height !== this.contentHeight) {
        this.contentHeight = entry.contentRect.height
        this.popperInstance?.update()
      }
    },
    observeHeight() {
      const content = this.$refs.content as HTMLElement
      if (!content) return
      this.contentHeight = content.clientHeight
      this.resizeObserver?.observe(content as Element)
    },
    /**
     * Show for given mouse event
     *
     * @param target
     * @param data
     */
    open(target: HTMLElement | null, data: Record<string, any> = {}) {
      if (target) {
        if (this.isActive) {
          if (this.transition) {
            this.isTransitionActive = true
          } else {
            this.isActive = false
          }
        }
        this.customData = data
        this.isActive = true
        this.clickDidOpen = true
        this.$emit('update:isOpen', true)
        // wait for dom to be filled with data for size calculation
        this.$nextTick(() => {
          let tooltip = this.$refs.tooltip as HTMLElement | null
          if (
            (!this.popperInstance ||
              target !== this.popperInstance.state.elements.reference) &&
            tooltip
          ) {
            this.createPopper(target, tooltip)
          }
          this.observeHeight()
          this.$emit('positioned')
        })
        // ignore close events for 100ms to prevent closing on opening click event
        // do not stop propagating event, otherwise we cannot close when clicking on a different open button
        window.setTimeout(() => {
          this.clickDidOpen = false
        }, 100)
      }
    },
    /**
     * Create popper instance
     */
    createPopper(target: HTMLElement, tooltip: HTMLElement) {
      if (!document) return
      if (this.popperInstance) {
        if (this.transition) {
          // update popper
          this.popperInstance.state.elements.reference = target
          this.popperInstance.state.placement = this.placement
          this.popperInstance.update()
          return
        } else {
          this.popperInstance.destroy()
          this.popperInstance = null
        }
      }
      const container = document.getElementsByClassName(
        'eb-content-container'
      )[0]
      const modifiers: PopperOptions['modifiers'] = [
        {
          name: 'preventOverflow',
          options: {
            boundary: container,
            padding: 8,
            escapeWithReference: true,
          },
        },
        {
          name: 'flip',
          enabled: this.allowFlip,
          options: {
            padding: 8,
          },
        },
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: true,
            adaptive: !this.transition, // false for smooth transitions
          },
        },
      ]
      this.popperInstance = createPopper(target, tooltip, {
        modifiers: modifiers,
        placement: this.placement,
      })
    },
    /**
     * Close popover
     */
    close(event?: MouseEvent) {
      if (!this.isActive || this.clickDidOpen) {
        return
      }
      // handle clicks inside of tooltip
      const tooltip = this.$refs.tooltip as HTMLElement | null
      if (
        event &&
        event.target &&
        (tooltip?.contains(<Node>event.target) ||
          this.defaultTarget?.contains(<Node>event.target))
      ) {
        return
      }
      // stop any further actions
      event?.stopPropagation()

      this.isActive = false
      this.isTransitionActive = false
      this.resizeObserver?.unobserve(this.$refs.content as Element)
      this.$emit('update:isOpen', false)
      this.$emit('close')
    },
    /**
     * Toggle popover with slot trigger
     */
    togglePopover() {
      if (this.isActive) {
        this.close()
      } else if (this.$refs.trigger) {
        this.open(this.$refs.trigger as HTMLElement)
      }
    },
  },
})
