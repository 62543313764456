export default () => {
    if (process.server) {
        return;
    }

    const detect = () => 'scrollBehavior' in document.documentElement.style;

    if (detect()) {
        return;
    }

    return new Promise((resolve, reject) => {
        require.ensure([
            "scroll-behavior-polyfill"
        ], function (require) {
            const polyfill = require("scroll-behavior-polyfill");

            resolve();
        });
    });
}