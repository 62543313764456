
import NotificationGroup from '../components/notifications/NotificationGroup.vue'
import Alert from '../components/modals/Alert.vue'
import CustomModal from '../components/modals/CustomModal.vue'
import ActionsModal from '../components/modals/ActionsModal.vue'
import StatusBar from '../components/partials/StatusBar.vue'
import { mapFields } from 'vuex-map-fields'
import { mapGetters } from 'vuex'
import Bowser from 'bowser'
import CookieConsent from '../components/utils/CookieConsent.vue'
import { timeout } from '../utils/timeout'
import CustomStyle from '@/shared/components/utils/CustomStyle.vue'
import Vue, { PropOptions } from 'vue'
import { UXState } from '@/shared/store/uxStore'
// eslint-disable-next-line no-unused-vars
import { MetaInfo } from 'vue-meta'

const uxStateMapping = {
  bowser: 'bowser',
  alert: 'alert',
  statusBar: 'statusBar',
  customModal: 'customModal',
  actionsModal: 'actionsModal',
  topbar: 'topbar',
  isEmbedded: 'isEmbedded',
  isPwa: 'isPwa',
  colorTheme: 'colorTheme',
  detectColorTheme: 'detectColorTheme',
}

// noinspection JSVoidFunctionReturnValueUsed
export default Vue.extend({
  name: 'BaseLayout',
  components: {
    NotificationGroup,
    Alert,
    CustomModal,
    ActionsModal,
    StatusBar,
    CookieConsent,
    CustomStyle,
  },
  props: {
    paddingLeft: {
      type: Number,
      default: 0,
    } as PropOptions<number>,
    paddingTop: {
      type: Number,
      default: 0,
    } as PropOptions<number>,
    paddingRight: {
      type: Number,
      default: 0,
    } as PropOptions<number>,
    paddingBottom: {
      type: Number,
      default: 0,
    } as PropOptions<number>,
    hasFooter: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    navbarHeight: {
      type: String,
      default: '0',
    } as PropOptions<string>,
    localStyles: {
      type: String,
      required: false,
      default: null,
    } as PropOptions<string | null>,
    backgroundImageUrl: {
      type: String,
      required: false,
      default: null,
    } as PropOptions<string | null>,
  },
  fetch(ctx) {
    // unregister axios interceptors in last hook
    // before results are sent to browser
    if (process.server && ctx.$unregisterAxiosInterceptors) {
      ctx.$unregisterAxiosInterceptors()
    }
  },
  head(): MetaInfo {
    // i18n docs: https://nuxt-community.github.io/nuxt-i18n/seo.html#merging-i18n-seo-metadata-with-your-own
    const i18nSeo = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      meta: [...(i18nSeo.meta ?? [])],
      link: [...(i18nSeo.link ?? [])],
    }
  },
  computed: {
    ...mapFields<typeof uxStateMapping, UXState>('ux', uxStateMapping),
    ...mapGetters('ux', ['getColorThemeStyles']),
    /**
     * Return true if any modal is open
     * @returns {*|boolean|boolean}
     */
    modalOpen(): boolean {
      return this.alert.show || this.customModal.show || this.actionsModal.show
    },
    colorThemeStyles(): Record<string, string> {
      if (this.colorTheme === 'dark') {
        return this.getColorThemeStyles
      } else {
        return {}
      }
    },
    mainMinHeight(): string {
      if (!this.isEmbedded) {
        return this.navbarHeight && this.navbarHeight !== '0'
          ? `calc(100vh - ${this.navbarHeight})`
          : '100vh'
      } else {
        return 'unset'
      }
    },
  },
  /**
   * Read query params
   */
  created() {
    let query = this.$route.query
    this.isEmbedded = query.embed ? !!query.embed : false
    this.isPwa = query.pwa ? !!query.pwa : false
    const darkMode = query.darkMode ? !!query.darkMode : false
    // disable theme detection
    if (this.isEmbedded || darkMode) {
      this.detectColorTheme = false
    }
    if (darkMode) {
      this.$store.commit('ux/setColorTheme', 'dark')
    }
  },
  mounted() {
    this.$store.dispatch('ux/listenToPreferredColorTheme')
    this.$store.dispatch('ux/startReactiveTime')
    this.$store.dispatch('ux/initModalEscapeListener')
    this.initBowser()
    this.initConnectivityStatus()
  },
  methods: {
    initBowser() {
      this.bowser = Bowser.getParser(window.navigator.userAgent)
    },
    /**
     * Display connectivity updates
     */
    initConnectivityStatus() {
      if (window) {
        let vm = this
        window.addEventListener('offline', () => {
          vm.$store.commit('ux/showStatusBar', {
            type: 'error',
            message: vm.$t('common.status.offline'),
          })
        })
        window.addEventListener('online', async () => {
          vm.$store.commit('ux/showStatusBar', {
            type: 'success',
            message: vm.$t('common.status.online'),
          })
          await timeout(1.5)
          vm.$store.commit('ux/hideStatusBar')
        })
      }
    },
    closeStatusBar() {
      this.$store.commit('ux/hideStatusBar')
    },
  },
})
