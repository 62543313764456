import { UrlBuilder } from '@/shared/types/UrlBuilder'
import { GetterTree, ActionTree, MutationTree } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { Context } from '@nuxt/types'
import { JsonApiService } from '@anny.co/vue-jsonapi-orm'
import {
  BookingAddOnParams,
  bookingCalculationFactory,
  SubBookingParams,
} from '@/shared/jsonapi-orm/bookingbuddy/Booking'

/**
 * Enum for current step in multi stepped form
 */
enum StepType {
  SERVICE = 'SERVICE',
  CALENDAR = 'CALENDAR',
  RESOURCE = 'RESOURCE',
  OPTIONS = 'OPTIONS',
}
/**
 * Type for date period array
 */
export type DatePeriod = (null | string)[]

export type FormStep = {
  key: string | number
  name: string
  icon: Array<string>
}

function generateToken() {
  // Random number to base 36 (0-9a-z), remove 0.
  return Math.random().toString(36).substr(2)
}

/**
 this is a namespaced vuex module
 */
export const namespaced = true as boolean

const getDefaultState = () => ({
  service_form_entries: {} as { [key: string]: any },
  // Basic required props
  resourceId: '' as string | null,
  serviceId: '' as string | null,
  resourceIsFixed: false as boolean,
  serviceIsFixed: false as boolean,
  description: '' as string,
  customerNote: '' as string,
  quantity: 1 as number,
  // Array of ids (strings)
  selectedChildResourceIds: [] as string[],

  // Indicates if an active booking has started
  started: false as boolean,

  // Options
  booking_add_ons: [] as BookingAddOnParams[],
  sub_bookings: [] as SubBookingParams[],

  currentStep: StepType.CALENDAR as StepType,

  // service
  firstServiceChange: false as boolean,
  serviceAccessCode: '' as string,

  // requested times
  requestedStartDatetime: '' as string,
  requestedEndDatetime: '' as string,

  // Period
  period: [null, null] as DatePeriod,
  previousPeriod: [null, null] as DatePeriod,
  customerRemainingBookings: 0,
  totalAvailableBookings: 0,

  // Token for changing period
  // Only the actor which has the token can update the period
  // Other can break up if their token is not valid before an action
  changingPeriodToken: '' as string,
  preferredStartDateTime: '' as string,
  preferredEndDateTime: '' as string,

  // Calculations
  calculation: bookingCalculationFactory(),
  calculationLoaded: false as boolean,
  fetchingCalculation: false as boolean,
  fetchingCalculationToken: '' as string,
})
/**
 * export state
 */
export const state = getDefaultState

/**
 * Create type of state from state factory function
 */
export type ActiveBookingState = ReturnType<typeof getDefaultState>

/**
 * export mutations
 */
export const mutations: MutationTree<ActiveBookingState> = {
  /**
   * use vuex map fields
   */
  updateField,
  /**
   * Set start datetime of period
   * @param state
   * @param startDatetime
   */
  updateStartDatetime(state, startDatetime) {
    if (state.period[0] !== startDatetime) {
      state.period = [startDatetime, state.period[1]]
    }
  },
  /**
   * Set end datetime of period
   *
   * @param state
   * @param endDatetime
   */
  updateEndDatetime(state, endDatetime) {
    if (state.period[1] !== endDatetime) {
      state.period = [state.period[0], endDatetime]
    }
  },
  /**
   * Reset state to default
   *
   * @param state
   */
  resetState(state) {
    Object.assign(state, getDefaultState())
  },
  /**
   * Reset calculation
   *
   * @param state
   */
  resetCalculation(state) {
    state.calculation = bookingCalculationFactory()
    state.calculationLoaded = false
    state.fetchingCalculation = false
  },
  /**
   *
   * @param state
   */
  setCalenderStep(state) {
    state.currentStep = StepType.CALENDAR
  },
}

/**
 * export getters, root state is unknown and thus any
 */
export const getters: GetterTree<ActiveBookingState, any> = {
  getField,
  periodIsValid(state, getters): boolean {
    return getters.startDateTime !== null && getters.endDateTime !== null
  },
  /**
   * Get resource object
   */
  resource:
    (state) =>
    (apiService: JsonApiService): Resource | null => {
      if (!state.resourceId) {
        return null
      }
      // Find resource from array
      try {
        return Resource.fromId(state.resourceId, apiService)
      } catch {
        return null
      }
    },
  /**
   * Get service object
   */
  service:
    (state) =>
    (apiService: JsonApiService): Service | null => {
      if (!state.serviceId) {
        return null
      }
      try {
        return Service.fromId(state.serviceId, apiService)
      } catch {
        return null
      }
    },
  /**
   * Return selectable resources
   */
  selectableResources:
    (state: ActiveBookingState, getters) =>
    (apiService: JsonApiService): Resource[] => {
      if (!state.started) {
        return []
      }
      const resource = getters.resource(apiService) as Resource | null
      const service = getters.service(apiService) as Service | null
      // We have three cases:
      // Case 1: resource is fixed -> resource is already selected
      // Case 2: service is fixed -> resources from service
      // Case 3: Neither service nor resource are fixed
      // -> If a service is selected we take resource from there, else from resources store

      if (state.resourceIsFixed) {
        if (resource) {
          return [resource]
        }
        return []
      } else if (state.serviceIsFixed) {
        if (service) {
          return service.resources ?? []
        }
        return []
      } else {
        // Neither resource or service is fixed
        // Check if we already have a service
        if (service) {
          return service.resources ?? []
        }

        // TODO: return colleciton of resources
        return []
      }
    },
  /**
   * Return selectable services
   *
   * @param state
   * @param getters
   */
  selectableServices:
    (state: ActiveBookingState, getters) =>
    (apiService: JsonApiService): Service[] => {
      const resource = getters.resource(apiService) as Resource | null
      if (!state.started || !resource?.services) {
        return []
      }
      const service = getters.service(apiService) as Service | null
      // We have three cases:
      // Case 1: resource is fixed -> services from resource
      // Case 2: service is fixed -> service is already selected
      // Case 3: Neither service nor resource are fixed -> We take all services from services store
      if (state.resourceIsFixed) {
        // Case 1
        if (resource) {
          return resource.services ?? []
        }
        return []
      } else if (state.serviceIsFixed) {
        if (service) {
          return [service]
        }
        return []
      } else {
        // TODO Return collection of services
        return []
      }
    },
  /**
   *
   * @param state
   * @param getters
   */
  steps:
    (state: ActiveBookingState, getters) => (apiService: JsonApiService) => {
      const selectableResources = getters.selectableResources(apiService)
      if (!state.started || !selectableResources) return []

      const possibleSteps: FormStep[] = []
      const selectableServices = getters.selectableServices(apiService)
      // Check if service can be selected
      // Also skip this step when we only have one service
      if (!state.serviceIsFixed && selectableServices.length > 1) {
        possibleSteps.push({
          key: StepType.SERVICE,
          name: 'common.components.panels.bookingPanel.steps.service',
          icon: ['fad', 'store'], // alt: shopping bag
        })
      }

      // Always push calendar for selecting a period
      possibleSteps.push({
        key: StepType.CALENDAR,
        name: 'common.components.panels.bookingPanel.steps.calendar',
        icon: ['fad', 'calendar-check'],
      })

      // Push resource select when resource is not fixed
      if (!state.resourceIsFixed) {
        possibleSteps.push({
          key: StepType.RESOURCE,
          name: 'common.components.panels.bookingPanel.steps.resource',
          icon: ['fad', 'swatchbook'], // alt: layers
        })
      }

      // Push options
      possibleSteps.push({
        key: StepType.OPTIONS,
        name: 'common.components.panels.bookingPanel.steps.options',
        icon: ['fad', 'tasks'],
      })

      return possibleSteps
    },
  /**
   * Get start datetime of period
   *
   * @param state
   */
  startDatetime(state: ActiveBookingState) {
    return state.period[0]
  },
  /**
   * Get end datetime of period
   *
   * @param state
   */
  endDatetime(state: ActiveBookingState) {
    return state.period[1]
  },
  /**
   * Return start date time from period
   *
   * @param state
   */
  startDateTime(state: ActiveBookingState) {
    return state.period[0]
  },
  /**
   * Return end date time from period
   * @param state
   */
  endDateTime(state: ActiveBookingState) {
    return state.period[1]
  },
  /**
   * Get request params for adding a booking a requesting calculation
   */
  requestParams(state: ActiveBookingState, getters) {
    return {
      resource_id: state.resourceId,
      service_id: state.serviceId,
      start_date: getters.startDatetime,
      end_date: getters.endDatetime,
      description: state.description,
      customer_note: state.customerNote,
      booking_add_ons: state.booking_add_ons,
      sub_bookings: state.sub_bookings,
      quantity: state.quantity,
      custom_fields: state.service_form_entries,
    }
  },
  /**
   * Check if we have a multi day, which means there are multiple end dates
   */
  isMultiDay(state: ActiveBookingState, getters) {
    if (!getters.service) {
      return false
    }
    return getters.service.maxDuration > 1440
  },
}

/**
 * export actions
 */
export const actions: ActionTree<ActiveBookingState, any> = {
  async reset({ commit }) {
    // We need to reset the store
    commit('resetState')
  },
  /**
   * Start an active booking without a fixed resource or service
   *
   * @param commit
   * @param dispatch
   */
  async startWithNothing({ commit, dispatch }) {
    await dispatch('reset')
    commit('updateField', {
      path: 'started',
      value: true,
    })
    // TODO: allow collections of services and resources
  },
  /**
   * Booking flow starts with a resource
   *
   * @param commit
   * @param dispatch
   * @param getters
   * @param resource
   * @param services
   * @param requestedStartDatetime
   * @param requestedEndDatetime
   */
  async startWithResource(
    { commit, dispatch },
    {
      resource,
      requestedStartDatetime = '',
      requestedEndDatetime = '',
    }: {
      resource: Resource
      requestedStartDatetime: string
      requestedEndDatetime: string
    }
  ) {
    await dispatch('reset')
    // Update resource and services
    commit('updateField', {
      path: 'resourceId',
      value: resource.id,
    })
    // Set requested start and end date times
    commit('updateField', {
      path: 'requestedStartDatetime',
      value: requestedStartDatetime,
    })
    commit('updateField', {
      path: 'requestedEndDatetime',
      value: requestedEndDatetime,
    })
    // Set resource to be fixed
    commit('updateField', {
      path: 'resourceIsFixed',
      value: true,
    })
    // Set flag that next service change is the first one
    commit('updateField', {
      path: 'firstServiceChange',
      value: true,
    })
    // Go to first step
    commit('updateField', {
      path: 'currentStep',
      value: StepType.SERVICE,
    })

    // When we only have one -> Choose it and dispatch service change
    // If there are more -> Dont choose any
    if (resource?.services?.length === 1) {
      commit('updateField', {
        path: 'currentStep',
        value: StepType.CALENDAR,
      })
      await dispatch('updateService', {
        serviceId: resource.services[0].id,
      })
    }

    // Set that active booking has started
    commit('updateField', {
      path: 'started',
      value: true,
    })
  },
  /**
   * Booking flow starts with a service
   *
   * @param commit
   * @param dispatch
   * @param service
   * @param requestedStartDatetime
   * @param requestedEndDatetime
   * @param serviceId
   */
  async startWithService(
    { commit, dispatch, getters },
    {
      service,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      requestedStartDatetime = '',
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      requestedEndDatetime = '',
    }: {
      service: Service
      requestedStartDatetime: string
      requestedEndDatetime: string
    }
  ) {
    await dispatch('reset')
    commit('updateField', {
      path: 'serviceId',
      value: service.id,
    })
    commit('updateField', {
      path: 'serviceIsFixed',
      value: true,
    })
    commit('updateField', {
      path: 'currentStep',
      value: StepType.CALENDAR,
    })

    const selectableResources = getters.selectableResources(
      service.apiService
    ) as Resource[]
    // When we only have one selectable resource -> select it
    // If there are more -> Dont choose any
    if (selectableResources.length === 1) {
      await dispatch('updateResource', {
        resourceId: selectableResources[0].id,
      })
    }

    commit('updateField', {
      path: 'started',
      value: true,
    })
  },
  /**
   * Change a service
   *
   * @param state
   * @param commit
   * @param rootGetters
   * @param serviceId
   */
  async updateService({ state, getters, commit }, { serviceId }) {
    if (!serviceId) {
      throw new Error('No service id was provided.')
    }
    // Abort when same service is selected
    if (serviceId === state.serviceId) {
      return
    }

    // We need to commit the service id
    commit('updateField', {
      path: 'serviceId',
      value: serviceId,
    })

    // After service is updated we will reset some props in the store
    // Reset sub_bookings and add_ons
    commit('updateField', {
      path: 'booking_add_ons',
      value: [],
    })
    commit('updateField', {
      path: 'sub_bookings',
      value: [],
    })
    commit('resetCalculation')

    if (state.firstServiceChange) {
      commit('updateField', {
        path: 'firstServiceChange',
        value: false,
      })

      // setting preferred dates will trigger refresh of time picker
      commit('updateField', {
        path: 'preferredStartDateTime',
        value: state.requestedStartDatetime,
      })
      commit('updateField', {
        path: 'preferredEndDateTime',
        value: state.requestedEndDatetime,
      })
    } else {
      // It is not the first service change, we have a previous selected service
      // Try to take over last date times
      // setting preferred dates will trigger refresh of time picker
      commit('updateField', {
        path: 'preferredStartDateTime',
        value: getters.startDateTime,
      })
      commit('updateField', {
        path: 'preferredEndDateTime',
        value: getters.endDateTime,
      })
    }
  },
  /**
   * Fetch calculation for current booking selection
   */
  async fetchCalculation({ state, commit, getters }) {
    // Check if we can fetch the calculation
    // We need a valid start and end date
    // And also a service and a resource
    if (!getters.periodIsValid || !state.serviceId || !state.resourceId) {
      return
    }

    // Create token
    const token = generateToken()
    commit('updateField', {
      path: 'fetchingCalculationToken',
      value: token,
    })

    const queryParams = getters.bookingQueryParams
    const requestParams = getters.requestParams

    const urlBuilder = new UrlBuilder({
      path: `/order/bookings/calculate`,
      queryParams: {
        ...queryParams,
        check_availability: false,
      },
    })

    commit('updateField', {
      path: 'fetchingCalculation',
      value: true,
    })

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = await (<Context>this).$clients.publicJsonApi.post(
        urlBuilder.url,
        requestParams
      )

      // check token
      if (state.fetchingCalculationToken !== token) return

      commit('updateField', {
        path: 'calculation',
        value: response.data,
      })
    } catch (e) {
      console.log(e)
    } finally {
      commit('updateField', {
        path: 'fetchingCalculation',
        value: false,
      })

      commit('updateField', {
        path: 'calculationLoaded',
        value: true,
      })
    }
  },
  /**
   * Update an add on for active booking -> change quantity or select / deselect it
   * @param state
   * @param commit
   * @param dispatch
   * @param addOnId
   * @param quantity
   */
  updateAddOn(
    { state, commit, dispatch },
    { addOnId, quantity }: { addOnId: number | string; quantity: number }
  ) {
    const bookingAddOns = JSON.parse(JSON.stringify(state.booking_add_ons))

    // Find add on in array
    const currentSelectedAddOnIndex = bookingAddOns.findIndex(
      (addOn: BookingAddOnParams) => addOn.add_on_id === addOnId
    )

    // If it is not found we can push it
    if (currentSelectedAddOnIndex < 0) {
      bookingAddOns.push({
        add_on_id: addOnId,
        quantity,
      })
    } else {
      // we need to check if it was deselected
      if (quantity <= 0) {
        // remove from array
        bookingAddOns.splice(currentSelectedAddOnIndex, 1)
      } else {
        // change in array
        bookingAddOns.splice(currentSelectedAddOnIndex, 1, {
          add_on_id: addOnId,
          quantity,
        })
      }
    }

    commit('updateField', {
      path: 'booking_add_ons',
      value: bookingAddOns,
    })

    dispatch('fetchCalculation')
  },
  /**
   * update sub resource for active booking
   *
   * @param state
   * @param commit
   * @param dispatch
   * @param subResourceId
   * @param serviceId
   * @param subServiceId
   * @param addSubResource
   */
  updateSubResource(
    { state, commit, dispatch },
    {
      subResourceId,
      quantity = 0,
      serviceId = null,
    }: { subResourceId: string; quantity: number; serviceId: string | null }
  ) {
    if (!subResourceId) return
    // copy value and emit it
    const subBookings = JSON.parse(JSON.stringify(state.sub_bookings))

    const index = subBookings.findIndex(
      (subBooking: SubBookingParams) =>
        subBooking.resource_id === subResourceId
    )

    if (!!serviceId && quantity > 0) {
      // We want to add sub resource or update its quantity
      if (index < 0) {
        // Only add it when it is not in the array
        subBookings.push({
          resource_id: subResourceId,
          service_id: serviceId,
          quantity,
        })
      } else {
        // else replace it
        subBookings.splice(index, 1, {
          resource_id: subResourceId,
          service_id: serviceId,
          quantity,
        })
      }
    } else {
      // We want to remove sub resource
      // only remove it when it is in the array
      if (index >= 0) {
        subBookings.splice(index, 1)
      }
    }

    commit('updateField', {
      path: 'sub_bookings',
      value: subBookings,
    })

    dispatch('fetchCalculation')
  },
}
