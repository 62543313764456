import {
  Attr,
  BelongsTo,
  HasMany,
  HasOne,
  JsonApiService,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { CustomField } from '@/shared/jsonapi-orm/custom-fields/CustomField'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { OegdTestSettingsType } from '@/bookingbuddy-admin/components/integrations/cwa/CoronaWarnApp.vue'
import { CustomFormModel } from '@/shared/jsonapi-orm/custom-fields/CustomFormModel'
import { CustomForm } from '@/shared/jsonapi-orm/custom-fields/CustomForm'

export type ResultNotification = {
  id: string
  email: string
  result: string
}

export class Test extends ApiResource {
  static jsonApiType = 'tests'
  @Attr() name: string
  @Attr() resultOptions: string[]
  @Attr() defaultTestType: string | null
  @Attr(null) testTypeOptions: string[] | null
  @Attr() autoResult: string | null
  @Attr() autoResultDelay: number
  @Attr() certificateType: string
  @Attr() certificateBody: string | null
  @Attr() certificateFooter: string | null
  @Attr() issuingAuthority: string | null
  @Attr() doctorName: string | null
  @Attr(false) displayTester: boolean
  @Attr(false) displayCheckInDate: boolean
  @Attr(false) validateDownload: boolean
  @Attr(true) sendCustomerNotification: boolean
  @Attr(true) showValidationCode: boolean
  @Attr(false) displayResource: boolean
  @Attr(false) printConfirmationLabel: boolean
  @Attr(false) printCustomerLabel: boolean
  @Attr(null) validPeriod: number | null
  @Attr(null) resultNotifications: ResultNotification[] | null
  @Attr() createdAt: string

  @Meta() oegdSettings?: Partial<OegdTestSettingsType> | null

  @HasOne() customFormModel?: CustomFormModel | null
  @HasOne() customForm?: CustomForm | null
  @HasMany() certificateCustomFields?: CustomField[]
  @BelongsTo() backgroundImage?: Image | null
}

/**
 * Get test results for
 * @param tests
 * @param jsonApiService
 */
export function getAllTestResultOptions(
  tests: Test[] | string[],
  jsonApiService: JsonApiService
): string[] {
  const testIds: string[] = tests.map((t: Test | string) =>
    typeof t === 'string' ? t : t.id
  )
  const testResults: string[] = []
  testIds.forEach((id: string) => {
    try {
      const test = Test.fromId(id, jsonApiService)
      test.resultOptions.forEach((res: string) => {
        // make selection unique
        if (!testResults.includes(res)) {
          testResults.push(res)
        }
      })
      test.$destruct()
    } catch (e) {
      // test not found in store -> skip
    }
  })
  return testResults
}
