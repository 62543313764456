var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"eb-error-container p-3 d-flex align-items-center"},[_c('div',{staticClass:"flex-fill mt-5 text-center"},[_c('h1',{staticClass:"eb-primary-color"},[_c('fa',{attrs:{"icon":_vm.icons.hasOwnProperty(_vm.error.statusCode)
            ? _vm.icons[_vm.error.statusCode]
            : ['fad', 'exclamation-circle']}})],1),_vm._v(" "),_c('div',{staticClass:"eb-panel mt-3"},[_c('div',{staticClass:"body"},[_c('h1',[_vm._v("\n          "+_vm._s(_vm.error.title || _vm.$t(`common.errors.${_vm.error.statusCode}.title`))+"\n        ")]),_vm._v(" "),_c('p',[_vm._v("\n          "+_vm._s(String(_vm.error.message).includes('\{')
              ? _vm.$t(`common.errors.${_vm.error.statusCode}.message`)
              : _vm.error.message)+"\n        ")]),_vm._v(" "),(
            _vm.error.apiError &&
            _vm.error.apiError.code === 'community_access_required'
          )?_c('div',{staticClass:"mb-2"},[_c('community-access-error',{attrs:{"error":_vm.error.apiError}})],1):_vm._e(),_vm._v(" "),(_vm.$config.supportEnabled && _vm.$config.platformSupportEmail)?_c('p',{staticClass:"eb-text-tertiary"},[_vm._v("\n          "+_vm._s(_vm.$t('common.errors.emailSupportTeam'))),_c('br'),_vm._v(" "),_c('a',{attrs:{"href":"mailto:support@anny.co?subject=Support"}},[_vm._v("\n            "+_vm._s(_vm.$config.platformSupportEmail)+"\n          ")])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }