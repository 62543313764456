
import LocaleSelect from './select/LocaleSelect.vue'
import Vue from 'vue'
import { prefLocaleCookieName } from '@/shared/assets/i18n/i18nHelper'
import { castEnv } from '@/shared/utils/env'

export default Vue.extend({
  name: 'LocaleSwitch',
  components: {
    LocaleSelect,
  },
  methods: {
    async selectLocale(locale: string) {
      // #langlebedatenschutz
      if (!castEnv(process.env.FF_MAX_PRIVACY_MODE)) {
        const cookieName = this.$config.storagePrefix + prefLocaleCookieName
        this.$cookies.set(cookieName, locale, {
          path: '/',
        })
      }
      const oldLocale = this.$i18n.locale
      this.$i18n.setLocale(locale)
      this.$emit('input', locale)

      // capture event
      this.$posthog?.capture('locale_switch:change_locale', {
        locale,
        oldLocale,
      })

      if (this.$authService.account) {
        this.$authService.account.locale = locale
        await this.$authService.account.save()
      }
    },
  },
})
