import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class PaymentMethod extends ApiResource {
  static jsonApiType = 'payment-methods'
  @Attr() name: string
  @Attr() description: string | null
  @Attr() iconPath: string | null
  @Attr() icon_path: string | null
  @Attr() provider: string
  @Attr() processingDuration: number
  @Attr() isAsync: boolean
  @Attr() requiredCustomerAction: boolean
  @Attr() supportsSubscriptions: boolean
  @Attr() supportedCurrencies: string[] | null
}
