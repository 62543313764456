
import Vue from 'vue'
import ExternalLink from '@/shared/components/navigation/ExternalLink.vue'
import Logo from '@/shared/components/partials/Logo.vue'
import { UXState } from '@/shared/store/uxStore'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'
import FooterUrls from './FooterUrls.vue'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'

const mappedFields = { isPwa: 'isPwa', now: 'now' }

export default Vue.extend({
  name: 'Footer',
  components: {
    FooterUrls,
    ExternalLink,
    Logo,
  },
  props: {
    withPaymentMethods: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imprint: {
        name: this.$config.platformName,
        streetAddress: this.$config.platformStreetAddress,
        zipCode: this.$config.platformZipCode,
        city: this.$config.platformCity,
        countryCode: this.$config.platformCountryCode,
        supportEmail: this.$config.platformSupportEmail,
        ceo: this.$config.platformCeo,
        vatId: this.$config.platformVatId,
        registerNumber: this.$config.platformRegisterNumber,
      },
      recentPosts: [],
      currentYear: dayjs().year() as number,
      activePlatform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
    }
  },
  computed: {
    ...mapFields<typeof mappedFields, UXState>('ux', mappedFields),
    moodIcon(): [string, string] {
      const now = dayjs(this.now)
      if (now.hour() > 6 && now.hour() < 18) {
        if (now.minute() < 30) {
          return ['fas', 'mug-tea']
        } else {
          return ['fas', 'mug-hot']
        }
      } else {
        return ['fas', 'glass-whiskey-rocks']
      }
    },
  },
})
