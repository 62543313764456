import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { clone } from 'lodash'

const getDefaultState = () => ({
  checkoutFormData: {} as Record<string, any>,
})

export type State = ReturnType<typeof getDefaultState>

export function useCheckoutStore() {
  /**
   * export state
   */
  const state = getDefaultState

  /**
   * export getters
   */
  const getters: GetterTree<State, any> = {
    getField,
    /**
     * Get saved form data for checkout
     */
    cachedFormData(state): Record<string, any> {
      return state.checkoutFormData
    },
  }

  const actions: ActionTree<State, any> = {}

  /**
   * export mutations
   */
  const mutations: MutationTree<State> = {
    updateField,
    cacheFormData: (state, checkoutFormData) => {
      state.checkoutFormData = clone(checkoutFormData)
    },
    clearState(state) {
      state.checkoutFormData = {}
    },
  }

  return {
    state,
    getters,
    mutations,
    actions,
  }
}
