import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'

export enum SubResourceBookingType {
  SUB_BOOKING = 'sub_booking',
  RECOMMENDED_WITHIN = 'recommended_within',
  RECOMMENDED_AFTER = 'recommended_after',
  RECOMMENDED_BEFORE = 'recommended_before',
  RECOMMENDED_OVERNIGHT = 'recommended_overnight',
}

export class ServiceSubResource extends ApiResource {
  static jsonApiType = 'service-sub-resources'
  static isPivotResource = true

  @Attr(0) orderIndex: number
  @Attr(SubResourceBookingType.SUB_BOOKING) bookingType: SubResourceBookingType

  @BelongsTo() service: Service
  @BelongsTo() resource: Resource
  // preselect service for booking
  @BelongsTo() preselectedService: Service | null
}
