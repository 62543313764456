var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ul',{staticClass:"eb-action-list",class:{ horizontal: _vm.horizontal }},_vm._l((_vm.visibleActions),function(action,index){return _c('li',{key:index,staticClass:"eb-action-list-item",class:{ buttons: _vm.buttons, danger: action.destructive }},[_vm._t(action.key,function(){return [(action.isLink)?_c('a',{staticClass:"eb-link d-inline-flex g-0 text-nowrap align-items-center",class:{
          'eb-danger-link': action.destructive,
          'eb-text-small': _vm.small,
        },attrs:{"target":"_blank","href":action.handler(_vm.argument)}},[(action.icon)?_c('span',[_c('fa',{staticClass:"fa-fw",attrs:{"icon":action.icon}})],1):_vm._e(),_vm._v(" "),(!_vm.hideLabel)?_c('span',{staticClass:"ms-1 text-start",class:{ 'ps-1': action.icon, 'ps-2': !_vm.horizontal }},[_vm._v("\n          "+_vm._s(action.i18n ? _vm.$t(action.name) : action.name)+"\n        ")]):_vm._e(),_vm._v(" "),_c('fa',{staticClass:"fa-fw",attrs:{"icon":['far', 'arrow-up-right-from-square']}})],1):_c('button',{staticClass:"eb-btn-link d-inline-flex g-0 text-nowrap",class:{
          'eb-danger-link': action.destructive,
          'eb-text-small': _vm.small,
        },on:{"click":function($event){$event.stopPropagation();return _vm.handleClick(action)}}},[(action.icon)?_c('span',[_c('fa',{staticClass:"fa-fw",attrs:{"icon":action.icon}})],1):_vm._e(),_vm._v(" "),(action.name && !_vm.hideLabel)?_c('span',{staticClass:"text-start",class:{ 'ps-1': action.icon, 'ps-2': !_vm.horizontal }},[_vm._v("\n          "+_vm._s(action.i18n ? _vm.$t(action.name) : action.name)+"\n        ")]):_vm._e()])]},{"action":action,"index":index})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }