
import Vue from 'vue'
import CommunityAccessError from '@/bookingbuddy-shop/components/communities/CommunityAccessError.vue'

export default Vue.extend({
  name: 'ErrorLayout',
  components: { CommunityAccessError },
  props: {
    error: {
      type: Object,
      default: () => ({}), // { statusCode, message }
    },
  },
  data() {
    return {
      icons: {
        404: ['fad', 'search'],
        403: ['fad', 'lock'],
        502: ['fad', 'mug-hot'],
      },
    }
  },
})
