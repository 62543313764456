var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.resource)?_c('div',{staticClass:"d-flex align-items-center"},[(_vm.showAvatar)?_c('div',{staticClass:"py-1"},[_c('Avatar',{attrs:{"name":_vm.resource.name,"image":_vm.resource.coverImage,"size":_vm.largeAvatar ? 46 : 36,"clickable":false}})],1):_vm._e(),_vm._v(" "),_c('div',{class:{
      'py-1 ms-2': _vm.withSpacing,
    }},[_c(_vm.size,{tag:"component",staticClass:"mb-0 eb-hidden-btn-container dont-break-out",class:{ 'fw-bold': _vm.bold }},[(_vm.resource.parent && _vm.showParent)?_c('span',[_vm._v("\n        "+_vm._s(_vm.resource.parent.name)+" -\n      ")]):_vm._e(),_vm._v(" "),(_vm.showLink)?_c('a',{staticClass:"eb-secondary-link",attrs:{"href":_vm.resourceUrl,"target":"_blank"}},[_vm._v("\n        "+_vm._s(_vm.resource.name)+"\n      ")]):(_vm.showEditLink)?_c('span',[_c('nuxt-link',{staticClass:"eb-hidden-btn-container eb-secondary-link",attrs:{"to":_vm.localePath({
              name: 'resources-resourceId',
              params: { resourceId: _vm.resource.id },
            })}},[_vm._v("\n          "+_vm._s(_vm.resource.name)+"\n          "),_c('fa',{staticClass:"fa-fw eb-hidden-btn",attrs:{"icon":['far', 'pencil']}})],1)],1):_c('span',[_vm._v("\n        "+_vm._s(_vm.resource.name)+"\n      ")]),_vm._v(" "),_vm._t("buttons",null,{"resource":_vm.resource})],2),_vm._v(" "),(_vm.showAvailability)?_c('div',{class:{ 'eb-text-small': _vm.size === 'h6' }},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resource.isAvailable === true),expression:"resource.isAvailable === true"}],key:'isAvailable',staticClass:"eb-success-color"},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":['far', 'check-circle']}}),_vm._v("\n        "+_vm._s(_vm.$t('pages.booking.availability.isAvailable', {
            resource: _vm.resource.name,
          }))+"\n      ")],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.resource.isAvailable === false),expression:"resource.isAvailable === false"}],key:'isUnavailable',staticClass:"eb-danger-color"},[_c('fa',{staticClass:"fa-fw",attrs:{"icon":['far', 'exclamation-circle']}}),_vm._v("\n        "+_vm._s(_vm.$t('pages.booking.availability.isUnavailable', {
            resource: _vm.resource.name,
          }))+"\n      ")],1)]):_vm._e(),_vm._v(" "),_vm._t("default",null,{"resource":_vm.resource})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }