var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showCreate)?_c('BaseButton',{staticClass:"w-100 primary-overlay mb-2",on:{"click":_vm.createItem}},[_vm._t("create-btn",function(){return [_c('fa',{attrs:{"icon":['far', 'plus']}}),_vm._v("\n      "+_vm._s(_vm.createText)+"\n    ")]})],2):_vm._e(),_vm._v(" "),(_vm.refreshOnIntersect)?_c('intersection-observer',{on:{"intersect":_vm.handleIntersection}}):_vm._e(),_vm._v(" "),_vm._t("list",function(){return [_c(_vm.containerComponent,_vm._b({tag:"component",attrs:{"name":"eb-collection","tag":"div"}},'component',_vm.containerAttrs,false),_vm._l((_vm.items),function(item,index){return _c(_vm.itemComponent,_vm._b({key:item.id,tag:"component",class:{
          'eb-border-bottom': _vm.useBorderBottom,
          'pt-2': _vm.usePaddingTop,
          'pb-2': _vm.usePaddingBottom,
        }},'component',_vm.itemAttrs,false),[_c('div',{class:{ 'eb-list-striped': _vm.striped, ..._vm.itemClasses }},[_c('div',{staticClass:"row align-items-center g-0"},[_c('div',{staticClass:"col-auto"},[_vm._t("left",null,{"item":item})],2),_vm._v(" "),_c('div',{staticClass:"col"},[_vm._t("preview",null,{"item":item,"index":index,"actions":_vm.listActions,"editItem":_vm.editItem}),_vm._v(" "),(_vm.showButtonsAndNotEmpty)?_c('div',[_c('action-list',{attrs:{"actions":_vm.listActions,"horizontal":true,"argument":item,"small":true}})],1):_vm._e()],2),_vm._v(" "),(_vm.showRightCol)?_c('div',{staticClass:"col-auto ms-3"},[_vm._t("right",null,{"item":item})],2):_vm._e()])])])}),1)]},{"items":_vm.items,"showEdit":_vm.showEdit,"showDelete":_vm.showDelete,"deleteItem":_vm.deleteItem,"editItem":_vm.editItem}),_vm._v(" "),(_vm.collection)?_c('div',[(_vm.showLoadMore && !_vm.collection.$isLoading && !_vm.collection.$isLastPage)?_c('button',{staticClass:"d-block w-100 eb-btn-link text-center py-2",on:{"click":function($event){$event.preventDefault();return _vm.loadMoreItems.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm.$t('common.actions.loadMore'))+"\n      "),_c('fa',{attrs:{"icon":['far', 'angle-down']}})],1):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.collection.$isLoading && _vm.showLoading),expression:"collection.$isLoading && showLoading"}],staticClass:"py-2"},[_vm._t("loading-indicator",function(){return [_c('div',{staticClass:"text-center eb-primary-color"},[_vm._v("\n          "+_vm._s(_vm.$t('common.status.loading'))+"\n          "),_c('spinner')],1)]})],2),_vm._v(" "),(
        _vm.items.length < 1 && !_vm.collection.$isLoading && _vm.mounted && _vm.showNoData
      )?_c('div',[_vm._t("noData",function(){return [_c('h6',{staticClass:"text-center eb-text-tertiary my-2"},[_vm._v("\n          "+_vm._s(_vm.$t('common.components.dataTable.noData'))+"\n        ")])]})],2):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.editedItem)?_c('orm-modal',{attrs:{"show":_vm.showDetailModal,"show-close-button":true,"scrollable":true,"resource":_vm.editedItem,"show-leaving-warning":_vm.showLeavingWarning,"append-to-body":true},on:{"close":_vm.closeDetailModal,"created":_vm.onCreate}},[_vm._t("detail",null,{"item":_vm.editedItem,"onCreate":_vm.onCreate,"onDelete":_vm.onDelete,"onSave":_vm.closeDetailModal})],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }