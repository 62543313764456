import { AxiosInstance } from 'axios'
import { Plugin } from '@nuxt/types'
import {
  createAuthJsonApiClient,
  createConditionalAuthJsonApiClient,
  createDefaultClient,
  createPublicJsonApiClient,
  ejectAllInterceptors,
  getApiBaseUrl,
  getCognitorApiBaseUrl,
} from '@/shared/services/axiosClientFactory'
import { Context } from '@nuxt/types/app'

export type Clients = {
  publicJsonApi: AxiosInstance
  authJsonApi: AxiosInstance
  cognitorApiProxy: AxiosInstance
  defaultApi: AxiosInstance
  authDefaultApi: AxiosInstance
  platformApi: AxiosInstance | null
  cognitorApi: AxiosInstance | null
  conditionalAuthJsonApi: AxiosInstance
} & Record<string, AxiosInstance | null>

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $clients: Clients
    $unregisterAxiosInterceptors?: () => void
    $updateAxiosClients: () => void
  }
  interface Context {
    $clients: Clients
    $unregisterAxiosInterceptors?: () => void
    $updateAxiosClients: () => void
  }
}

declare module 'vue/types/vue' {
  interface Vue {
    $clients: Clients
    $unregisterAxiosInterceptors?: () => void
    $updateAxiosClients: () => void
  }
}

const initClients = (ctx: Context): Clients => {
  return {
    publicJsonApi: createPublicJsonApiClient(
      ctx,
      '/api/v1',
      getApiBaseUrl(ctx)
    ),
    authJsonApi: createAuthJsonApiClient(
      ctx,
      '/api/v1',
      getApiBaseUrl(ctx),
      ''
    ),
    cognitorApiProxy: createAuthJsonApiClient(
      ctx,
      '/api/cognitor/v1',
      getApiBaseUrl(ctx),
      ''
    ),
    defaultApi: createDefaultClient(ctx, '', getApiBaseUrl(ctx)),
    authDefaultApi: createDefaultClient(ctx, '', getApiBaseUrl(ctx), true),
    platformApi: ctx.$config.cognitorBaseUri
      ? createPublicJsonApiClient(ctx, '/api/v1', getCognitorApiBaseUrl(ctx))
      : null,
    cognitorApi: ctx.$config.cognitorBaseUri
      ? createAuthJsonApiClient(ctx, '/api/v1', getCognitorApiBaseUrl(ctx))
      : null,
    conditionalAuthJsonApi: createConditionalAuthJsonApiClient(
      ctx,
      '/api/v1',
      getApiBaseUrl(ctx)
    ),
  }
}

const axiosClientPlugin: Plugin = function (ctx, inject) {
  const clients: Clients = initClients(ctx)

  const unregisterAxiosInterceptors = () => {
    Object.values(clients).forEach((client: AxiosInstance | null) => {
      if (client) {
        ejectAllInterceptors(client)
      }
    })
    console.log('Unregistered interceptors')
  }

  const updateAxiosClients = () => {
    const newClients = initClients(ctx)
    Object.entries(newClients).forEach(([key, value]) => {
      ctx.$clients[key] = {
        ...value,
        defaults: {
          ...value!.defaults,
          baseURL: getApiBaseUrl(ctx),
        },
      } as AxiosInstance
    })
  }

  // optionally: setTimeout to clear axios interceptors later

  inject('clients', clients)
  inject('unregisterAxiosInterceptors', unregisterAxiosInterceptors)
  inject('updateAxiosClients', updateAxiosClients)
}

export default axiosClientPlugin
