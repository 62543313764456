import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { BookingQuotaGrant } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaGrant'
import { BookingBundle } from '@/shared/jsonapi-orm/booking-quotas/BookingBundle'
import { CustomerAccount } from '@/shared/jsonapi-orm/bookingbuddy/CustomerAccount'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'

export enum BookingPassStatusType {
  DRAFT = 'draft',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
}

export class BookingPass extends ApiResource {
  static jsonApiType = 'booking-passes'

  @Attr() code: string
  @Attr() price: number
  @Attr('EUR') currency: string
  @Attr(null) expiresAt: string | null
  @Attr() createdAt: string | null
  @Attr() updatedAt: string | null
  @Attr(false) hasPayment: boolean
  @Attr(BookingPassStatusType.COMPLETED) status?: BookingPassStatusType

  @BelongsTo() bookingBundle: BookingBundle
  @HasMany() bookingQuotaGrants: BookingQuotaGrant[]
  @BelongsTo() customer: Customer | null
  @BelongsTo() customerAccount: CustomerAccount | null
  @BelongsTo() lastPayment?: Payment | null
  @BelongsTo() invoice?: Invoice
  @BelongsTo() tmpInvoice?: Invoice
  @HasMany() invoiceHistory?: Invoice[]
}
