import { Context } from '@nuxt/types'
import { Workbox, WorkboxUpdatableEvent } from 'workbox-window'

declare global {
  interface Window {
    $workbox: Promise<Workbox>
  }
}

export default async function (ctx: Context) {
  /**
   * Create workbox for pwa.
   */
  if (ctx.query.pwa) {
    const workbox = await window.$workbox
    // test
    if (workbox) {
      // Service worker is available
      const message = ctx.app.$i18n
        ? ctx.app.$i18n.t('common.notifications.pwa.newUpdate')
        : 'A new update is available. Click OK to reload the page.'
      workbox.addEventListener('installed', (event: WorkboxUpdatableEvent) => {
        if (event.isUpdate) {
          if (confirm(message)) {
            window.location.reload()
          }
        }
      })
    }
  }
}
