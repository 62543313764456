
import Vue, { PropOptions, PropType } from 'vue'
import { ApiResourceBase, ResourceCollection } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Sorting } from '@anny.co/vue-jsonapi-orm/dist/builder/BaseQueryBuilder'
import BaseButton from '@/shared/components/buttons/BaseButton.vue'
import OrmModal from '@/shared/components/orm/OrmModal.vue'
import Spinner from '@/shared/components/partials/Spinner.vue'
import IntersectionObserver from '@/shared/components/utils/IntersectionObserver.vue'
import dayjs from 'dayjs'
import { ActionDefinition } from '@/shared/types/DataTable'
import ActionDropdown from '@/shared/components/buttons/ActionDropdown.vue'
import MoreOptionsButton from '@/shared/components/buttons/MoreOptionsButton.vue'
import ActionList from '@/shared/components/buttons/ActionList.vue'

export default Vue.extend({
  name: 'OrmList',
  components: {
    ActionList,
    MoreOptionsButton,
    ActionDropdown,
    IntersectionObserver,
    BaseButton,
    OrmModal,
    Spinner,
  },
  props: {
    /**
     * Resource Collection
     */
    collection: {
      type: [Object, null],
      required: false,
      default: null,
    } as PropOptions<ResourceCollection<ApiResource> | null>,
    collectionItems: {
      type: Array,
      required: false,
      default: () => [],
    } as PropOptions<ApiResource[]>,
    /**
     * Define if items should be requested
     */
    requestItems: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    /**
     * Columns for data table
     */
    columns: {
      type: Array,
      default: () => [],
    } as PropOptions<Record<string, any>[]>,
    defaultAttrs: {
      type: Object,
      default: () => ({}),
    } as PropOptions<Record<string, any>>,
    refreshOnMount: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    refreshOnIntersect: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    showDelete: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showEdit: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showCreate: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showDuplicate: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    striped: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    labelKey: {
      type: String,
      default: 'name',
    } as PropOptions<string>,
    showButtons: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    createText: {
      type: String,
      default: '',
    } as PropOptions<string>,
    showLoadMore: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showLoading: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showNoData: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showRightCol: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    showLeavingWarning: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
    /**
     * Overwrite with custom component, like
     * Container and draggable for vue-smooth-dnd..
     */
    containerComponent: {
      type: [Object, Function, String] as PropType<any>,
      default: 'transition-group',
      required: false,
    },
    itemComponent: {
      type: [Object, Function, String] as PropType<any>,
      default: 'div',
      required: false,
    },
    itemClasses: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    useBorderBottom: {
      type: Boolean,
      default: true,
    },
    usePaddingBottom: {
      type: Boolean,
      default: true,
    },
    usePaddingTop: {
      type: Boolean,
      default: true,
    },
    itemAttrs: {
      type: Object,
      default: () => ({}),
    },
    containerAttrs: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Define custom filtering method.
     */
    localItemsFilter: {
      type: Function as PropType<null | ((item: ApiResource) => boolean)>,
      required: false,
      default: null,
    },
    actions: {
      type: Array as PropType<ActionDefinition[]>,
      default: () => [],
    },
  },
  data() {
    return {
      editedItem: null as null | ApiResourceBase,
      showDetailModal: false,
      mounted: false,
    }
  },
  computed: {
    showButtonsAndNotEmpty() {
      return this.showButtons && this.listActions.length > 0
    },
    items(): ApiResource[] {
      let items = this.collection ? this.collection.items : this.collectionItems
      if (typeof this.localItemsFilter === 'function') {
        items = items.filter(this.localItemsFilter)
      }
      return items
    },
    listActions(): ActionDefinition[] {
      let actions = [] as ActionDefinition[]
      if (this.showEdit) {
        actions.push({
          key: 'edit-btn',
          name: this.$t('common.actions.edit').toString(),
          icon: ['far', 'pencil'],
          handler: this.editItem,
        })
      }

      if (this.showDuplicate) {
        actions.push({
          key: 'duplicate-btn',
          name: this.$t('common.actions.duplicate').toString(),
          icon: ['far', 'clone'],
          handler: this.duplicateItem,
        })
      }

      if (this.showDelete) {
        actions.push({
          key: 'delete-btn',
          name: this.$t('common.actions.delete').toString(),
          icon: ['far', 'trash-alt'],
          handler: this.deleteItem,
          destructive: true,
        })
      }
      return [...actions, ...this.actions]
    },
  },
  async mounted() {
    this.mounted = true
    if ((this.requestItems || this.refreshOnMount) && this.collection) {
      try {
        await this.collection.requestItems()
        this.$emit('loaded')
      } catch (e) {
        console.log(e)
      }
    }
  },
  /**
   * Refresh inside keep alive
   */
  async activated() {
    // called on initial mount
    // and every time it is re-inserted from the cache
    if (this.refreshOnMount && this.collection?.lastRequestedAt) {
      try {
        await this.collection?.requestItems()
      } catch (e) {
        console.log(e)
      }
    }
  },
  methods: {
    /**
     * Sort items
     */
    async sortItems({ sort, order }: Sorting) {
      if (sort && this.collection) {
        try {
          await this.collection.orderBy(sort, order)
        } catch (e) {
          console.log(e)
        }
      }
    },
    /**
     * Refresh table manually
     */
    async refresh() {
      if (!this.collection) return
      try {
        await this.collection.requestItems()
      } catch (e) {
        console.log(e)
      }
    },
    /**
     * Delete selected item
     */
    async deleteItem(item: ApiResourceBase) {
      this.$store.commit('ux/showAlert', {
        cancelText: this.$t('common.components.alerts.deletion.cancel'),
        proceedText: this.$t('common.components.alerts.deletion.proceed'),
        message: this.$t('common.components.alerts.deletion.message', {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          resource: item[this.labelKey] ?? '',
        }),
        cancelable: true,
        destructive: true,
        proceedAction: async () => {
          try {
            // delete relationship
            await item.destroy()
            this.$emit('deleted', item)
          } catch (e) {
            console.log(e)
          }
        },
      })
    },
    /**
     * Show detail modal with selected item
     */
    editItem(item: ApiResourceBase) {
      this.editedItem = item.clone()
      this.showDetailModal = true
      this.$emit('edit', item)
    },
    /**
     * Show detail modal an inject new (unsaved) item with default attrs
     */
    createItem() {
      if (!this.collection) return

      this.editedItem = this.collection.newItem({
        ...this.defaultAttrs,
      })
      this.showDetailModal = true
      this.$emit('newItem', this.editedItem)
    },
    async duplicateItem(item: ApiResourceBase) {
      if (!this.showDuplicate || !this.collection) return
      try {
        const { data } = await item
          .api()
          .include(this.collection.include ?? [])
          .request('duplicate')
        let duplicate = ApiResourceBase.resourceFromResponse(
          data,
          this.$jsonApiService
        ).data
        this.collection.prependItem(duplicate)
        this.$emit('duplicated', duplicate)
        this.editItem(duplicate)
      } catch (e) {
        console.log(e)
      }
    },
    async loadMoreItems() {
      if (!this.collection) return

      try {
        await this.collection.nextPage()
      } catch (e) {
        console.log(e)
      }
    },
    closeDetailModal() {
      this.showDetailModal = false
      // remove instance from memory
      this.editedItem?.$destruct()
      this.editedItem = null
    },
    onDelete(item: ApiResourceBase) {
      this.showDetailModal = false
      this.editedItem?.$destruct()
      this.$emit('deleted', item)
    },
    onCreate(item: ApiResourceBase) {
      if (!this.collection) return

      this.collection.appendItem(item)
      this.$emit('created', item)
      this.closeDetailModal()
    },
    handleIntersection() {
      if (!this.collection) return

      if (this.refreshOnIntersect && !this.collection.$isLoading) {
        const lastRefreshed = this.collection.lastRequestedAt
        if (lastRefreshed && dayjs().diff(lastRefreshed, 'seconds') > 15) {
          // prevent too many refreshes
          this.refresh()
        }
      }
    },
  },
})
