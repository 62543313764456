import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { Service } from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { Context, Plugin } from '@nuxt/types'
import { PublicCommunityInvite } from '@/shared/jsonapi-orm/bookingbuddy/CommunityInvite'

const plugin: Plugin = function (ctx: Context) {
  ctx.$jsonApiService
    .useClient(ctx.$clients.conditionalAuthJsonApi)
    .useClient(ctx.$clients.conditionalAuthJsonApi, PublicCommunityInvite)
  Organization.routeKeyName = 'slug'
  Resource.routeKeyName = 'slug'
  Service.routeKeyName = 'slug'
}

export default plugin
