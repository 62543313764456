
import Vue, { PropType } from 'vue'
import IconWithBackground from '@/shared/components/utils/IconWithBackground.vue'
import { ContextNavigationItem } from '@/bookingbuddy-shop/store/ux'

export default Vue.extend({
  name: 'ContextNavigationItem',
  components: {
    IconWithBackground,
  },
  props: {
    contextNavigationItem: {
      type: Object as PropType<null | ContextNavigationItem>,
      default: null,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    titleNormalized(): string | null {
      if (!this.contextNavigationItem) {
        return null
      }
      return this.contextNavigationItem.i18n
        ? this.$t(this.contextNavigationItem.title).toString()
        : this.contextNavigationItem.title
    },
  },
})
