import { ApiResourceBase, Attr, BelongsTo, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { OrganizationUser } from '@/shared/jsonapi-orm/bookingbuddy/OrganizationUser'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { JsonApiIdentifier } from '@anny.co/vue-jsonapi-orm/dist/JsonApiTypes'
import { Bouncer } from '@/shared/jsonapi-orm/auth/Bouncer'
import { Ability } from '@/shared/jsonapi-orm/auth/Ability'
import { Role } from '@/shared/jsonapi-orm/auth/Role'
import { SexType } from '../cognitor/User'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'

export class User extends ApiResource {
  static jsonApiType = 'users'
  static convertSnakeCase = true

  private cachedBouncer: Bouncer | null = null

  @Attr() name: string
  @Attr() title: string
  @Attr(SexType.UNKNOWN) sex?: SexType
  @Attr() givenName: string
  @Attr() familyName: string
  @Attr() email: string
  @Attr() emailVerifiedAt?: string
  @Attr() company?: string
  @Attr() mobile?: string
  @Attr() isOperator?: boolean
  @Attr() mobileVerifiedAt?: string
  @Attr() phone?: string
  @Attr() timezone: string
  @Attr() cognitorId: string
  @Attr('de') locale: string
  @Attr() createdAt: string

  // marketing
  @Attr(null) leadSource: string | null
  @Attr(null) position: string | null

  @Meta() intercomIdentityHash?: string | null
  @Meta() intercomUserId?: string | null
  @Meta() communityIds: string[]
  @HasMany() notifications?: Notification[]
  @HasMany() organizationUsers?: OrganizationUser[]
  @BelongsTo() activeOrganization?: Organization | null
  @BelongsTo() profileImage?: Image | null
  @HasMany() abilities?: Ability[]
  @HasMany() roles?: Role[]

  get bouncer(): Bouncer {
    if (!this.cachedBouncer) {
      let abilities = this.abilities ? [...this.abilities] : []
      if (this.roles) {
        this.roles.forEach((r) => {
          if (r.abilities) {
            abilities = [...abilities, ...r.abilities]
          }
        })
      }
      this.cachedBouncer = new Bouncer(abilities)
    }
    return this.cachedBouncer
  }

  public can(
    action: string,
    argument?: string | JsonApiIdentifier | ApiResourceBase
  ): boolean {
    // check access level for resource groups and categories
    if (argument instanceof Resource) {
      if (argument.group && this.can(action, argument.group)) {
        return true
      }
      if (argument.category && this.can(action, argument.category)) {
        return true
      }
    }
    // check regular access
    return this.bouncer.can(action, argument)
  }

  public canForAny(action: string, entity: string): boolean {
    return this.bouncer.canForAny(action, entity)
  }

  public canAny(
    actions: string[],
    argument?: string | JsonApiIdentifier | ApiResourceBase
  ): boolean {
    for (const action of actions) {
      if (this.can(action, argument)) {
        return true
      }
    }
    return false
  }

  async validateEmail(): Promise<boolean> {
    if (!this.persisted) return true
    try {
      const response = await this.api()
        .query({ email: this.email })
        .request('validate-unique')
      return response.data.is_available as boolean
    } catch (e) {
      console.log(e)
      return false
    }
  }

  async validateMobile(): Promise<boolean> {
    if (!this.persisted) return true
    try {
      const response = await this.api()
        .query({ mobile: this.mobile })
        .request('validate-unique')
      return response.data.is_available as boolean
    } catch (e) {
      console.log(e)
      return false
    }
  }
}

export class NewUser extends User {
  static jsonApiVariant = 'public'
  @Attr() password: string
  @Attr() passwordConfirmation: string
  @Attr() acceptTerms: boolean
}
