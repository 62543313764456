import Vue from 'vue'
import VueDOMPurifyHTML from 'vue-dompurify-html'

Vue.use(VueDOMPurifyHTML, {
  hooks: {
    afterSanitizeAttributes: (currentNode) => {
      if ('target' in currentNode) {
        currentNode.setAttribute('rel', 'noopener noreferrer');
        currentNode.setAttribute('target', '_blank')
      }
    },
  },
})
