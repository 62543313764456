import { Attr, HasMany, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Resource } from './Resource'

export class Voucher extends ApiResource {
  static jsonApiType = 'vouchers'
  @Attr('') code: string
  @Attr('EUR') currency: string
  @Attr(0) absoluteDiscount: number
  @Attr(0) relativeDiscount: number
  @Attr() numberAvailable: number
  @Attr(null) expiresAt: string | null
  @Attr(false) includeAddOns: boolean
  @Attr() description: string | null
  @Attr() createdAt: string

  @Meta() numberRedeemed: number

  @HasMany() resources?: Resource[]
}
