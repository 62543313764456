import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'

export class Item extends ApiResource {
  static jsonApiType = 'items'
  static defaultInclude = ['document']

  @Attr() title: string
  @Attr() subtitle: string | null
  @Attr(1) quantity: number

  @Attr(0) grossAmount: number
  @Attr() gross_amount: number
  @Attr(0) netAmount: number
  @Attr() net_amount: number
  @Attr(0) taxAmount: number
  @Attr(0) singleGrossAmount: number
  @Attr() single_gross_amount: number
  @Attr(0) singleNetAmount: number
  @Attr(0) singleTaxAmount: number
  @Attr(0) orderIndex: number

  @Attr(19) taxRate: number
  @Attr() tax_rate: number
  @Attr('EUR') currency: string

  @BelongsTo() document?: Invoice
  @BelongsTo() product?: ApiResource | null
}
