import {
  Attr,
  BelongsTo,
  HasMany,
  JsonApiService,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import {
  Booking,
  BookingStatus,
} from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { Customer } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { Payment } from '@/shared/jsonapi-orm/payments/Payment'
import { Invoice } from '@/shared/jsonapi-orm/accounting/Invoice'
import { Voucher } from '@/shared/jsonapi-orm/bookingbuddy/Voucher'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'
import {
  ORDER_ACCESS_TOKENS_KEY,
  ORDER_IDS_KEY,
} from '@/shared/services/ShopService'
import {
  ServicePaymentFlow,
  ServicePaymentSettings,
} from '@/shared/jsonapi-orm/bookingbuddy/Service'
import { CustomEntryMap } from '@/shared/jsonapi-orm/custom-fields/CustomEntry'

export const defaultInclude: string[] = [
  'customer',
  'voucher',
  'bookings.booking_add_ons.add_on',
  'bookings.sub_bookings.resource',
  'bookings.sub_bookings.service',
  'bookings.service',
  'bookings.customer',
  'bookings.cancellation_policy',
  'bookings.resource.cover_image',
  'bookings.resource.parent.cover_image',
  'sub_orders.bookings',
  'sub_orders.organization.legal_documents',
]

export enum OrderStatus {
  DRAFT = 'draft',
  OPEN = 'open',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  PAYMENT_REQUIRED = 'payment-required',
}

export class Order extends ApiResource {
  static jsonApiType = 'orders'

  @Attr() number: string
  @Attr('EUR') currency: string
  @Attr() total: number
  @Attr() modificationTotal: number
  @Attr() netAmount: number
  @Attr() taxAmount: number
  @Attr() discountAmount: number
  @Attr() status: OrderStatus
  @Attr() expiresAt: string | null
  @Attr() createdAt: string
  @Attr() accessToken: string
  @Attr() startDate: string | null
  @Attr() endDate: string | null
  @Attr() paymentSettings: ServicePaymentSettings
  @Attr({}) customEntryMap: CustomEntryMap

  // admin only
  @Attr() note?: string | null
  @Attr() manuallyCreated?: boolean
  @Attr() hasPayment?: boolean
  @Attr() systemFee?: number

  // meta
  @Meta() downloadUrl: string
  @Meta() voucherCode: string | null
  @Meta() isBaseOrder: boolean
  @Meta() paymentFlow: ServicePaymentFlow
  @Meta() invoiceDueDate?: string
  @Meta() payNowAmount?: number

  // relations
  @HasMany() bookings?: Booking[]
  @BelongsTo() customer?: Customer | null
  @BelongsTo() invoice?: Invoice
  @BelongsTo() tmpInvoice?: Invoice
  @HasMany() invoiceHistory?: Invoice[]
  @BelongsTo() organization?: Organization
  @BelongsTo() voucher?: Voucher | null
  @BelongsTo() lastPayment?: Payment | null
  @HasMany() subOrders?: this[]

  // methods
  async cancelWithToken(): Promise<void> {
    const builder = this.api().include(defaultInclude).query({
      access_token: this.accessToken,
    })
    builder.path = `${Order.apiPath}/${Order.jsonApiType}/${this.number}/cancel`
    const response = await builder.request()
    Order.resourceFromResponse(response.data, this.apiService)
  }

  async cancel(): Promise<void> {
    const { data } = await this.api().request(`cancel`)
    Order.resourceFromResponse(data, this.apiService)
  }

  async resendMail(): Promise<void> {
    await this.api().request(`resend-mail`)
  }

  async updateBookingStatus(
    status: BookingStatus,
    sendNotification = true
  ): Promise<void> {
    const { data } = await this.api()
      .query({ status, sendNotification })
      .with(['bookings'])
      .request(`set-booking-status`)

    Order.resourceFromResponse(data, this.apiService).data.$withoutReactivity()
  }

  async refreshWithToken(include: string[] = []): Promise<void> {
    const model = await this.$staticThis
      .api(this.apiService)
      .with(include)
      .find(this.number, {
        params: {
          access_token: this.accessToken,
        },
      })

    // destruct additional model to save memory
    model.$withoutReactivity()
  }

  /**
   * Request all last succeed orders
   */
  static async lastSucceedOrders(
    apiService: JsonApiService,
    succeededOrderIds: string[],
    succeededOrderAccessTokens: string[]
  ): Promise<Order[]> {
    const response = await new QueryBuilder(
      `${this.apiPath}/order`,
      apiService.getClient(this),
      apiService
    )
      .include([
        ...defaultInclude,
        'last_payment.method',
        'organization',
        'bookings.reminders',
        'bookings.testResults',
      ])
      .query({
        [ORDER_IDS_KEY]: succeededOrderIds,
        [ORDER_ACCESS_TOKENS_KEY]: succeededOrderAccessTokens,
      })
      .request('last-succeeded')

    return this.resourcesFromResponse(response.data, apiService).data
  }

  async resendMailWithToken(email: string): Promise<void> {
    await Order.api(this.apiService)
      .query({
        access_token: this.accessToken,
        email,
      })
      .request(`${this.number}/resend-mail`)
  }
}
