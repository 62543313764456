import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'

export class UnpersonalizedCommunityInvite extends ApiResource {
  static jsonApiType = 'unpersonalized-community-invites'
  @Attr() expiry: string | null
  @Attr() join_url: string
  @BelongsTo() community?: Community
  @BelongsTo() organization?: Organization

  /**
   * Accept invite when logged in as customer.
   */
  async accept(commit = false, token: string) {
    try {
      this.$isLoading = true
      const response = await this.api().request(`accept?token=${token}`)
      if (commit) {
        UnpersonalizedCommunityInvite.resourceFromResponse(
          response.data,
          this.apiService
        )
      }
      return true
    } catch (e) {
      return false
    } finally {
      this.$isLoading = false
    }
  }

  async regenerateURL() {
    try {
      this.$isLoading = true
      await this.api().request('regenerate-url')
    } catch (e) {
      console.log(e)
    } finally {
      this.$isLoading = false
    }
  }
}
