
import Vue from 'vue'
import Watcher from '../components/partials/Watcher.vue'
import BaseLayout from '@/shared/layouts/BaseLayout.vue'

import { mapFields } from 'vuex-map-fields'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { MetaInfo } from 'vue-meta'
import ContextNavigation from '@/bookingbuddy-shop/components/partials/ContextNavigation.vue'
import { UXState } from '@/bookingbuddy-shop/store/ux'
import AppNavigation from '@/bookingbuddy-shop/components/partials/AppNavigation.vue'

const uxStoreFields = {
  footerHeight: 'footerHeight',
  sidebar: 'sidebar',
  bottomBar: 'bottomBar',
  topbar: 'topbar',
  statusBar: 'statusBar',
  colorTheme: 'colorTheme',
  showNav: 'showNav',
  fabTimeout: 'fabTimeout',
  bowser: 'bowser',
  contextNavigationItem: 'contextNavigationItem',
  backgroundImageUrl: 'backgroundImageUrl',
  isMobile: 'isMobile',
}

export default Vue.extend({
  name: 'AccountLayout',
  components: {
    AppNavigation,
    ContextNavigation,
    BaseLayout,
    Watcher,
  },
  middleware: ['browserSupport', 'preferredLocale', 'requiresAuth', 'settings', 'shouldLoginQuery'],
  data() {
    return {
      platform: this.$store.getters['platform/platform'](
        this.$jsonApiService
      ) as Platform | null,
      appNavigationPosition: 'left' as 'left' | 'bottom',
    }
  },
  head(): MetaInfo {
    const svgFaviconUrl = this.platform?.config.svgFaviconUrl
    const platformName = this.platform?.name ?? 'anny'
    let links: MetaInfo['link'] = []
    if (this.platform) {
      links = svgFaviconUrl
        ? [
            {
              rel: 'icon',
              type: 'image/svg+xm',
              href: svgFaviconUrl,
              sizes: 'any',
            },
          ]
        : []
    } else {
      links = [
        {
          rel: 'apple-touch-startup-image',
          hid: 'apple-touch-startup-image',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'apple-touch-icon',
          hid: 'apple-touch-icon',
          type: 'image/png',
          href: '_favicons/apple-touch-icon.png',
          sizes: 'any',
        },
        {
          rel: 'shortcut icon',
          href: '_favicons/favicon.ico',
          hid: 'shortcut icon',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-16x16.png',
          sizes: '16x16',
        },
        {
          rel: 'icon',
          type: 'image/png',
          href: '_favicons/favicon-32x32.png',
          sizes: '32x32',
        },
      ]
    }

    return {
      title: `${platformName} Account`,
      link: links,
    }
  },
  computed: {
    ...mapFields<typeof uxStoreFields, UXState>('ux', uxStoreFields),
    platformStyles(): string | null {
      return this.platform?.config?.styles ?? null
    },
    /**
     * Route when clicking on logo.
     */
    logoPath(): string {
      if (this.platform) {
        return this.localePath({
          name: 'explore',
        })
      }
      if (this.$authService.account) {
        return this.localePath({
          name: 'account',
        })
      }
      return this.localePath({
        name: 'checkout',
      })
    },
  },
  created() {
    // pre-determine mobile or non mobile based on useragent
    if (this.$authService.account) {
      if (this.isMobile) {
        this.showBottomAppNavigation()
      } else {
        this.showSidebarAppNavigation()
      }
    } else {
      this.hideAppNavigation()
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.subscribeUpdates(true)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
    this.subscribeUpdates(false)
  },
  methods: {
    /**
     * Subscribe to updates for the current account.
     * Check community changes.
     * @param subscribe
     */
    subscribeUpdates(subscribe = true) {
      const channel = `customer-accounts.${this.$authService.account!.id}`
      if (subscribe) {
        this.$echo
          .private(channel)
          .listen('.communities.updated', this.reloadCommunities)
      } else {
        this.$echo
          .private(channel)
          .stopListening('.communities.updated', this.reloadCommunities)
      }
    },
    async reloadCommunities() {
      try {
        await this.$authService.account!.load('communities')
      } catch (e) {
        console.log(e)
      }
    },
    handleResize() {
      if (!this.$authService.account) {
        this.hideAppNavigation()
        return
      }
      const width = window.innerWidth
      // toggle sidebar based on window size
      if (width >= 768) {
        this.showSidebarAppNavigation()
      } else {
        this.showBottomAppNavigation()
      }
    },
    showSidebarAppNavigation() {
      this.appNavigationPosition = 'left'
      // show sidebar
      this.sidebar = {
        width: 70,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // hide bottom bar
      this.bottomBar = {
        enabled: this.bottomBar.enabled,
        hidden: true,
        height: 0,
      }
    },
    showBottomAppNavigation() {
      this.appNavigationPosition = 'bottom'
      // hide sidebar
      this.sidebar = {
        width: 0,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // show bottom bar
      this.bottomBar = {
        enabled: this.bottomBar.enabled,
        hidden: false,
        height: 70,
        hiddenMobile: this.bottomBar.hiddenMobile,
      }
    },
    hideAppNavigation() {
      // hide sidebar
      this.sidebar = {
        width: 0,
        hidden: false,
        overlay: false,
        iconWidth: 0,
      }
      // hide bottom bar
      this.bottomBar = {
        enabled: this.bottomBar.enabled,
        hidden: true,
        height: 0,
        hiddenMobile: this.bottomBar.hiddenMobile,
      }
    },
  },
})
