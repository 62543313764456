import { Attr } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'

export enum BookingParticipantType {
  HOST = 'host',
  ATTENDEE = 'attendee',
  ROOM = 'room',
}

export enum BookingParticipantReplyStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
  TENTATIVE = 'tentative',
  PENDING = 'pending',
}

export interface BookingParticipantInput {
  id: string
  email: string
  name: string | null
  uuid: string | null
  can_edit: boolean
}

export class BookingParticipant extends ApiResource {
  static jsonApiType = 'booking-participants'

  @Attr() name: string | null
  @Attr() email: string
  @Attr() mobile: string | null
  @Attr() imageUrl: string | null
  @Attr() participantType: BookingParticipantType
  @Attr() replyStatus: BookingParticipantReplyStatus
}
