
import Vue, { PropOptions } from 'vue'
import Modal from '@/shared/components/modals/Modal.vue'
import OrmLeaveWarning from '@/shared/components/orm/OrmLeaveWarning.vue'
import { ApiResourceBase } from '@anny.co/vue-jsonapi-orm'
import { Validation } from 'vuelidate'

export default Vue.extend({
  name: 'OrmModal',
  components: {
    Modal,
    OrmLeaveWarning,
  },
  inheritAttrs: false,
  props: {
    resource: {
      type: Object,
      required: true,
    } as PropOptions<ApiResourceBase>,
    validation: {
      type: Object,
      required: false,
      default: null,
    } as PropOptions<Validation>,
    show: {
      type: Boolean,
      required: true,
    } as PropOptions<boolean>,
    showLeavingWarning: {
      type: Boolean,
      required: false,
      default: true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      isLeaving: false,
    }
  },
  computed: {
    listeners(): Record<string, (...args: any[]) => void> {
      // only intercept close event if showLeavingWarning is true
      if (!this.showLeavingWarning) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return { ...this.$listeners }
      }

      return {
        ...this.$listeners,
        close: this.interceptClose,
      }
    },
  },
  methods: {
    /**
     * Propagate close event to close modal
     */
    closeModal() {
      this.isLeaving = false
      this.$emit('close')
    },
    /**
     * Perform dirty check by LeaveWarning component.
     */
    interceptClose() {
      this.isLeaving = true
    },
  },
})
