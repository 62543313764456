import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import {
  Community,
  CommunityRoleType,
} from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { Booking } from '@/shared/jsonapi-orm/bookingbuddy/Booking'
import { PlanSubscription } from '@/shared/jsonapi-orm/subscriptions/PlanSubscription'
import { DomainOwnership } from '@/shared/jsonapi-orm/bookingbuddy/DomainOwnership'

export enum CommunityAccountStatus {
  REQUESTED = 'requested',
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
}

export class CommunityAccount extends ApiResource {
  static jsonApiType = 'community-accounts'

  @Attr() status: CommunityAccountStatus
  // account data
  @Attr('') email: string | null
  @Attr() givenName: string | null
  @Attr() familyName: string | null
  @Attr() fullName: string | null
  @Attr() startDate: string | null
  @Attr() endDate: string | null
  @Attr(false) incognito: boolean
  @Attr() createdAt: string
  @Attr(CommunityRoleType.MEMBER) role: CommunityRoleType
  @Attr() profileImg: string | null

  @BelongsTo() community?: Community
  @BelongsTo() joinedThrough?:
    | Booking
    | PlanSubscription
    | DomainOwnership
    | null

  /**
   * Accept invite when logged in as customer.
   */
  async accept(): Promise<boolean> {
    if (this.status !== CommunityAccountStatus.REQUESTED) {
      return false
    }
    try {
      this.status = CommunityAccountStatus.ACCEPTED
      await this.save()
      return true
    } catch (e) {
      console.log(e)
      return false
    }
  }
}
