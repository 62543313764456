import { Attr, BelongsTo } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Plan } from './Plan'
import { PlanFeature } from '@/shared/jsonapi-orm/subscriptions/PlanFeature'

export class PlanFeatureQuota extends ApiResource {
  static jsonApiType = 'plan-feature-quotas'
  static isPivotResource = true

  @Attr(null) quota: number | null
  @Attr(true) isAllowed: boolean
  @Attr(false) unlimited: boolean
  @Attr(false) hidden: boolean
  @Attr(0) usageFee: number
  @Attr(null) displayUsageFee: number | null
  @Attr() effectiveUsageFee: number
  @Attr() usageFeeDisclaimer: string | null
  @Attr() resettablePeriod: number
  @Attr() orderIndex: number
  @Attr() resettableInterval: 'day' | 'month' | 'year'

  @BelongsTo() plan: Plan
  @BelongsTo() feature: PlanFeature
}
