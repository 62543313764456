import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import {
  Attr,
  BelongsTo,
  HasMany,
  JsonApiService,
  Meta,
} from '@anny.co/vue-jsonapi-orm'
import { Address } from '@/shared/jsonapi-orm/common/Address'
import { SexType } from '@/shared/jsonapi-orm/bookingbuddy/Customer'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'
import { QueryBuilder } from '@anny.co/vue-jsonapi-orm/dist/builder/QueryBuilder'
import { Favorite } from '@/shared/jsonapi-orm/bookingbuddy/Favorite'
import { Community } from '@/shared/jsonapi-orm/bookingbuddy/Community'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { PlanSubscription } from '@/shared/jsonapi-orm/subscriptions/PlanSubscription'
import { Resource } from '@/shared/jsonapi-orm/bookingbuddy/Resource'
import { CommunityAccount } from '@/shared/jsonapi-orm/bookingbuddy/CommunityAccount'
import { AccountSkill } from '@/shared/jsonapi-orm/bookingbuddy/AccountSkill'
import { BookingQuotaGrant } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaGrant'
import { BookingPass } from '@/shared/jsonapi-orm/booking-quotas/BookingPass'

export class CustomerAccount extends ApiResource {
  static jsonApiType = 'customer-accounts'

  @Attr('de') locale: string
  @Attr() title?: string
  @Attr(SexType.UNKNOWN) sex?: SexType
  @Attr(null) birthDate: string | null
  @Attr() givenName: string
  @Attr() familyName: string
  @Attr() email: string
  @Attr() company?: string | null
  @Attr() bio: string | null
  @Attr() department: string | null
  @Attr() jobTitle: string | null

  @Attr() mobile?: string
  @Attr() phone?: string
  @Attr() timezone?: string
  @Attr() timezoneOffset?: number
  @Attr() cognitorId: string
  // Only for creating
  @Attr() password: string | null
  @Attr() passwordConfirmation: string | null
  @Attr() acceptTerms: boolean | null
  @Attr() hasOrganization: boolean | null

  // Meta
  @Meta() likedResourceIds?: string[]
  @Meta() hasSubscriptions: boolean

  // Relations
  @HasMany() addresses?: Address[]
  @HasMany() communities?: Community[]
  @HasMany() favorites?: Favorite[]
  @HasMany() likedFavorites?: Favorite[]
  @HasMany() resources?: Resource[]
  @BelongsTo() primaryAddress?: Address | null
  @HasMany() orders?: Order[]
  @HasMany() subscriptions?: PlanSubscription[]

  @HasMany() communityAccounts?: CommunityAccount[]
  @HasMany() accountSkills?: AccountSkill[]
  @HasMany() bookingPasses?: BookingPass[]
  @HasMany() bookingQuotaGrants?: BookingQuotaGrant[]
  @BelongsTo() profileImage: Image | null

  get name(): string {
    return this.givenName + ' ' + this.familyName
  }
  set name(newName: string) {
    const parts: string[] = newName.split(' ')
    if (parts.length > 1) {
      this.givenName = parts[0]
      this.familyName = parts.slice(1).join(' ')
    } else if (parts.length === 1) {
      this.givenName = parts[0]
      this.familyName = ''
    } else {
      this.givenName = ''
      this.familyName = ''
    }
  }

  async validateEmail(): Promise<boolean> {
    const { data } = await this.api()
      .query({
        email: this.email,
      })
      .request('validate-unique')
    return !!data && (data.is_available as boolean)
  }

  async validateMobile(): Promise<boolean> {
    const { data } = await this.api()
      .query({
        mobile: this.mobile,
      })
      .request('validate-unique')
    return !!data && (data.is_available as boolean)
  }

  /**
   * Request current signed in user
   */
  static async requestUser(
    apiService: JsonApiService
  ): Promise<CustomerAccount | null> {
    try {
      // const response =
      const response = await new QueryBuilder(
        `${this.apiPath}/customer-account`,
        apiService.getClient(this),
        apiService
      )
        .include([
          'primaryAddress',
          'likedFavorites',
          'profileImage',
          'communities',
          'accountSkills.skill',
          'bookingQuotaGrants',
        ])
        .request()

      return CustomerAccount.resourceFromResponse(response.data, apiService)
        .data
    } catch (e) {
      console.error(e)
      return null
    }
  }
}
