import { getField, updateField } from 'vuex-map-fields'

import { storeLastVisitedOrganizations } from '@/shared/utils/trackingHelpers'
import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { Organization } from '@/shared/jsonapi-orm/bookingbuddy/Organization'
import { uniqBy } from 'lodash'
import { Context } from '@nuxt/types'

export interface VisitedOrganization {
  id: string
  name: string
  slug: string
}

const getDefaultState = () => ({
  lastVisitedOrganization: null as VisitedOrganization | null,
  lastVisitedOrganizations: [] as VisitedOrganization[] | null,
})

export type State = ReturnType<typeof getDefaultState>

export const state = getDefaultState

export const getters: GetterTree<State, any> = {
  getField,
}

export const mutations: MutationTree<State> = {
  updateField,
  setLastVisitedOrganizations: (
    state: State,
    lastVisitedOrganizations: VisitedOrganization[]
  ) => {
    state.lastVisitedOrganizations = lastVisitedOrganizations
  },
}

export const actions: ActionTree<State, any> = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  visitOrganization(
    this: Context,
    { commit, getters },
    organization: Organization
  ) {
    if (!organization) {
      return
    }

    const visitedOrganization: VisitedOrganization = {
      id: organization.id,
      slug: organization.slug,
      name: organization.name,
    }

    // get visited organizations
    let visitedOrgs: VisitedOrganization[] | null = getters.getField(
      'lastVisitedOrganizations'
    )
    if (!Array.isArray(visitedOrgs)) {
      visitedOrgs = []
    }

    visitedOrgs = JSON.parse(JSON.stringify(visitedOrgs))

    visitedOrgs!.push(visitedOrganization)

    visitedOrgs = uniqBy(visitedOrgs, 'id')
    // get last 8
    visitedOrgs = visitedOrgs.slice(0, 8)

    commit('setLastVisitedOrganizations', visitedOrgs)

    storeLastVisitedOrganizations(
      visitedOrgs,
      this.$cookies,
      this.$config.useSecureCookies
    )
  },
}
