
import Modal from './Modal.vue'
import { mapMutations } from 'vuex'
import Vue, { PropType } from 'vue'
import { ActionDefinition } from '@/shared/types/DataTable'
import { ActionsModal } from '@/shared/store/uxStore'

export default Vue.extend({
  name: 'ActionsModal',
  components: {
    Modal,
  },
  props: {
    actionsModal: {
      type: Object as PropType<ActionsModal>,
      required: true,
    },
  },
  computed: {
    relevantActions(): ActionDefinition[] {
      if (!this.actionsModal?.actions) {
        return []
      }
      return this.actionsModal.actions.filter((action) => {
        return (
          !Object.prototype.hasOwnProperty.call(action, 'condition') ||
          action.condition
        )
      })
    },
    /**
     * Filter visible actions
     */
    visibleActions(): ActionDefinition[] {
      return this.relevantActions.filter((action) => {
        if (typeof action.hidden === 'function') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return !action.hidden()
        } else {
          return !action.hidden
        }
      })
    },
  },
  methods: {
    ...mapMutations('ux', ['hideActionsModal']),
    handleClick(action: ActionDefinition) {
      action.handler()
      this.hideActionsModal()
    },
  },
})
