
import Vue, { PropOptions } from 'vue'

export default Vue.extend({
  name: 'CustomStyle',
  props: {
    css: {
      type: String,
      required: true,
    } as PropOptions<string>,
  },
  computed: {
    purifiedCss(): string {
      if (this.css) {
        // strip tags from css
        return this.css.replace(/(<([^>]+)>)/gi, '')
      }
      return ''
    },
  },
})
