import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'
import { Attr, HasMany, HasOne, Meta } from '@anny.co/vue-jsonapi-orm'
import {
  BookingQuota,
  BookingQuotaValueType,
} from '@/shared/jsonapi-orm/booking-quotas/BookingQuota'
import { Image } from '@/shared/jsonapi-orm/common/Image'
import { BookingQuotaModel } from '@/shared/jsonapi-orm/booking-quotas/BookingQuotaModel'
import { LegalDocument } from '@/shared/jsonapi-orm/common/LegalDocument'

export type BookingQuotaPreview = {
  id: number
  type: BookingQuotaValueType
  label: string
}

export class BookingBundle extends ApiResource {
  static jsonApiType = 'booking-bundles'

  @Attr() slug: string
  @Attr('') name: string
  @Attr({}) localNameI18n?: Record<string, string> | null
  @Attr(null) description: string | null
  @Attr({}) descriptionI18n?: Record<string, string> | null
  @Attr(50) price: number
  @Attr('EUR') currency: string
  @Attr(19) taxRate: number
  @Attr(false) isNetPrice: boolean
  @Attr(false) live: boolean
  @Attr(24) expiryPeriod?: number | null
  @Attr() createdAt: string | null
  @Attr() updatedAt: string | null
  @Attr() orderIndex: string | null

  @Meta() bookingQuotaPreviews?: BookingQuotaPreview[]

  @HasMany() bookingQuotaModels?: BookingQuotaModel[]
  @HasMany() bookingQuotas?: BookingQuota[]
  @HasOne() coverImage?: Image | null
  @HasMany() legalDocuments?: LegalDocument[]
  @HasMany() legalDocumentLinks?: LegalDocument[]
}
