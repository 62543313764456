<template>
  <Modal :show="customModal.show" @close="hideCustomModal">
    <component
      :is="customModal.component"
      v-bind="customModal.attrs"
      v-on="customModal.listeners"
      @close="hideCustomModal"
    />
  </Modal>
</template>

<script>
import Modal from './Modal'
import { mapMutations } from 'vuex'

export default {
  name: 'CustomModal',
  components: {
    Modal,
  },
  props: {
    customModal: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapMutations('ux', ['hideCustomModal']),
  },
}
</script>

<style scoped></style>
