
import Vue from 'vue'
import { timeout } from '@/shared/utils/timeout'
import { TimeObject } from '@/shared/store/cartStore'
import { Order } from '@/shared/jsonapi-orm/bookingbuddy/Order'

export default Vue.extend({
  name: 'Watcher',
  computed: {
    /**
     * Get remaining order time from server to watch it
     */
    remainingOrderTime(): TimeObject {
      return this.$store.getters['cart/getRemainingTimeObject'](
        this.$jsonApiService
      )
    },
    /**
     * Compute whether to ignore a order
     * Ignore it when it don't contain bookings
     */
    ignoreOrder(): boolean {
      // Ignore order when it dont contain any booking
      return (!this.order || this.order.bookings?.length === 0) ?? true
    },
    /**
     * Return current order
     */
    order(): Order | null {
      return this.$shopService.activeOrder
    },
  },
  watch: {
    remainingOrderTime: async function (newRemainingOrderTime): Promise<void> {
      // There is no order to expire
      if (!newRemainingOrderTime || this.ignoreOrder) {
        return
      }

      // Push notification, that order will expire in 2 minutes
      if (
        newRemainingOrderTime.minutes === 2 &&
        newRemainingOrderTime.seconds === 0
      ) {
        await timeout(2)
        await this.$store.dispatch('ux/pushNotification', {
          type: 'danger',
          html: this.$i18n.t(
            'pages.checkout.notifications.orderExpiresIn2Minutes'
          ),
        })
      }

      // Order not expired -> Nothing to do
      if (
        newRemainingOrderTime.minutes > 0 ||
        newRemainingOrderTime.seconds > 0
      ) {
        return
      }

      // Order expired -> delete order and push notification
      await this.$store.dispatch('ux/pushNotification', {
        type: 'default',
        html: this.$i18n.t('pages.checkout.notifications.orderExpired'),
      })

      await this.$store.dispatch('cart/clearCart')
    },
  },
  async mounted() {
    if (!this.order) {
      await this.$shopService.fetchOrder()
    }
  },
})
