var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"eb-modal"}},[(_vm.show)?_c('div',_vm._g({ref:"modal",staticClass:"eb-modal-mask",class:{ scrollable: _vm.scrollable, embedded: _vm.isEmbedded },style:({ zIndex: _vm.zIndex }),on:{"mousedown":_vm.handleCancelAttempt}},_vm.$listeners),[_c('div',{ref:"container",staticClass:"eb-modal-container",class:{
        'modal-padding': _vm.applyPadding,
        scrollable: _vm.scrollable,
        'eb-modal-full': _vm.fullWidth,
        'video-modal-container': _vm.isVideo,
        'eb-modal-container': !_vm.isVideo,
      },style:({ marginTop: _vm.marginTop })},[(_vm.showCloseButton)?_c('button',{staticClass:"eb-btn-link eb-modal-close-btn eb-secondary-link",style:({ zIndex: _vm.zIndex + 1 }),on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.cancel.apply(null, arguments)}}},[_c('fa',{attrs:{"icon":['far', 'times']}})],1):_vm._e(),_vm._v(" "),_vm._t("default")],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }