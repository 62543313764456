<template>
  <fa v-if="!iconLoaded" :icon="['far', 'spinner-third']" spin />
  <fa v-else-if="iconFailed" :icon="['far', 'exclamation-circle']" />
  <fa v-else :icon="faIcon" />
</template>

<script>
import { camelCase } from 'lodash'

/**
 * Lazy loaded Font Awesome icons
 */
export default {
  name: 'LazyFa',
  props: {
    icon: {
      type: [String, Array],
      default: '',
    },
  },
  data() {
    return {
      iconName: '',
      iconLoaded: false,
      iconFailed: false,
      faIcon: null,
    }
  },
  watch: {
    /**
     * Update icon
     */
    icon: {
      handler: function (icon) {
        this.setIconName(icon)
        this.loadIcon()
      },
    },
  },
  async mounted() {
    this.setIconName()
    this.loadIcon()
  },
  methods: {
    /**
     * Set icon name
     */
    setIconName() {
      let iconName
      if (Array.isArray(this.icon)) {
        iconName = this.icon[1]
      } else {
        iconName = this.icon
      }
      this.iconName = iconName
    },
    /**
     * Async load icon
     * @return {Promise<void>}
     */
    async loadIcon() {
      let iconKey = camelCase(this.iconName)
      iconKey =
        String(iconKey).charAt(0).toUpperCase() + String(iconKey).substr(1)
      try {
        this.faIcon = (
          await import(
            /* webpackChunkName: "far-l-[request]" */
            /* webpackExclude: /index.*\.js$/ */
            `@fortawesome/pro-regular-svg-icons/fa${iconKey}.js`
          )
        )['fa' + iconKey]
        this.iconLoaded = true
      } catch (e) {
        this.iconFailed = true
      }
    },
  },
}
</script>

<style lang="scss">
object.eb-lazy-fa {
  svg {
  }
}
</style>
