<template>
  <div>
    <client-only>
      <slot />
    </client-only>
  </div>
</template>

<script>
/**
 * This component moves its scope to any target element
 */
export default {
  name: 'AnnyPortal',
  props: {
    targetId: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      targetElement: null,
    }
  },
  mounted() {
    if (this.disabled) return
    let id = this.targetId
    if (id.startsWith('#')) {
      id = id.slice(1)
    }
    let targetElement = document.getElementById(this.targetId)
    if (!targetElement) {
      throw new Error(`Target element with id #${id} was not found`)
    }
    targetElement.insertBefore(this.$el, targetElement.firstChild)
    this.targetElement = targetElement
  },
  beforeDestroy() {
    if (this.$el) {
      this.$el.remove()
    }
  },
}
</script>

<style scoped></style>
