
import Vue, { PropType } from 'vue'
import { Location } from 'vue-router'

export default Vue.extend({
  name: 'BaseButton',
  props: {
    label: {
      type: String as PropType<string>,
      default: '',
    },
    href: {
      type: String as PropType<string>,
      required: false,
      default: undefined,
    },
    route: {
      type: [Object, String] as PropType<string | Location>,
      required: false,
      default: undefined,
    },
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  methods: {
    handleClick(e: MouseEvent) {
      // handle navigation
      if (this.href && window !== undefined) {
        window.open(this.href)
      } else if (this.route) {
        if (typeof this.route === 'string') {
          this.$router.push(this.route)
        } else {
          this.$router.push(this.localePath(this.route))
        }
      }
      this.$emit('click', e)
    },
  },
})
