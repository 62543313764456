import { Attr, Meta } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '@/shared/jsonapi-orm/ApiResource'

export class Address extends ApiResource {
  static jsonApiType = 'addresses'
  @Attr() name: string | null
  @Attr() streetAddress: string | null
  @Attr() street_address: string | null
  @Attr() zipCode: string | null
  @Attr() zip_code: string | null
  @Attr() city: string | null
  @Attr('DE') countryCode: string
  @Attr('DE') country_code: string

  @Attr() latitude: number | null
  @Attr() longitude: number | null

  @Meta() addressLine?: string

  getAddressText(): string {
    return `${this.streetAddress}\n${this.zipCode} ${this.city}\n${this.countryCode}`
  }
}
