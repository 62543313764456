import { Context } from '@nuxt/types'
import { getDomainDetails } from '@/shared/utils/multi-platform/parseIncomingRequest'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { handleError } from '@/shared/utils/handleError'
import VueI18n from 'vue-i18n'
import { set } from 'lodash'
import {
  defaultPlatformConfig,
  defaultPlatformKeyMapping,
} from '@/shared/utils/multi-platform/platformRuntimeConfig'
import { AxiosError } from 'axios'

/**
 * Transform dot-notation into nested object.
 * @param messages
 * @param defaultObj
 */
function getLocaleMessagesObject(
  messages: Record<string, string>,
  defaultObj?: VueI18n.LocaleMessageObject
) {
  if (!defaultObj) {
    defaultObj = {}
  }
  Object.entries(messages).forEach(([path, message]) => {
    set(defaultObj!, path, message)
  })
  return defaultObj
}

/**
 * Merge translation messages.
 * @param messages
 * @param context
 */
function mergeTranslationMessages(
  messages: Record<string, Record<string, string>>,
  context: Context
) {
  Object.entries(messages).forEach(([locale, messages]) => {
    const nestedMessages = getLocaleMessagesObject(messages)
    context.app.i18n.mergeLocaleMessage(locale, nestedMessages)
  })
}

/**
 * Merge runtime config
 * @param config
 * @param context
 */
function mergeRuntimeConfig(config: Platform['config'], context: Context) {
  config = { ...defaultPlatformConfig, ...config } // apply defaults
  const projectConfigMapping = context.$config.platformConfigMapping ?? {}
  const configMapping: Record<string, string | string[]> = {
    ...defaultPlatformKeyMapping,
    ...projectConfigMapping,
  }
  Object.entries(configMapping).forEach(([key, runtimeKey]) => {
    if (key in config) {
      if (Array.isArray(runtimeKey)) {
        for (const k of runtimeKey) {
          context.$config[k] = config[key]
        }
      } else {
        context.$config[runtimeKey] = config[key]
      }
    }
  })
}

/**
 * Initialize platform details.
 * @param context
 */
export async function initPlatform(context: Context) {
  let platform = context.store.getters['platform/platform'](
    context.$jsonApiService
  ) as Platform | null
  if (!context.store.getters['platform/isInitialized'] && process.server) {
    // parse request
    const domainDetails = getDomainDetails(context.req)
    const defaultBaseUrl = String(process.env.BASE_URL)
    const clientSelect = String(context.query.secret)

    // check if static id is given
    let platformId: string | undefined
    if (context.$config.activePlatformId) {
      platformId = String(context.$config.activePlatformId)
    } else if (context.query.p) {
      platformId = String(context.query.p)
    }

    // explicit platform specified
    if (platformId) {
      try {
        platform = await Platform.api(context.$jsonApiService).find(platformId)
      } catch (e) {
        return handleError(context, e)
      }
    }

    // different domain
    else if (!defaultBaseUrl.startsWith(domainDetails.origin)) {
      console.log(
        `Platform domain detected: ${domainDetails.origin}`,
        process.env.BASE_URL
      )
      // request platform by domain
      try {
        platform = await Platform.fromOrigin(
          domainDetails.origin,
          context.$jsonApiService
        )
      } catch (e) {
        if ((<AxiosError>e).response?.status === 404) {
          console.warn(`Origin ${domainDetails.origin} is not allowed.`)
        } else {
          console.log(e)
          return handleError(context, e)
        }
      }
    }

    if (platform) {
      const messages = platform.config.clientMessages ?? null
      // init platform
      context.store.commit('platform/init', {
        platformId: platform.id,
        host: domainDetails.host,
        clientSecret: clientSelect,
        styles: platform.config.styles ?? null,
        messages,
      })
    }
    // regular app
    else {
      // set platform initialized to prevent further checks
      context.store.commit('platform/init')
    }
  }

  if (platform) {
    context.$config.activePlatformId = platform.id
    const messages = platform.config.clientMessages ?? null
    // merge custom messages
    if (messages) {
      mergeTranslationMessages(messages, context)
    }

    mergeRuntimeConfig(platform.config, context)
  }
  // deref orm model in ssr
  if (process.server) {
    platform?.$destruct()
  }
}
