import { Attr, BelongsTo, HasMany } from '@anny.co/vue-jsonapi-orm'
import { ApiResource } from '../ApiResource'
import { ResourceCategory } from '@/shared/jsonapi-orm/bookingbuddy/ResourceCategory'
import { ResourceProperty } from './ResourceProperty'

export enum PropertyType {
  BOOLEAN = 'boolean',
  NUMBER = 'number',
  STRING = 'string',
}

export class Property extends ApiResource {
  static jsonApiType = 'properties'
  @Attr() label: string
  @Attr({}) labelI18n?: Record<string, string> | null
  @Attr() icon: string
  @Attr(PropertyType.STRING) valueType: PropertyType
  @Attr(true) filterable: boolean
  @Attr(true) private: boolean

  @BelongsTo() category?: ResourceCategory | null
}
