// eslint-disable-next-line no-unused-vars
import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
import { cancelSource } from '../services/axiosClientFactory'
import { Context } from '@nuxt/types'
import { DefaultNuxtLoading } from '@nuxt/types/app'
import { getDomainDetails } from '@/shared/utils/multi-platform/parseIncomingRequest'
import { get } from 'lodash'

// TODO: move to context
let currentRequests = 0

/**
 * A noop loading interface for when $nuxt is not yet ready
 * @type {{fail: noopLoading.fail, set: noopLoading.set, start: noopLoading.start, finish: noopLoading.finish}}
 */
/* eslint-disable @typescript-eslint/no-empty-function */
const noopLoading = {
  finish: () => {},
  start: () => {},
  fail: () => {},
  set: () => {},
}
const $loading: () => Pick<
  DefaultNuxtLoading,
  'start' | 'finish' | 'fail' | 'set'
> = () =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  !process.server && window?.$nuxt?.$loading?.start
    ? window?.$nuxt?.$loading
    : noopLoading

/**
 * handle upload/download progress
 */
const onProgress = (e: ProgressEvent) => {
  if (!currentRequests) {
    return
  }
  const progress = (e.loaded * 100) / (e.total * currentRequests)
  $loading().set(Math.min(100, progress))
}

/**
 * Add progress bar to requests
 * @param instance {AxiosInstance}
 */
export function addProgressBarInterceptor(instance: AxiosInstance) {
  instance.defaults.onUploadProgress = onProgress
  instance.defaults.onDownloadProgress = onProgress
  // handle request
  instance.interceptors.request.use(function (request: AxiosRequestConfig) {
    currentRequests++
    $loading().start()
    return request
  })
  // handle response
  instance.interceptors.response.use(
    function (response: AxiosResponse) {
      currentRequests--
      if (currentRequests <= 0) {
        currentRequests = 0
        $loading().finish()
      }
      return response
    },
    function (error) {
      currentRequests--
      $loading().fail()
      $loading().finish()
      return Promise.reject(error)
    }
  )
}

/**
 * Handle json api errors
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addJsonApiErrorInterceptor(
  instance: AxiosInstance,
  ctx: Context
) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      // handle only on client side
      if (!process.server && error && error.response) {
        const response = error.response
        const config = error.config
        const withoutShowingError = get(config, 'params.without_showing_error')
        const data = response.data
        const status = response.status
        // push notifications for alerts
        if (data && data.errors) {
          if (status === 422) {
            // validation error
            let html = `<b>${ctx.app.i18n.t(
              'common.errors.422.message'
            )}</b><ul>`
            const errors: { title?: string; detail?: string }[] | undefined =
              error.response.data.errors
            if (errors && Array.isArray(errors)) {
              errors.forEach((error) => {
                html += `<li>${error.detail}</li>`
              })
            }
            html += '</ul>'
            if (!withoutShowingError) {
              ctx.store
                .dispatch('ux/pushNotification', {
                  type: 'error',
                  html: html,
                })
                .then()
            }
          } else if (![401, 403].includes(status)) {
            // handle 401 and 403 in dedicated interceptor
            // other errors
            data.errors.forEach(
              (error: { title?: string; detail?: string }) => {
                let html = ''
                if (error.title) html = `<strong>${error.title}</strong>`
                if (error.detail) html += `<br>${error.detail}`
                if (html && !withoutShowingError) {
                  ctx.store.dispatch('ux/pushNotification', {
                    type: 'error',
                    html: html,
                  })
                }
              }
            )
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

export function addApiErrorInterceptor(instance: AxiosInstance, ctx: Context) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error && error.response) {
        const response = error.response
        const data = response.data
        const status = response.status
        // push notifications for alerts
        if (data && data.errors && data.errors.length > 0) {
          if (status === 422) {
            // validation error
            let html = `<b>${ctx.app.i18n.t(
              'common.errors.422.message'
            )}</b><ul>`
            const errors: Record<string, string[]> = error.response.data.errors
            Object.values(errors).map((fieldErrors: string[]) => {
              fieldErrors.forEach((error: string) => {
                html += `<li>${error}</li>`
              })
            })
            html += '</ul>'
            ctx.store
              .dispatch('ux/pushNotification', {
                type: 'error',
                html: html,
              })
              .then()
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Catch 403 unauthorized error
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addUnauthorizedInterceptor(
  instance: AxiosInstance,
  ctx: Context
) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error?.response?.status === 403) {
        ctx.store.dispatch('ux/pushNotification', {
          type: 'error',
          id: 'unauthorized',
          html: ctx.app.i18n.t('common.errors.403.message'),
        })
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Catch 404 not found error
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addNotFoundInterceptor(instance: AxiosInstance, ctx: Context) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error?.response?.status === 404) {
        ctx.store.dispatch('ux/pushNotification', {
          type: 'error',
          id: 'resource_not_found',
          html: ctx.app.i18n.t('common.errors.404.message'),
        })
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Catch network error
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addNetworkErrorInterceptor(
  instance: AxiosInstance,
  ctx: Context
) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error && error.response) {
        // service unavailable error
        if (error.response.status === 503) {
          ctx.store
            .dispatch('ux/pushNotification', {
              type: 'error',
              id: 'server_maintenance',
              html: ctx.app.i18n.t('common.errors.503.message'),
            })
            .then()
        }
      } else if (error && error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        ctx.store
          .dispatch('ux/pushNotification', {
            type: 'error',
            id: 'network_error',
            html: ctx.app.i18n.t('common.errors.network.message'),
          })
          .then()
      } else if (error) {
        // Something happened in setting up the request that triggered an Error
        ctx.store
          .dispatch('ux/pushNotification', {
            type: 'error',
            id: 'connection_error',
            html: ctx.app.i18n.t('common.errors.connection.message'),
          })
          .then()
      } else {
        // stop propagation of empty error
        return
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Log every request on server side
 * @param instance {AxiosInstance}
 */
export function addServerLogInterceptor(instance: AxiosInstance) {
  // request logging
  instance.interceptors.request.use(
    function (request: AxiosRequestConfig) {
      if (process.server)
        console.log('ServerLog request: ' + request.method + ' ' + request.url)
      return request
    },
    (error: AxiosError) => {
      if (process.server)
        console.log(
          'ServerLog request error: ' + error.message + ' for ' + error.request
            ? error.request.url
            : 'N/A'
        )
      return Promise.reject(error)
    }
  )
  // response logging
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (process.server)
        console.log(
          'ServerLog response: ' + response.status + ' for ' + response.request
            ? response.request.url
            : 'N/A'
        )
      return response
    },
    (error: AxiosError) => {
      if (process.server)
        console.log(
          'ServerLog response error: ' + error.message + ' for ' + error.request
            ? error.request.url
            : 'N/A'
        )
      return Promise.reject(error)
    }
  )
}

/**
 * Set accept language header
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addAcceptLanguageInterceptor(
  instance: AxiosInstance,
  ctx: Context
) {
  instance.interceptors.request.use(
    function (config) {
      config.headers['Accept-Language'] = ctx.app.i18n.locale
      return config
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )
}

/**
 * Add base url as origin header if not set otherwise
 * @param instance
 * @param ctx
 */
export function addActiveOrgInterceptor(instance: AxiosInstance, ctx: Context) {
  instance.interceptors.request.use(
    function (config) {
      const activeOrganizationId = ctx.$authService.organizationId

      if (activeOrganizationId && !config.params?.o) {
        // set o param for active organization id
        config.params = {
          ...config.params,
          o: activeOrganizationId,
        }
      }

      return config
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )
}

/**
 * Add base url as origin header if not set otherwise
 * @param instance
 * @param ctx
 */
export function addOriginInterceptor(instance: AxiosInstance, ctx: Context) {
  instance.interceptors.request.use(
    function (config) {
      if (
        process.server &&
        !config.headers['Origin'] &&
        ctx.req !== undefined
      ) {
        const domainDetails = getDomainDetails(ctx.req)
        // setting runtimeConfig.baseUrl will prevent the inital domain
        // to be detected when no platform is set
        config.headers['Origin'] = domainDetails.origin
      }
      return config
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )
}

/**
 * Set access token on every request
 * @param instance {AxiosInstance}
 * @param isOptional
 * @param ctx
 */
export function addAccessTokenInterceptor(
  instance: AxiosInstance,
  isOptional = false,
  ctx: Context
) {
  // request interceptor
  instance.interceptors.request.use(
    async function (config: AxiosRequestConfig) {
      // Check if this interceptor is conditional and we dont have an access token
      // If its true -> skip this interceptor
      if (isOptional && !ctx.$authService.hasAccessToken) {
        return config
      }

      const accessToken = await ctx.$authService.getAccessToken()

      if (accessToken) {
        config.headers.Authorization = 'Bearer ' + accessToken

        return config
      } else {
        // do request to get 401 error
        if (ctx?.$annyDebugRequestId) {
          console.log(
            ctx.$annyDebugRequestId,
            'performing auth request without token',
            config.url
          )
        }
        return config
      }
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )

  // response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
      if (error?.response?.status === 401) {
        if (ctx?.$annyDebugRequestId) {
          console.log(
            ctx.$annyDebugRequestId,
            'addAccessTokenInterceptor: status = 401'
          )
        }
        const accessToken = await ctx.$authService.getAccessToken(true)

        // If it is conditional and there was no access token this will fail
        if (isOptional && !accessToken) {
          return Promise.reject(error)
        }

        // check if access token has changed and retry request
        if (error.config.headers['Authorization'] !== 'Bearer ' + accessToken) {
          if (ctx?.$annyDebugRequestId) {
            console.log(
              ctx.$annyDebugRequestId,
              'addAccessTokenInterceptor: access token already updated, retry',
              error.config.url
            )
          }
          error.config.headers['Authorization'] = 'Bearer ' + accessToken
          return instance.request(error.config)
        } else {
          try {
            const accessToken = await ctx.$authService.getAccessToken()

            if (!accessToken) {
              if (ctx?.$annyDebugRequestId) {
                console.log(
                  ctx.$annyDebugRequestId,
                  'addAccessTokenInterceptor error: access token is empty'
                )
              }
              return Promise.reject(error)
            }

            error.config.headers['Authorization'] = 'Bearer ' + accessToken
            // retry request
            if (ctx?.$annyDebugRequestId) {
              console.log(
                ctx.$annyDebugRequestId,
                'addAccessTokenInterceptor: retry request',
                error.config.url
              )
            }
            return instance.request(error.config)
          } catch (e) {
            if (ctx?.$annyDebugRequestId) {
              console.log(
                ctx.$annyDebugRequestId,
                'addAccessTokenInterceptor error: ' + e ? e.message : ''
              )
            }
            return Promise.reject(e)
          }
        }
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Redirect to login on 401 response code
 * this will only be invoked after trying to refresh the token
 *
 * @param instance {AxiosInstance}
 * @param ctx
 */
export function addUnauthenticatedInterceptor(
  instance: AxiosInstance,
  ctx: Context
) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response
    },
    (error: AxiosError) => {
      if (error && error.response) {
        // invalid login
        const status = error.response.status
        if (status === 401) {
          if (ctx.$annyDebugRequestId) {
            console.log(
              ctx.$annyDebugRequestId,
              'addUnauthenticatedInterceptor: status = 401'
            )
          }
          // cancelActiveAuthRequests() -> will also cancel refresh request
          if (!ctx.$authService.redirectedToLogin) {
            ctx.$authService.removeTokens()
            if (process.client && !ctx.$authService.redirectedToLogin) {
              return ctx.$authService.redirectToLogin()
            } else {
              return Promise.reject(error)
            }
          }
        } else if (status === 419) {
          // session expired
          ctx.store.dispatch('ux/pushNotification', {
            type: 'error',
            id: 'session_expired',
            html: ctx.app.i18n.t('common.errors.419.message'),
          })
          if (location) location.reload()
        }
      }
      return Promise.reject(error)
    }
  )
}

/**
 * Allow cancellation of request by setting a cancelToken
 * @link https://github.com/axios/axios#cancellation
 * @param instance {AxiosInstance}
 */
export function addCancelInterceptor(instance: AxiosInstance) {
  // request interceptor
  instance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      config.cancelToken = cancelSource.token
      return config
    },
    (error: AxiosError) => {
      // handle canceled request
      if (Axios.isCancel(error)) {
        console.log('Stop canceled request')
      } else {
        return Promise.reject(error)
      }
    }
  )

  // response interceptor
  instance.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      // handle canceled request
      if (Axios.isCancel(error)) {
        console.log('Stop canceled response')
      } else {
        return Promise.reject(error)
      }
    }
  )
}

export function addStrapiResponseInterceptor(instance: AxiosInstance) {
  instance.interceptors.response.use(
    (response: AxiosResponse) => {
      let data = response.data
      if (Array.isArray(data)) {
        // find request
        data = data.map((e) => {
          ;['created_by', 'updated_by', 'created_at', 'updated_at'].forEach(
            (f) => {
              delete e[f]
            }
          )
          return e
        })
      } else {
        ;['created_by', 'updated_by', 'created_at', 'updated_at'].forEach(
          (f) => {
            delete data[f]
          }
        )
      }
      return {
        ...response,
        data,
      }
    },
    (error: AxiosError) => {
      return Promise.reject(error)
    }
  )
}
