import { getField, updateField } from 'vuex-map-fields'
import { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex'
import { Platform } from '@/shared/jsonapi-orm/bookingbuddy/Platform'
import { JsonApiService } from '@anny.co/vue-jsonapi-orm'

interface PlatformState {
  initialized: boolean
  host: string | null
  platformId: string | null
  clientSecret: string | null
  styles: string | null
  messages: string | null
}

interface PlatformStore<S> extends StoreOptions<S> {
  state: () => S
}

type PlatformStoreOptions<S> = {
  state: S
  getters?: GetterTree<PlatformState & S, any>
  mutations?: MutationTree<PlatformState & S>
  actions?: ActionTree<PlatformState & S, any>
}

export default function <S>(options: PlatformStoreOptions<S>) {
  const getDefaultState = (): PlatformState & S => ({
    initialized: false,
    host: null,
    platformId: null,
    clientSecret: null,
    messages: null,
    styles: null,
    ...options.state,
  })
  const platformStore: PlatformStore<PlatformState & S> = {
    state: getDefaultState,
    // getters
    getters: {
      getField,
      getPlatformId: (state): string | null => {
        return state.platformId
      },
      /**
       * Check if platform was initialized.
       * @param state
       */
      isInitialized: (state): boolean => {
        return state.initialized
      },
      /**
       * Get platform
       */
      platform:
        (state) =>
        (apiService: JsonApiService): Platform | null => {
          try {
            return state.platformId
              ? Platform.fromId(state.platformId, apiService)
              : null
          } catch {
            return null
          }
        },
      ...options.getters,
    },
    // mutations
    mutations: {
      updateField,
      /**
       * Initialize platform
       * @param state
       * @param options
       */
      init: (state, options?: Omit<PlatformState, 'initialized'>) => {
        state.initialized = true
        if (options) {
          state.host = options.host
          state.platformId = options.platformId
          state.clientSecret = options.clientSecret
          state.styles = options.styles
          state.messages = options.messages
        }
      },
      ...options.mutations,
    },
    // actions
    actions: {
      ...options.actions,
    },
  }
  return platformStore
}
