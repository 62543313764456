
import Vue, { PropOptions } from 'vue'
import Modal from '@/shared/components/modals/Modal.vue'
import { isEqual } from 'lodash'
import { ApiResourceBase } from '@anny.co/vue-jsonapi-orm'
import { Validation } from 'vuelidate'
import { isValid, touchInvalid } from '@/shared/utils/VuelidateHelper'
import Spinner from '@/shared/components/partials/Spinner.vue'

export default Vue.extend({
  name: 'OrmLeaveWarning',
  components: {
    Modal,
    Spinner,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    } as PropOptions<ApiResourceBase>,
    validation: {
      type: Object,
      required: false,
      default: null,
    } as PropOptions<Validation>,
    /**
     * Optionally pass custom callback for next
     */
    next: {
      type: Function,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      default: () => {
        console.warn('no next callback defined.')
      },
    } as PropOptions<() => void>,
    /**
     * Optionally define leaving state from outside.
     */
    isLeaving: {
      type: Boolean,
      default: false,
    } as PropOptions<boolean>,
    watchRoute: {
      type: Boolean,
      default: true,
    } as PropOptions<boolean>,
  },
  data() {
    return {
      showWarning: this.isLeaving,
      nextFn: this.next,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      unwatchRoute: (() => {}) as () => void,
    }
  },
  watch: {
    isLeaving(newValue: boolean) {
      if (newValue) {
        this.checkForUnsavedChanges(this.next)
      }
    },
  },
  mounted() {
    if (this.watchRoute) {
      // browser warning on close
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      let vm = this
      window.onbeforeunload = () => {
        if (vm.resource.$anyDirty) {
          return this.$t('common.components.alerts.unsavedChanges.message')
        }
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.unwatchRoute = this.$router.beforeEach((to, from, next) => {
        let firstMatchedTo = to.matched?.[0].path ?? ''
        let firstMatchedFrom = from.matched?.[0].path ?? ''
        if (firstMatchedFrom === firstMatchedTo) {
          if (!isEqual(from.params, to.params)) {
            this.checkForUnsavedChanges(next)
          } else {
            next()
          }
        } else {
          this.checkForUnsavedChanges(next)
        }
      })
    }
  },
  beforeDestroy() {
    window.onbeforeunload = null
    this.unwatchRoute()
  },
  methods: {
    checkForUnsavedChanges(next: () => void) {
      // check if all changes are saved
      if (this.resource.$anyDirty && !this.resource.$markedForDeletion) {
        this.nextFn = next
        this.showWarning = true
      } else {
        next()
      }
    },
    discardChanges() {
      this.resource.discardChanges()
      this.close()
      this.nextFn()
      this.nextFn = this.next
    },
    async saveChanges() {
      if (this.validation && !(await isValid(this, this.validation))) {
        touchInvalid(this.validation)
        await this.$store.dispatch('ux/pushNotification', {
          type: 'error',
          html: this.$t('common.errors.invalidInput'),
        })
        await this.close()
        return
      }
      try {
        const isNew = !this.resource.persisted
        await this.resource.save()
        if (isNew) {
          this.$emit('created', this.resource)
        } else {
          this.$emit('updated', this.resource)
        }
        this.$emit('saved', this.resource)
        await this.close()
        this.nextFn()
        this.nextFn = this.next
      } catch (e) {
        console.log(e)
      }
    },
    cancel() {
      this.close()
    },
    async close() {
      this.showWarning = false
      await this.$nextTick()
      this.$emit('close')
    },
  },
})
